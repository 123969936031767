<template>
  <van-popup v-model="show" @close="close" closeable close-icon-position="top-right" position="bottom" :style="{ height: '100%', 'padding-top': '45px' }">
    <div class="user">{{ $t('view.report.login.text017') }}</div>
    <van-field left-icon="search" v-model.trim="searchKey" :placeholder="$t('view.report.login.text018')" @input="handleSearch"></van-field>

    <div v-for="(item, index) in accountList1" :key="index + Math.random()" @click="handleChange(item)" class="select_option">
      <div class="option_left">
        <van-checkbox shape="square" :value="selectedList.includes(item)" icon-size="18px"></van-checkbox>
        <div class="float_left">{{ item.userName[0] }}</div>
        <div class="float_right">
          <div class="user_name">{{ item.userName }}</div>
          <div class="user_type">{{ item.email }}</div>
        </div>
      </div>
      <div class="option_right">{{ item.userType | filterUserType }}</div>
    </div>
    <div class="selected_foot">
      <div>
        {{ $t('view.report.login.text019', [(selectedList && selectedList.length) || 0]) }}
        <!-- 已选择{{ selectedList && selectedList.length }}人 -->
      </div>
      <div class="btn" @click="toRecommend">{{ $t('view.report.login.text020') }}</div>
    </div>
    <van-dialog class="remark_dialog" v-model="showDialog" :show-confirm-button="false">
      <div class="title">
        <div>{{ $t('view.report.login.text021') }}</div>
        <van-icon @click="showDialog = false" name="cross" />
      </div>
      <van-field input-class="input_class" v-model="remarkContent" rows="4" autosize type="textarea" maxlength="500" :placeholder="$t('view.report.login.text022')" show-word-limit />
      <div class="confirm_btn">
        <van-button type="info" size="small" round @click="confirm">{{ $t('view.report.login.text023') }}</van-button>
      </div>
    </van-dialog>
  </van-popup>
</template>
<script>
import { listUnfrozenUser } from '@/api/candidate.js'
import i18n from '../../utils/i18n'
export default {
  data() {
    return {
      show: false,
      accountList: '',
      searchKey: '',
      accountList1: '',
      selectedList: [],
      showDialog: false,
      remarkContent: '',
    }
  },
  props: { showList: { default: false } },
  watch: {
    showList: {
      immediate: true,
      handler() {
        this.show = this.showList
        if (this.show) {
          this.listUnfrozenUser()
        }
      },
    },
  },
  methods: {
    handleSearch() {
      console.log(this.searchKey)
      const value = this.searchKey
      if (this.searchKey) {
        this.accountList1 = this.accountList.filter(item => {
          console.log(item)
          if (
            item.userName.indexOf(value) !== -1 ||
            item.userName.toUpperCase().indexOf(value.toUpperCase()) !== -1 ||
            (item.email && item.email.indexOf(value) !== -1) ||
            (item.email && item.email.toUpperCase().indexOf(value.toUpperCase()) !== -1)
          ) {
            console.log(item.userName, item.email, value)
            return true
          }
        })
      } else {
        this.accountList1 = this.accountList
      }
    },
    handleChange(item) {
      // this.selectItem = item
      if (this.selectedList.includes(item)) {
        const index = this.selectedList.findIndex(i => {
          return i.userId === item.userId
        })
        this.selectedList.splice(index, 1)
      } else {
        this.selectedList.push(item)
      }
      // return false
      // if (item.email) {
      //   this.$emit('change', item)
      // } else {
      //   this.$toast({ type: 'fail', message: '该账号没有邮箱' })
      // }
    },
    confirm() {
      this.$emit('change', { selectedList: this.selectedList, remarkContent: this.remarkContent })
      this.showDialog = false
    },
    listUnfrozenUser() {
      listUnfrozenUser({ positionIdList: this.positionId }).then(res => {
        this.accountList = res.data.map(item => {
          return { ...item }
        })
        this.accountList1 = res.data.map(item => {
          return { ...item }
        })
      })
    },
    close() {
      console.log('cclose')
      this.$emit('close')
    },
    toRecommend() {
      if (this.selectedList && this.selectedList.length) {
        this.showDialog = true
      } else {
        this.$toast({ type: 'info', message: i18n.t('view.report.login.text024') })
      }
    },
  },
  computed: {
    positionId() {
      return this.$store.state.positionId
    },
  },
  filters: {
    filterUserType(value) {
      if (value === 'superAdmin') {
        return i18n.t('view.report.login.text025')
      } else if (value === 'admin') {
        return i18n.t('view.report.login.text026')
      } else if (value === 'general') {
        return i18n.t('view.report.login.text027')
      } else if (value === 'interviewer') {
        return i18n.t('view.report.login.text028')
      } else if (value === 'read') {
        return i18n.t('view.report.login.text029')
      } else if (value === 'freeze') {
        return i18n.t('view.report.login.text030')
      } else if (value === 'helpUser') {
        return i18n.t('view.report.login.text031')
      } else if (value === 'publishUser') {
        return i18n.t('view.report.login.text032')
      } else {
        return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.user {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  padding-left: 15px;
}
.select_option {
  display: flex;
  justify-content: space-between;
  // margin-top: 10px;
  min-height: 40px;
  width: 100%;
  padding: 15px 0;
  padding-left: 10px;
  border-bottom: 1px solid #dddddd;
  .option_left {
    display: flex;
    align-items: center;

    .float_left {
      height: 40px;
      width: 40px;
      /* padding: 5px; */
      border: 1px solid;
      border-radius: 45px;
      text-align: center;
      background: #1890ff;
      /* align-items: center; */
      line-height: 40px;
      //   margin-top: 20px;
      color: #ffffff;
      font-size: 14px;
      margin-left: 10px;
    }
    .float_right {
      margin-left: 10px;
      // max-width: 130px;
      //   margin-top: 10px;
      div {
        line-height: 18px;
        margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .user_name {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
      }
      .user_type {
        font-size: 12px;
        font-weight: 400;
        color: #aaaaaa;
      }
    }
  }
  .option_right {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #aaaaaa;
    line-height: 38px;
    margin-right: 20px;
  }
}
.selected_foot {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
  background: #ffffff;
  z-index: 1;
  padding: 0 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    border: 1px solid #1890ff;
    color: #1890ff;
    padding: 10px 10px;
    border-radius: 30px;
    line-height: 14px;
  }
}
.remark_dialog {
  padding: 10px 15px;
  .van-dialog__content {
    .title {
      // color: red;
      margin-bottom: 10px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .van-cell {
      border: 1px solid #dddddd;
      border-radius: 5px;
    }
    .confirm_btn {
      margin-top: 10px;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
</style>

<template>
  <div class="ai-home">
    <Header @closeVideo="closeVideo" @openVideo="openVideo" ref="menu"></Header>
    <div class="header">
      <div class="banner-top" v-if="false">
        <div class="banner-top_info">
          <img class="img-tips" src="@/assets/images/five-years/5-tips.svg" />
          不同版本均<span class="info-zs">赠送</span>50-200次数量不等的L2级别AI面试，在线考试系统，在线编程考试，认知能力测试，职业性格测试，心理健康测试等服务。
        </div>
        <div class="view-detail" @click="toDetail">查看详情</div>
      </div>
      <!-- <img class="word-logo" src="~@/assets/images/wordLogo.png" /> -->
      <div class="word-txt">
        <div>得贤L5级别AIGC面试官</div>
        <div>基于AIGC的可追问、千人千问和多轮对话的L5级别AI面试官</div>
      </div>
      <div class="word-line"></div>
      <div class="video-player-logo" v-show="false">
        <video-player ref="videoPlayer2" :playsinline="true" class="video-player-banner" :options="playerOption2"></video-player>
        <div class="unmute_btn" @click="unmute2" type="primary">
          <img :src="muted2 ? require('@/assets/images/unmuted.png') : require('@/assets/images/muted.png')" />
          <div>{{ muted2 ? '静音' : '解除静音' }}</div>
        </div>
      </div>
      <EditionOverview v-if="false" />
      <div class="tip-desc">帮助组织提升招聘效率和人均效率打造独特的人才竞争优势！</div>
      <div class="video-desc">
        <div class="ai-videos-list">
          <div v-for="(v, i) in aiVideoList" :key="i" @click="changeAiVideo(v)" class="ai-videos-item">
            <img class="content-png" :src="v.imgUrl" />
            <img class="player-png" src="@/assets/images/ai-videos/video-player2.png" />
          </div>
        </div>
      </div>
      <div class="l4-desc">
        <div class="tip-one">Hi，我们是</div>
        <div class="tip-two">L5级别AIGC面试官</div>
      </div>
    </div>
    <div class="description">
      AI得贤招聘官是一款人工智能时代的招聘和面试SaaS平台，拥有基于先进的篇章级语义识别算法和多模态算法构建的人力资源行业超脑-近屿超脑。AI得贤招聘官致力于做更懂HR的智慧搭档，通过AI、RPA、BI等先进的技术，不仅助力企业实现革命性的招聘效率提升，还助力企业精准筛选优质候选人，打造独特的人才竞争优势！
    </div>
    <section class="section1">
      <h3>行业痛点</h3>
      <div class="mood">
        <div class="mood-btn">情绪</div>
        <div class="mood-btn">疲劳</div>
        <div class="mood-btn">偏见</div>
        <div class="mood-btn">歧视</div>
        <div class="mood-btn">人类面试官</div>
        <div class="line1"></div>
        <div class="line2"></div>
      </div>
      <div class="arrow"><img src="./images/arrow_bottom.png" alt="" /></div>
      <div class="card">
        <div class="card-left">
          <div class="card-title">日常面试</div>
          <p>进入面试环节 20人【1名面试官资源】</p>
          <img src="./images/interview1.png" alt="" />
        </div>
        <div class="card-right">
          <div class="expression">
            <div class="expression-left">
              <div class="expression-top">1名面试官 × 面试10小时</div>
              <div class="expression-bottom">20名候选</div>
              <div class="expression-line"></div>
            </div>
            <div class="expression-equal">=</div>
            <div class="expression-right">1名面试官面试2名候选人/小时</div>
          </div>
          <div class="expression-result">=平均 <i>30</i>分钟/候选人</div>
        </div>
      </div>
      <div class="card">
        <div class="card-left">
          <div class="card-title">校招场景</div>
          <p>
            收到简历10,000份 → 淘汰 9,000人→ 进入<br />
            面试环节1,000人【10名面试官资源】<br /><br />
            收到简历10,000份 → 简历筛选5000份→ 笔试<br />
            筛选3000人→ 群面筛选1000人→ 进入面试环节
          </p>
          <img src="./images/interview2.png" alt="" />
        </div>
        <div class="card-right">
          <div class="expression">
            <div class="expression-left">
              <div class="expression-top">10名面试官 × 面试10小时</div>
              <div class="expression-bottom">1000名候选</div>
              <div class="expression-line"></div>
            </div>
            <div class="expression-equal">=</div>
            <div class="expression-right">1名面试官面试10名候选人/小时</div>
          </div>
          <div class="expression-result">=平均 <i>6</i>分钟/候选人</div>
        </div>
      </div>
      <div class="title-label">
        <div class="label-btn">AI面试优势</div>
      </div>
      <div class="card">
        <div class="card-left">
          <div class="card-title">
            我们为什么不认真面试10000名候选人<br />
            从中挑选最合适的人呢?
          </div>
          <p>
            被淘汰的9,000位候选人真的应该被淘汰吗?我们是否在盲人摸象?<br />
            进入面试环节的1000位候选人对面试的体验满意吗？<br />
            人类面试官真的能在6分钟内认真执行完结构化面试吗？
          </p>
        </div>
        <div class="card-right">
          <div class="txt-content">
            <P class="txt-title">大批量面试</P>
            <p class="txt-detail">L5级AI面试官能够独立自动面试10,000位候选人并将候选人分为五类:</p>
            <ul>
              <tr>
                <th>过于优秀</th>
                <th>优秀</th>
                <th>合格</th>
                <th>一般</th>
                <th>不合格</th>
              </tr>
              <tr>
                <td v-for="(item, index) in rateArray" :key="index">
                  <img class="star-five" v-for="i in item" :key="i" src="./images/star.png" />
                </td>
              </tr>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="content">
        <h3>AI+RPA 招聘综合解决方案</h3>
        <div class="solution-process">
          <div class="box1">
            <div class="left">
              <div class="title">HR咨询服务</div>
              <ul>
                <li>组织诊断</li>
                <li>AI面试<br />流程再造</li>
                <li>AI胜任力<br />建模</li>
              </ul>
            </div>
            <div class="right">
              <span class="line1"></span>
              <span class="line2"></span>
            </div>
          </div>
          <div class="box2">
            <div class="box2-item" v-for="(item, index) in RPAData" :key="index">
              <div class="title">
                <span v-html="item.title"></span>
              </div>
              <div class="box2-item-content">
                <div class="option" v-for="(val, i) in item.option" :key="i">
                  <span v-html="val"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="icons">
            <img src="./images/arrowBottom.png" alt="" />
            <img src="./images/arrowBottom.png" alt="" />
            <img src="./images/arrowBottom.png" alt="" />
            <img src="./images/arrowBottom.png" alt="" />
          </div>
          <div class="box3">
            <div class="companyItem">
              <img src="./images/RPA_icon1.png" alt="" />
              <p>Success<br />Factors</p>
            </div>
            <div class="companyItem">
              <img src="./images/RPA_icon2.png" alt="" />
              <p>AI得贤招聘官</p>
            </div>
            <div class="companyItem">
              <img src="./images/RPA_icon3.png" alt="" />
              <p>钉钉<br />智能招聘</p>
            </div>
            <div class="companyItem">
              <img src="./images/RPA_icon4.png" alt="" />
              <p>其他<br />系统厂商</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section3">
      <div class="left-part">
        <div class="tip">核心技术</div>
        <div class="content">
          AI得贤招聘官在组织诊断、AI面试流程再造和胜任力建模的基础上，通过结构化行为或案例面试方案，同时结合工业心理学，利用自然语言处理技术进行篇章级别的语义分析，并通过计算机视觉和听觉技术，对人类表情和声音进行分析，帮助企业对候选人进行胜任力预测和评估。
        </div>
      </div>
      <div class="right-part" @click="changeAiVideo(technology)">
        <img class="bg" :src="require('@/assets/images/coreTechnology.png')" />
        <img class="player-png" src="./images/technology_play.png" />
      </div>
    </section>
    <section class="section4">
      <h3>客户证言</h3>
      <div class="testimony-box">
        <el-carousel height="100%" indicator-position="none" arrow="never" :interval="5000">
          <el-carousel-item v-for="(item, index) in testimonyData" :key="index" class="testimony-box-item">
            <p>{{ item.detail }}</p>
            <div class="footer">
              <div class="name">{{ item.autor }}</div>
              <img :src="item.imgUrl" alt="" />
              <div class="line"></div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="image_bg">
        <img src="./images/testimony.png" alt="" />
      </div>
    </section>
    <section class="section5">
      <div class="c-title">他们都在用AI得贤招聘官</div>
      <div class="customer-wrapper">
        <div v-for="(logo_p,index_p) in logsArr" :key="index_p" class="item-box">
          <div v-for="(logo_c,index_c) in logo_p" :key="index_c" class="item">
            <!-- <img src="~@/assets/images/customer/samsung.png" /> -->
            <img :src="logo_c" />
          </div>
          <!-- <div class="item">
            <img src="~@/assets/images/customer/zsyh.png" />
          </div>
          <div class="item">
            <img src="~@/assets/images/customer/zhongyuan.png" />
          </div> -->
        </div>
        <!-- <div class="item-box">
          <div class="item">
            <img src="~@/assets/images/customer/svn.png" />
          </div>
          <div class="item">
            <img src="@/assets/images/customer/bgi.png" />
          </div>
          <div class="item">
            <img src="~@/assets/images/customer/koule.png" />
          </div>
        </div>
        <div class="item-box">
          <div class="item">
            <img src="~@/assets/images/customer/acs.png" />
          </div>
          <div class="item">
            <img src="~@/assets/images/customer/yjl.png" />
          </div>
          <div class="item">
            <img src="~@/assets/images/customer/beijing.png" />
          </div>
        </div>
        <div class="item-box">
          <div class="item">
            <img src="~@/assets/images/customer/dianwang.png" />
          </div>
          <div class="item">
            <img src="~@/assets/images/customer/pfBlank.png" />
          </div>
          <div class="item">
            <img src="~@/assets/images/customer/TCT.png" />
          </div>
        </div> -->
      </div>
    </section>
    <section class="section6">
      <div class="item-box" style="flex: 1">
        <div class="iso-img"><img src="./images/iso.png" /></div>
        <div class="iso-text">ISO27001认证</div>
        <div class="split"></div>
      </div>
      <!-- <div class="item-box" style="flex: 1">
        <p>信息系统安全等级保护</p>
        <p>备案证明（三级）</p>
        <p>31010499069-20001</p>
        <div class="split"></div>
      </div> -->
      <div class="item-box" style="flex: 1">
        <div class="iso-img"><img src="./images/anab.png" /></div>
        <div class="iso-text">ANAB认证</div>
      </div>
    </section>
    <section class="footer">
      <div class="qrcode-box">
        <div class="qrcode">
          <div class="qrcode-img">
            <img src="~@/assets/images/qrcode2.png" />
          </div>
          <div class="qrcode-text">
            <div>扫码添加</div>
            <div>企业微信</div>
          </div>
        </div>

        <div class="qrcode">
          <div class="qrcode-img">
            <img src="~@/assets/images/qrcode1.png" />
          </div>
          <div class="qrcode-text">
            <div>关注AI得贤招聘官</div>
            <div>公众号</div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>Copyright © 上海近屿智能科技有限公司</p>
        <p>Shanghai Jinyu Intelligent Technology Co.，Ltd.</p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank"> 沪ICP备19028147号</a>
          <span class="dot"></span>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402008003" target="_blank">沪公网安备 31010402008003号</a>
          <!-- 沪ICP备19028147号 沪公网安备 31010402008003号 -->
        </p>
        <p @click="toPrivacy">隐私政策</p>
      </div>
    </section>

    <img class="bg1" src="~@/assets/images/circular.png" />
    <!-- <div class="menuIcon" @click="openMenu">
      <svg aria-label="Menu" class="sidebarMenuIcon_fgN0" width="24" height="24" viewBox="0 0 30 30" role="img" focusable="false">
        <title>Menu</title>
        <path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path>
      </svg>
    </div> -->
    <tools></tools>
    <el-dialog
      :visible.sync="videoDialog"
      top="25vh"
      width="100%"
      @open="openVanPopup"
      @close="closeVanPopup"
      class="video-popup"
      lock-scroll
      :show-close="false"
      @close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <video-player v-if="videoDialog" ref="videoPlayer" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions"></video-player>
      <div class="unmute_btn" @click.stop="unmute" type="primary">
        <img :src="muted ? require('@/assets/images/unmuted.png') : require('@/assets/images/muted.png')" />
        <div>{{ muted ? '静音' : '解除静音' }}</div>
      </div>
      <van-icon class="video-dialog_close" v-if="videoDialog" @click="closeVanPopup" name="close"></van-icon>
    </el-dialog>
    <!-- <div class="video-popup" @click="videoDialog = false" v-if="videoDialog">
      <div class="video-popup_main">
        <video-player ref="videoPlayer" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions"></video-player> -->
    <!-- <div class="unmute_btn" @click.stop="unmute" type="primary">
          <img src="@/assets/images/muted.png" />
          <div>{{ muted ? '静音' : '解除静音' }}</div>
        </div> -->
    <!-- </div>
    </div> -->
    <FiveYearsDialog />
  </div>
</template>

<script>
import Vue from 'vue'
import Tools from '@/components/tools'
import Header from '@/components/menu'
import EditionOverview from './edition-overview.vue'
import { Dialog, Carousel, CarouselItem } from 'element-ui'
import FiveYearsDialog from '@/components/five-years-dialog'
Vue.use(Carousel)
Vue.use(Dialog)
Vue.use(CarouselItem)
export default {
  components: { Tools, Header, EditionOverview, FiveYearsDialog },
  data() {
    return {
      showCount: false,
      active: 0,
      timer: null,
      rateArray: [5, 4, 3, 2, 1],
      technology: {
        videoUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/video/gwvideo/aimsfj.mp4',
        poster: '',
        showPlayer: false,
      },
      RPAData: [
        {
          title: 'RPA',
          option: ['一键发布', '简历库<br />集合查重'],
        },
        {
          title: 'AI+HR',
          option: ['AI简历筛选器', 'AI视频面试', 'AI聊天机器人', 'AI电话机器人'],
        },
        {
          title: 'AI开放式<br/>考试系统',
          option: ['认知能力测试', '心理测试', '专业能力考试', '模拟编程'],
        },
        {
          title: '大数据及<br/>调查服务',
          option: ['雇佣前背景调查'],
        },
      ],
      list: [
        {
          title: '中国 过去20年',
          type: '线下面试 实时视频面试', //面试方式
          p1: 500, //候选人数量
          d1: 1, //邀约
          p2: 20, //每天面试
          i1: 25, //面试
          total: 26, //合计
          v: 0, //审阅视频
          r: 0, //效率提升
          img: require('./images/bg1.png'),
        },
        {
          title: '欧美 过去20年',
          type: 'AI视频面试-通用版', //面试方式
          p1: 500, //候选人数量
          d1: 1, //邀约
          p2: 500, //每天面试
          i1: 1, //面试
          total: 7, //合计
          v: 5, //审阅视频
          r: 270, //效率提升
          img: require('./images/bg2.png'),
        },
        {
          title: 'AI时代',
          type: 'AI视频面试-定制版', //面试方式
          p1: 500, //候选人数量
          d1: 1, //邀约
          p2: 500, //每天面试
          i1: 1, //面试
          total: 3, //合计
          v: 1, //审阅视频
          r: 770, //效率提升
          img: require('./images/bg3.png'),
        },
      ],
      touchStartX: 0,
      videoDialog: false,
      viewingUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/video/gwvideo/aimsfj.mp4',
      currentVideoUrl: '',
      currentPoster: '',
      aiVideoList: [
        //AI面试解决什么样的问题：
        {
          id: 0,
          videoUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/video/gwvideo/AI%E9%9D%A2%E8%AF%95%E8%A7%A3%E5%86%B3%E9%97%AE%E9%A2%98%281%29.mp4',
          imgUrl: require('@/assets/images/ai-videos/1.png'),
          poster: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/video/gwvideo/aimsjjsmwt.png',
          showPlayer: false,
        },
        //面试分级-完整版本
        {
          id: 1,
          videoUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/l5introduce.mp4',
          // poster: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/video/gwvideo/aimslcty.png',
          poster: require('@/assets/images/ai-videos/video-p-2.png'),
          imgUrl: require('@/assets/images/ai-videos/video-p-2.png'),
          showPlayer: false,
        },
        //ai面试流程体验：
        {
          id: 2,
          videoUrl:
            'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/video/gwvideo/%E8%BF%91%E5%B1%BF%E6%99%BA%E8%83%BDCEO%E8%A7%A3%E8%AF%B4AI%E9%9D%A2%E8%AF%95%E5%88%86%E7%BA%A7%EF%BC%88%E5%AE%8C%E6%95%B4%E7%89%88%EF%BC%89.mp4',
          poster: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/video/gwvideo/aimsfjjs.png',
          imgUrl: require('@/assets/images/ai-videos/3.png'),
          showPlayer: false,
        },
      ],
      muted: false,
      muted2: false,
      testimonyData: [
        {
          detail:
            '中原银行股份有限公司科技人才服务部副总经理陈光表示，2020年6月开始，中原银行使用了近屿智能的AI得贤招聘官。有了AI得贤招聘官，中原银行不再需要人工来筛选求职者简历，因为面试每一位候选人成为了可能，HR从繁重的简历筛选和初轮面试中解放出来，终于可以将关注的焦点放在优秀候选人身上，节省了大量的时间和成本。',
          autor: '中原银行股份有限公司',
          imgUrl: require('./images/testimony_blank.png'),
        },
        {
          detail:
            '从2019秋季校招到2020秋季校招，三星中国（投资）有限公司在AI得贤招聘官的帮助下，让候选人参与AI面试，面试的跟进过程与评价候选人的过程无需人工干预，HR直接安排筛选后的候选人参加后续的面试环节，首轮的面试就直接节约人工时间约80%，提升了面试效率，节约大量的面试成本。',
          autor: '三星中国（投资）有限公司',
          imgUrl: require('./images/testimony_sum.png'),
        },
        {
          detail:
            '从客户指定的核心校区收集到1500+人次的数据量进行定制模型的训练，并将模型应用于所有校区的教师招聘环节，所有数据留案备查；总部与校区用统一标准，避免由于招聘标准不统一，总部需要在20天长周期的教师入职培训环节，在培训成已经发生的情况下淘汰教师，将淘汰前置，直接节约培训成本50%左右。',
          autor: '乂学教育',
          imgUrl: require('./images/testimony_edu.png'),
        },
        {
          detail:
            '自去年10月开始，阿卡索开始使用AI得贤招聘官来招聘海外教师，效果称得上立竿见影，“减少了资金成本，将测试标准统一化了，HR的人数有50%到70%的下降，不用投入大量的人力去做这个事情，实现了人力上的节省，时间的节省，效率的提升。”',
          autor: '马书钰',
          imgUrl: require('./images/testimony_acs.png'),
        },
        {
          detail:
            '2020年春季招聘，北京外国语大学国际教育集团需要招募大量青少英语教师，在Al得贤招聘官的助力之下，招聘效率提升300%，保障了校招的顺利进行。Al视频面试建立了客观统一的面试标准，使得员工入职后的学生家长满意度从原本的40%提升至60%，帮助企业获得可见的绩效提升。',
          autor: '北京外国语大学国际教育集团',
          imgUrl: require('./images/testimony_beijing.png'),
        },
        {
          detail:
            'AI得贤招聘官资深的顾问专业团队不仅为我们设计了一整套适合我们使用的综合能力考核模块，让我们哪怕是在线上也能全面的考核申请人的综合能力。还定制化地设计了考生界面和防作弊功能，保障了评估过程的公平公正，快速地响应，全面地考核，定制化的功能，让我们的申请人在面试过程中，再次感受到了上海交通大学上海高级金融学院的专业度。',
          autor: '杨琳',
          imgUrl: require('./images/testimony_svp.png'),
        },
        {
          detail:
            '科勒（中国）投资有限公司在亚太管培生校招项目中，采用AI得贤招聘官面试系统助力高潜力候选人筛选，节省了HR团队200多小时/人的工作量，直接将校园招聘的项目周期缩短了2周，降低83%简历筛选的时间成本，提高20%的高层管理者复试录用率，降低了10%的应届毕业生同比离职率，提升了企业效能。',
          autor: '科勒（中国）投资有限公司',
          imgUrl: require('./images/testimony_kohler.png'),
        },
      ],
      logos: [
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/H3C%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/LOGITECH%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/SAMSUNG%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/SIEMENS%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/TCL%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/TOTO%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/kao%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/nott%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%89%E5%8F%B6%E5%84%BF%E7%AB%A5%E5%8F%A3%E8%85%94%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%8A%E6%B5%B7%E4%BA%A4%E9%80%9A%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%8A%E6%B5%B7%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%8A%E6%B5%B7%E8%B4%A2%E7%BB%8F%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%96%E7%BA%AA%E5%8D%8E%E9%80%9A%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E5%8E%9F%E9%93%B6%E8%A1%8C%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E5%9B%BD%E5%A4%AA%E5%B9%B3%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E5%9B%BD%E9%93%B6%E8%A1%8C%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E5%A4%AE%E8%B4%A2%E7%BB%8F%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E9%AA%8F%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%BA%A4%E6%8E%A7%E7%A7%91%E6%8A%80%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E5%85%B4%E4%B8%9A%E9%93%B6%E8%A1%8C%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E5%8A%A0%E5%A4%9A%E5%AE%9D%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E5%8C%97%E4%BA%AC%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E5%AF%B9%E5%A4%96%E7%BB%8F%E6%B5%8E%E8%B4%B8%E6%98%93%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%8B%9B%E5%95%86%E9%93%B6%E8%A1%8C%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%90%BA%E7%A8%8B%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%B5%99%E6%B1%9F%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%B5%99%E6%B1%9F%E5%B7%A5%E5%95%86%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%B7%B1%E5%9C%B3%E9%AB%98%E7%BA%A7%E4%B8%AD%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%B8%A9%E5%B7%9E%E7%A7%91%E6%8A%80%E8%81%8C%E4%B8%9A%E5%AD%A6%E9%99%A2%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E7%89%B9%E5%8F%98%E7%94%B5%E5%B7%A5%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E7%99%BD%E8%B1%A1%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E7%BE%8E%E5%9B%A2%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E9%87%8D%E5%BA%86%E5%A4%A7%E5%AD%A6%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E9%94%A6%E6%B1%9F%E9%85%92%E5%BA%97%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E9%98%BF%E5%8D%A1%E7%B4%A2%402x.png',
        'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E9%9B%85%E5%B1%85%E4%B9%90%402x.png',
      ],
      logsArr: []
    }
  },
  methods: {
    toDetail() {
      this.$router.push({
        path: '/price',
      })
    },
    toPrivacy() {
      this.$router.push({
        path: '/privacy',
      })
    },
    openMenu() {
      this.$refs.menu.dialogVisible = true
      this.$refs.videoPlayer2.$el.style.display = 'none'
    },
    closeVideo() {
      this.$refs.videoPlayer2.$el.style.display = 'none'
      this.videoDialog = false
    },
    openVideo() {
      this.$refs.videoPlayer2.$el.style.display = 'block'
    },
    unmute() {
      const player = this.$refs.videoPlayer.player
      player.muted(this.muted)
      this.muted = !this.muted
    },
    unmute2(e) {
      e.stopPropagation()
      const player = this.$refs.videoPlayer2.player
      player.muted(this.muted2)
      this.muted2 = !this.muted2
    },
    changeAiVideo(v) {
      this.$refs.videoPlayer2.player.pause()
      this.currentVideoUrl = v.videoUrl
      this.currentPoster = v.poster
      this.videoDialog = true
      this.$refs.videoPlayer2.$el.style.display = 'none'
    },
    to(name) {
      this.$router.push({ name })
    },
    onChangeCarousel(n) {
      this.active = n - 1
      this.startTimer()
    },
    openVanPopup() {
      setTimeout(() => {
        this.$refs.videoPlayer2.$el.style.display = 'none'
      }, 0)
    },
    closeVanPopup() {
      this.videoDialog = false
      this.$refs.videoPlayer2.$el.style.display = 'block'
    },
    startTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.active >= 2) {
          this.active = 0
        } else {
          this.active++
        }
      }, 5000)
    },
    listenSwipe() {
      const carouselItems = document.querySelectorAll('.carousel-item')
      carouselItems.forEach(item => {
        console.log(item)
        item.addEventListener('touchstart', this.touchstart)
        item.addEventListener('touchend', this.touchend)
      })
    },
    touchstart(e) {
      this.touchStartX = e.touches[0] ? e.touches[0].clientX : e.changedTouches[0] ? e.changedTouches[0].clientX : 0
      e.preventDefault()
      e.stopPropagation()
      // console.log('touchstart =>', this.touchStartX)
    },
    touchend(e) {
      const touchEndX = e.touches[0] ? e.touches[0].clientX : e.changedTouches[0] ? e.changedTouches[0].clientX : 0
      e.preventDefault()
      e.stopPropagation()
      // console.log('touchend =>', touchEndX)
      // console.log('offset =>', touchEndX - this.touchStartX)
      const offset = touchEndX - this.touchStartX
      if (offset > 5) {
        this.active--
        this.active < 0 ? (this.active = 2) : ''
        this.startTimer()
      }
      if (offset < -5) {
        this.active++
        this.active > 2 ? (this.active = 0) : ''
        this.startTimer()
      }
    },
    chunkArray(arr, chunkSize) {
      var result = []
      for (var i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize))
      }
      return result
    },
  },
  mounted() {
    this.logsArr = this.chunkArray(this.logos, 3)
    this.startTimer()
    this.listenSwipe()
    document.querySelector('.video-player-logo').style.height = this.$refs.videoPlayer2.$el.offsetHeight + 'px'
  },
  computed: {
    playerOptions() {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: this.currentVideoUrl, // url地址
          },
        ],
        poster: this.currentPoster, // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      }
    },
    playerOption2() {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: this.viewingUrl, // url地址
          },
        ],
        poster: require('./images/banner-poster.jpg'), // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false, // 是否显示全屏按钮
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ai-home {
  position: relative;
  overflow: hidden;
  /deep/ .vjs-big-play-button {
    display: none;
  }
  /deep/ .el-dialog__header,
  /deep/ .el-dialog__body {
    padding: 0;
  }
  p {
    margin: 0;
  }
  .menuIcon {
    position: fixed;
    bottom: 86px;
    right: 10px;
    font-size: 35px;
    width: 64px;
    height: 35px;
    line-height: px;
    background: #ebedf0;
    color: #1c1e21;
    border-radius: 5px;
    display: none;
    justify-content: center;
    align-items: center;
    .sidebarMenuIcon_fgN0 {
      display: block;
    }
  }
  .header {
    width: 100%;
    position: relative;
    background: linear-gradient(#468afc, #1762fa);
    padding-top: 45px;
    padding-bottom: 30px;
    box-sizing: border-box;
    .banner-top {
      background: #f86065;
      color: #ffffff;

      // display: flex;
      // align-items: center;
      // justify-content: center;
      z-index: 2;
      line-height: 18px;
      padding: 10px 0;
      font-size: 14px;
      .banner-top_info {
        width: 90%;

        line-height: 20px;
        text-align: center;
        margin: auto;
        margin-bottom: 8px;
        .img-tips {
          margin-right: 4px;
          height: 20px;
        }
        .info-zs {
          color: #ffd58d;
          margin: 0 5px;
          font-weight: 600;
        }
      }
      .view-detail {
        margin: auto;
        width: 80px;
        text-align: center;
        background: #fe9f01;
        padding: 5px 0;
        border-radius: 30px;
      }
    }
    .word-logo {
      width: 299px;
      padding: 0 25px;
      margin-top: 45px;
    }
    .word-txt {
      color: #ffffff;
      font-size: 22px;
      padding: 0 25px;
      margin-top: 45px;
      font-family: DDRegular;
    }
    .word-line {
      margin-top: 9px;
      margin-left: 25px;
      background: #3deeed;
      border-radius: 3px;
      width: 50px;
      height: 4px;
    }
    .video-player-logo {
      margin-top: 20px;
      padding: 0 25px;
      position: relative;
      .video-player-banner {
        height: 100%;
        position: relative;
        video {
          z-index: 1;
        }
        /deep/ video::-webkit-media-controls-start-playback-button {
          display: none;
        }
        /deep/ .video-js .vjs-big-play-button {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .unmute_btn {
        position: absolute;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 40px;
        bottom: 30px;
        background: rgba(0, 0, 0, 0.5);
        width: 90px;
        height: 25px;
        line-height: 25px;
        color: #ffffff;
        font-size: 12px;
        border-radius: 14px;
        cursor: pointer;
        img {
          margin-right: 5px;
          width: 17px;
          height: 15px;
        }
      }
    }
    .tip-desc {
      margin-top: 40px;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      color: #a0c9fd;
      line-height: 27px;
      letter-spacing: 2px;
      padding: 0 25px;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -15px;
        left: 25px;
        background: #5ca4ff;
        width: calc(100% - 50px);
        height: 1px;
        opacity: 0.5;
      }
    }
    .video-desc {
      margin-top: 29px;
      display: flex;
      overflow-y: auto;
      width: 100%;
      padding: 0 25px;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .ai-videos-list {
        display: flex;
        justify-content: space-between;
        .ai-videos-item {
          position: relative;
          cursor: pointer;
          .content-png {
            width: 122px;
            display: block;
          }
          .player-png {
            display: block;
            width: 30px;
            position: absolute;
            left: 40%;
            top: 30%;
          }
        }
        .ai-videos-item + .ai-videos-item {
          margin-left: 10px;
        }
      }
    }
    .l4-desc {
      width: 100%;
      margin-top: 4px;
      padding: 0 25px;
      .tip-one,
      .tip-two {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        line-height: 30px;
      }
    }
    @media screen and (min-width: 768px) {
      .video-desc .ai-videos-list .ai-videos-item .content-png {
        width: 225px;
      }
      .l4-desc {
        top: 670px;
      }
    }
  }
  .description {
    padding: 35px 25px 12px 25px;
    font-size: 12px;
    font-weight: 400;
    color: #627098;
    line-height: 22px;
  }
  .section1 {
    h3 {
      font-size: 15px;
      font-weight: 600;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
    .mood {
      width: 331px;
      height: 104px;
      margin: 0 auto;
      position: relative;
      .mood-btn {
        width: 87px;
        height: 35px;
        background: #4f8fff;
        border-radius: 24px;
        color: #ffffff;
        position: absolute;
        line-height: 35px;
        text-align: center;
        font-size: 12px;
        &:nth-child(1) {
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          right: 0;
          top: 0;
        }
        &:nth-child(3) {
          left: 0;
          bottom: 0;
        }
        &:nth-child(4) {
          right: 0;
          bottom: 0;
        }
        &:nth-child(5) {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: #006afe;
        }
      }
      .line1 {
        width: 70px;
        height: 70px;
        border-top: 1px dotted #4f8fff;
        border-bottom: 1px dotted #4f8fff;
        border-right: 1px dotted #4f8fff;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        position: absolute;
        left: 75px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }
      .line2 {
        width: 70px;
        height: 70px;
        border-top: 1px dotted #4f8fff;
        border-bottom: 1px dotted #4f8fff;
        border-left: 1px dotted #4f8fff;
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
        position: absolute;
        right: 75px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }
    }
    .arrow {
      text-align: center;
      margin: 9px auto 0;
      img {
        width: 25px;
        height: 36px;
      }
    }
    .card {
      padding: 15px 22px;
      .card-left {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 45px;
        margin-bottom: 15px;
        position: relative;
        .card-title {
          margin-bottom: 6px;
          font-size: 11px;
          font-weight: 600;
          color: #4f8fff;
          line-height: 15px;
        }
        p {
          color: #b5becf;
          font-size: 9px;
          font-weight: 500;
          line-height: 13px;
          margin: 0;
        }
        > img {
          width: 61px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:last-child {
        .card-left {
          text-align: left;
        }
        .card-right {
          height: 108px;
        }
      }
      .card-right {
        box-sizing: border-box;
        background: #4f8fff;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 98px;
        .expression {
          display: flex;
          justify-content: center;
          align-items: center;
          .expression-left {
            text-align: center;
            position: relative;
            font-size: 8px;
            line-height: 13px;
            .expression-bottom {
              margin-top: 6px;
            }
            .expression-line {
              width: 96px;
              height: 1px;
              background: #ffffff;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .expression-equal {
            margin: 0 9px;
          }
        }
        .expression-result {
          width: 145px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          font-size: 9px;
          background: #ff984f;
          border-radius: 23px;
          margin: 17px auto 0;
          i {
            font-size: 15px;
            font-style: normal;
          }
        }
        .txt-content {
          text-align: center;
          .txt-title {
            font-size: 12px;
            font-weight: 600;
            color: #ffffff;
            line-height: 17px;
            margin-bottom: 3px;
          }
          .txt-detail {
            width: 198px;
            margin: 0 auto 7.5px;
            font-size: 8px;
            color: #ffffff;
            line-height: 14px;
          }
          ul {
            display: inline-block;
            margin: 0 auto;
            font-size: 8px;
            tr {
              th,
              td {
                width: 50px;
                height: 18px;
                line-height: 18px;
                text-align: center;
                box-sizing: border-box;
                > .star-five {
                  width: 7px;
                  margin-right: 1px;
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .title-label {
      display: flex;
      justify-content: center;
      padding-top: 35px;
      padding-bottom: 5px;
      .label-btn {
        width: 87px;
        height: 35px;
        line-height: 35px;
        background: #006afe;
        border-radius: 24px;
        color: #fff;
        text-align: center;
      }
    }
  }
  .section2 {
    background: #ffffff;
    padding: 0 22px;
    > .content {
      margin: 0 auto;
      padding: 56px 0 71px 0;
      h3 {
        color: #333333;
        margin-bottom: 50px;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        line-height: 21px;
      }
      .solution-process {
        position: relative;
        .box1 {
          width: 100%;
          .left {
            border: 1px dotted #a9c8ff;
            border-bottom: transparent;
            padding-bottom: 20px;
            .title {
              width: 87px;
              height: 23px;
              line-height: 23px;
              text-align: center;
              background: #1890ff;
              font-size: 12px;
              font-weight: 600;
              color: #ffffff;
              border-radius: 12px;
              margin: 15px auto 20px;
            }
            ul {
              display: flex;
              justify-content: space-around;
              li {
                width: 80px;
                height: 80px;
                border-radius: 40px;
                text-align: center;
                background: #ecf2ff;
                font-size: 12px;
                color: #333333;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .right {
            width: 100%;
            height: 12px;
            overflow: hidden;
            position: relative;
            .line1 {
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 24px;
              height: 1px;
              border-top: 1px dotted #a9c8ff;
              overflow: hidden;
              transform-origin: 0px 0px;
              transform: rotate(30deg);
            }
            .line2 {
              display: block;
              width: 24px;
              height: 1px;
              border-top: 1px dotted #a9c8ff;
              overflow: hidden;
              transform-origin: 24px 0px;
              transform: rotate(-30deg);
              position: absolute;
              right: 0;
            }
          }
        }
        .box2 {
          width: 100%;
          height: 300px;
          border: 1px dotted #a9c8ff;
          padding: 9px 7px 0 7px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-around;
          flex-flow: wrap;
          .box2-item {
            width: 45%;
            height: 130px;
            border-radius: 3px;
            background: rgba(79, 143, 255, 0.1);
            .title {
              height: 40px;
              background: #1890ff;
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
            }
            .box2-item-content {
              height: 90px;
              padding: 6px 0 4px 0;
              .option {
                text-align: center;
                font-size: 9px;
                color: #333333;
                line-height: 13px;
                position: relative;
                text-align: left;
                max-width: 85px;
                margin: 0 auto;
                padding: 3.5px 0;
                &::before {
                  content: '';
                  width: 4px;
                  height: 4px;
                  border-radius: 2px;
                  display: block;
                  position: absolute;
                  left: -10px;
                  top: 8px;
                  background: #006afe;
                }
              }
            }
          }
        }
        .icons {
          width: 100%;
          height: 42px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          img {
            width: 18px;
            height: 25px;
          }
        }
        .box3 {
          width: 100%;
          height: 70px;
          border: 1px dotted #a9c8ff;
          box-sizing: border-box;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .companyItem {
            text-align: center;
            width: 75px;
            img {
              display: block;
              margin: 0 auto;
              width: 23px;
              height: 23px;
            }
            &:first-child {
              img {
                width: 38px;
              }
            }
            p {
              width: 100%;
              height: 32px;
              background: #4f8fff;
              color: #ffffff;
              border-radius: 3px;
              margin-top: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-weight: 500;
              line-height: 15px;
            }
          }
        }
      }
    }
  }
  .section3 {
    padding: 0 25px;
    .left-part {
      width: 100%;
      .tip {
        font-size: 15px;
        line-height: 21px;
        font-weight: 600;
        color: #333333;
        text-align: center;
      }
      .content {
        width: 100%;
        font-size: 12px;
        line-height: 22px;
        color: #627098;
        margin-top: 35px;
        margin-bottom: 30px;
      }
    }
    .right-part {
      position: relative;
      width: 100%;
      .bg {
        width: 100%;
        height: 189px;
      }
      .player-png {
        width: 63px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
  .section4 {
    height: 334px;
    overflow: hidden;
    position: relative;
    margin-top: 52px;
    @keyframes breath {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
    .image_bg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      animation: breath 5s ease-in-out 100ms infinite;
      img {
        width: 100%;
      }
    }
    h3 {
      text-align: center;
      font-size: 15px;
      color: #ffffff;
      line-height: 21px;
      margin: 35px auto 28px;
      font-weight: 400;
    }
    .testimony-box {
      width: 86.67%;
      height: 216px;
      background: rgba(0, 0, 0, 0.34);
      border-radius: 43px 0px 43px 0px;
      margin: 0 auto;
      /deep/ .el-carousel {
        height: 100%;
      }
      .testimony-box-item {
        padding: 45px 15px 28px 15px;
        box-sizing: border-box;
        p {
          font-size: 12px;
          color: #ffffff;
          line-height: 19px;
        }
        .footer {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          position: absolute;
          bottom: 20px;
          right: 15px;
          .name {
            height: 17px;
            font-size: 12px;
            color: #ffffff;
            line-height: 17px;
          }
          > img {
            width: 33px;
            margin: 0 10px 0 12px;
          }
          .line {
            width: 50px;
            height: 1px;
            background: #ffffff;
          }
        }
      }
    }
  }
  .section5 {
    padding: 50px 18px;
    background: #2e75e6;
    .c-title {
      font-size: 26px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 55px;
      text-align: center;
    }
    .customer-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .item-box {
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        .item {
          margin-right: 15px;
          width: calc(33% - 15px);
          height: 47px;
          background: #ffffff;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 90%;
            height: 90%;
            object-fit: contain;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .section6 {
    width: 100%;
    height: 120px;
    background: #4f8fff;
    display: flex;

    .item-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // width: 33%;
      // height: 67px;
      position: relative;
      .iso-img {
        img {
          width: 44px;
        }
      }
      .iso-text {
        margin-top: 15px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        margin: 4px 0;
        padding: 0;
      }
      .split {
        position: absolute;
        width: 1px;
        height: 90px;
        right: 0;
        background: #ffffff;
      }
    }
  }

  > .footer {
    margin-top: 60px;
    padding-bottom: 15px;
    .qrcode-box {
      display: flex;
    }
    .qrcode {
      display: flex;
      flex-direction: column;
      width: 50%;
      .qrcode-img {
        text-align: center;
        img {
          width: 145px;
        }
      }
      .qrcode-text {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        text-align: center;
      }
    }
    .copyright {
      text-align: center;
      margin: 50px 0 0;
      font-size: 10px;
      font-weight: 400;
      color: #a6a6a6;
      p {
        margin: 0;
      }
      .dot {
        padding: 0 5px;
      }
      a {
        color: #a6a6a6;
      }
    }
  }

  .bg1 {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 610px;
    left: -75px;
    z-index: -1;
  }

  .advantage {
    position: relative;
    margin-top: 10px;
    .bg2 {
      width: 95%;
      margin-left: 5%;
      height: auto;
      height: auto;
    }
    .circle-1,
    .circle-2 {
      position: absolute;
      width: 36px;
      height: 36px;
    }
    .circle-1 {
      top: 20px;
      right: 10%;
      animation: mymove1 5s infinite;
    }
    .circle-2 {
      bottom: 50px;
      left: 30%;
      animation: mymove2 3s infinite;
    }
    @keyframes mymove1 {
      0% {
        top: 20px;
      }
      25% {
        top: 10px;
      }
      75% {
        top: 30px;
      }
      100% {
        top: 20px;
      }
    }
    @keyframes mymove2 {
      0% {
        bottom: 50px;
      }
      25% {
        bottom: 40px;
      }
      75% {
        bottom: 60px;
      }
      100% {
        bottom: 50px;
      }
    }
  }
  .video-popup {
    overflow: hidden;
    min-height: 211px;
    .unmute_btn {
      position: absolute;
      z-index: 4;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      bottom: 30px;
      background: rgba(0, 0, 0, 0.5);
      padding: 3px 6px;
      width: 90px;
      white-space: nowrap;
      line-height: 25px;
      color: #ffffff;
      // font-size: 12px;
      cursor: pointer;
      border-radius: 16px;
      img {
        margin-right: 5px;
        width: 12px;
        // height: 15px;
      }
    }
    .video-dialog_close {
      position: absolute;
      bottom: -70px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2027;
      font-size: 42px;
      color: #ffffff;
    }
  }
}
</style>
<style lang="scss">
video::-webkit-media-controls {
  display: none !important;
}
</style>

<template>
  <div class="voice-result">
    <div class="title">{{ $t('com.report.reportV3.text055') }}</div>
    <div class="voice-score">
      <div class="score-item">
        <!-- <el-rate :value="aiData.isescore.iseAllScore" disabled></el-rate> -->
        <van-rate :value="aiData.isescore ? aiData.isescore.iseAllScore : 0" :size="18" :gutter="2" color="#ffd21e" readonly />
        <div class="score-name">{{ $t('com.report.reportV3.text056') }}</div>
        <!-- <div>{{ aiData.isescore.iseAllScore }}分</div> -->
      </div>
      <div class="score-item">
        <!-- <el-rate :value="aiData.isescore.iseFluencyScore" disabled></el-rate> -->
        <van-rate :value="aiData.isescore ? aiData.isescore.iseFluencyScore : 0" :size="18" :gutter="2" color="#ffd21e" readonly />
        <div class="score-name">{{ $t('com.report.reportV3.text057') }}</div>
        <!-- <div>{{ aiData.isescore.iseFluencyScore }}分</div> -->
      </div>
      <div class="score-item">
        <!-- <el-rate :value="aiData.isescore.iseAccuracyScore" disabled></el-rate> -->
        <van-rate :value="aiData.isescore ? aiData.isescore.iseAccuracyScore : 0" :size="18" :gutter="2" color="#ffd21e" readonly />
        <div class="score-name">{{ $t('com.report.reportV3.text058') }}</div>
        <!-- <div>{{ aiData.isescore.iseAccuracyScore }}分</div> -->
      </div>
    </div>
    <br />
    <!-- <div class="title">口音高频词</div>
    <br />
    <div id="voice-result-chart"></div> -->
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: ['aiData'],

  computed: {
    topFrequencyWord() {
      return JSON.parse(this.aiData.isescore?.topFrequencyWord || '[]').map(i => ({
        name: i.word,
        value: +i.count,
      }))
    },
    topFrequencyWordTotalCount() {
      return this.topFrequencyWord.reduce((pre, cur) => pre + +cur.value, 0)
    },
    fiexdTopFrequencyWord() {
      // const max = Math.max(...this.topFrequencyWord.map(i => i.name.length))
      return this.topFrequencyWord.map(i => ({
        ...i,
        // padLength: max,
        // fixedName: i.name.padEnd(max, ' '),
        percent: ((i.value / this.topFrequencyWordTotalCount) * 100).toFixed(2) + '%',
      }))
    },
  },

  mounted() {
    // this.drawCharts()
  },

  methods: {
    drawCharts() {
      const myChart = echarts.init(document.getElementById('voice-result-chart'))
      const option = {
        backgroundColor: '#ffffff',
        color: ['#C5C7DC', '#FFE679', '#5FA4FF', '#00E880', '#1890FF', '#6572F8', '#F5B919'],
        title: {
          text: this.topFrequencyWordTotalCount + '次',
          top: '35%',
          left: 'center',
          textStyle: {
            lineHeight: 30,
            color: '#AFB6CA',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}  {d}% {c}次',
        },
        legend: {
          show: true,
          orient: 'horizontal',
          top: '75%',
          padding: [5, 25, 5, 25],
          itemGap: 20,
          formatter: name => {
            const detail = this.fiexdTopFrequencyWord.find(i => i.name === name)
            const percent = detail?.percent || 0
            const count = detail?.value || 0
            const text = `${name}    ${percent}    ${count}次`
            // console.log(text, text.length)
            return text // fixedName + '     ' + percent + '     ' + count + '次'
          },
          // formatter: `{name|{name}} ${pe}`,
          data: this.topFrequencyWord,
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'inner',
              formatter: '{c}',
            },
            data: this.topFrequencyWord,
          },
        ],
      }

      myChart.setOption(option)
    },
  },

  watch: {
    aiData: {
      deep: true,
      handler() {
        // this.drawCharts()
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.voice-result {
  padding: 0 20px;
  .title {
    font-weight: 600;
    font-size: 16px;
  }
  .voice-score {
    display: flex;
    flex-wrap: wrap;
    .score-item {
      padding: 16px 10px 0 10px;
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .score-name {
        color: #333333;
        font-size: 14px;
      }
    }
  }
  #voice-result-chart {
    box-sizing: border-box;
    background-color: #fafafa;
    width: 100%;
    height: 480px;
  }
}
</style>

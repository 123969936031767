export default {
  text001: '面試安排',
  text002: '面試時間',
  text003: '面試輪次',
  text004: '面試負責人',
  text005: '面試助理',
  text006: '房間號碼',
  text007: '會議號',
  text008: '騰訊會議鏈接',
  text009: '面試密碼',
  text010: '面試資訊',
  text011: '面試官姓名',
  text012: '面試官鏈接',
  text013: '複製鏈接',
  text014: '面試官密碼',
  text015: '微信小程式搜索「得賢遠程面試」輸入密碼開始面試。',
  text016: '面試回饋',
  text017: '填寫回饋',
  text018: '查看回饋',
  text019: '撤銷未進行',
  text020: '面試地點',
  text021: '面試未進行',
  text022: '等待hr安排面試~',
  text023: '您可以點擊下方的按鈕去安排或更新您的面試時間',
  text024: '更新空閒時間',
  text025: '未回應',
  text026: '接受',
  text027: '已拒絕',
  text028: '已取消',
  text029: '線下麵試',
  text030: '線上得賢面試',
  text031: '線上語音',
  text032: '線上騰訊會議',
  text033: '視頻面試',
  text034: '該功能需要登錄操作',
  text035: '確認將面試修改為未進行狀態？',
  text036: '操作成功',
  text037: '您暫無權限進行操作，請聯繫HR',
  text038: '確認後您將可以給候選人填寫回饋',
  text039: '撤銷面試未進行',
  text040: '複製成功',
  text041: '回饋結果不能為空',
  text042: '回饋成功',
  text043: '錯誤',
  text044: '拒絕',
  text045: '通過',
  text046: '待定',
  text047: '面試未進行',
  text048: '總分',
  text049: '{0}分',
  text050: '模組分數',
  text051: '綜合回饋',
  text052: '面試結果',
  text053: '綜合評價',
  text054: '這些是我客觀的回饋評價，如有異議或疑問我們隨時溝通',
  text055: '內容不允許為空',
  text056: '取消',
  text057: '確定',
  text058: '{content}內容不能為空',
  text059: '回饋成功',
}

/* eslint-disable quotes */
export default {
  text001: 'Manual ASS',
  text002: 'Manual COMP ASS',
  text003: "1. Please evaluate the candidate's resume and fill in the specific reasons",
  text004: 'Fill in the specific reason(limit 500 characters)',
  text005: '1. Please evaluate the overall performance of the candidate and fill in the specific reasons',
  text006: 'Please fill in the specific evaluation(limit 500 characters)',
  text007: '1. Suggested positions',
  text008: 'Please fill in the specific position',
  text009: '2. Comprehensive evaluation',
  text010: "2. Please evaluate the candidate's resume and fill in the specific reasons",
  text011: "3. Please evaluate the candidate's video interview performance and fill in the specific reasons",
  text012: 'Cancel',
  text013: 'Submit',
  text014: 'Submit reminder',
  text015: 'Your evaluation has not been submitted, it will be cleared after returning, please confirm whether to return',
  text016: "Do you still want to interview TA after reading the candidate's resume?",
  text017: "Please make a five-star evaluation of the candidate's overall performance and decide whether to recommend it for the next round of interviews",
  text018: "Please rate the candidate's performance in the video interview with five stars",
  text019: 'Yes',
  text020: 'No',
  text021: 'TBD',
  text022: 'Passed',
  text023: 'Reject',
  text024: 'Add failed',
  text025: 'Submitted successfully',
  text026: 'Evaluation record',
  text027: 'No evaluation record',
  text028: 'Passed',
  text029: 'Pending',
  text030: 'Rejected',
  text031: 'Candidate resume evaluation',
  text032: 'Comprehensive Evaluation of Candidates',
  text033: 'Candidate Video Interview Evaluation',
  text034: 'Overview',
  text035: 'Suggested position',
  text036: 'Please fill in at least one',
  text037: 'Fold up',
  text038: 'Unfold',
}

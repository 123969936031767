<template>
  <div class="ats">
    <Header></Header>
    <section class="section1">
      <div class="barnner">
        <img class="banner-img" src="./images/banner.png" />
        <div class="text-wrapper">
          <span>ATS招聘管理系统</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="floor">
        <h3>ATS招聘系统概览</h3>
        <img src="./images/ats_progress.png" width="1200" />
      </div>
      <div class="floor">
        <h3>自动化招聘流程</h3>
        <p>
          AI得贤招聘官可以为企业提供从一键发布到评估录用的自动化招聘服务，提供多个AI招聘服务场景：一键发布、AI简历解析、远程实时视频面试、AI视频面试、AI面试聊天机器人、在线考试系统、背景调查服务等，企业用户还可以根据自己的招聘需求，自定义招聘环节，缩减企业招聘流程，提高招聘效率。
        </p>
        <img src="./images/ats_program.png" width="1046" />
      </div>
    </section>
    <div class="bg1">
      <img src="~@/assets/images/circular.png" height="430" />
    </div>
    <tools></tools>
  </div>
</template>

<script>
import Tools from '@/components/tools'
import Header from '@/components/menu'
export default {
  components: { Tools, Header },
  data() {
    return {}
  },
  created() {
    document.title = 'ATS招聘管理系统-AI得贤招聘官'
  },
}
</script>

<style lang="scss" scoped>
.ats {
  overflow: hidden;
  position: relative;
  // padding-top: 60px;
  .section1 {
    .barnner {
      position: relative;
      max-height: 240px;
      overflow: hidden;
      .banner-img {
        display: block;
        width: 100%;
        height: auto;
      }
      .text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 240px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
          line-height: 34px;
          letter-spacing: 3px;
        }
      }
    }
  }
  .content {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 37px;
    .floor {
      padding-top: 40px;
      h3 {
        margin-bottom: 23px;
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        line-height: 42px;
        text-align: center;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: #627098;
        line-height: 22px;
        width: 89.33%;
        margin: 0 auto 24px;
      }
      img {
        width: 89.33%;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .bg1 {
    position: absolute;
    left: 0;
    bottom: 264px;
    width: 82px;
    height: 164px;
    overflow: hidden;
    img {
      height: 100%;
      position: relative;
      left: -82px;
      top: 0;
    }
  }
}
</style>

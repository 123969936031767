<template>
  <section class="mandate-presentation">
    <Header></Header>
    <div class="ai-content">
      <div class="tip">AI RecruiTas</div>
      <div class="second-tip">AI视频面试背后的算法逻辑</div>
      <div class="stage">
        <div class="stage-one">
          AI得贤招聘官是一款人工智能时代的招聘和面试SaaS平台，拥有基于先进的篇章级语义识别算法和多模态算法构建的人力资源行业超级AI-近屿超脑。AI得贤招聘官致力于做懂HR的智慧搭档，通过AI、RPA、BI等先进技术，帮助组织提升招聘效率和人均效率，从而打造独特的人才竞争优势！
        </div>
        <div class="stage-two">
          AI得贤招聘官在组织诊断、AI面试流程再造和胜任力建模的基础上，通过结构化行为或案例面试方案，结合工业心理学，利用先进的自然语言处理技术进行篇章级别的语义分析，并通过计算机视觉和听觉技术，对候选人的表情和声音进行分析，帮助企业全方位、精准、稳定、高效的对候选人进行胜任力预测和评估。
        </div>
      </div>
    </div>
    <div class="hr-characters-intro">
      <div class="left-part">
        <div>HR使用的招聘系统由我们HR自己设计</div>
        <div>
          AI得贤招聘官的顾问团队平均拥有15年以上大型、知名企业HR管理和经验，对HR业务理解透彻，深刻理解甲方HR的痛点和业务诉求，和甲方HR拥有共同的语言。AI得贤招聘官的顾问团队已顺利实施并交付过多个大项目，拥有丰富的复杂项目管理经验，并形成了成熟的方法论、工具、模版、知识库等知识体系。
        </div>
      </div>
      <div class="right-part">
        <div class="photo-wrap">
          <img v-for="(item, index) in personsImg" :key="index" @mouseenter="personMouseEnter(index)" @mouseleave="personMouseLeave" :src="require('./images/' + item)" />
        </div>
      </div>
    </div>
    <div class="ai-team">
      <div class="tip">国际一流的AI团队，让HR的智能化成为现实</div>
      <div class="description">
        AI得贤招聘官和国内优秀院校合作，拥有业内优秀的算法团队，该团队由拥有20多年算法和工程融合经验的中文自然语言领域专家带领，聚集了一批青年才俊，技术水平遥遥领先。
      </div>
      <ul class="scientist">
        <li>
          <div class="left-part">
            <img :src="require('./images/scientistOne.png')" />
          </div>
          <div class="right-part">
            <div class="name">陈清财</div>
            <div class="position">合伙人</div>
            <div class="description">
              <div class="paragraph">
                <div><span></span></div>
                <div>哈工大（深圳）智能计算研究中心主任，教授、博导，中国中文信息学会理事</div>
              </div>
              <div class="paragraph">
                <div><span></span></div>
                <div>
                  中文自然语言处理领域专家，具有20余年丰富算法和工程融合经验
                </div>
              </div>
              <div class="paragraph">
                <div><span></span></div>
                <div>
                  曾任英特尔（中国）有限公司高级软件工程师，香港理工大学访问学者等职
                </div>
              </div>
              <!-- <div class="paragraph">
                <div><span></span></div>
                <div>
                  曾在英特尔（中国）有限公司任高级软件工程师，主要从事口语学习系统研发、UPnP协议研发等工作
                </div>
              </div> -->
            </div>
          </div>
        </li>
        <li v-if="false">
          <div class="left-part">
            <img :src="require('./images/scientistTwo.png')" />
          </div>
          <div class="right-part">
            <div class="name">陈凯</div>
            <div class="position">CTO</div>
            <div class="description">
              <div class="paragraph">
                <div><span></span></div>
                <div>哈尔滨工业大学（深圳）博士</div>
              </div>
              <div class="paragraph">
                <div><span></span></div>
                <div>
                  5年以上NLP研究经验，主要研究方向包括自然语言生成、文本摘要、机器阅读理解；对开放问答评估、对话系统构建、口音识别等具有丰富经验
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <img src="./images/circle.png" alt="" class="bg1" />
    <tools></tools>
  </section>
</template>

<script>
import Tools from '@/components/tools'
import Header from '@/components/menu'
export default {
  components: { Tools, Header },
  data() {
    return {
      personsImg: ['person1.png', 'person2.png', 'person3.png', 'person4.png'],
      //'person5.png'
      mouseEnterIndex: 0,
      personsInfo: [
        {
          name: '方小雷',
          job: 'CEO',
          descriptionList: [
            '南京大学工商管理学士，多伦多大学Rotman商学院MBA',
            '中兴通讯，德国瑞好，万科集团相关工作经验，具有地产、零售、化学和通信等多行业的管理经验',
            '11年人力资源管理经验，曾在中国、德国、西班牙、英国和加拿大等地全面负责所在区域的人力资源管理工作',
            '是中国稀缺的具有国际实战经验的人力资源劳动关系专家、薪酬专家和人才培养与发展专家',
          ],
        },
        {
          name: 'Dr.Laurence Lau',
          job: '首席架构师',
          descriptionList: [
            'Grenoble Ecole de Management工商管理博士',
            '核心模型Talent-DNA发明人',
            '近20年HRD经验',
            '德国卡尔蔡司IMT总经理，负责东南亚区域销售、客服、技术管理相关工作；荷兰飞利浦商务运营总监，负责VCD/DVD机芯的研发、生产、市场管理相关工作；德国瑞好大中华区以及亚洲区HR总监',
          ],
        },
        {
          name: '崔晓燕',
          job: '市场副总裁',
          descriptionList: [
            '首都经济贸易大学学士，人力资源管理',
            '20多年SaaS/PaaS服务销售运营、咨询服务经验',
            '曾任CDP集团事业部副总裁、文思海辉国际集团共享服务运营中心副总裁、中国惠普资源管理经理等工作',
            '负责文思海辉共享服务运营中心的建设、优化、持续迭代和成功市场化',
          ],
        },
        {
          name: '吴欣',
          job: '首席咨询顾问',
          descriptionList: [
            '浙江大学经济学学士、硕士，英国曼彻斯特大学人力资源硕士',
            '13年外资企业人力资源管理经验',
            '曾任职于科勒、Parkway Health， Johnson Electrics等跨国企业/集团，在制造、医疗、服务、消费品等行业中对中国区和亚太区的人力资源管理上具有丰富的实战经验',
          ],
        },
      ],
      isShowPersonPresent: false,
    }
  },
  created() {
    document.title = '核心技术-AI得贤招聘官'
  },
  methods: {
    personMouseEnter(index) {
      // 展示人物弹框
      this.isShowPersonPresent = true
      this.mouseEnterIndex = index
    },
    personMouseLeave() {
      // 隐藏人物弹框
      this.isShowPersonPresent = false
    },
  },
}
</script>
<style lang="scss" scoped>
.mandate-presentation {
  position: relative;
  .ai-content {
    padding: 90px 25px 0;
    .tip {
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
    .second-tip {
      margin-top: 5px;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .stage {
      margin: 25px 0 50px 0;
      .stage-one,
      .stage-two {
        display: block;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333333;
        line-height: 22px;
      }
      .stage-two {
        margin-top: 20px;
      }
    }
  }
  .hr-characters-intro {
    position: relative;
    height: 372px;
    background: #13397c;
    padding: 50px 25px;
    box-sizing: border-box;
    .left-part {
      div:first-of-type {
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 21px;
        text-align: center;
      }
      div:last-of-type {
        margin-top: 25px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .right-part {
      margin-top: 20px;
      .photo-wrap {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        img {
          width: 74px;
          height: 74px;
          // width: 60px;
          // height: 60px;
          // border-radius: 5px;
        }
      }
    }
  }
  .ai-team {
    padding: 50px 0 40px;
    .tip {
      text-align: center;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 21px;
    }
    > .description {
      margin-top: 25px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #333333;
      line-height: 22px;
      padding: 0 25px;
    }
    .scientist {
      padding: 20px 25px 0px;
      li {
        margin-bottom: 20px;
        position: relative;
        &:last-child {
          margin: 0;
        }
        .left-part {
          position: absolute;
          left: 15px;
          top: 20px;
          img {
            display: block;
            width: 42px;
            height: 42px;
          }
        }
        .right-part {
          background: #eef1f8;
          padding: 20px 15px;
          .name {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 20px;
            margin-left: 50px;
            margin-top: 3px;
          }
          .position {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 17px;
            margin-left: 50px;
          }
          .description {
            margin-top: 13px;
            .paragraph {
              margin-bottom: 10px;
              display: flex;
              div:first-of-type {
                margin-right: 8px;
                text-align: center;
                span {
                  display: inline-block;
                  width: 2px;
                  height: 2px;
                  border-radius: 1px;
                  background: #627098;
                  // transform: translateY(-2px);
                }
              }
              div:last-of-type {
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }
  .bg1 {
    width: 164px;
    position: absolute;
    top: 382px;
    left: -82px;
  }
}
</style>

export default {
  text001: '認知能力測評',
  text002: '得賢圖形推理測驗是一種非言語智力測驗,其主要評估候選人的觀察能力、問題解決能力,以及思維能力。具體來說，本測試評估的是候選人：',
  text003: '• 從大量模糊，不完整的資訊當中，迅速識別有利於決策的資訊的能力；',
  text004: '• 在獲取各種資訊後能夠對資訊進行整理、歸類和篩選，對事物的性質、內涵、狀態等形成清晰認識的能力；',
  text005: '• 在面對複雜問題時，保持頭腦清楚，並靈活應對的能力。',
  text006:
    '得賢圖形推理測試的得分能夠有效預測候選人在某些職位上未來的工作績效。這類職位通常需要候選人具備清晰而又準確地思維能力，能夠準確識別問題發生原因的能力，以及能夠利用已用資訊制定最優決策的能力。這些職位包括但不僅限於：管理崗，技術人員，諮詢人員等。',
  text007: '{name}百分位成績為:{score}',
  text008: '分數解讀',
  text009: '沒有內容可以查看',
}

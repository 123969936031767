export default {
  text001: 'Exam',
  text002: 'Assessment',
  text003: 'Add manually',
  text004: 'Examination position',
  text005: 'Grade',
  text006: 'Report',
  text007: 'Preview',
  text008: 'Return',
  text009: 'Save',
  text010: 'Add exam/assessment',
  text011: 'Name',
  text012: 'Please enter a name',
  text013: 'Grade',
  text014: 'Please enter the grade',
  text015: 'Type',
  text016: 'Report',
  text017: 'Click to upload',
  text018: 'Supports the preview of pdf, doc(x), and txt files, the maximum of which does not exceed 50M',
  text019: 'Scores or reports must have one filled with information',
  text020: 'The report file can only be uploaded once',
  text021: 'The upload format is incorrect',
  text022: 'The file size limit is 50M',
  text023: 'Upload failed, please try again',
  text024: 'Successful submission',
  text025: 'Updated successfully',
  text026: 'Deleted successfully',
  vendor: 'Supplier',
  haneo: 'Haneo Review',
  other: 'Others',
  reviewLink: 'Review Link',
  reviewStatus: 'Review Status',
  notReviewed: 'Not Reviewed',
  underReview: 'Under Review',
  reviewCompleted: 'Review Completed',
  whetherToDelete: 'Are you sure to delete the assessment?',
}

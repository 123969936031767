<template>
  <div class="company-awards">
    <img src="@/assets/images/company-awards/awards_title.svg" class="company-awards_img" />
    <div class="company-awards_title">公司奖项</div>
    <div class="company-time">
      <div class="time-line">
        <div class="line-s" :class="currentIndex === 0 ? 'bgw' : ''"></div>
        <div v-for="(line, index) in list" :key="index" class="line-item" :class="index + 1 === list.length ? '' : 'flex1'">
          <div class="line-dot" :class="currentIndex === index ? 'dot-active' : ''" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)" @click="setActiveItem(index)">
            <div class="label">{{ line.key }}</div>
            <div class="dot-o">
              <div class="dot-i"></div>
            </div>
          </div>
          <div class="line-l" :class="index === currentIndex - 1 ? 'bgw' : ''"></div>
        </div>
        <div class="line-s"></div>
      </div>

      <el-carousel ref="elCarouselAwards" indicator-position="outside" class="el-carousel_awards" :autoplay="false" arrow="always" :initial-index="0" @change="handleChange">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="l flex-center">
            <img :src="require(`@/assets/images/company-awards/${item.key}@2x.png`)" />
          </div>
          <div class="r">
            <div v-for="(tip, k) in item.tips" :key="k" class="tips">
              <div class="dot-blue"></div>
              <div class="dot-label">{{ tip }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          key: 2018,
          tips: ['2018哈佛创业创新大赛亚太区预选赛季军'],
        },
        {
          key: 2019,
          tips: ['2019上海最具潜力50佳创业企业', '2019科大讯飞全球1024开发者节“最具商业价值”奖', 'HR领域在中国人工智能协会和中国中文信息协会唯一一家企业会员单位'],
        },
        {
          key: 2020,
          tips: [
            '2020第四届“中国创翼”创业创新大赛优胜奖',
            '2020科大讯飞全球1024开发者节“最受喜爱展商”奖',
            '2020大中华地区 HRVP 高峰论坛“中国十大影响力人力资源品牌”',
            '2020大中华地区 HRVP 高峰论坛“最具创新招聘产品”',
          ],
        },
        {
          key: 2021,
          tips: ['2021年度十大影响力资源品牌', '2021年SAP Success Factor合作伙伴创新大赛黑科技奖', '2021年度最具投资价值奖'],
        },
        {
          key: 2022,
          tips: ['2022年，成功上架SAP APP Center，向全球企业提供真正智能的AI招聘和面试解决方案', '2022年，2022智享会招聘与任用供应商价值大奖'],
        },
      ],
      currentIndex: 0,
      autoPlay: true,
    }
  },
  methods: {
    handleChange(index) {
      this.currentIndex = index
    },
    mouseenter(index) {
      this.autoPlay = false
      this.setActiveItem(index)
    },
    mouseleave(index) {
      this.autoPlay = true
      this.setActiveItem(index)
    },
    setActiveItem(index) {
      this.$refs.elCarouselAwards && this.$refs.elCarouselAwards.setActiveItem(index)
    },
  },
}
</script>
<style lang="scss" scoped>
.company-awards {
  background: url('~@/assets/images/company-awards/bg.png');
  background-size: cover;
  color: #ffffff;
  text-align: center;
  // height: 440px;
  .company-awards_img {
    margin-top: 20px;
  }
  .company-awards_title {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    line-height: 28px;
    margin-bottom: 50px;
  }
  .company-time {
    // width: 1200px;
    margin: auto;
    .time-line {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .line-s {
        width: 28px;
        height: 2px;
        background: rgba(255, 255, 255, 0.15);
      }
      .line-item {
        // flex: 1;
        display: flex;
        align-items: center;
        .line-dot {
          position: relative;
          cursor: pointer;
          .label {
            position: absolute;
            top: -28px;
            left: -10px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(255, 255, 255, 0.85);
            line-height: 18px;
          }
          .dot-o {
            width: 16px;
            height: 16px;
            background: #3ba0ff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .dot-i {
              width: 7px;
              height: 7px;
              background: rgba(255, 255, 255, 0.65);
              border-radius: 50%;
            }
          }
        }
        .dot-active {
          .label {
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }
          .dot-o {
            .dot-i {
              background: #ffffff;
            }
          }
        }
        .line-l {
          flex: 1;
          height: 2px;
          background: rgba(255, 255, 255, 0.15);
        }
      }
      .flex1 {
        flex: 1;
      }
    }
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bgw {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%) !important;
}
</style>
<style lang="scss">
.el-carousel_awards {
  // width: 1040px;
  margin: auto;
  border-radius: 5px;
  .el-carousel__container {
    height: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 35, 67, 0.05);
    .el-carousel__item {
      display: flex;
      background: url('~@/assets/images/company-awards/awards_card_bg@2x.png');
      background-size: cover;

      .l {
        padding: 0 10px 0 5px;
        img {
          width: 120px;
        }
      }
      .r {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 5px;
        .tips {
          font-size: 14px;
          font-weight: 400;
          color: #555555;
          line-height: 18px;
          text-align: left;
          display: flex;
          align-items: baseline;
          .dot-blue {
            width: 5px;
            height: 5px;
            background: #ffffff;
            border: 4px solid #1990ff;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>

export default {
  text001: 'Comment result',
  text002: 'Type',
  text003: 'Score',
  text004: 'Please read the following precautions and fill them in carefully',
  text005: '①Each group has four options: A, B, C, and D, with scores of 1, 3, 5, and 7, respectively from the character that is least like you to the character that resembles you. ',
  text006: '②Each group 1, 3, 5, and 7 must appear and cannot be repeated. ',
  text007: '③ Summarize the scores of options A, B, C, and D in each group. The total score must be 80 points and each item must be an odd number. ',
  text008: 'Group',
  text009: 'Description and explanation of habits',
  text010: 'Self-rating',
}
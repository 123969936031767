<template>
  <div class="process-wrapper">
    <div class="process-item" :style="getActiveStyle(n)" v-for="n in colors" :key="n.key">
      {{ n.key === score ? n.label : '' }}
    </div>
  </div>
</template>
<script>
import i18n from '../../../utils/i18n'

export default {
  props: {
    score: { type: Number, default: 0 },
    type: { type: String, default: 'five' },
  },
  data() {
    return {
      scoreFiveColors: [
        {
          key: 1,
          inactiveColor: 'rgba(255, 234, 227, 1)',
          activeColor: 'rgba(243, 109, 85, 1)',
          boxShadow: '0px 2px 10px 0px #FF9683',
          label: i18n.t('com.report.reportV3.text086'),
        },
        {
          key: 2,
          inactiveColor: 'rgba(252, 242, 223, 1)',
          activeColor: 'rgba(251, 172, 0, 1)',
          boxShadow: '0px 2px 10px 0px rgba(255, 150, 113, 0.5)',
          label: i18n.t('com.report.reportV3.text087'),
        },
        {
          key: 3,
          inactiveColor: 'rgba(189, 231, 254, 1)',
          activeColor: 'rgba(135, 198, 255, 1)',
          boxShadow: '0px 2px 10px 0px rgba(24, 144, 255, 0.5)',
          label: i18n.t('com.report.reportV3.text088'),
        },
        {
          key: 4,
          inactiveColor: 'rgba(189, 231, 254, 1)',
          activeColor: 'rgba(86, 174, 255, 1)',
          boxShadow: '0px 2px 10px 0px rgba(24, 144, 255, 0.5)',
          label: i18n.t('com.report.reportV3.text089'),
        },
        {
          key: 5,
          inactiveColor: 'rgba(201, 244, 244, 1)',
          activeColor: 'rgba(0, 217, 199, 1)',
          boxShadow: '0px 2px 10px 0px rgba(0, 217, 199, 0.5)',
          label: i18n.t('com.report.reportV3.text090'),
        },
      ],
      scoreTenColors: [
        {
          key: 1,
          inactiveColor: 'rgba(255, 234, 227, 1)',
          activeColor: 'rgba(243, 109, 85, 1)',
          boxShadow: '0px 2px 10px 0px #FF9683',
          label: '1',
        },
        {
          key: 2,
          inactiveColor: 'rgba(255, 234, 227, 1)',
          activeColor: 'rgba(243, 109, 85, 1)',
          boxShadow: '0px 2px 10px 0px #FF9683',
          label: '2',
        },
        {
          key: 3,
          inactiveColor: 'rgba(252, 242, 223, 1)',
          activeColor: 'rgba(251, 172, 0, 1)',
          boxShadow: '0px 2px 10px 0px rgba(255, 150, 113, 0.5)',
          label: '3',
        },
        {
          key: 4,
          inactiveColor: 'rgba(252, 242, 223, 1)',
          activeColor: 'rgba(251, 172, 0, 1)',
          boxShadow: '0px 2px 10px 0px rgba(255, 150, 113, 0.5)',
          label: '4',
        },
        {
          key: 5,
          inactiveColor: 'rgba(189, 231, 254, 1)',
          activeColor: 'rgba(135, 198, 255, 1)',
          boxShadow: '0px 2px 10px 0px rgba(24, 144, 255, 0.5)',
          label: '5',
        },
        {
          key: 6,
          inactiveColor: 'rgba(189, 231, 254, 1)',
          activeColor: 'rgba(135, 198, 255, 1)',
          boxShadow: '0px 2px 10px 0px rgba(24, 144, 255, 0.5)',
          label: '6',
        },
        {
          key: 7,
          inactiveColor: 'rgba(189, 231, 254, 1)',
          activeColor: 'rgba(86, 174, 255, 1)',
          boxShadow: '0px 2px 10px 0px rgba(24, 144, 255, 0.5)',
          label: '7',
        },
        {
          key: 8,
          inactiveColor: 'rgba(189, 231, 254, 1)',
          activeColor: 'rgba(86, 174, 255, 1)',
          boxShadow: '0px 2px 10px 0px rgba(24, 144, 255, 0.5)',
          label: '8',
        },
        {
          key: 9,
          inactiveColor: 'rgba(201, 244, 244, 1)',
          activeColor: 'rgba(0, 217, 199, 1)',
          boxShadow: '0px 2px 10px 0px rgba(0, 217, 199, 0.5)',
          label: '9',
        },
        {
          key: 10,
          inactiveColor: 'rgba(201, 244, 244, 1)',
          activeColor: 'rgba(0, 217, 199, 1)',
          boxShadow: '0px 2px 10px 0px rgba(0, 217, 199, 0.5)',
          label: '10',
        },
      ],
    }
  },

  computed: {
    colors() {
      return this.type === 'five' ? this.scoreFiveColors : this.scoreTenColors
    },
  },

  methods: {
    getActiveStyle(n) {
      return {
        background: n.key === this.score ? n.activeColor : n.inactiveColor,
        'box-shadow': n.key === this.score ? n.boxShadow : '',
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.process-wrapper {
  display: flex;
  margin-top: 6px;
  .process-item {
    flex: 1;
    height: 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .process-item:not(:first-child) {
    margin-left: 2px;
  }
}
</style>

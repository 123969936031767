<template>
  <div class="ai-solution">
    <Header></Header>
    <section class="section1">
      <div class="barnner">
        <img class="banner-img" src="./images/banner.png" />
        <div class="text-wrapper">
          <span>直击痛点</span>
          <span>综合解决方案</span>
        </div>
      </div>
    </section>
    <section class="section2" ref="section2">
      <div class="content1">
        <div class="text">覆盖10<sup>+</sup>行业</div>
        <div class="map">
          <img src="./images/map.png" width="100%" />
        </div>
      </div>
      <div class="content2">
        <div class="text-wrapper">
          <div class="text-title">通用岗位胜任力模型覆盖广泛</div>
          <div class="image-box">
            <img src="./images/1.png" width="100%" />
          </div>
          <div class="list-wrapper">
            <div class="list-row">
              <span class="list-col">销售</span>
              <span class="list-col">金融/财务/行政文员</span>
            </div>
            <div class="list-row">
              <span class="list-col">医药代表/校招管培生</span>
              <span class="list-col">客服/技术工人</span>
            </div>
            <div class="list-row">
              <span class="list-col">IT/程序员/保险代理人</span>
              <span class="list-col">教育</span>
            </div>
            <div class="list-row">
              <span class="list-col">工程师/运营/市场</span>
              <span class="list-col">供应链及更多</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-txt" v-for="(item, index) in contentData" :key="index">
      <div class="title">
        {{ item.title }}
      </div>
      <div class="conet-wrapper">
        <img :src="item.imgUrl" width="68.27%" />
        <div class="text-wrapper">
          <div class="g-text-box" v-for="(value, Index) in item.txtArray" :key="Index">
            <p class="label">{{ value.label }}</p>
            <p class="desc" v-for="(v, i) in value.desc" :key="i">{{ v }}</p>
          </div>
        </div>
      </div>
    </section>
    <tools style="margin-top:40px;"></tools>
  </div>
</template>

<script>
import Tools from '@/components/tools'
import Header from '@/components/menu'
export default {
  components: { Tools, Header },
  data() {
    return {
      contentData: [
        {
          title: '校园招聘',
          imgUrl: require('./images/2.png'),
          txtArray: [
            {
              label: '招聘痛点',
              desc: ['简历投递量大，筛选效率低下；', '企业内部人员难调配，校招面试流程长。'],
            },
            {
              label: 'AI得贤招聘官为您解决',
              desc: ['AI面试官7*24小时不停歇；', '海量候选人轻松面完，全息简历灵活流转；', '随身移动招聘，管理灵活便捷，沟通决策更为高效。'],
            },
          ],
        },
        {
          title: '程序员',
          imgUrl: require('./images/3.png'),
          txtArray: [
            {
              label: '招聘痛点',
              desc: ['海量简历要筛选，候选人实际水平难评估；', 'HR完全不懂技术决策标准难统一。'],
            },
            {
              label: 'AI得贤招聘官为您解决',
              desc: ['硬技能、软素质，Al面试来评估；', '技术好?编程强?线上编译见分晓；', 'HR不需要掌握IT技能，就可以轻松选出优秀的候选人。'],
            },
          ],
        },
        {
          title: '金牌销售',
          imgUrl: require('./images/4.png'),
          txtArray: [
            {
              label: '招聘痛点',
              desc: ['候选人跨区域分布，线下面试难度大；', '销售业绩难预测，试用期内离职率高。'],
            },
            {
              label: 'AI得贤招聘官为您解决',
              desc: ['高精度简历解析，匹配符合发展要求的候选人；', '远程面试+Al面试相结合，沟通效率高。'],
            },
          ],
        },
      ],
    }
  },
  created() {
    document.title = '解决方案-AI得贤招聘官'
  },
}
</script>

<style lang="scss" scoped>
.ai-solution {
  overflow: hidden;
  .section1 {
    position: relative;
    // padding-top: 60px;
    .barnner {
      position: relative;
      max-height: 240px;
      overflow: hidden;
      .banner-img {
        display: block;
        width: 100%;
        height: auto;
      }
      .text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 240px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          display: block;
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
          line-height: 34px;
          letter-spacing: 3px;
        }
      }
    }
  }
  .section2 {
    .content1 {
      padding: 47px 0 27px;
      .text {
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 21px;
        letter-spacing: 2px;
        text-align: center;
      }
      .map {
        margin-top: 18px;
        img {
          display: block;
        }
      }
    }
    .content2 {
      .text-wrapper {
        padding: 50px 25px;
        background: #f2f4f8;
        .text-title {
          font-size: 15px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 21px;
          margin-bottom: 18px;
          text-align: center;
        }
        .image-box {
          text-align: center;
          width: 82.46%;
          margin: 0 auto;
          img {
            display: block;
          }
        }
        .list-wrapper {
          margin-top: 13px;
          .list-row {
            margin-bottom: 10px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            &:last-child {
              margin: 0;
            }
            .list-col {
              display: inline-block;
              font-size: 12px;
              font-weight: 400;
              width: 45%;
              color: #333333;
              line-height: 17px;
              letter-spacing: 1px;
              &::before {
                display: inline-block;
                content: '';
                width: 9px;
                height: 9px;
                margin-right: 4px;
                background: url('./images/hook.png');
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }
  .section-txt {
    padding-top: 50px;
    > .title {
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
    .conet-wrapper {
      padding-top: 25px;
      text-align: center;
      .text-wrapper {
        padding-top: 5px;
        .g-text-box {
          padding-top: 20px;
          > p {
            margin: 0;
          }
          .label {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            line-height: 20px;
            color: #a5acbd;
            margin-bottom: 10px;
          }
          .desc {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>

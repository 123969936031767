export default {
  text001: '附件',
  text002: '预览',
  text003: '链接',
  text004: '背景调查资料表',
  text005: '1.是否完成证明人调查（工作履历/违纪情况）:',
  text006: '2.是否存在舞弊记录:',
  text007: '3.违纪查询结果:',
  text008: '是',
  text009: '否',
  text010: '确认删除{0}?',
  text011: '删除成功',
  text012: '删除失败',
}

<template>
  <!-- 得分总览 -->
  <van-panel class="module" title="得分总览">
    <van-row style="margin: 0px; padding-right: 20px" type="flex" justify="space-between">
      <van-col span="8">
        <div>
          <div class="card">
            <div class="font-12 margin-bottom-5" style="padding-top: 5px">AI人才画像总分</div>
            <van-circle ref="circle" v-model="value1" size="50" layer-color="#d5d5d5" :color="gradientColor2" :stroke-width="80" :rate="aiData.aiallscore * 20" :text="`${aiData.aiallscore || 0}分`" />
            <div class="font-mini font-light">总分 5</div>
          </div>
        </div>
      </van-col>
      <van-col span="8">
        <div>
          <div class="card">
            <div class="font-12 margin-bottom-5" style="padding-top: 5px">定制化考核项目</div>
            <van-circle v-model="value2" :color="gradientColor" layer-color="#d5d5d5" :rate="rate" size="50" :stroke-width="80" :text="`${isNoshow ? labelGetScore + '分' : '无'}`" />
            <div class="font-mini font-light">总分 {{ isNoshow ? labelTotalScore : '无' }}</div>
          </div>
        </div>
      </van-col>
      <van-col span="8">
        <div>
          <div class="card right-card">
            <div>用时{{ timeStatus }}</div>
            <div>{{ usetime || 0 }}</div>
            <van-progress :percentage="percents" :show-pivot="false" />
            <van-row type="flex" class="font-light" justify="space-between">
              <span>过短</span>
              <span>正常</span>
              <span>过长</span>
            </van-row>
          </div>
        </div>
      </van-col>
    </van-row>
    <DescList title="AI Talent-DNA 得分" desc="基于篇章级别的自然语言算法对候选人回答问题的质量的综合判断得分" :totle="5" :score="aiData.aitdna"></DescList>
    <DescList title="宏表情分析得分" desc="基于宏表情分析的计算机视觉算法对候选人情绪和回答问题真实性的综合判断得分" :totle="5" :score="aiData.aiemotion"></DescList>
    <DescList title="AI 声音" desc="基于人工智能的语音算法对候选人回答问题时音量音调语速紧张程度和流畅程度的综合判断得分" :totle="5" :score="aiData.aispeech"></DescList>
    <van-row style="padding: 20px 20px 0px 0px">
      <div>五分制说明</div>
      <van-row type="flex" justify="space-between" style="font-size: 8px; color: #666666; margin: 7px 0 2px">
        <span>0</span>
        <span>5</span>
      </van-row>
      <van-progress :percentage="percentage" :show-pivot="false" />
      <van-row type="flex" class="font-light" style="font-size: 8px; color: #666666; margin-top: 10px" justify="space-between">
        <span>完全不胜任</span>
        <span>不胜任</span>
        <span>胜任</span>
        <span>优秀</span>
        <span>超出期望</span>
      </van-row>
    </van-row>
  </van-panel>
</template>
<script>
import DescList from '../components/DescList'

export default {
  components: {
    DescList,
  },
  props: ['aiData', 'inteviewInfo', 'labelGetScore', 'labelTotalScore', 'usetime', 'isNoshow'],
  data() {
    return {
      value1: 0,
      value2: 0,
      gradientColor: '',
      gradientColor2: '',
      rate: 0,
    }
  },
  computed: {
    percents() {
      if (this.aiData.totaltime && this.aiData.totaltime !== -1) {
        const usetime = this.usetime && this.usetime.indexOf('分') > -1 && this.usetime.indexOf('秒') > -1
        if (usetime) {
          var use = this.usetime.split('分')[0] * 60 + Number(this.usetime.split('分')[1].split('秒')[0])
          return (use / this.aiData.totaltime) * 100 > 100 ? 100 : (use / this.aiData.totaltime) * 100
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    // 状态
    timeStatus() {
      if (this.percents < 30) {
        return '过快'
      } else if (this.percents >= 30 && this.percents < 90) {
        return '正常'
      } else if (this.percents >= 90) {
        return '较长'
      }
      return 0
    },
    percentage() {
      let num = this.aiData.aiallscore ? (this.aiData.aiallscore / 5) * 100 : 0
      if (num >= 100) num = 100
      return num
    },
  },
  watch: {
    labelGetScore(val) {
      let color = val ? { '0%': '#35C3FF', '100%': '#1890FF' } : '#d5d5d5'
      this.gradientColor = color
      this.rate = (val / this.labelTotalScore) * 100
    },
    aiData: {
      deep: true,
      handler(val) {
        let color = val.aiallscore ? { '0%': '#35C3FF', '100%': '#1890FF' } : '#d5d5d5'
        this.gradientColor2 = color
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../common/styles.scss';
</style>

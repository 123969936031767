<template>
  <div class="remark-content">
    <div class="wrapper">
      <div>
        <van-field v-model="remarkContent" rows="4" autosize type="textarea" maxlength="500" :placeholder="$t('com.report.remark.text001')" show-word-limit />
        <div class="button-group">
          <!-- <div class="cancle" @click="remarkContent = ''">取消</div> -->
          <div class="confirm" @click="submit">{{ $t('com.report.remark.text002') }}</div>
        </div>
      </div>

      <van-steps direction="vertical" v-if="remarkList && remarkList.length">
        <van-step v-for="item in remarkList" :key="item.id">
          <div class="spot" slot="active-icon"></div>
          <div class="spot" slot="inactive-icon"></div>

          <div class="title">
            <span>{{ moment(item.createdTime).format('YYYY/MM/DD HH:mm') }}</span>
            <span class="delete" :title="$t('com.report.remark.text003')" @click="deleteMark(item)" v-if="false"><i class="el-icon-delete"></i></span>
          </div>
          <div class="content" v-if="item.estimateType === 4">
            <p class="content_title" :style="{ fontWeight: item.source === 8 ? 'bold' : 'normal' }">
              <span :class="{ conmunicate: item.source === 8 }">{{ item.estimateUserName }}</span
              >添加了
              <span>{{ item.source | translateEstimateTypeTextBySource }}</span>
            </p>
            <div class="box">
              <pre>{{ item.estimateContent }}</pre>
            </div>
          </div>
          <div class="content" v-else>
            <p class="content_title">
              {{ item.estimateUserName }}添加了{{ item.estimateType === 1 ? '简历评价' : item.estimateType === 2 ? '综合评估' : '人工视频评价'
              }}<span>
                <span v-if="item.result && item.result !== -1">
                  <span v-if="item.estimateType === 2 || item.estimateType === 3"> ( {{ item.result | filterEstimate }} )</span>
                  <span v-if="item.estimateType === 1"> ( {{ item.result | filterEstimate }})</span>
                </span>
              </span>
            </p>
            <div class="rat" v-if="item.estimateScore !== null && item.estimateScore !== -1">
              <van-rate v-model="item.estimateScore" color="#ffd21e" :size="14" :gutter="0" void-icon="star" void-color="#ffffff" readonly />
            </div>
            <div class="box">
              <pre>{{ item.estimateContent }}</pre>
            </div>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { selectAllEstimate } from '@/api/mark'
import { addComment } from '@/api/candidate'
import { Dialog } from 'vant'
import EventBus from '@/common/event-bus'
import i18n from '../../utils/i18n'
export default {
  data() {
    return {
      remarkContent: '',
      remarkList: [],
      moment,
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    jobseekerChannelId() {
      return this.$store.state.jobseekerChannelId
    },
    interviewId() {
      return this.$store.state.interviewId
    },
    userId() {
      if (this.$store.state.userInfo && this.$store.state.userInfo.userId) {
        return this.$store.state.userInfo.userId
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.listEstimate()
  },
  filters: {
    translateEstimateTypeTextBySource(source) {
      switch (source) {
        case 4:
          return i18n.t('com.report.remark.text004')
        case 8:
          return i18n.t('com.report.remark.text005')
      }
      return ''
    },
    filterEstimate(val) {
      if (val === 1) {
        return i18n.t('com.report.remark.text006')
      } else if (val === 2) {
        return i18n.t('com.report.remark.text007')
      } else if (val === 3) {
        return i18n.t('com.report.remark.text008')
      }
    },
  },
  methods: {
    submit() {
      if (!(this.userInfo && this.userInfo.userId)) {
        return Dialog.confirm({
          title: i18n.t('com.report.remark.text009'),
          confirmButtonColor: '#1989fa',
        }).then(() => {
          EventBus.$emit('toLogin')
        })
      }
      if (this.remarkContent) {
        var data = {
          estimateText: this.remarkContent,
          jobseekerChannelId: this.$store.state.jobseekerChannelId || undefined,
          source: 4,
          type: 2,
          isModify: 0,
          isAdd: 0,
          userId: this.userId,
        }
        addComment(data).then(res => {
          if (res && res.code === 0) {
            this.$notify({ type: 'success', message: i18n.t('com.report.remark.text010') })
            this.remarkContent = ''
            this.listEstimate()
          } else {
            this.$toast({ type: 'danger', message: res.msg })
            if (res.code === 1004 || res.code === 1001) {
              EventBus.$emit('toLogin', '1004')
            }
          }
        })
      } else {
        this.$toast({ type: 'info', message: i18n.t('com.report.remark.text011') })
      }
    },
    listEstimate() {
      var params = {}

      params = {
        jobseekerChannelId: this.jobseekerChannelId,
      }

      params.interviewId = this.interviewId
      params.estimateMode = 8

      selectAllEstimate(params).then(res => {
        if (res && res.code === 0) {
          this.remarkList = (res.data || []).filter(item => {
            return item.estimateType !== 1 && item.estimateType !== 2 && item.estimateType !== 3
          })
          this.$store.commit('setRemarkList', this.remarkList)
        }
      })
    },
  },
}
</script>
<style lang="scss">
.remark-content {
  width: 100%;
  padding-top: 1rem;
  .wrapper {
    width: 90%;
    margin: 0 auto;

    .van-field {
      width: 100%;
      padding: 0;
      border-radius: 5px;
      border: 0.03rem solid #eaeaea;
      background: #fafafa;
      padding: 5px;
    }

    .button-group {
      width: 100%;
      // padding: 0 0.8rem;
      margin: 2vh auto 0 auto;
      display: flex;
      justify-content: flex-end;

      div {
        padding: 0.18rem 0.4rem;
        font-size: 0.3rem;
        border-radius: 5px;
      }

      .cancle {
        border-radius: 0.06rem;
        border: 0.03rem solid #d9d9d9;
        margin-right: 0.6rem;
      }
      .confirm {
        background-color: #1890ff;
        color: #ffffff;
      }
    }
  }
  .van-step__title--active {
    color: #999999;
  }
  .spot {
    width: 0.4rem;
    height: 0.4rem;
    background: rgba(24, 144, 255, 1);
    border: 4px solid #b9ddff;
    border-radius: 100%;
  }

  .conmunicate {
    color: #1890ff;
    margin-right: 10px;
  }

  .content {
    .box {
      pre {
        white-space: pre-wrap;
      }
    }
  }
}
</style>

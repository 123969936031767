<template>
  <van-popup v-model="realVisible" position="bottom" :style="{ height: '100%', overFlow: 'hidden' }" :close-on-click-overlay="false">
    <div class="drawer-title">
      <div @click="toEdit">{{ $t('com.report.interviewPlan.text016') }}</div>
      <div><van-icon name="cross" @click="$emit('close')" /></div>
    </div>
    <detail ref="content" v-if="feedbackType === 'detail' && visible" :info="info" :feedback="feedback" @edit="toEdit" :curTemplateInfo="curTemplateInfo"></detail>
    <edit ref="content" :curInterviewerItem="curInterviewerItem" v-if="feedbackType === 'edit' && visible" :info="info" :feedback="feedback" @closed="closed" :curTemplateInfo="curTemplateInfo"></edit>
  </van-popup>
</template>

<script>
import detail from '@/components/feedback/detail'
import edit from '@/components/feedback/edit'
export default {
  components: {
    detail,
    edit,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return null
      },
    },
    feedback: {
      type: Object,
      default() {
        return null
      },
    },
    feedbackType: {
      type: String,
      default: 'detail',
    },
    curInterviewerItem: {
      type: Object,
      default() {
        return null
      },
    },
    curTemplateInfo: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      realVisible: this.visible,
    }
  },
  computed: {
    feedbackId() {
      return (this.feedback || {}).id
    },
  },
  methods: {
    cancel() {
      this.realVisible = false
      this.closed()
    },
    toEdit() {
      this.$emit('update:feedbackType', 'edit')
    },
    handleClose(done) {
      if (this.feedbackType === 'edit') {
        done()
        // this.$confirm('检查到当前页面有未保存的内容，关闭后将丢失这部分信息', '确认离开？', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // })
        //   .then(() => {
        //     done()
        //   })
        //   .catch(() => {})
      } else {
        done()
      }
    },
    closed() {
      if (this.$refs.content) {
        this.$refs.content.closed()
      }
      this.$emit('update:visible', false)
    },
  },
  watch: {
    visible(value) {
      this.realVisible = value
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-drawer__close-btn {
  outline: none;
}
.drawer-title {
  padding: 12px 24px 12px 24px;
  font-size: 24px;
  font-weight: bold;
  color: #121316;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  line-height: 60px;
}
/deep/.el-drawer__body {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

import service from '@/config/http'
import qs from 'qs'

//
export const selectAllEstimate = data => {
  const req = {
    url: '/mark/employ/estimate/selectAllEstimate',
    params: data,
  }
  return service(req)
}

export const findId = data => {
  const req = {
    url: '/candidate/employment/interview/findId',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
// 手机端面试报告
export const reportNew4Mobile = data => {
  const req = {
    url: '/mark/interview/interview/reportNew4Mobile',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
// 教育经历
export const findAllEducation = data => {
  const req = {
    url: '/candidate/employment/edution/findAllEducation',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
// 查询面试列表
export const queryInterviewPlan = data => {
  const req = {
    url: '/candidate/interviewPlan/queryInterviewPlan',
    data,
    method: 'post',
  }
  return service(req)
}
// 工作经历
export const findAllWorkInfo = data => {
  const req = {
    url: '/candidate/employment/work/findAllWorkInfo',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
// 工作经历
export const interviewInfo = data => {
  const req = {
    url: '/mark/interview/interview/interviewInfo',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}

// 报告
export const reportNew = data => {
  const req = {
    url: '/mark/interview/interview/reportNew',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
// 校验密码
export const checkReportPwd = data => {
  const req = {
    url: '/candidate/employment/interview/checkReportPwd',
    data,
    method: 'post',
  }
  return service(req)
}
// 分享
export const shareByEmail = data => {
  const req = {
    url: '/candidate/jobseekerChannel/jobseekerChannel/shareByEmail',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
// candidate/jobseekerChannel/jobseekerChannel/transmitResume
// 转发候选人
export const transmitResume = data => {
  const req = {
    url: '/candidate/jobseekerChannel/jobseekerChannel/transmitResume',
    data,
    method: 'post',
  }
  return service(req)
}
//获取基本信息
export const interviewInfoTalent = data => {
  const req = {
    url: '/candidate/employ/airesult/interviewInfo',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}

//文件格式转化
export const convertFile = data => {
  const req = {
    url: '/file/convert/getUrl',
    params: data,
    method: 'get',
  }
  return service(req)
}
// 评价简历或者报告
export const addComment = data => {
  const req = {
    url: '/candidate/EstimateResume/EstimateResume/addEstimateResume',
    data,
    method: 'post',
  }
  return service(req)
}
export const getUserInfo = data => {
  const req = {
    url: '/open/dingtalk/user/getUserInfo',
    params: data,
    // method: 'post',
  }
  return service(req)
}
export const platformLogin = data => {
  const req = {
    url: '/admin/platformLogin',
    params: data,
    // method: 'post',
  }
  return service(req)
}
// /open/dingtalk/user/getUserInfo
export const createInterviewFeedback = data => {
  const req = {
    url: '/candidate/interviewPlan/createOrUpdateInterviewFeedback',
    data,
    method: 'post',
  }
  return service(req)
}
// 初筛个人简历
export const jobseekerChannelInfo = data => {
  const req = {
    url: '/candidate/jobseekerChannel/jobseekerChannel/jobseekerChannelInfo',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}

// 获取钉钉 js-api ticket
export const getJsApiTicket = data => {
  const req = {
    url: '/open/dingtalk/jsapi/getJsApiTicket',
    method: 'get',
    params: data,
  }
  return service(req)
}

// 分享买你是报告
export const shareReportOrResume = data => {
  const req = {
    url: '/candidate/jobseekerChannel/jobseekerChannel/shareReportOrResume',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
//获取所有简历评价
export const listEstimate = data => {
  console.log(data)
  const req = {
    url: '/candidate/EstimateResume/EstimateResume/listEstimate',
    data,
    method: 'post',
  }
  return service(req)
}

//添加或保存应聘注册表项目
export const getEmployInfo = data => {
  const req = {
    url: '/candidate/registerForm/info/getEmployInfo',
    params: data,
  }
  return service(req)
}

//获取获取系统模板
export const getTempleteList = data => {
  const req = {
    url: '/candidate/registerForm/info/getTempleteList',
    params: data,
  }
  return service(req)
}

//获取所有应聘登记表列表
export const showFormsList = data => {
  const req = {
    url: '/candidate/registerForm/info/showFormsList',
    params: data,
  }
  return service(req)
}
export const getPositionRelationFormId = data => {
  const req = {
    url: '/candidate/registerForm/info/getPositionRelationFormId',
    params: data,
    method: 'get',
  }
  return service(req)
}

//获取获取系统模板
export const sendMsg = data => {
  const req = {
    url: '/open/dingtalk/sendMsg',
    params: data,
  }
  return service(req)
}

// 提交评估
export const submitEstimate = data => {
  const req = {
    url: '/mark/interview/interview/submitEstimate',
    data,
    method: 'post',
  }
  return service(req)
}

// 查看评估
export const listInterviewOperLog = data => {
  const req = {
    url: '/mark/interview/interview_opr_log/listInterviewOperLog',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}

//获取人工综合评价记录
export const showComprehensiveEstimate = data => {
  const req = {
    url: '/candidate/employ/estimate/showComprehensiveEstimate',
    params: data,
  }
  return service(req)
}

//添加人工评价
export const addComprehensiveEstimate = data => {
  const req = {
    url: '/candidate/employ/estimate/addComprehensiveEstimate',
    data,
    method: 'post',
  }
  return service(req)
}

//mark里面的获取人工综合评价记录
export const markShowComprehensiveEstimate = data => {
  const req = {
    url: '/mark/employ/estimate/showComprehensiveEstimate',
    params: data,
  }
  return service(req)
}

//mark里面的添加人工评价
export const markAddComprehensiveEstimate = data => {
  const req = {
    url: '/mark/employ/estimate/addComprehensiveEstimate',
    data,
    method: 'post',
  }
  return service(req)
}

//获取项目经历
export const getProjectExperience = data => {
  const req = {
    url: '/candidate/employment/work/getProjectExperience',
    params: data,
    method: 'get',
  }
  return service(req)
}

// 手机端面试报告v2
export const report4MobileV2 = data => {
  const req = {
    url: '/mark/interview/interview/report4MobileV2',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}

// 获取简历入库时间
export const jobseekerResumeInfo = data => {
  const req = {
    url: '/candidate/jobseekerChannel/jobseekerChannel/jobseekerResumeInfo',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}

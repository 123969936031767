<template>
  <div class="program">
    <img class="bg" src="./images/banner.png" alt="" />
    <Header></Header>
    <section class="section1">
      <div class="barnner">
        <div class="text-wrapper">
          <div class="left">
            <div class="title">
              <p>在线模拟编程</p>
              <div class="line1"></div>
              <div class="line2"></div>
            </div>
            <div class="detail">AI得贤招聘官提供的在线模拟编程考试系统，可替代传统的笔试形式，帮助企业高效、精准、低成本的甄选出优质候选人。</div>
          </div>
          <div class="right">
            <img src="./images/program_opera.png" alt="" width="680" />
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <h3>程序员招聘痛点</h3>
      <ul>
        <li>
          <p class="title">海量简历要筛选<br />候选人实际水平难评估</p>
          <p class="detail">对于HR来说，要在巨量的纸质简历中，甄别出代码编写能力强的候选人，是一件非常困难的事情。</p>
        </li>
        <li>
          <p class="title">HR不是技术出身<br />评估候选人代码质量难度大</p>
          <p class="detail">HR作为人才选拔的第一守门人，对人才质量的把控至关重要。但是，在现实场景中，由于HR完全不是技术出身，面对不同的候选人，不同的HR给出的评判结果也会大相径庭。</p>
        </li>
        <li>
          <p class="title">面试结果难同步<br />内部沟通成本高</p>
          <p class="detail">在多场面试中，不同的候选人在应对不同面试官时，由于面试官之间的面试结果同步不及时，会直接导致内部沟通成本的增加。</p>
        </li>
      </ul>
    </section>
    <section class="section3">
      <div class="content">
        <h3>AI得贤招聘官解决方案</h3>
        <div class="box">
          <div class="left">
            <img src="./images/program_solve.png" alt="" />
          </div>
          <div class="right">
            AI得贤招聘官提供的在线编程考试系统，不仅可针对多种编程语言的在线编程能力测试，还支持核心代码和ACM格式。目前支持java，python，c，c#，c++，JavaScript等多种语言，即将支持php, ruby, mysql,
            css, html, go, shell, perl。<br /><br />
            AI得贤招聘官提供的在线编程考试系统，企业不仅可以自定义考试问题，同时系统内置海量专业考试题库，企业可以随心选择。<br /><br />
            AI得贤招聘官提供的模拟编程，不仅可以评估候选人的代码输出结果，还可以考核候选人的代码质量，候选人完成面试后，系统会自动给出面试分数，让编程能力考核不再纸上谈兵，大幅降低成本，帮助HR按照统一的标准快速甄选优质候选人。<br /><br />
            AI得贤招聘官提供的模拟编程，可全方位记录候选人的面试过程以及每一位面试官对该候选人的评价，保证了在多场面试中，每一位面试官都能清楚的了解该候选人的胜任力情况，大大降低了沟通成本。同时，系统内置的防作弊系统，也保障了面试流程的公平、公正。
          </div>
        </div>
      </div>
    </section>
    <tools></tools>
  </div>
</template>

<script>
import Tools from '@/components/tools'
import Header from '@/components/menu'
export default {
  components: { Tools, Header },
  data() {
    return {}
  },
  created() {
    document.title = '模拟编程-AI得贤招聘官'
  },
}
</script>

<style lang="scss" scoped>
.program {
  position: relative;
  // padding-top: 60px;
  .bg {
    width: 100%;
    height: 640px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .section1 {
    .barnner {
      position: relative;
      padding: 0 25px;
      overflow: hidden;
      .text-wrapper {
        .left {
          overflow: hidden;
          color: #fff;
          .title {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 3px;
            position: relative;
            margin-top: 103px;
            text-align: center;
            p {
              margin: 0;
            }
            .line1,
            .line2 {
              display: none;
            }
          }
          .detail {
            margin-top: 25px;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
          }
        }
        .right {
          display: inline-block;
          width: 100%;
          margin-top: 50px;
          > img {
            width: 100%;
            height: 197px;
          }
        }
      }
    }
  }
  .section2 {
    padding: 50px 25px 56px;
    h3 {
      font-size: 15px;
      color: #ffffff;
      line-height: 21px;
      text-align: center;
      font-weight: 600;
    }
    ul {
      margin: 31px auto 0;
      z-index: 1;
      li {
        width: 100%;
        height: 160px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e1e6f4;
        padding: 15px 27px 24px 28px;
        box-sizing: border-box;
        margin-bottom: 25px;
        overflow: hidden;
        p {
          margin: 0;
        }
        &:first-child {
          height: 127px;
        }
        &:last-child {
          height: 143px;
          margin: 0;
        }
        .title {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          line-height: 20px;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 3px;
            height: 3px;
            border-radius: 1.5px;
            background: #333333;
            position: absolute;
            left: -5px;
            top: 10px;
          }
        }
        .detail {
          font-size: 12px;
          color: #aaaaaa;
          line-height: 17px;
          margin-top: 15px;
        }
      }
    }
  }
  .section3 {
    padding: 0 25px;
    .content {
      h3 {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        text-align: center;
        line-height: 21px;
      }
      .box {
        margin-top: 25px;
        overflow: hidden;
        .left {
          width: 100%;
          img {
            width: 100%;
            display: block;
          }
        }
        .right {
          width: 100%;
          padding-top: 35px;
          padding-bottom: 114px;
          float: left;
          font-size: 12px;
          color: #627098;
          line-height: 22px;
        }
      }
    }
  }
  .bg1 {
    position: absolute;
    left: 0;
    bottom: 489px;
    width: 215px;
    height: 430px;
    overflow: hidden;
    img {
      width: 215px;
      height: 430px;
      position: relative;
      left: 0px;
      top: 0;
    }
  }
}
</style>

export default {
  text001: '职业性格测评',
  text002:
    '得贤职业性格测试是一套专门针对商业组织应用的职业性格测量问卷。 该工具专注于测量常态下的职业性格特征，共包含七个测量维度，用以描述候选人在职场的表现，包括：如何在压力下调节情绪，如何与他人互动沟通，如何处理工作任务，以及如何解决问题等。该报告逐一呈现各维度的分数，指出了候选人在每个维度上的优点和缺点，为企业在人才招聘，领导力发展，接班人计划和人才管理项目上提供指导性的意见和建议。',
  text003: '报告总结',
  text004: '该候选人',
  text005: '她',
  text006: '他',
  text007: '基于{name}在得贤职业性格测试中的回答，在日常情况下，{ta}倾向于：',
  text008: '没有内容可以查看',
  text009: '责任心',
  text010: '好奇心',
  text011: '和谐性',
  text012: '适应性',
  text013: '社交性',
  text014: '抱负心',
  text015: '责任心',
  text016: '好奇心',
  text017: '自律性',
  text018: '和谐性',
  text019: '适应性',
  text020: '含蓄，安静，不好交际，喜欢独处；抑制,谨慎,对外部世界不太感兴趣。',
  text021: '外向开朗，乐于被人陪伴，主动寻求关注；热情、果断、充满活力、健谈。',
  text022: '对负面事件更敏感；情绪调节能力差；在感受到强烈诱惑时，不容易抑制。',
  text023: '沉着冷静，压力下依然能够保持情绪稳定，头脑清晰；自我控制强，能够抵挡诱惑。',
  text024: '注重实效,偏爱常规,比较传统和保守；遵守惯例，缺乏创新。',
  text025: '富有想象力和创造力，偏爱抽象思维；好奇心强，追求艺术享受；主动求变，个体在从事某项任务时愿意考虑尝试新想法。',
  text026: '与关心他人相比，更加关注自己的利益；冷淡且态度强硬，直率，敢于直面冲突。',
  text027: '温暖热情，体贴友好，乐于助人；愿意谦让，规避冲突，总是能和他人和谐相处。',
  text028: '消极，被动，做事拖拉；冲动，无序，做事马虎；按部就班，不在意工作结果。',
  text029: '可靠，尽责，做事积极；有条理，谨慎，注重细节；工作勤奋，热情度高，追求高质量完成任务。',
  text030: '安于现状，对挑战或困境持悲观态度；在困难/挫折面前会认为达成目标遥不可及，从而采取消极回避行为。',
  text031: '成就导向，好胜心强，敢于设置挑战性目标；面对困难或深陷危急时，不轻言放弃，能坚持不懈地尝试各种方法克服障碍，完成任务。',
  text032: '反复无常，心口不一;对不良行为接纳度较高;厚此薄彼，偏袒他人。',
  text033: '遵守诺言，言出必行；严于律己，严格遵守规章制度；公平公正，一视同仁。',
}

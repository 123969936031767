<template>
  <div class="estimate-record-dialog">
    <van-popup v-model="showRefuseDialog" position="bottom" :style="{ height: '70%' }" round :close-on-click-overlay="false">
      <div class="estimate-top-row">
        <span class="text">{{ $t('com.report.estimate.text026') }}</span>
        <van-icon name="cross" @click="$emit('close')" />
      </div>
      <div class="refuseDialog">
        <div class="estimate-log-wrapper" v-if="allEstimate && allEstimate.length">
          <div class="estimate-log-item" v-for="(estimateItem, index) in allEstimate" :key="index">
            <div class="estimate-content-log">
              <div class="top-title-row">
                <div class="name">
                  {{ estimateItem.estimateUserName }} <span v-if="estimateItem.stageName">（{{ estimateItem.stageName }}）</span>
                </div>
                <div class="result-icon" :class="{ wait: estimateItem.estimateStatus === 4, pass: estimateItem.estimateStatus === 1, reject: estimateItem.estimateStatus === 2 }">
                  <div class="img-wrapper">
                    <img src="@/assets/img/pass-icon.png" v-if="estimateItem.estimateStatus === 1" />
                    <img src="@/assets/img/reject-icon.png" v-if="estimateItem.estimateStatus === 2" />
                    <img src="@/assets/img/wait-icon.png" v-if="estimateItem.estimateStatus === 4" />
                  </div>
                  <span class="result-text">{{ estimateItem.estimateStatus | translateEstimateStatus }}</span>
                </div>
              </div>
              <div class="time-row">{{ moment(estimateItem.estimateOperateTime).format('YYYY-MM-DD HH:mm') }}</div>
              <div class="content-wrapper" v-for="estimates in estimateItem.estimates" :key="estimates.id">
                <div class="content-desc">
                  {{ estimates.estimateType | translateEstimateType2 }}
                </div>
                <div class="rate-row" v-if="isShowAiVideo">
                  <van-rate :value="estimates.estimateScore" color="#ffd21e" :size="24" :gutter="10" void-icon="star" void-color="#eee" readonly />
                </div>
                <div class="estimate-content-row">
                  <span class="text"> {{ estimates.estimateContent }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty :description="$t('com.report.estimate.text027')" v-else />
        <!-- <div class="button-group">
          <div class="cancle" @click="showRefuseDialog = false">取消</div>
          <div class="confirm" @click="confirmRefuse">确认</div>
        </div> -->
      </div>
    </van-popup>

    <!-- <div class="top-row"><i class="iconfont" @click="back">&#xe61d;</i><span class="text">人工综合评价</span></div>
    <div class="estimate-row">
      <van-rate v-model="estimateScore" :size="20" void-icon="star" void-color="#eee" />
      <div class="submit-btn" @click="onSubmit">提交</div>
    </div>
    <div class="textarea-wrapper">
      <textarea v-model="estimateContent" :rows="6" autosize type="textarea" placeholder="请填写评价(限制500字符)" style="width:100%"></textarea>
    </div>
    <div class="suggerlog-wrapper">
      <div class="suggerlog" v-for="(l, index) of logList" :key="index">
        <div class="line1">
          <div class="left">
            <div class="name">{{ l.userName }}</div>
            <div class="dafen">打分为：</div>
            <van-rate v-model="l.estimateScore" :size="12" void-icon="star" :gutter="0" void-color="#eee" readonly />
          </div>
          <div class="right">{{ moment(l.createdTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
        </div>
        <div class="line2">
          <span class="pingjia">评价为:</span>
          <span class="text">{{ l.estimateContent }}</span>
        </div>
      </div>
    </div> -->
    <van-dialog v-model="showConfirmDialog" :title="$t('com.report.estimate.text014')" show-cancel-button @confirm="confirmDialog">
      <div class="dialog-wrapper">{{ $t('com.report.estimate.text015') }}</div>
    </van-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import { markShowComprehensiveEstimate, markAddComprehensiveEstimate, addComment } from '@/api/report'
import { selectAllEstimateGroupBy, selectAllEstimateGroupByforCMBC } from '@/api/mark'
import i18n from '../utils/i18n'
export default {
  data() {
    return {
      resumeResult: -1,
      resumeText: '',
      resumeScore: 0,

      videoResult: -1,
      videoText: '',
      videoScore: 0,

      totalResult: -1,
      totalText: '',
      totalScore: 0,

      showConfirmDialog: false,
      moment: moment,
      estimateScore: 0,
      estimateContent: '',
      logList: null,
      allEstimate: [],
      showRefuseDialog: true,
      showMoreEstimate: false,
      zsCompanyId: [
        // '2565243e57df433da761e20a57fe030c',
        '0a4e956ededb406cae3cb394dac9e78f', //招商正式区
        '686d823e59304ffe953b2d548cf6819c',
        // '295eb58474a84b8f9409f6b5621b04ef', //mini
        // 'bb96166e77be41e8a18d30e28ba63199', //mini
        // '295eb58474a84b8f9409f6b5621b04ef', //正式区  测试17321346370
      ],
    }
  },
  computed: {
    isZsCompany() {
      const companyId = this.$store.state.companyId
      return this.zsCompanyId.includes(companyId) ? true : false
      // return Number(this.$store.state.userInfo.isShowAiVideo) === 1 ? false : true
    },
    recommendStageId() {
      return this.$route.query.stageId
    },
    corpId() {
      return this.$store.state.corpId
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    interviewId() {
      return this.$store.state.interviewId
    },
    jobseekerChannelId() {
      return this.$store.state.jobseekerChannelId
    },
    estimateType() {
      return this.$store.state.estimateType
    },
    isShowAiVideo() {
      // return Number(this.$store.state.userInfo.isShowAiVideo) === 1 ? true : false
      const isShowAiVideo = this.$store.state.isShowAiVideo

      return isShowAiVideo === 1 ? true : false
    },
  },
  filters: {
    translateEstimateStatus(val) {
      if (val === 1) {
        return i18n.t('com.report.estimate.text026')
      } else if (val === 4) {
        return i18n.t('com.report.estimate.text021')
      } else if (val === 2) {
        return i18n.t('com.report.estimate.text023')
      } else {
        return ''
      }
    },
    translateResult2(val) {
      if (val === 1) {
        return i18n.t('com.report.estimate.text028')
      } else if (val === 4) {
        return i18n.t('com.report.estimate.text029')
      } else if (val === 2) {
        return i18n.t('com.report.estimate.text030')
      } else {
        return ''
      }
    },
    translateEstimateType2(val) {
      if (val === 1) {
        return i18n.t('com.report.estimate.text031')
      } else if (val === 2) {
        return i18n.t('com.report.estimate.text032')
      } else if (val === 3) {
        return i18n.t('com.report.estimate.text033')
      } else if (val === 5) {
        return i18n.t('com.report.estimate.text034')
      } else if (val === 6) {
        return i18n.t('com.report.estimate.text035')
      }
    },
    //1.简历 2.综合评估  3.视频人工总分
    translateEstimateType(val) {
      if (val === 1) {
        return i18n.t('com.report.estimate.text016')
      } else if (val === 2) {
        return i18n.t('com.report.estimate.text017')
      } else if (val === 3) {
        return i18n.t('com.report.estimate.text018')
      }
    },
    translateResult(l) {
      if (l.estimateType === 1) {
        if (l.result === 1) {
          return i18n.t('com.report.estimate.text019')
        } else if (l.result === 2) {
          return i18n.t('com.report.estimate.text020')
        } else if (l.result === 3) {
          return i18n.t('com.report.estimate.text021')
        }
      } else if (l.estimateType === 2 || l.estimateType === 3) {
        if (l.result === 1) {
          return i18n.t('com.report.estimate.text022')
        } else if (l.result === 2) {
          return i18n.t('com.report.estimate.text023')
        } else if (l.result === 3) {
          return i18n.t('com.report.estimate.text021')
        }
      }
    },
  },
  created() {
    //this.showEstimateRecord()
    this.closeAllVideo()
    this.getAllEstimate()
  },
  methods: {
    getAllEstimate() {
      let data = {
        jobseekerChannelId: this.jobseekerChannelId,
        interviewId: this.interviewId,
      }
      if (this.isZsCompany) {
        data = {
          ...data,
          recommendStageId: this.recommendStageId,
        }
        //获取招商推荐记录
        selectAllEstimateGroupByforCMBC(data).then(res => {
          if (res && res.code === 0) {
            this.allEstimate = res.data
            this.$store.commit('setAllEstmate', this.allEstimate)
          }
        })
      } else {
        selectAllEstimateGroupBy(data).then(res => {
          if (res && res.code === 0) {
            this.allEstimate = res.data
            this.$store.commit('setAllEstmate', this.allEstimate)
          }
        })
      }
    },
    totalSubmit() {
      let requestArray = []
      //评价简历

      if (this.resumeText.trim().length > 0 || this.resumeScore > 0 || this.resumeResult > 0) {
        let data1 = {
          estimateText: this.resumeText,
          result: this.resumeResult,
          estimateScore: this.resumeScore,
          userId: this.userInfo.userId,
          jobseekerChannelId: this.jobseekerChannelId ? this.jobseekerChannelId : undefined,
          corpId: this.corpId,
          source: 1,
        }
        requestArray.push(addComment(data1))
      }
      //this.$toast({ type: 'warning', message: res.msg })

      //人工视频打分
      if (this.videoText.trim().length > 0 || this.videoScore > 0 || this.videoResult > 0) {
        let data2 = {
          estimateContent: this.videoText,
          interviewId: this.interviewId,
          estimateScore: this.videoScore,
          result: this.videoResult,
          estimateType: 2,
        }
        requestArray.push(markAddComprehensiveEstimate(data2))
      }

      //人工综合评价
      if (this.totalText.trim().length > 0 || this.totalScore > 0 || this.totalResult > 0) {
        let data3 = {
          estimateContent: this.totalText,
          interviewId: this.interviewId,
          estimateScore: this.totalScore,
          result: this.totalResult,
          estimateType: 1,
        }
        requestArray.push(markAddComprehensiveEstimate(data3))
      }

      if (requestArray && requestArray.length) {
        Promise.all(requestArray).then(result => {
          let flag = true
          for (let i = 0; i < result.length; i++) {
            if (result[i] && result[i].code !== 0) {
              this.$toast({ type: 'warning', message: result[i].msg })
              flag = false
            }
          }
          if (flag) {
            this.$toast({ type: 'info', message: i18n.t('com.report.estimate.text025') })
          }

          this.resumeResult = -1
          this.resumeText = ''
          this.resumeScore = 0

          this.videoResult = -1
          this.videoText = ''
          this.videoScore = 0

          this.totalResult = -1
          this.totalText = ''
          this.totalScore = 0

          this.getAllEstimate()
        })
      } else {
        this.$toast({ type: 'info', message: i18n.t('com.report.estimate.text036') })
      }
    },
    closeAllVideo() {
      let videoArray = document.getElementsByTagName('video')
      if (videoArray && videoArray.length) {
        for (let i = 0; i < videoArray.length; i++) {
          videoArray[i].pause()
        }
      }
    },
    confirmDialog() {
      this.$store.commit('setShowManualEstimateDialog', false)
    },
    goBack() {
      console.log('122222222222')
      this.$router.go(-1)
      // if (this.estimateContent.trim().length > 0) {
      //   this.showConfirmDialog = true
      // } else {
      //   this.$store.commit('setShowManualEstimateDialog', false)
      // }
    },
    showEstimateRecord() {
      markShowComprehensiveEstimate({ interviewId: this.interviewId, estimateType: 1 }).then(res => {
        if (res && res.code === 0) {
          this.logList = res.data || []
        }
      })
    },

    onSubmit() {
      let data = {
        interviewId: this.interviewId,
        estimateScore: this.estimateScore,
        estimateContent: this.estimateContent,
        estimateType: 1,
      }
      markAddComprehensiveEstimate(data).then(res => {
        if (res && res.code === 0) {
          this.$toast({ type: 'info', message: i18n.t('com.report.estimate.text025') })
          this.showEstimateRecord()
          this.estimateContent = ''
          this.estimateScore = 0
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.estimate-record-dialog {
  .estimate-top-row {
    position: sticky;
    top: 0px;
    width: 100%;
    color: #333333;
    font-size: 18px;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    z-index: 2;
  }

  .refuseDialog {
    padding: 10px 20px 20px 20px;
    .text-description {
      font-size: 0.35rem;
      color: #000000;
    }

    .radio-wrapper {
      margin-top: 0.2rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .radio-item {
        font-size: 0.35rem;
        width: 26vw;
        padding: 0.2rem 0;
        text-align: center;
        border: 1px solid #dcdfe6;
      }

      .active {
        background-color: #14b1ff;
        color: #ffffff;
      }
    }

    .rate-wrapper {
      margin-top: 10px;
    }

    .van-field {
      margin-top: 10px;
      background-color: #fafafa;
      padding: 0;
    }

    .more-estimate-row {
      margin-top: 10px;
      text-align: right;
      color: #14b1ff;
      font-size: 16px;
      span {
        text-decoration: underline;
      }
    }

    .btn-wrapper {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
      .cancle {
        border-radius: 0.06rem;
        border: 0.03rem solid #d9d9d9;
        margin-right: 0.6rem;
      }
      .confirm-btn {
        font-size: 0.35rem;
        padding: 0.2rem 0.5rem;
        background-color: #14b1ff;
        color: #ffffff;
        border-radius: 5px;
      }
    }

    .estimate-row {
      box-sizing: border-box;
      width: 100%;
      padding-left: 0.5rem;
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .submit-btn {
        cursor: pointer;
        padding: 0.2rem;
        width: 2rem;
        color: #ffffff;
        background-color: #00aaff;
        text-align: center;
        font-size: 0.4rem;
      }
    }

    .desc {
      padding: 0.2rem 0;
      text-align: left;
    }

    .textarea-wrapper {
      width: 100%;
      box-sizing: border-box;
      /deep/ textarea {
        margin-top: 0.2rem;
        border: 1px solid #c8c9cc;
        border-radius: 4px;
        padding: 10px;
        transform: translateX(0.8);
        transform: translateX(10rpx);
        box-sizing: border-box;
      }
    }
    .suggerlog-wrapper {
      margin-top: 0.2rem;
      //border: 1px solid #cccccc;
      border-radius: 5px;
      padding: 12px 16px;
      .suggerlog {
        padding: 0.2rem 0;
        .line1 {
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;
            align-items: center;
            .name {
              max-width: 1.7rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 600;
            }

            .dafen {
              margin-left: 0.1rem;
              font-weight: 600;
              //color: #cccccc;
            }
          }
        }
        .line2 {
          margin-top: 0.2rem;
          .pingjia {
            font-weight: 600;
          }
        }
      }
    }

    .estimate-log-wrapper {
      .estimate-log-item {
        margin-top: 0.5rem;
        .estimate-content-log {
          .top-title-row {
            display: flex;
            justify-content: space-between;
            .name {
              font-size: 0.35rem;
              color: #333333;
              font-weight: 600;
            }
            .date {
              font-size: 0.35rem;
              color: #666666;
            }
            .result-icon {
              width: 56px;
              border-radius: 10px;
              font-size: 13px;
              display: flex;
              align-items: center;
              line-height: 20px;
              .img-wrapper {
                width: 21px;
                height: 21px;
                margin-left: -1px;
                img {
                  width: 100%;
                }
              }
              .result-text {
                margin-left: 2px;
                white-space: nowrap;
              }
            }

            .pass {
              color: #1890ff;
              border: 1px solid #1890ff;
            }

            .reject {
              color: #f04d4d;
              border: 1px solid #f04d4d;
            }

            .wait {
              border: 1px solid #f7b500;
              color: #f7b500;
            }
          }

          .time-row {
            color: #aaaaaa;
            width: 100%;
            text-align: right;
            font-size: 14px;
            margin-top: 10px;
          }

          .content-wrapper {
            margin-top: 0.3rem;
            background-color: #f8f9fb;
            padding: 0.3rem;
            font-size: 0.35rem;
            color: #666666;
            .content-desc {
              font-size: 0.35rem;
              color: #979797;
            }
            .result-row {
              margin-top: 10px;
              .text {
                margin-left: 0.3rem;
              }
            }

            .rate-row {
              margin-top: 10px;
              display: flex;
              // .van-rate {
              //   margin-left: 0.3rem;
              // }
            }

            .estimate-content-row {
              margin-top: 10px;
              // .text {
              //   margin-left: 0.3rem;
              // }
            }
          }
        }
      }
    }

    .dialog-wrapper {
      width: 80%;
      margin: 0 auto;
      padding: 1rem 0;
    }
  }
}
</style>

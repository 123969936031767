<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: 'DDRegular';
  font-weight: 400;
  src: url('~@/assets/fonts/DingTalk_JinBuTi_Regular.woff2') format('woff2'), url('~@/assets/fonts/DingTalk_JinBuTi_Regular.woff') format('woff');
  font-display: swap;
}
#app {
  font-family: system,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,PingFang SC,Segoe UI,Microsoft YaHei,wenquanyi micro hei,Hiragino Sans GB,Hiragino Sans GB W3,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  //min-width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  // overflow-y: auto;
  // overflow-x: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.van-toast {
  min-height: 0;
  width: fit-content;
}
::-webkit-scrollbar {
  width: 6px; /* 纵向滚动条*/
  height: 6px; /* 横向滚动条 */
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(255, 255, 255, 0.2);
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
<script>
export default {
  created() {},
}
</script>

<template>
  <div>
    <div class="cognitive-ability" v-if="hasRealizeScore || (aiData && aiData.realizeScore.realizeComment1)">
      <template v-if="hasRealizeScore">
        <div class="line1">
          <div class="line1-title">{{ $t('com.report.cognition.text001') }}</div>
          <p class="line1-content">{{ $t('com.report.cognition.text002') }}</p>
          <p class="line1-content">{{ $t('com.report.cognition.text003') }}</p>
          <p class="line1-content">{{ $t('com.report.cognition.text004') }}</p>
          <p class="line1-content">{{ $t('com.report.cognition.text005') }}</p>
          <p class="line1-content">{{ $t('com.report.cognition.text006') }}</p>
        </div>

        <div class="indicator-wrappper">
          <div class="percentile-score">
            {{ $t('com.report.cognition.text007', { name: interviewResult.name}) }}<span>{{ aiData.realizeScore.realize }}%</span>
            <!-- {{ interviewResult.name || '' }}{{ $t('com.report.cognition.text007') }}：<span>{{ aiData.realizeScore.realize }}%</span> -->
          </div>
          <PercentWrapper :sort="aiData.realizeScore.realize" />
        </div>
      </template>
      <div class="score-explain" v-if="aiData && aiData.realizeScore.realizeComment1">
        <div class="title">{{ $t('com.report.cognition.text008') }}</div>
        {{ (aiData.realizeScore.realizeComment1 && aiData.realizeScore.realizeComment1.replace(/XXX/g, interviewResult.name)) || '' }}
        <pre v-html="aiData.realizeScore.realizeComment2"></pre>
      </div>
      <CopyrightNotice />
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
import CopyrightNotice from '@/components/reportV3/common/copyright-notice.vue'
import PercentWrapper from '@/components/reportV3/common/percent-wrapper.vue'
import Empty from '@/components/reportV3/common/empty.vue'

export default {
  components: { PercentWrapper, CopyrightNotice, Empty },
  computed: {
    hasRealizeScore() {
      return this.aiData && Object.keys(this.aiData.realizeScore).length > 0
    },
    aiData() {
      return this.$store.state.aiData
    },
    interviewResult() {
      return this.$store.state.interviewResult
    },
  },
}
</script>
<style lang="scss" scoped>
.cognitive-ability {
  margin-top: 20px;
}
.line1 {
  .line1-title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .line1-content {
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.indicator-wrappper {
  // width: calc(100% - 50px);
  margin: 20px auto 0 auto;

  position: relative;
  .percentile-score {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
    margin-bottom: 10px;
    span {
      color: #1890ff;
    }
  }
}

.score-explain {
  // width: calc(100% - 50px);
  margin: 20px auto 0 auto;
  border-radius: 10px;
  border: 1px dashed #d6dde8;
  padding: 10px;
  line-height: 24px;

  color: #666666;
  font-size: 14px;
  .title {
    font-weight: 600;
    color: #333333;
  }
  .show-full-explain {
    text-align: right;
    cursor: pointer;
    color: #1890ff;
  }

  pre {
    white-space: pre-wrap;
    word-break: break-word;
    font-family: auto;
    margin: 5px 0;
  }
}
</style>

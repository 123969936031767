export default {
  text001: 'Answer and score',
  text002: '{0}points',
  // eslint-disable-next-line quotes
  text003: 'His/Her answer',
  text004: 'Programming language',
  text005: 'Correct answer',
  text006: 'Correct',
  text007: 'Error',
  text008: 'No content can be viewed',
  text009: 'Programming question',
}

<template>
  <div class="attach-info">
    <div v-if="(attachInfo && attachInfo.length) || (isZj && isFill)" class="info-list">
      <div v-if="attach && attach.length" class="title">{{ $t('com.report.attach.text001') }}</div>
      <div class="attach-list">
        <div class="attach-item" v-for="attach in attachList" :key="attach.attachInfoId">
          <div class="name">{{ attach.attachName }}</div>
          <div class="button preview" @click="preview(attach)">{{ $t('com.report.attach.text002') }}</div>
          <!-- <div class="button" @click="delAttach(attach, 0)">删除</div> -->
        </div>
      </div>
      <br />
      <div v-if="urlList && urlList.length" class="title">{{ $t('com.report.attach.text003') }}</div>
      <div class="attach-list">
        <div class="attach-item" v-for="attach in urlList" :key="attach.attachInfoId">
          <div class="name">{{ attach.attachUrl }}</div>
          <!-- <div class="button" @click="delAttach(attach, 1)">删除</div> -->
        </div>
      </div>
      <div v-if="isZj && isFill" class="title">{{ $t('com.report.attach.text004') }}</div>
      <div class="bd-list" v-if="isZj && isFill">
        <div class="bd-item" v-for="bd in bdFormArr" :key="bd.key">
          <span class="label">{{ bd.label }}</span>
          <span class="value">{{ bd.value }}</span>
        </div>
      </div>
    </div>
    <div v-else class="no-data">
      <Empty :title="$t('com.report.onlinetest.text016')" />
    </div>
  </div>
</template>

<script>
import { queryCandidateAttachInfo, deleteCandidateAttachInfo, queryCheck } from '@/api/candidate'
import { Dialog, Toast } from 'vant'
import EventBus from '@/common/event-bus'
import i18n from '../../utils/i18n'
import Empty from '@/components/reportV3/common/empty.vue'

export default {
  components: { Empty },
  props: ['jobseekerChannelId'],
  data() {
    return {
      attachInfo: [],
      isFill: false,
      bdFormArr: [
        { key: 'finishCheck', value: '', label: i18n.t('com.report.attach.text005') },
        { key: 'cheatFlag', value: '', label: i18n.t('com.report.attach.text006') },
        { key: 'violationResult', value: '', label: i18n.t('com.report.attach.text007') },
      ],
    }
  },
  created() {
    this.getAttachInfo()
    this.queryCheck()
  },
  methods: {
    queryCheck() {
      queryCheck({ jobseekerChannelId: this.jobseekerChannelId }).then(res => {
        if (res && res.code === 0) {
          const data = res.data
          this.bdFormArr = this.bdFormArr.map(i => {
            let value = data[i.key]
            if (i.key !== 'violationResult') {
              value = value === 0 ? i18n.t('com.report.attach.text009') : i18n.t('com.report.attach.text008')
            }
            return { ...i, value }
          })
          this.isFill = res.data?.isFill || false
        }
      })
    },
    preview(attach) {
      EventBus.$emit('openPreviewer', attach.attachUrl)
    },
    getAttachInfo() {
      queryCandidateAttachInfo({ jobSeekerChannelId: this.jobseekerChannelId }).then(res => {
        if (res.code === 0) {
          this.attachInfo = res.data || []
        }
      })
    },
    delAttach(attach, type) {
      Dialog.confirm({
        // title: `确认删除${type === 0 ? '附件 ' + attach.attachName : '链接 ' + attach.attachUrl} ?`,
        message: i18n.t('com.report.attach.text010', [type === 0 ? i18n.t('com.report.attach.text001') + attach.attachName : i18n.t('com.report.attach.text003') + attach.attachUrl]), // `确认删除${type === 0 ? '附件 ' + attach.attachName : '链接 ' + attach.attachUrl} ?`,
      }).then(() => {
        deleteCandidateAttachInfo({ attachInfoId: attach.attachInfoId }).then(res => {
          if (res.code === 0) {
            Toast(i18n.t('com.report.attach.text011'))
            this.getAttachInfo()
          } else {
            Toast(i18n.t('com.report.attach.text012'))
          }
        })
      })
    },
  },
  computed: {
    isZj() {
      const companyId = this.$store.state.companyId
      return this.$store.state.userInfo?.customerType === 'CUSTOMERTYPE_ZHONGJUN' || this.$store.state.zjCompanyId.includes(companyId)
    },
    attachList() {
      return this.attachInfo.filter(attach => +attach.attachType === 0)
    },
    urlList() {
      return this.attachInfo.filter(attach => +attach.attachType === 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.attach-info {
  .info-list {
    padding: 0 20px;
  }
  .title {
    font-size: 14px;
    color: #333;
  }
  .attach-list {
    .attach-item {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 18px;
      }
      .button {
        cursor: pointer;
        color: #e02020;
      }
      .preview {
        color: #1890ff;
      }
    }
  }
  .bd-list {
    .bd-item {
      // display: flex;
      line-height: 28px;
      .label {
        margin-right: 10px;
      }
    }
  }
  .no-data {
    text-align: center;
    img {
      margin: 60px auto;
      width: 40%;
      height: auto;
    }
  }
}
</style>

<template>
  <div class="custom-image-viewer">
    <div class="wrapper">
      <div class="control left">
        <i :class="['icon', 'el-icon-d-arrow-left', { enable: preEnble }]" @click="switchImage('first')" />
        <i :class="['icon', 'el-icon-arrow-left', { enable: preEnble }]" @click="switchImage('pre')" />
      </div>
      <div class="container">
        <div class="button-container">
          <span class="button" @click="openDialog">{{ $t('com.report.antiCheating.enterPhotoWall') }}</span>
        </div>
        <div ref="imageContainer" class="image-container">
          <template v-if="displayMode == 'expand'">
            <template v-for="(item, index) in imageList">
              <!-- 第一张图片，需要控制margin-left -->
              <div v-if="index === 0" :key="index" :class="['image-card', { active: currIndex == index }]" :style="{ marginLeft: marginLeft + 'px' }" @click="previewImage(index)">
                <img :src="item.url" class="image" />
                <p class="image-date">{{ item.date || formatDate(item.createdTime) }}</p>
              </div>
              <!-- 除第一张图片外的其它图片 -->
              <div v-else :key="index" :class="['image-card', { active: currIndex == index }]" @click="previewImage(index)">
                <img :src="item.url" class="image" />
                <p class="image-date">{{ item.date || formatDate(item.createdTime) }}</p>
              </div>
            </template>
          </template>
          <template v-if="displayMode == 'fold'">
            <template v-for="(item, index) in imageList">
              <div v-if="currIndex == index" :key="index" :class="['image-card fold', { active: currIndex == index }]" @click="previewImage(index)">
                <img :src="item.url" class="image" />
                <p class="image-date">{{ item.date || formatDate(item.createdTime) }}</p>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="control right">
        <i :class="['icon', 'el-icon-arrow-right', { enable: nextEnble }]" @click="switchImage('next')" />
        <i :class="['icon', 'el-icon-d-arrow-right', { enable: nextEnble }]" @click="switchImage('last')" />
      </div>
    </div>
    <!-- 照片墙 -->
    <van-dialog v-model="photoWallShow" :title="$t('com.report.antiCheating.photoWall')" :confirmButtonText="$t('com.report.antiCheating.close')" class="photo-wall-dialog">
      <div v-if="!multipleTab" class="photo-list">
        <!-- 单个tab -->
        <div v-for="(item, index) in imageList" :key="index" class="image-card" @click="previewImage(index)">
          <img :src="item.url" class="image" />
          <p class="image-date">{{ item.date || formatDate(item.createdTime) }}</p>
        </div>
      </div>
      <div v-else>
        <!-- 多个tab -->
        <van-tabs v-model="activeTab">
          <template v-for="(item, index) in multipleImageList">
            <van-tab v-if="multipleImageList[index].show" :key="index" :title="item.label" :name="String(index)">
              <div class="photo-list">
                <div v-for="(o, i) in multipleImageList[index].list" :key="i" class="image-card" @click="previewImage(i)">
                  <img :src="o.url" class="image" />
                  <p class="image-date">{{ formatDate(o.createdTime) }}</p>
                </div>
              </div>
            </van-tab>
          </template>
        </van-tabs>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import moment from 'moment'

export default {
  name: 'CustomImageViewer',
  props: {
    // 显示模式：展开(expand) or 折叠(fold)
    displayMode: {
      type: String,
      default: 'fold', // 默认折叠
    },
    // 图片列表
    imageList: {
      type: Array,
      default: () => [],
    },
    // 多tab
    multipleTab: {
      type: Boolean,
      default: false,
    },
    multipleImageList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currIndex: 0, // 当前索引
      marginLeft: 0, // 第一张图片的margin-left
      // 照片墙
      photoWallShow: false,
      // 多tab
      activeTab: String(this.multipleImageList.findIndex(o => o.show) || 0),
    }
  },
  computed: {
    // 上一张按钮是否可点击
    preEnble() {
      return this.imageList.length && this.currIndex !== 0
    },
    // 下一张按钮是否可点击
    nextEnble() {
      return this.imageList.length && this.currIndex !== this.imageList.length - 1
    },
  },
  watch: {
    // 监听当前索引，通过改变第一张图的margin-left以保证图片出现在视野内
    currIndex(curr, pre) {
      if (!this.imageList.length || this.displayMode === 'fold') return
      const containerEl = this.$refs.imageContainer // 容器
      const containerWidth = containerEl.clientWidth // 容器宽度
      const firstImageEl = containerEl.firstChild // 第一张图片
      const marginRight = Number.parseInt(getComputedStyle(firstImageEl).marginRight) // 获取第一张图片的右边距
      const imageWidth = firstImageEl.offsetWidth + marginRight // 图片的总宽度（宽度 + 右边距）
      const displayNum = Math.floor(containerWidth / imageWidth) // 图片能显示完整的数量
      if (curr > pre && curr + 1 > displayNum) {
        // 点击下一张，且已经超出视野内能容纳的图片数
        this.marginLeft = -(imageWidth * (curr + 1 - displayNum))
      }
      if ((curr < pre) & (this.marginLeft < 0)) {
        // 点击上一张，且左边距不为0
        const val = -(imageWidth * (curr + 1 - displayNum))
        this.marginLeft = val >= 0 ? 0 : val
      }
    },
  },
  methods: {
    // 切换图片
    switchImage(action) {
      switch (action) {
        case 'pre':
          if (this.preEnble) this.currIndex--
          break
        case 'first':
          if (this.preEnble) this.currIndex = 0
          break
        case 'next':
          if (this.nextEnble) this.currIndex++
          break
        case 'last':
          if (this.nextEnble) this.currIndex = this.imageList.length - 1
          break
      }
    },
    // 预览图片
    previewImage(index) {
      let images = []
      if (this.multipleTab && this.photoWallShow) {
        images = this.multipleImageList[this.activeTab].list.map(item => item.url)
      } else {
        images = this.imageList.map(item => item.url)
      }
      ImagePreview({
        images,
        startPosition: index,
        showIndicators: true,
        closeable: true,
      })
      if (!this.photoWallShow) {
        // 不响应照片墙里的点击
        this.currIndex = index
      }
    },
    // 预览图片时的切换事件
    previewSwitch(index) {
      if (!this.photoWallShow) {
        // 不响应照片墙里的切换
        this.currIndex = index
      }
    },
    // 打开照片墙
    openDialog() {
      this.photoWallShow = true
    },
    // 格式化时间
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>

<style lang="scss" scoped>
p,
h4 {
  margin: 0;
}
:deep(.van-dialog) {
  &.photo-wall-dialog {
    top: 50%;
    .van-dialog__content {
      padding: 16px;
    }
    .photo-list {
      height: 55vh;
      overflow: scroll;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .image-card {
        width: calc(33.3% - 5px);
        height: 100px;
        margin: 0 5px 16px 0;
        .image {
          width: 100%;
          height: calc(100% - 20px);
          object-fit: cover;
          vertical-align: middle; // 消除幽灵空白节点
          cursor: pointer;
        }
        .image-date {
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          color: #6d7278;
          line-height: 13px;
          margin-top: 7px;
        }
      }
    }
  }
}
:deep(.van-tabs) {
  .van-tabs__line {
    background-color: #409eff;
  }
  .van-tabs__content {
    margin-top: 10px;
  }
}
.custom-image-viewer {
  .wrapper {
    display: flex;
    align-items: center;
    .control {
      width: 55px;
      display: flex;
      justify-content: space-between;
      margin-top: 14px;
      .icon {
        font-size: 22px;
        color: #999999;
        cursor: not-allowed;
        &.enable {
          color: #1990ff;
          cursor: pointer;
        }
      }
    }
    .container {
      width: calc(100% - 110px);
      padding: 0 15px;
      overflow: hidden;
      .button-container {
        text-align: center;
        margin-bottom: 20px;
        .button {
          font-size: 16px;
          font-weight: 500;
          color: #1990ff;
          text-decoration: underline;
          line-height: 22px;
          cursor: pointer;
        }
      }
      .image-container {
        display: flex;
        .image-card {
          width: 142px;
          height: 108px;
          margin-right: 18px;
          flex-shrink: 0;
          transition: margin 0.3s, scale 0.3s;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            scale: 1.05;
            .image {
              border: 2px solid #1990ff;
            }
          }
          .image {
            width: 100%;
            height: calc(100% - 28px);
            object-fit: cover;
            vertical-align: middle; // 消除幽灵空白节点
            cursor: pointer;
          }
          .image-date {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #6d7278;
            line-height: 20px;
            margin-top: 6px;
          }
          &.fold {
            width: 100%;
            height: 150px;
            .image-date {
              font-size: 17px;
              margin-top: 6px;
            }
            &.active {
              scale: 1;
            }
          }
        }
      }
    }
  }
}
</style>

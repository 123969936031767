export default {
  text001: '请添加备注',
  text002: '添加',
  text003: '删除',
  text004: '备注',
  text005: '沟通记录',
  text006: '通过',
  text007: '拒绝',
  text008: '待定',
  text009: '该功能需要登录操作',
  text010: '添加备注成功',
  text011: '请输入备注',
}

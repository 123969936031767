import service from '@/config/http'
import qs from 'qs'

//微信公众号-获取用户的openId
export const sendSms = data => {
  return service({
    url: '/admin/login/sendSms',
    data: qs.stringify(data),
    method: 'post',
  })
}

/**
 * admin/login/loginDirect post请求  ud 用户id   overtime面试结束时间  vcode加密字符串
 * @param {*} data
 */
//从邮件里的链接直接点过来登录
export const loginDirect = data => {
  return service({
    url: '/admin/login/loginDirect',
    data: qs.stringify(data),
    method: 'post',
  })
}
// /admin/user/updateWechatHint
export const updateWechatHint = data => {
  return service({
    url: '/admin/user/updateWechatHint',
    params: data,
    method: 'get',
  })
}
/**
 * 文件格式转化
 * @param data
 */
export const convertFile = data => {
  const req = {
    url: '/file/convert/getUrl',
    params: data,
    method: 'get',
  }
  return service(req)
}
/**
 * 【个人信息】获取用户基本信息
 * @param data
 */
export const getUserInfo = data => {
  return service({
    url: '/admin/user/getUserInfo',
    method: 'get',
    params: data,
  })
}
/**
 * 用户登录
 * @param data
 */
export const login = data => {
  return service({
    url: '/admin/login',
    data: qs.stringify(data),
    method: 'post',
  })
}
/**
 * 用户注册
 * @param data
 */
export const register = data => {
  return service({
    url: '/admin/login/register',
    data: qs.stringify(data),
    method: 'post',
  })
}

/**
 * 获取用户权限信息
 * @param data
 */
export const getUserPermissions = data => {
  return service({
    url: '/admin/auth/role/getRolesByUser',
    method: 'post',
    data,
  })
}

/**
 * 获取全息简历页签设置
 */
export const getResumeLabelConfig = data => {
  return service({
    url: '/admin/report/tab/getConfig',
    method: 'post',
    data,
  })
}

//获取ai面试标注配置
export const getAiInterLabelConfig = data => {
  const req = {
    url: '/admin/auth/role/getAiAnnotationSettings',
    method: 'post',
    data,
  }
  return service(req)
}

// 获取报告配置
export const getSystemConfigList = data => {
  const req = {
    url: `/admin/system/getSystemConfigList?companyId=${data}`,
    data,
    method: 'get',
  }
  return service(req)
}
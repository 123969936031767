export default {
  text001: '回答及得分',
  text002: '{0}分',
  text003: 'TA的回答',
  text004: '編程語言',
  text005: '正確答案',
  text006: '正確',
  text007: '錯誤',
  text008: '沒有內容可以查看',
  text009: '編程題',
}

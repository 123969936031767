<template>
  <div :class="showTools ? 'show_tool' : ''">
    <div v-if="showTools" class="tools fixed">
      <div class="left button" @click="toCall">
        <img src="~@/assets/images/phone.png" />
        <span>电话咨询</span>
      </div>
      <div class="right button" @click="toTryout">
        <img src="~@/assets/images/edit.png" />
        <span>注册使用</span>
      </div>
    </div>
    <van-dialog v-model="visible" title="注册AI得贤招聘官" :beforeClose="beforeClose" closeOnClickOverlay confirmButtonColor="#1989fa">
      <van-form>
        <van-field v-model="companyName" label-width="60px" label="公司名称" />
        <van-field v-model="userName" label-width="60px" label="姓名" />
        <van-field v-model="position" label-width="60px" label="职位" />
        <van-field v-model="phone" label-width="60px" type="tel" label="手机号" />
        <van-field v-model="vcode" label-width="60px" center clearable label="验证码" placeholder="请输入短信验证码">
          <template #button>
            <van-button @click="sendSms" :disabled="count > 0" size="small" type="primary">{{ count > 0 ? count : '发送验证码' }}</van-button>
          </template>
        </van-field>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { register, sendSms } from '@/api/admin'
import { chinaPhoneReg } from '@/utils'
import { Dialog } from 'vant'
export default {
  props: { showTools: { default: true } },
  data() {
    return {
      visible: false,
      companyName: '',
      userName: '',
      position: '',
      phone: '',
      vcode: '',
      seconds: 0,
      timeout: 0,
      timer: null,
      count: 0,
      isClick: true,

      //
      code: '',
      codeLength: 6,
      telDisabled: false,
      focused: false,
      submitFlag: false,
    }
  },
  computed: {
    codeArr() {
      return this.code.split('')
    },
    cursorIndex() {
      return this.code.length
    },
  },
  methods: {
    sendSms() {
      if (!chinaPhoneReg.test(this.phone)) {
        return this.$notify({ type: 'warning', message: '请输入正确手机号' })
      }
      if (this.isClick) {
        const params = {
          prefixPhone: 86,
          phone: this.phone,
          smsType: 2,
        }
        this.isClick = false
        sendSms(params).then(res => {
          this.isClick = true
          if (res.code === 0) {
            this.$notify({ type: 'success', message: '验证码发送成功' })
            this.setTimeHandle()
          } else {
            this.$notify({ type: 'warning', message: res.msg })
          }
        })
      }
    },
    // 倒计时
    setTimeHandle() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        //   companyName: '',
        // userName: '',
        // position: '',
        if (!this.companyName) {
          done(false)
          return this.$notify({ type: 'warning', message: '请输入公司名称' })
        }
        if (!this.userName) {
          done(false)
          return this.$notify({ type: 'warning', message: '请输入姓名' })
        }
        if (!this.position) {
          done(false)
          return this.$notify({ type: 'warning', message: '请输入职位名称' })
        }
        if (!chinaPhoneReg.test(this.phone)) {
          done(false)
          return this.$notify({ type: 'warning', message: '请输入正确手机号' })
        }
        if (!this.vcode) {
          done(false)
          return this.$notify({ type: 'warning', message: '请输入验证码' })
        }
        const param = {
          prefixPhone: 86,
          phone: this.phone,
          vcode: this.vcode,
          companyName: this.companyName,
          userName: this.userName,
          position: this.position,
        }
        register(param).then(res => {
          if (res.code === 0) {
            this.count = 0
            this.phone = ''
            this.vcode = ''
            this.companyName = ''
            this.userName = ''
            this.position = ''
            done()
            Dialog.confirm({
              title: '注册成功',
              message: '感谢您注册AI得贤招聘官，我们将在1个工作日内与您取得联系，请您保持手机畅通，祝您有一个美好的心情！',
              confirmButtonColor: '#1989fa',
            }).then(() => {})
          } else {
            this.$notify({ type: 'warning', message: res.msg })
            this.count = 0
            done(false)
          }
        })
        done()
      } else {
        this.count = 0
        this.phone = ''
        this.vcode = ''
        done()
      }
    },
    toCall() {
      let a = document.createElement('a')
      a.href = 'tel:4000963520'
      a.click()
    },
    toTryout() {
      // this.$router.push({ name: 'tryout' })
      this.visible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.show_tool {
  padding-top: 100px;
  overflow: hidden;
}
.fixed {
  position: fixed;
  bottom: 0;
  z-index: 110;
  width: 100%;
}
.tools {
  height: 68px;
  display: flex;
  .button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 50%;
    flex: 1;
    box-shadow: 0px 0px 10px 0px rgba(81, 88, 109, 0.3);
    font-size: 18px;
    // text-shadow: 0px 0px 10px rgba(81, 88, 109, 0.5);
    padding-bottom: 8px;

    img {
      width: 28px;
    }
  }
  .left {
    background: #ffffff;
    color: #4f8fff;
  }
  .right {
    background: #4f8fff;
    color: #ffffff;
  }
}
</style>

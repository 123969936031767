export default {
  text001: '人工評價',
  text002: '人工綜合評價',
  text003: '1.請對候選人簡歷進行評價，並填寫具體原因',
  text004: '請填寫具體原因(限制500字元)',
  text005: '1.請對候選人的綜合表現進行評價，並填寫具體原因',
  text006: '請填寫具體評價(限制500字元)',
  text007: '1.建議崗位',
  text008: '請填寫具體崗位',
  text009: '2.綜合評價',
  text010: '2.請對候選人簡歷進行評價，並填寫具體原因',
  text011: '3.請對候選人視頻面試表現進行評價，並填寫具體原因',
  text012: '取消',
  text013: '提交',
  text014: '提交提醒',
  text015: '您的評價尚未提交，返回後將被清空，請您確認是否返回',
  text016: '您看完候選人的簡歷之後，還想面試TA嗎',
  text017: '請對候選人的綜合表現進行五星評價，並決定是否推薦進入下一輪面試',
  text018: '請對候選人視頻面試的表現進行五星評價',
  text019: '是',
  text020: '否',
  text021: '待定',
  text022: '通過',
  text023: '拒絕',
  text024: '添加失敗',
  text025: '提交成功',
  text026: '評價記錄',
  text027: '暫無評價記錄',
  text028: '已通過',
  text029: '已待定',
  text030: '已拒絕',
  text031: '候選人簡歷評價',
  text032: '候選人綜合評價',
  text033: '候選人視頻面試評價',
  text034: '綜合評價',
  text035: '建議崗位',
  text036: '請至少填寫一項',
  text037: '收起',
  text038: '展開',
}

export default {
  text001: '職業性格測評',
  text002:
    '得賢職業性格測試是一套專門針對商業組織應用的職業性格測量問卷。 該工具專注於測量常態下的職業性格特徵，共包含七個測量維度，用以描述候選人在職場的表現，包括：如何在壓力下調節情緒，如何與他人互動溝通，如何處理工作任務，以及如何解決問題等。該報告逐一呈現各維度的分數，指出了候選人在每個維度上的優點和缺點，為企業在人才招聘，領導力發展，接班人計畫和人才管理專案上提供指導性的意見和建議。',
  text003: '報告總結',
  text004: '該候選人',
  text005: '她',
  text006: '他',
  text007: '基於{name}在得賢職業性格測試中的回答，在日常情況下，{ta}傾向於：',
  text008: '沒有內容可以查看',
  text009: '責任心',
  text010: '好奇心',
  text011: '和諧性',
  text012: '適應性',
  text013: '社交性',
  text014: '抱負心',
  text015: '責任心',
  text016: '好奇心',
  text017: '自律性',
  text018: '和諧性',
  text019: '適應性',
  text020: '含蓄，安靜，不好交際，喜歡獨處；抑制,謹慎,對外部世界不太感興趣。',
  text021: '外向開朗，樂於被人陪伴，主動尋求關注；熱情、果斷、充滿活力、健談。',
  text022: '對負面事件更敏感；情緒調節能力差；在感受到強烈誘惑時，不容易抑制。',
  text023: '沉著冷靜，壓力下依然能夠保持情緒穩定，頭腦清晰；自我控制強，能夠抵擋誘惑。',
  text024: '注重實效,偏愛常規,比較傳統和保守；遵守慣例，缺乏創新。',
  text025: '富有想像力和創造力，偏愛抽象思維；好奇心強，追求藝術享受；主動求變，個體在從事某項任務時願意考慮嘗試新想法。',
  text026: '與關心他人相比，更加關注自己的利益；冷淡且態度強硬，直率，敢於直面衝突。',
  text027: '溫暖熱情，體貼友好，樂於助人；願意謙讓，規避衝突，總是能和他人和諧相處。',
  text028: '消極，被動，做事拖拉；衝動，無序，做事馬虎；按部就班，不在意工作結果。',
  text029: '可靠，盡責，做事積極；有條理，謹慎，注重細節；工作勤奮，熱情度高，追求高質量完成任務。',
  text030: '安於現狀，對挑戰或困境持悲觀態度；在困難/挫折面前會認為達成目標遙不可及，從而採取消極回避行為。',
  text031: '成就導向，好勝心強，敢於設置挑戰性目標；面對困難或深陷危急時，不輕言放棄，能堅持不懈地嘗試各種方法克服障礙，完成任務。',
  text032: '反復無常，心口不一;對不良行為接納度較高;厚此薄彼，偏袒他人。',
  text033: '遵守諾言，言出必行；嚴於律己，嚴格遵守規章制度；公平公正，一視同仁。',
}

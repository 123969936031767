export default {
  text001: '考試',
  text002: '測評',
  text003: '手動添加',
  text004: '考試職位',
  text005: '成績',
  text006: '報告',
  text007: '預覽',
  text008: '返回',
  text009: '保存',
  text010: '添加考試/測評',
  text011: '名稱',
  text012: '請輸入名稱',
  text013: '成績',
  text014: '請輸入成績',
  text015: '類型',
  text016: '報告',
  text017: '點擊上傳',
  text018: '支持pdf、doc(x)、txt檔的預覽，最大不超過50M',
  text019: '成績或報告必須有一個填有資訊',
  text020: '報告檔僅限上傳一份',
  text021: '上傳格式不正確',
  text022: '檔限制大小為50M',
  text023: '上傳失敗，請重試',
  text024: '提交成功',
  text025: '更新成功',
  text026: '刪除成功',
  vendor: '供應商',
  haneo: '嗨優評測',
  other: '其他',
  reviewLink: '  評測鏈接',
  reviewStatus: '評測狀態',
  notReviewed: '未評測',
  underReview: '評測中',
  reviewCompleted: '評測完成',
  whetherToDelete: '是否確認刪除該測評？',
}

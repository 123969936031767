<template>
  <div class="report-mobile">
    <!-- <reportReport v-if="false"></reportReport> -->
    <ReportContent :isAnonymous="isAnonymous" :interviewPlanOption="{ isCheckOwner: isCheckOwner }" />
  </div>
</template>
<script>
import ReportContent from '@/components/ReportContent'
// import reportReport from '../components/reportReport'
import store from '@/store'
export default {
  components: {
    // reportReport,
    ReportContent,
  },
  computed: {
    isAnonymous() {
      const config = this.$store.state.evaluationConfig || {}
      return config.screeningInfoStatus === 1 ? true : false
    },
    isCheckOwner() {
      const config = this.$store.state.evaluationConfig || {}
      return config.evaluationInvisibleStatus === 1 ? true : false
    },
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('getEvaluationConfig').finally(() => {
      next()
    })
  },
}
</script>

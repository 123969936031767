export default {
  text001: 'Appendix',
  text002: 'Preview',
  text003: 'Link',
  text004: 'Background check information form',
  text005: '1. Whether to complete the investigation of references (work history/disciplinary violations):',
  text006: '2. Whether there is a fraud record:',
  text007: '3. Disciplinary inquiry results:',
  text008: 'Yes',
  text009: 'No',
  text010: 'Are you sure you want to delete {0}?',
  text011: 'Successfully deleted',
  text012: 'Failed to delete',
}

<template>
  <div v-if="isOpenL2 && l2status === 0" class="l2-question">
    <div v-if="false" class="l2-q_t p-l">{{ $t('com.report.reportV3.text010') }}</div>
    <div v-if="false" class="l2-q_ranking">
      <div class="l2-q_sp">
        <div v-for="index in step" :key="index" class="sp-i" :class="index === 1 ? 'first' : index === step ? 'last' : ''"></div>
        <div class="buoy" :style="{ left: `${fromLeft}` }">{{ sort }}%</div>
      </div>
    </div>
    <div v-if="false" class="l2-q_r" id="scoreRadar"></div>
    <div class="l2-title p-l">{{ $t('com.report.reportV3.text060') }}</div>
    <template v-for="(answers, idx) in answerList">
      <div class="l2-q_item" v-for="(problem, index) in answers" :key="'problem-' + idx + '-' + index">
        <div class="stem p-l" v-html="getTitle(getProblem(problem).question, idx, index)"></div>
        <div class="tip p-l">
          <AnswerText :answerText="getProblem(problem).answerText" :keyPointInfoResult="getKPIR(getProblem(problem))" :language="getProblem(problem).language" :assessmentPoint="getProblem(problem).assessmentPoint" />
        </div>
        <div class="score">
          <div class="total p-l">
            <span style="font-weight: 600" @click="getKPIR(getProblem(problem))">{{ $t('com.report.reportV3.text061') }}：</span>
            <!-- <el-rate :value="problem.calculateStar" disabled></el-rate> -->
            <van-rate v-if="aiMarkCfg.singleStatus" v-model="getProblem(problem).calculateStar" :size="14" color="#ffd21e" :gutter="2" void-icon="star" void-color="#D1DAF7" readonly :count="aiData.isL5Position === 1 ? 3 : 5" />
            <van-rate v-else :value="0" :size="14" color="#ffd21e" void-icon="star" void-color="#eee" readonly :count="aiData.isL5Position === 1 ? 3 : 5" />
            <div @click="getProblem(problem).visible = !getProblem(problem).visible" v-if="aiMarkCfg.singleStatus" class="detail-bt">
              {{ getProblem(problem).visible ? $t('com.report.reportV3.text062') : $t('com.report.reportV3.text063') }}
            </div>
          </div>
          <div class="weight p-l">
            {{ $t('com.report.reportV3.text064') }}：<span>{{ getProblem(problem).calculateRatio }}%</span>
          </div>
          <div class="detail" v-if="getProblem(problem).visible">
            <div class="p-l" @click="getL2Dimensions(getProblem(problem))">
              {{ $t('com.report.reportV3.text065') }}：<span>{{ getProblem(problem).assessmentPoint }}</span>
              <div>{{ getProblem(problem).assessmentDesc }}</div>
            </div>
            <div v-for="(score, index2) in getL2Dimensions(getProblem(problem))" :key="'score' + index2" class="score-wrapper">
              <div class="desc">{{ score.label }}</div>
              <div class="info">{{ score.info }}</div>
              <div class="process-wrapper">
                <ProcessWrapper :score="score[score.scoreKey]" />
                <!-- <div class="process-item" :class="{ active: score[score.scoreKey] >= n }" v-for="n in 5" :key="'step' + n"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
// import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
// import hologram from '@/store/modules/hologram'
import * as echarts from 'echarts'
import AnswerText from '@/components/answer-text'
import { l2Dimensions } from '@/assets/js/keyToName'
import ProcessWrapper from '@/components/reportV3/common/process-wrapper.vue'

export default {
  // @Prop({ required: false, default: false }) isDownloadReport!: any

  // @Prop({ required: true, default: () => null }) answerResult!: any
  // @Prop({ required: true, default: () => null }) interviewResult!:

  components: { AnswerText, ProcessWrapper },

  data() {
    return {
      detailVisible: false,
      l2Dimensions: l2Dimensions,
      answerInfos: [],
      echarts: echarts,
      step: 20,
    }
  },

  computed: {
    aiMarkCfg() {
      return this.$store.state.aiMarkCfg
    },
    l2status() {
      return this.l2ReportInfo?.l2status
    },
    isOpenL2() {
      const isOrderAi = this.aiData?.isOrderAi || this.$store.state.companyInfo?.isOrderAi
      return isOrderAi === 2 || isOrderAi === 3
    },
    aiData() {
      return this.$store.state.aiData
    },
    l2ReportInfo() {
      return this.aiData?.l2ReportInfo
    },
    fromLeft() {
      return this.sort - 11 + '%'
    },
    sort() {
      return this.l2ReportInfo?.sort
    },
    BQ() {
      return this.isYJLCompany ? 'BQ' : this.$t('com.report.reportV3.text021')
    },
    SY() {
      return this.isYJLCompany ? 'SY' : this.$t('com.report.reportV3.text022')
    },
    YZ() {
      return this.isYJLCompany ? 'YZ' : this.$t('com.report.reportV3.text023')
    },
    isYJLCompany() {
      return this.$store.state.yjlCompanyId.includes(this.$store.state.companyId)
    },
    isNewShareReport() {
      return this.$route.path === '/newShareReport' || this.$route.path === '/downloadReport' ? true : false
    },
    answerList() {
      const answerResult = this.$store.state.answerResult
      const list = answerResult.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return info.question?.problem_answer_method === 1
      })
      return list
    },
    // currentPerson() {
    //   return hologram.currentPerson
    // },
    indicator() {
      return this.answerInfos.map(item => {
        const score = item.calculateStar.toFixed(0)
        return {
          //
          // name: `${item.assessmentPoint}(${score})星`,
          name: `${item.assessmentPoint}(${this.$t('com.report.reportV3.text059', [score])})`,
          max: 5,
        }
      })
    },

    seriesData() {
      return this.answerInfos.map(item => {
        const score = item.calculateStar
        return score
      })
    },
    radar() {
      return {
        indicator: this.indicator,
        scale: true,
        axisTick: {
          //刻度设置
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#B1D8FF',
          },
        },
        axisName: {
          show: true,
          color: '#333',
          fontWeight: 'blod',
          width: 10,
          overflow: 'breakAll',

          rich: {
            width: 10,
            overflow: 'breakAll',
          },
        },
        splitNumber: 5, //刻度
        splitLine: {
          //刻度连接线
          show: false,
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)'],
          },
        },
      }
    },
    series() {
      return [
        {
          type: 'radar',
          label: {
            show: false,
          },
          data: [
            {
              value: this.seriesData,
              // [4, 4, 4, 4, 1],
              areaStyle: {
                // color: '#409EFF',
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(90, 230, 191, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(24, 144, 255, 1)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                color: 'rgba(90, 230, 191, 1)',
                opacity: 1,
              },
              itemStyle: {
                color: '#409EFF',
              },
            },
          ],
        },
      ]
    },
  },

  mounted() {
    this.$nextTick(() => {
      // this.initScoreRadar()
    })
  },

  methods: {
    initScoreRadar() {
      this.detailVisible = !this.detailVisible
      const chartDom = document.getElementById('scoreRadar')
      console.log('chartDom', chartDom)
      if (chartDom) {
        const myChart = this.echarts.init(chartDom)
        const option = {
          radar: this.radar,
          series: this.series,
        }
        option && myChart.setOption(option)
      }
    },
    getProblem(answer) {
      return this.answerInfos.find(item => item.problemAnswerId === answer.id) || {}
    },
    getKPIR(problem) {
      console.log(problem)
      // const kpi = problem?.keyPointInfo ? JSON.parse(problem?.keyPointInfo) : [] //JSON.parse(keyPointInfo) // ||
      const kpir = (problem?.keyPointInfoResult ? JSON.parse(problem?.keyPointInfoResult) : []).filter(item => {
        return item.num && item.num > 0
      }) //JSON.parse(keyPointInfoResult) //
      // const arr = kpir.map(i => {
      //   const highLightColor = kpi.find(k => {
      //     return k.keyPoint === i.keyPoint
      //   })?.highLightColor
      //   return { ...i, highLightColor }
      // })
      return this.getUniArr(kpir)
    },

    getUniArr(arr) {
      const a = []
      arr.forEach(i => {
        const index = a.findIndex(k => {
          return k.keyPoint === i.keyPoint
        })
        if (index === -1) {
          a.push(i)
        }
      })
      return a
    },

    getTitle(question, index, $index) {
      let innerText = question
      const isProbe = $index === 1
      if (innerText && /<p.*?>/.test(innerText)) {
        innerText = innerText.replace(/<p.*?>/, '<p>' + (isProbe ? this.$t('com.report.reportV3.text131') : index + 1) + '.')
      } else {
        innerText = (isProbe ? this.$t('com.report.reportV3.text131') : index + 1 + '.') + innerText
      }

      return innerText.replace(/<img\s(.*?)\s*(([^&]>)|(\/>)|(<\/img>))/gi, '').replace(/<video\s(.*?)\s*(([^&]>)|(\/>)|(<\/video>))/gi, '')
    },
    getL2Dimensions(problem) {
      const languages = [
        //
        '',
        this.$t('com.report.reportV3.text066'),
        this.$t('com.report.reportV3.text067'),
      ]
      const infos = [
        //
        '',
        this.$t('com.report.reportV3.text068'),
        this.$t('com.report.reportV3.text069'),
      ]
      console.log('problem', problem)
      const arr = this.l2Dimensions.map(item => {
        // const i = JSON.parse(JSON.stringify(item))
        const keys = item.keys
        const obj = {}
        keys.forEach(k => {
          obj[k] = problem[k]
        })
        console.log('L2Dimension==>', obj)
        let label = ''
        let info = ''
        if (item.key === 'language') {
          label = languages[obj[item.key]]
          info = infos[obj[item.key]]
        } else {
          label = item.label
          info = item.info
        }

        return { ...item, ...obj, label, info }
      })
      console.log('getL2Dimensions===>', arr)
      return arr.filter(i => {
        return i[i.key] //找到开启的维度
      })
    },
  },

  watch: {
    l2ReportInfo: {
      immediate: true,
      deep: true,
      handler() {
        const arr = (this.l2ReportInfo?.answerInfos || []).map(item => {
          return { ...item, visible: false }
        })

        this.answerInfos = arr.sort((a, b) => {
          return a?.sortIndex - b?.sortIndex
        })
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.l2-question {
  // width: calc(100% - 16px);
  // margin: 20px auto 0 auto;
  margin-top: 20px;
  .l2-q_t {
    margin-bottom: 20px;
  }
  .l2-q_ranking {
    margin-bottom: 20px;
    .l2-q_sp {
      // background: green;
      display: flex;
      align-items: center;
      margin: auto;
      width: 304px;
      position: relative;
      background-image: linear-gradient(to right, rgba(189, 231, 254, 1), rgba(201, 244, 244, 1));
      border-radius: 20px;
      .sp-i {
        width: 2px;
        min-width: 2px;
        // max-width: 2px;
        height: 20px;

        background: #ffffff;
      }
      .first {
        border-radius: 50% 0 0 50%;
        // margin-left: 20px;
        width: 0;
        min-width: 0;
      }
      .last {
        border-radius: 0 50% 50% 0;
        width: 0;
        min-width: 0;
      }
      .buoy {
        width: 22%;
        height: 34px;
        background: rgba(68, 215, 182, 1);
        color: #ffffff;
        position: absolute;
        left: -40px;
        box-shadow: 0px 2px 10px 0px #44d7b6;
        text-align: center;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .sp-i + .sp-i {
        margin-left: 14px;
      }
    }
  }
  .l2-q_r {
    height: 260px;
    width: 360px;

    margin: auto;
    margin-bottom: 20px;
  }
  .l2-title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 16px;
  }
  .l2-q_item {
    .stem {
      margin-bottom: 10px;
    }
    .tip {
      margin-bottom: 10px;
    }
    .score {
      .total {
        display: flex;
        align-items: center;
        .detail-bt {
          cursor: pointer;
          color: #1890ff;
        }
      }
      .weight {
        margin-bottom: 10px;
        font-weight: 600;
      }
      .detail {
        .score-wrapper {
          // width: calc(100% - 16px);
          margin: 20px 0 0 0;
          padding: 0px 0px 10px 0px;

          .desc {
            margin-top: 20px;
            color: #afb6ca;
            padding-left: 20px;
          }

          .info {
            display: none;
            margin-top: 8px;
            color: #333333;
            padding: 0 20px;
          }

          .process-wrapper {
            // display: flex;
            margin-top: 6px;
            // padding: 0 20px;

            // .process-item {
            // 	flex: 1;
            // 	background-color: #f0f3f7;
            // 	height: 20px;
            // }

            // .process-item:not(:first-child) {
            // 	margin-left: 2px;
            // }

            // .process-item:first-child {
            // 	border-radius: 100px 0px 0px 100px;
            // }

            // .process-item:last-child {
            // 	border-radius: 0px 100px 100px 0px;
            // }
          }
          .process-wrapper-other {
            padding: 0 20px;
          }

          .score-explain {
            margin-top: 8px;
            padding-left: 20px;
            color: #afb6ca;
          }
        }

        .score-wrapper:hover {
          box-shadow: 2px 4px 10px 0px rgba(169, 193, 205, 0.34);
          .info {
            display: block;
          }
        }
        .score-wrapper-download {
          box-shadow: 2px 4px 10px 0px rgba(169, 193, 205, 0.34);
          padding-top: 10px;
          .desc {
            margin-top: 0px;
          }
          .info {
            display: block;
          }
        }
      }
    }
  }
}
// .p-l {
// 	padding-left: 20px;
// }
</style>

<template>
  <div class="interview">
    <Header></Header>
    <section class="section1">
      <div class="barnner">
        <img class="banner-img" src="./images/banner.png" />
        <div class="text-wrapper">
          <span>AI视频面试</span>
        </div>
      </div>
    </section>
    <section class="content">
      <h3>AI视频面试</h3>
      <p>
        在自动驾驶领域，基于驾驶自动化系统在执行动态驾驶任务中的角色分配，以及有无设计运行条件限制，自动驾驶汽车被划分为Level 0-Level
        5共6个不同的等级；同样，在AI面试领域，根据AI应用的深度和价值，AI面试同样可以被划分为Level 0-Level 5共6个不同的等级。<br /><br />
        AI面试领域的智能等级划分，<i>AI得贤招聘官是一款L5级别自动面试系统</i>。她可以直接告诉企业来应聘的候选人是否应该被录用。因为她可以模拟人类面试官进行最终决策，所以特别适合校园招聘和面试量较大的社会招聘，革命性的提升招聘的效率和精准度。
      </p>
      <div class="box">
        <ul class="level-wrap">
          <li class="normal-item">
            <img class="tag" :src="require('@/assets/images/tag.png')" />
            <div class="level-tip">
              <span>Lv.0</span>
              <span>视频转录</span>
            </div>
            <div class="item-wrap">
              <div class="item">
                <div><span></span></div>
                <div>
                  面试视频录播
                </div>
              </div>
            </div>
          </li>
          <li class="dotted-line"></li>

          <li class="normal-item">
            <img class="tag" :src="require('@/assets/images/tag.png')" />
            <div class="level-tip">
              <span>Lv.1</span>
              <span>基础面试辅助</span>
            </div>
            <div class="item-wrap">
              <div class="item">
                <div><span></span></div>
                <div>
                  语音转写文本
                </div>
              </div>
              <div class="item">
                <div><span></span></div>
                <div>
                  面试回答文本关键词提取
                </div>
              </div>
            </div>
          </li>
          <li class="dotted-line"></li>

          <li class="normal-item">
            <img class="tag" :src="require('@/assets/images/tag.png')" />
            <div class="level-tip">
              <span>Lv.2</span>
              <span>深度面试辅助</span>
            </div>
            <div class="item-wrap">
              <div class="item">
                <div><span></span></div>
                <div>
                  以下功能中的一种或几种：<br />
                  AI表情分析<br />
                  AI职业形象分析<br />
                  AI声音分析
                </div>
              </div>
            </div>
          </li>
          <li class="normal-item">
            <img class="tag" :src="require('@/assets/images/tag.png')" />
            <div class="level-tip">
              <span>Lv.3</span>
              <span>高度面试辅助</span>
            </div>
            <div class="item-wrap">
              <div class="item">
                <div><span></span></div>
                <div>
                  AI文本内容分析，基于篇章级别语义识别算法预测候选人的胜任力，深度辅助人类面试决策。
                </div>
              </div>
            </div>
          </li>
          <li class="dotted-line"></li>

          <li class="normal-item">
            <img class="tag" :src="require('@/assets/images/tag.png')" />
            <div class="level-tip">
              <span>Lv.4</span>
              <span>有条件自动面试</span>
            </div>
            <div class="item-wrap">
              <div class="item">
                <div><span></span></div>
                <div>
                  基于预训练的多模态算法，结合候选人的回答质量、表情、声音、职业形象等多维度，对候选人进行综合性评估，代替人类面试决策。
                </div>
              </div>
              <div class="item">
                <div><span></span></div>
                <div>
                  适用于校园招聘和面试量较大的职位族招聘。
                </div>
              </div>
            </div>
          </li>
          <li class="dotted-line"></li>

          <li class="special-item">
            <img class="tag" :src="require('@/assets/images/tag.png')" />
            <div class="level-tip">
              <span>Lv.5</span>
              <span>完全自动面试</span>
            </div>
            <div class="stage">
              <img src="./images/stars.png" />
              <i>AI得贤招聘官所在阶段</i>
            </div>
            <div class="item-wrap">
              <div>
                <div class="item">
                  <div><span></span></div>
                  <div>
                    多轮对话
                  </div>
                </div>
                <div class="item">
                  <div><span></span></div>
                  <div>
                    追问技术
                  </div>
                </div>
                <div class="item">
                  <div><span></span></div>
                  <div>
                    无需预训练
                  </div>
                </div>
              </div>
              <div>
                <div class="item">
                  <div><span></span></div>
                  <div>
                    自适应面试题生成技术
                  </div>
                </div>
              </div>
              <div>
                <div class="item">
                  <div><span></span></div>
                  <div>
                    可自由应答候选人的提问
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="dotted-line"></li>
        </ul>
      </div>
    </section>
    <tools></tools>
  </div>
</template>

<script>
import Tools from '@/components/tools'
import Header from '@/components/menu'
export default {
  components: { Tools, Header },
  data() {
    return {}
  },
  created() {
    document.title = 'AI视频面试-AI得贤招聘官'
  },
}
</script>

<style lang="scss" scoped>
.interview {
  overflow: hidden;
  // padding-top: 60px;
  p {
    margin: 0;
  }
  .section1 {
    .barnner {
      position: relative;
      max-height: 240px;
      overflow: hidden;
      .banner-img {
        display: block;
        width: 100%;
        height: auto;
      }
      .text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 240px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
          line-height: 34px;
          letter-spacing: 3px;
        }
      }
    }
  }
  .content {
    padding: 40px 25px 70px 25px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    h3 {
      margin-bottom: 25px;
      font-size: 15px;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
    p {
      font-size: 12px;
      color: #627098;
      line-height: 22px;
      i {
        font-style: normal;
      }
    }
    .box {
      margin-top: 25px;
      .level-wrap {
        width: 100%;
        margin: 0 auto;
        position: relative;
        .dotted-line {
          display: none;
        }
        .normal-item,
        .special-item {
          cursor: pointer;
          position: relative;
          padding: 10px;
          box-sizing: border-box;
          width: 100%;
          height: 160px;
          border: 1px solid #e1e6f4;
          margin-bottom: 25px;
          overflow: hidden;
          background: #ffffff;
          z-index: 1;
          .tag {
            position: absolute;
            display: inline-block;
            width: 16px;
            height: 22px;
            top: 0;
            left: 10px;
          }
          .level-tip {
            display: flex;
            justify-content: space-between;
            span:first-of-type {
              font-size: 14px;
              line-height: 17px;
              font-family: AppleMyungjo;
              color: #333333;
              margin-left: 37px;
            }
            span:last-of-type {
              font-size: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              line-height: 19px;
              color: #333333;
            }
          }
          .stage {
            position: absolute;
            right: 10px;
            top: 30px;
            height: 23px;
            line-height: 23px;
            text-align: right;
            vertical-align: middle;
            img {
              width: 23px;
              display: inline-block;
              vertical-align: middle;
            }
            i {
              font-style: normal;
              display: inline-block;
              vertical-align: middle;
              font-size: 12px;
              font-weight: 400;
              color: #979797;
              line-height: 17px;
            }
          }
          .item-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 118px;
            margin-top: 10px;
            .item {
              display: flex;
              margin-bottom: 10px;
              div:first-of-type {
                height: 18px;
                display: flex;
                align-items: center;
                margin-right: 8px;
                span {
                  width: 4px;
                  height: 4px;
                  background: #d8d8d8;
                }
              }
              div:last-of-type {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                color: #9fa8b8;
                line-height: 18px;
              }
            }
          }
          &:hover {
            transform: scale(1.1);
            box-shadow: 2px 2px 25px 0px rgba(61, 87, 163, 0.21);
            transition: all 1s;
          }
        }
        .special-item .item-wrap {
          > div:first-child {
            display: flex;
            justify-content: space-between;
          }
        }
        .special-item {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>

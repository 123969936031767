import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home'
import ATS from '../views/ats'
import video from '../views/video'
import Program from '../views/program'
import Case from '../views/case'
import MandatePresentation from '../views/mandatePresentation'
import Solution from '../views/solution'
import About from '../views/about'
import Team from '../views/team'
import report from '../views/report'
import shareUrl from '../views/shareUrl'
import ReportDetail from '../views/reportDetail'
import originalResume from '../views/originalResume'
import Vidio from '../views/home/vidio'
import RealAi from '../views/home/real-ai'
import Face from '../views/home/face'
import Eye from '../views/home/eye'
import Terms from '../views/home/terms'
import store from '@/store'
import Price from '@/views/price/index'
import Aigc from '@/views/aigc/index'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    return false
  })
}
// replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  { path: '/', name: 'home', component: Home },
  {
    path: '/product/recruitment',
    name: 'ats',
    component: ATS,
  },
  {
    path: '/product/aiinterview',
    name: 'video',
    component: video,
  },
  {
    path: '/product/coding',
    name: 'program',
    component: Program,
  },
  {
    path: '/case',
    name: 'case',
    component: Case,
  },
  {
    path: '/mandatePresentation',
    name: 'mandatePresentation',
    component: MandatePresentation,
  },
  {
    path: '/solution',
    name: 'solution',
    component: Solution,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/team',
    name: 'team',
    component: Team,
  },
  {
    path: '/price',
    name: 'price',
    component: Price,
  },
  {
    path: '/aigc',
    name: 'aigc',
    component: Aigc,
  },
  { path: '/tryout', name: 'tryout', component: () => import('../views/home/tryout') },
  { path: '/vidio', name: 'vidio', component: Vidio },
  { path: '/real-ai', name: 'realAi', component: RealAi },
  { path: '/face', name: 'face', component: Face },
  { path: '/eye', name: 'eye', component: Eye },
  { path: '/terms', name: 'terms', component: Terms },
  { path: '/interview', name: 'interview', component: () => import('../views/interview'), props: { default: true } },
  //正常分享
  { path: '/sharereport', name: 'report', component: report, redirect: { name: 'newShareReport' } },
  //钉钉分享
  { path: '/shareurl', name: 'shareurl', component: shareUrl },
  { path: '/reportDetail', name: 'reportDetail', component: ReportDetail },
  { path: '/originalResume', name: 'originalResume', component: originalResume },
  { path: '/showCandidateForm', name: 'showCandidateForm', component: () => import('@/views/showCandidateForm') },
  { path: '/reportContent', name: 'ReportContent', component: () => import('@/components/ReportContent') },
  { path: '/newShareReport', name: 'newShareReport', component: () => import('@/views/newShareReport') },
  { path: '/login', name: 'Login', component: () => import('@/views/Login.vue') },
  { path: '/loginOut', name: 'LoginOut', component: () => import('@/views/LoginOut.vue') },
  { path: '/authenticaResult', name: 'AuthenticaResult', component: () => import('@/views/AuthenticaResult.vue') },
  { path: '/interview-satisfaction', name: 'interviewSatisfaction', component: () => import('@/views/interview-satisfaction') },
  // {
  //   path: '/talentList',
  //   name: 'TalentList',
  //   component: () => import('@/views/TalentList.vue'),
  // },
  // {
  //   path: '/talentList2',
  //   name: 'TalentList2',
  //   component: () => import('@/views/TalentList2.vue'),
  // },

  { path: '/redirect', name: 'Redirect', component: () => import('@/views/Redirect.vue') },
  { path: '/datePicker', name: 'DatePicker', component: () => import('@/views/DatePicker.vue') },
  {
    path: '/first',
    component: () => import('@/views/First.vue'),
    children: [
      // 候选人管理人才列表
      {
        path: 'talentList',
        name: 'TalentList',
        component: () => import('@/views/TalentList.vue'),
      },
      {
        path: 'talentList2',
        name: 'TalentList2',
        component: () => import('@/views/TalentList2.vue'),
      },
    ],
  },
  { path: '/manualEstimateDialog', name: 'ManualEstimateDialog', component: () => import('@/views/ManualEstimateDialog.vue') },
  { path: '/mail-report', name: 'mail-report', component: () => import('@/views/mail-report.vue') },
  {
    path: '/emailFeedback',
    component: () => import('@/views/email-feedback/index.vue'),
  },
  {
    path: '/schedule-an-interview',
    component: () => import('@/views/schedule-an-interview/index.vue'),
    children: [
      {
        path: '/',
        name: 'schedule-an-interview',
        component: () => import('@/views/schedule-an-interview/schedule-an-interview.vue'),
      },
      {
        path: 'confirm-reserve',
        name: 'confirm-reserve',
        component: () => import('@/views/schedule-an-interview/confirm-reserve.vue'),
      },
      {
        path: 'success-reserve',
        name: 'success-reserve',
        component: () => import('@/views/schedule-an-interview/success-reserve.vue'),
      },
      {
        path: 'feedback-success',
        name: 'feedback-success',
        component: () => import('@/views/schedule-an-interview/feedback-success.vue'),
      },
    ],
  },
  {
    path: '/welfareActivities',
    component: () => import('@/views/welfare-activities/index.vue'),
  },
  {
    path: '/auth',
    component: () => import('@/views/auth/index.vue'),
  },
  {
    path: '/privacy',
    component: () => import('@/views/privacy/index.vue'),
  },
  {
    path: '/idcollect',
    component: () => import('@/views/idcollect/index.vue'),
  },
  {
    path: '/idcheck',
    component: () => import('@/views/idcheck/index.vue'),
  },
  { path: '/mobilemonitor', name: 'mobilemonitor', component: () => import('@/views/mobile-monitor/index.vue') },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})
const isDingtalk = process.env.VUE_APP_ENV === 'dingtalk'
const isUat = process.env.VUE_APP_ENV === 'uat'
const isMobile = /(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(navigator.userAgent.toLowerCase())
const url =
  process.env.NODE_ENV === 'production'
    ? isDingtalk
      ? 'https://www.airczp.cn'
      : 'https://hr.aidexianzhaopinguan.com'
    : isDingtalk
    ? 'https://uat.airczp.cn'
    : isUat
    ? 'https://uat.aidexianzhaopinguan.com'
    : 'https://mini.aidexianzhaopinguan.com'

router.beforeEach((to, from, next) => {
  // console.log(store.state.userInfo)
  // console.log(to.path)
  // console.log(to.path)
  if (!isDingtalk) {
    if ((!store.state.userInfo || store.state.userInfo.authStatus === 0) && to.path !== '/login' && store.state.goWechat) {
      if (to.path !== '/loginOut') {
        store.commit('setRedirectUrl', to.fullPath)
      }
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})
//  判断终端
router.beforeEach((to, from, next) => {
  // if (!isDingtalk) {
  if (to.path === '/mail-report' || to.path === '/sharereport' || to.path === '/welfareActivities' || to.path === '/newShareReport') {
    if (!isMobile) {
      // const url = process.env.VUE_APP_API_ROOT
      window.location.href = `${url}` + to.fullPath
    } else {
      next()
    }
    // } else {
    //   next()
    // }

    // if (to.path === '/sharereport' || to.path === '/welfareActivities') {
    //   if (!/(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(navigator.userAgent.toLowerCase())) {
    //     const url = process.env.NODE_ENV === 'production' ? 'https://hr.aidexianzhaopinguan.com' : 'https://mini.aidexianzhaopinguan.com'
    //     window.location.href = `${url}` + to.fullPath
    //   } else {
    //     next()
    //   }
    // } else {
    //   next()
    // }

    // if (to.path === '/newShareReport') {
    //   if (!/(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(navigator.userAgent.toLowerCase())) {
    //     const url = process.env.NODE_ENV === 'production' ? 'https://hr.aidexianzhaopinguan.com' : 'https://mini.aidexianzhaopinguan.com'
    //     console.log(`${url}` + to.fullPath)
    //     window.location.href = `${url}` + to.fullPath
    //   } else {
    //     next()
    //   }
    // } else {
    //   next()
    // }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  console.log('===========to', to)
  console.log('===========from', from)
  if (to.path === '/login' || from.path === '/auth') {
    store.commit('setRouteLength', 1)
  } else {
    store.commit('setRouteLength', store.state.routeLength + 1)
  }
})

export default router

<template>
  <div>
    <div id="expreCharts" style="width: 100%; height: 300px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    emotionscore: {
      type: Object,
      default() {
        return {
          emtions: [],
        }
      },
    },
  },
  data() {
    return {
      data: [],
      dataValue: [
        {
          name: '高兴',
          value: 0,
        },
        {
          name: '中性',
          value: 0,
        },
        {
          name: '难过',
          value: 0,
        },
        {
          name: '惊讶',
          value: 0,
        },
        {
          name: '害怕',
          value: 0,
        },
        // {
        //   name: '讨厌',
        //   value: 0,
        // },
        {
          name: '愤怒',
          value: 0,
        },
        {
          name: '厌恶',
          value: 0,
        },
      ],
    }
  },
  methods: {
    drawLine() {
      var myChart = echarts.init(document.getElementById('expreCharts'))
      // 绘制图表
      myChart.setOption({
        // tooltip: {
        //   //提示框组件
        //   trigger: "item" ,//触发类型(饼状图片就是用这个)
        //    type: 'scroll',
        // orient: 'vertical',

        // },
        tooltip: {
          trigger: 'item',
          formatter: '{b} {d}%',
        },
        color: ['#FFA23A', '#1890FF', '#B96Df0', '#F4D75E', '#EE5F4A', '#71C74D', '#a4c8cd'], //手动设置每个图例的颜色
        legend: {
          //图例组件
          type: 'plain',
          //right:100,  //图例组件离右边的距离
          // orient: "vertical", //布局  纵向布局 图例标记居文字的左边 vertical则反之
          width: 210, //图行例组件的宽度,默认自适应
          //图例显示在右边
          y: 'bottom', //图例在垂直方向上面显示居中
          itemWidth: 12, //图例标记的图形宽度
          itemHeight: 4, //图例标记的图形高度
          // data: ["高兴", "中性", "难过", "惊讶", "害怕", "愤怒", "讨厌"],
          data: this.dataValue.map(item => item.name),
          // data: this.data.map(item => item.name),
          textStyle: {
            //图例文字的样式
            color: '#333', //文字颜色
            fontSize: 8, //文字大小
            width: 30,
            rich: {
              a: {
                fontSize: 8,
                width: 40,
              },
            },
          },
          align: 'left',
          itemGap: 16,
          orient: 'horizontal',
          // width: 220,
          formatter: name => {
            var total = this.data.reduce((pre, next) => {
              return pre + parseInt(next.value)
            }, 0)

            var item = this.data.find(item => {
              return item.name === name
            }).value
            return '{a|' + name + (total === 0 ? 0 : ((item / total) * 100).toFixed(2)) + '%' + '}'
          },
        },
        series: [
          //系列列表
          {
            name: '', //系列名称
            type: 'pie', //类型 pie表示饼图
            center: ['50%', '50%'], //设置饼的原心坐标 不设置就会默认在中心的位置
            // radius : '45%',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true, //避免折线重叠
            // right: 20,
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            label: {
              normal: {
                show: false,
                textStyle: {
                  fontSize: '14',
                },
                formatter: '{b}{d}%',
              },
              emphasis: {
                show: false,
                textStyle: {
                  fontSize: '14',
                },
                formatter: '{d}%',
              },
            },
            labelLine: {
              lineStyle: {
                width: 2,
              },
              length: 10,
              length2: 20,
            },
            data: this.dataValue,
          },
        ],
      })
    },
  },
  watch: {
    // emotionscore() {
    //   for (var i = 0; i < this.emotionscore.emtions.length; i++) {
    //     this.data.push({
    //       name: this.emotionscore.emtions[i].emotionname,
    //       value: this.emotionscore.emtions[i].emotioncount
    //     });
    //   }
    //   console.log(this.data, " this.data");
    //   this.drawLine();
    // }
  },
  mounted() {
    for (var i = 0; i < this.emotionscore.emtions.length; i++) {
      this.data.push({
        name: this.emotionscore.emtions[i].emotionname,
        value: this.emotionscore.emtions[i].emotioncount,
      })
    }
    this.dataValue.forEach(i => {
      let arr = this.data.find(k => k.name === i.name)
      i.value = arr.value
    })
    let arr = this.dataValue.splice(-2)
    let num = arr.reduce((p, c) => p + c.value, 0)
    this.dataValue.push({ name: '讨厌', value: num })
    this.data = this.dataValue
    // this.dataValue = this.dataValue.map(item => {
    //   return {
    //     ...item,
    //     value: this.data.find(item2 => item.name === item2.name).value,
    //   }
    // })

    // find函数 找出第一个符合条件的数组成员
    // console.log(this.data, " this.data");
    this.drawLine()
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="ai-vidio">
    <div class="header">
      <img class="img-bg" src="~@/assets/images/bg-2.png" />
      <img class="logo" src="~@/assets/images/five-years/LOGO-5.png" @click="toHome" />
      <div class="text">
        <p>人工智能时代的</p>
        <p>招聘管理系统</p>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="title-box">
        <div class="title">
          <span>什么是AI视频面试</span>
        </div>
      </div>
      <div class="content-box">
        <div class="text-tags">
          <div class="tag">语义识别算法</div>
          <div class="tag">表情识别算法</div>
        </div>
        <div class="text-tags">
          <div class="tag">声音识别算法</div>
          <div class="tag">职业形象识别算法</div>
        </div>

        <div class="text">
          AI视频面试的原理就是通过对候选人的回答内容、表情和声音特质的解析，AI将自动匹配岗位的胜任力模型，进行公平、公正的评估打分，最终提供人才评估报告。业务经理只需要进行二次选择，就能筛选出在高胜任力+强企业(团队)文化融入的候选人，避免人才聘用失败的风险。
        </div>
      </div>
    </div>
    <tools></tools>
  </div>
</template>

<script>
import Tools from '@/components/tools'
export default {
  components: { Tools },
  mounted() {
    window.scrollTo({ top: 0 })
  },
  methods: {
    toHome() {
      this.$router.push({ name: 'home' })
    },
  },
}
</script>

<style lang="scss" scoped>
.ai-vidio {
  position: relative;
  height: 100vh;
  .header {
    width: 100%;
    height: 240px;
    position: relative;
    .img-bg {
      width: 100%;
    }
    .logo {
      position: absolute;
      top: 20px;
      left: 25px;
      height: 42px;
    }
    .text {
      position: absolute;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      top: 80px;
      width: 100%;
      p {
        width: 100%;
        text-align: center;
        margin: 0;
      }
    }
  }
  .content-wrapper {
    padding-top: 64px;
    position: relative;
    padding-bottom: 80px;
    .title-box {
      position: absolute;
      top: 30px;
      z-index: 2;
      width: 100%;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 60px;
      margin: 0 auto;
      background: linear-gradient(90deg, #0099ff 0%, #0064fa 100%);
      border-radius: 24px 0px 24px 0px;

      span {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .content-box {
      margin: 0px 5%;
      padding: 40px 5%;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      .text-tags {
        display: flex;
        margin: 15px 0;
        justify-content: space-between;

        .tag {
          width: calc(50% - 8px);
          padding: 15px;
          background: #e1eeff;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.7);
          text-align: center;
        }
      }
      p {
        margin: 0;
        padding: 0;
      }
      .text {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
      }
    }
  }
}
</style>

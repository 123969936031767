<template>
  <div :style="docPreviewStyle">
    <iframe :src="previewResumeUrl" :style="iframeStyle" name="preview-iframe" id="preview-iframe" frameborder="0" scrolling="auto" width="100%"></iframe>
  </div>
</template>

<script>
import { getPreviewUrl } from '@/api/rpa'
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String || Number,
      default: '',
    },
  },
  data() {
    return {
      previewResumeUrl: '',
    }
  },
  computed: {
    docPreviewStyle() {
      return {
        height: this.minHeight,
      }
    },
    iframeStyle() {
      return {
        minHeight: this.minHeight,
      }
    },
  },
  methods: {
    getPreviewUrl(url) {
      getPreviewUrl({ sourceUrl: url }).then(res => {
        if (res && res.code === 0) {
          this.previewResumeUrl = res.data
        } else {
          this.previewResumeUrl = ''
        }
      })
    },
  },
  watch: {
    url: {
      immediate: true,
      handler() {
        this.previewResumeUrl = ''
        const url = this.url.replace('http://', 'https://')
        this.getPreviewUrl(url)
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>

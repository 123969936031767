<template>
  <div class="ai-vidio">
    <div class="header">
      <img class="img-bg" src="~@/assets/images/bg-2.png" />
      <img class="logo" src="~@/assets/images/five-years/LOGO-5.png" @click="toHome" />
      <div class="text">
        <p>人工智能时代的</p>
        <p>招聘管理系统</p>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="title-box">
        <div class="title">
          <span>如何辨别真假“AI”</span>
        </div>
      </div>
      <div class="content-box">
        <div class="text-tags">
          <div class="tag">“上量”！</div>
        </div>

        <div class="text">
          <p>要求演示成功的案例，没有成功案例的可能都是试验品，风险较大。</p>
          <p style="margin-top: 20px">
            同时邀请500个候选人进行AI面试，如果在很短的时间内，Al面试分析报告都可以在管理后台呈现的，Al技术含金量较高；如果Al面试报告是滞后的，要很久才能出来，Al的真实性需要被质疑，可能是后台人为标注。
          </p>
        </div>

        <div class="text-tags" style="margin-top: 30px">
          <div class="tag">假AI的“遮羞布”</div>
        </div>

        <div class="text">
          <p>
            如果500个候选人都呈现了Al面试报告，但是候选人的答案不符合胜任力标准，仍得高分，可能是来自于简历解析，并没有Al视频面试的技术模型。
          </p>
          <p style="margin-top: 20px">
            将Al面试(视频或者文字题)和传统的心理测题打包在一起，这样确实也可以立即看到一份Al面试分析报告，但是这个报告也许和Al没有一点关系。
          </p>
        </div>
      </div>
    </div>
    <tools></tools>
  </div>
</template>

<script>
import Tools from '@/components/tools'
export default {
  components: { Tools },
  mounted() {
    window.scrollTo({ top: 0 })
  },
  methods: {
    toHome() {
      this.$router.push({ name: 'home' })
    },
  },
}
</script>

<style lang="scss" scoped>
.ai-vidio {
  height: 100vh;
  position: relative;
  .header {
    width: 100%;
    position: relative;
    .img-bg {
      width: 100%;
    }
    .logo {
      position: absolute;
      top: 20px;
      left: 25px;
      height: 42px;
    }
    .text {
      position: absolute;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      top: 80px;
      width: 100%;
      p {
        width: 100%;
        text-align: center;
        margin: 0;
      }
    }
  }
  .content-wrapper {
    padding-top: 64px;
    position: relative;
    padding-bottom: 80px;
    .title-box {
      position: absolute;
      top: 30px;
      z-index: 2;
      width: 100%;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 60px;
      margin: 0 auto;
      background: linear-gradient(90deg, #0099ff 0%, #0064fa 100%);
      border-radius: 24px 0px 24px 0px;
      position: relative;
      z-index: 2;
      span {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .content-box {
      margin: 0px 5%;
      padding: 40px 5%;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      border-radius: 4px;

      .text-tags {
        display: flex;
        margin: 15px 0;
        justify-content: space-between;

        .tag {
          width: 160px;
          padding: 15px;
          background: #e1eeff;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.7);
          text-align: center;
        }
      }
      p {
        margin: 0;
        padding: 0;
      }
      .text {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
      }
    }
  }
}
</style>

export default {
  text001: '回答及得分',
  text002: '{0}分',
  text003: 'TA的回答',
  text004: '编程语言',
  text005: '正确答案',
  text006: '正确',
  text007: '错误',
  text008: '没有内容可以查看',
  text009: '编程题',
}

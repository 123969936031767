export default {
  text001: '評論結果',
  text002: '類型',
  text003: '得分',
  text004: '請閱讀以下注意事項，並認真填寫',
  text005: '①每組有A、B、C、D四個選項，分值為1、3、5、7，分別從最不像你的性格→像你的性格。',
  text006: '②每組1、3、5、7均需出現，不能重複。',
  text007: '③匯總各組A、B、C、D選項得分，總分必須在80分且每一項均為奇數。',
  text008: '組別',
  text009: '習性的描述與說明',
  text010: '自我評分',
}

export default {
  text001: 'Meritas-心理健康測評',
  text002:
    '得賢Meritas-心理健康問卷基於心理健康雙因素模型理論（Keyes,1998)，將 “積極心理指標”和“心理症狀指標”作為心理健康診斷的兩個必不可少 的因素，以綜合評估候選人的心理健康程度。積極心理指標包括：自我肯定、希望、樂觀、韌性這4個維度。擁有這些心理狀態的個體能夠 從消極事件中快速恢復、調解自己的心理狀態，順應環境，從而在工作 中往往展現更高的工作效率和更強的工作滿意度。心理症狀指標包括：焦慮、抑鬱、人際敏感、敵對、偏執這5個在職場中最為常見的心理症狀。',
  text003: '{name}的心理健康度：',
  text004: '處於',
  text005: '具體表現為',
  text006: '積極心理',
  text007: '消極心理',
  text008: '{0}分',
  text009: '沒有內容可以查看',

  text010: '在人際交往過程中自卑感較強，心神不安，表現出明顯的不自在，並排斥各類社交活動',
  text011: '合群，外向，活躍，能夠自如應付人際關係，且樂於和他人溝通交流。',
  text012: '情緒低落，缺乏活力，幹什麼都打不起精神，以及對生活缺乏信心，感覺自己沒有什麼價值等。',
  text013: '樂觀積極，心境愉快，認可自己的價值，工作生活中充滿活力。',
  text014: '情緒反復無常，急躁易怒、行為衝動、甚至還可能存在妄想等情況。',
  text015: '心態平和，放鬆，穩定，遇事之後能夠積極調解情緒，不慌亂。',
  text016: '脾氣難以控制，好爭論，對他人缺少基本的信任，難以合作，且經常與他人發生矛盾或爭論。',
  text017: '陽光、樂觀，隨和、待人友好，不喜歡爭論，信任他人，願意與任何人合作。',
  text018: '多疑，敏感，固執，不願意相信別人，以及很難接受別人的建議和意見',
  text019: '謙虛，靈活，客觀理性，能虛心傾聽、接受別人的建議和意見，並加以改正。',
  text020: '對成長和成就有著強烈的需求，能夠鍥而不捨地追求目標的完成',
  text021: '順從，缺乏成就動因，缺乏目標，容易退縮，自暴自棄',
  text022: '積極，現實，靈活，把成功歸因為自身的能力，而把失敗事件歸因為運氣等不穩定的因素。',
  text023: '消極，悲觀，把失敗歸結於自身的能力，把成功歸結於運氣等不穩定的因素',
  text024: '自信心高，即使面對挑戰性的任務，依然能夠充滿自信並努力達成目標',
  text025: '自信心低，常常自我否定，往往在困難面前猶豫不決，懷疑自己的能力。',
  text026: '意志堅定，受到打擊或身處逆境時，能夠及時地自我調節，使自己迅速地恢復過來',
  text027: '意志薄弱，遇到挫折或困難時，傾向於放棄，逃避',

  text028: '非常健康的心理狀態',
  text029: '較為健康的心理狀態',
  text030: '正常狀態',
  text031: '部分健康心理狀態',
  text032: '完全障礙心理狀態',
}

<template>
  <div class="ai-vidio">
    <div class="header">
      <img class="img-bg" src="~@/assets/images/bg-2.png" />
      <img class="logo" src="~@/assets/images/five-years/LOGO-5.png" @click="toHome" />
      <div class="text">
        <p>人工智能时代的</p>
        <p>招聘管理系统</p>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="title-box">
        <div class="title">
          <span>无效的微表情分析</span>
        </div>
      </div>
      <div class="content-box">
        <div class="text">
          持续时间小于0.5秒的表情，通常称为微表情，持续时间在1s~5s之间，称为宏表情。目前，科学家最领先的水平都无法精确的检测微表情，更不用提分析微表情的含义了，商用微表情基本都是无稽之谈，南北差异、年龄差异、性别差异都可能让微表情所表达的意思南辕北辙。
        </div>
      </div>
    </div>
    <tools></tools>
  </div>
</template>

<script>
import Tools from '@/components/tools'
export default {
  components: { Tools },
  mounted() {
    window.scrollTo({ top: 0 })
  },
  methods: {
    toHome() {
      this.$router.push({ name: 'home' })
    },
  },
}
</script>

<style lang="scss" scoped>
.ai-vidio {
  position: relative;
  height: 100vh;
  .header {
    width: 100%;
    height: 240px;
    position: relative;
    .img-bg {
      width: 100%;
    }
    .logo {
      position: absolute;
      top: 20px;
      left: 25px;
      height: 42px;
    }
    .text {
      position: absolute;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      top: 80px;
      width: 100%;
      p {
        width: 100%;
        text-align: center;
        margin: 0;
      }
    }
  }

  .content-wrapper {
    padding-top: 64px;
    position: relative;
    padding-bottom: 120px;
    padding-bottom: 80px;
    .title-box {
      position: absolute;
      top: 30px;
      z-index: 2;
      width: 100%;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 60px;
      margin: 0 auto;
      background: linear-gradient(90deg, #0099ff 0%, #0064fa 100%);
      border-radius: 24px 0px 24px 0px;
      span {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .content-box {
      margin: 0px 5%;
      padding: 40px 5%;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      .text {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
      }
    }
  }
}
</style>

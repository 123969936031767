<template>
	<div class="l2-q_sp">
		<div v-for="index in step" :key="index" class="sp-i" :class="index === 1 ? 'first' : index === step ? 'last' : ''">
			<div v-if="index !== 1" class="sp-i_l"></div>
			<div class="sp-i_r"></div>
		</div>
		<div class="buoy" :style="{ left: fromLeft }">{{ sort }}%</div>
	</div>
</template>
<script>
export default {
	props: {
		step: { default: 20, type: Number },
		sort: { default: 0, type: Number },
	},

	computed: {
		fromLeft() {
			let left = 0
			left = this.sort
			if (this.sort < 5) {
				left = 0
			}
			if (this.sort > 80) {
				left = 80
			}
			return `${left}%`
		},
	},
}
</script>
<style lang="scss" scoped>
.l2-q_sp {
	// background: green;
	width: 100%;
	display: flex;
	align-items: center;
	// margin: auto;
	// width: 624px;
	position: relative;
	background-image: linear-gradient(to right, rgba(189, 231, 254, 1), rgba(201, 244, 244, 1));
	border-radius: 10px;
	.sp-i {
		// width: 2px;
		flex: 1;
		// min-width: 2px;
		// max-width: 2px;
		height: 20px;
		display: flex;

		.sp-i_l {
			width: 2px;
			min-width: 2px;
			max-width: 2px;
			background: #ffffff;
		}
	}
	.first {
		border-radius: 50% 0 0 50%;
		// margin-left: 20px;
		width: 0;
		min-width: 0;
	}
	.last {
		border-radius: 0 50% 50% 0;
		width: 0;
		min-width: 0;
	}
	.buoy {
		width: 20%;
		height: 34px;
		background: rgba(68, 215, 182, 1);
		color: #ffffff;
		position: absolute;
		left: -40px;
		box-shadow: 0px 2px 10px 0px #44d7b6;
		text-align: center;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>

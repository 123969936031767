<template>
  <div class="candidateForm">
    <div class="candidateForm-content" v-if="employInfoList.length || attachList.length">
      <div class="employInfo">
        <div class="menue-block" v-for="(employInfo, index) in employInfoList" :key="index">
          <div class="parentInfo">
            <div class="menueItem">{{ employInfo.name }}</div>
          </div>
          <div class="childInfo" v-for="(childItem, cIndex) in filterArray(employInfo.children)" :key="cIndex">
            <div class="childInfo-lable ellipsis">{{ childItem.menuName }}:</div>
            <div class="childInfo-content">{{ childItem.fileValue }}</div>
          </div>
        </div>
      </div>
      <div class="attachfo" v-if="attachList && attachList.length">
        <div class="menue-block">
          <div class="parentInfo">
            <div class="menueItem">{{ $t('com.report.resume.text002') }}</div>
          </div>
          <div class="childInfo" v-for="(attachItem, cIndex) in filterArray(attachList)" :key="cIndex">
            <div class="childInfo-lable ellipsis">{{ attachItem.attachName }}</div>
            <div class="childInfo-content">
              <div v-if="getFileType(attachItem.fileValue) === 1" class="operate">
                <div class="preview" @click="preview(attachItem)">{{ $t('com.report.resume.text003') }}</div>
                <!-- <div class="downLoadText" @click="downLoad(attachItem.fileValue, attachItem.attachName)">
                  点击下载
                </div> -->
                <!-- <img :src="attachItem.fileValue" /> -->
              </div>

              <div v-if="getFileType(attachItem.fileValue) === 2" class="operate">
                <div class="preview" @click="preview(attachItem)">{{ $t('com.report.resume.text003') }}</div>
                <!-- <div class="downLoadText" @click="downLoad(attachItem.fileValue, attachItem.attachName)">
                  点击下载
                </div> -->
                <video :src="attachItem.fileValue" id="vid" controls>
                  <source type="video/mp4" />
                </video>
              </div>

              <div v-if="getFileType(attachItem.fileValue) === 3" class="operate">
                <div class="preview" @click="preview(attachItem)">{{ $t('com.report.resume.text003') }}</div>
                <!-- <div class="downLoadText" @click="downLoad(attachItem.fileValue, attachItem.attachName)">
                  点击下载
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-empty :description="$t('com.report.resume.text004')" v-else />
  </div>
</template>
<script>
import { getTempleteList, getEmployInfo } from '@/api/candidate'
import fs from 'file-saver'
import EventBus from '@/common/event-bus'

export default {
  // @CandidateModule.Action('getTempleteList') getTempleteList!: (data?: any) => Promise<IHttpResponse>
  // @CandidateModule.Action('getEmployInfo') getEmployInfo!: (data?: any) => Promise<IHttpResponse>
  computed: {
    interviewResult() {
      return this.$store.state.interviewResult
    },
    positionId() {
      return this.$store.state.positionId
    },
    jobseekerChannelId() {
      return this.$store.state.jobseekerChannelId
    },
    canStartRequest() {
      if (this.positionId && this.jobseekerChannelId && this.interviewResult) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    canStartRequest: {
      handler: function(val) {
        if (val) {
          this.phone = this.interviewResult.phone || ''
          Promise.all([
            getEmployInfo({
              phone: this.phone,
              positionId: this.positionId,
              jobseekerChanneId: this.jobseekerChannelId || '',
            }),
            getTempleteList(),
          ]).then(result => {
            this.initData(result)
          })
        }
      },
      immediate: true,
    },
  },
  created() {},
  data() {
    return {
      phone: 0,
      employInfoList: [],
      attachList: [],
    }
  },
  methods: {
    preview(attach) {
      EventBus.$emit('openPreviewer', attach.fileValue)
    },
    isObjectEmpty(obj) {
      if (Object.keys(obj).length === 0) {
        return true
      }
      return false
    },
    filterArray(employInfoChildList) {
      const tempArray = employInfoChildList.filter(item => {
        return item.fileValue !== ''
      })
      return tempArray
    },

    downLoad(sourceUrl, sourceName) {
      console.log(sourceUrl, sourceName)
      fs.saveAs(sourceUrl, sourceName)
    },

    getFileType(fileName) {
      const fileType = fileName.split('.').slice(-1)[0]
      if (/^(jpeg|png|jpg|JPG|JPEG)$/.test(fileType)) {
        return 1
      } else if (/^(mov|mp4|avi|wov)$/.test(fileType)) {
        return 2
      } else {
        return 3
      }
    },

    initData(result) {
      if (result[0] && result[1] && result[0].code === 0 && result[1].code === 0) {
        if (result[0].data && result[1].data) {
          const employInfoList = result[0].data.infos
          const templeteList = result[1].data
          this.attachList = result[0].data.attachs
          const parentMaxNumMap = new Map()
          employInfoList.forEach(item => {
            const parentId = item.parentId
            if (parentMaxNumMap.has(parentId)) {
              if (parentMaxNumMap.get(parentId) < item.arrayIndex) {
                parentMaxNumMap.set(parentId, item.arrayIndex)
              }
            } else {
              parentMaxNumMap.set(parentId, item.arrayIndex)
            }
          })
          const parentItemArray = []
          for (const [parentId, maxNum] of parentMaxNumMap) {
            for (let i = 0; i <= maxNum; i++) {
              const parentItem = {
                parentId: parentId,
                name: '',
                children: [],
              }
              employInfoList.forEach(chidlItem => {
                if (chidlItem.parentId === parentId && chidlItem.arrayIndex === i) {
                  parentItem.children.push(chidlItem)
                }
              })

              const templeteItem = templeteList.find(templeteItem => {
                return templeteItem.id + '' === parentId
              })
              parentItem.name = templeteItem?.menuName
              parentItemArray.push(parentItem)
            }
          }
          this.employInfoList = parentItemArray
        }
      }

      if (!this.employInfoList.length && !this.attachList.length) {
        this.$emit('removeCanidateForm')
      }
      console.log('initDatainitDatainitDatainitData')
    },
  },
}
</script>

<style lang="scss">
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.candidateForm {
  min-height: 100vh;
  //overflow: scroll;
  padding: 0px 20px 50px 20px;
  box-sizing: border-box;

  .candidateForm-content {
    padding: 10px 0 0 0;
    .employInfo {
      .menue-block:not(:first-child) {
        margin-top: 50px;
      }
      .parentInfo {
        .menueItem {
          font-size: 18px;
          font-weight: 600;
        }
        .menueItem:before {
          content: '| ';
          font-size: 20px;
          font-weight: 600;
          color: rgba(24, 144, 255, 1);
        }
      }
      .childInfo {
        display: flex;
        padding: 12px 0px;
        font-size: 16px;
        margin-top: 10px;
        color: #666;
        border-bottom: 1px solid #ebedf0;
        .childInfo-lable {
          flex: 3;
        }
        .childInfo-content {
          flex: 5;
        }
      }
    }

    .attachfo {
      .menue-block {
        margin-top: 50px;
      }
      .parentInfo {
        font-size: 18px;
        font-weight: 600;
        .menueItem:before {
          content: '| ';
          font-size: 20px;
          font-weight: 600;
          color: rgba(24, 144, 255, 1);
        }
      }
      .childInfo {
        display: flex;
        flex-direction: row;
        padding: 12px 0px;
        font-size: 16px;
        margin-top: 10px;
        color: #666;
        border-bottom: 1px solid #ebedf0;
        .childInfo-lable {
          flex: 3;
        }
        .childInfo-content {
          // flex: 5;
          overflow: scroll;
          .operate {
            display: flex;
            align-items: center;
            .preview {
              text-align: center;
              text-decoration: underline;
              text-decoration-color: rgba(24, 144, 255, 1);
              color: rgba(24, 144, 255, 1);
              cursor: pointer;
              margin-right: 10px;
            }
          }
          img {
            width: 100%;
            background-size: cover;
          }
          video {
            width: 100%;
          }
          .downLoadText {
            text-align: center;
            text-decoration: underline;
            text-decoration-color: rgba(24, 144, 255, 1);
            color: rgba(24, 144, 255, 1);
            cursor: pointer;
          }
        }
      }
    }
  }

  .no-data-wrapper {
    padding: 20vh;
    .no-data-text {
      text-align: center;
    }
    .img-wrapper {
      width: 80%;
      margin: 20px auto 0 auto;
      img {
        width: 100%;
      }
    }
  }
}
</style>

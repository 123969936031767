<template>
  <!-- <div> -->
  <iframe class="resumeUrl" frameborder="0" scrolling="auto" width="100%" height="100%" :src="url"></iframe>
  <!-- </div> -->
</template>

<script>
export default {
  data() {
    return {
      url: '',
    }
  },
  created() {
    this.url = sessionStorage.getItem('url').replace('http://', 'https://')
    // this.url = this.$route.query.url
    console.log(this.url)
  },
  methods: {},
  beforeDestroy() {
    sessionStorage.setItem('url', '')
  },
  beforeRouteLeave(to, from, next) {
    next(() => {
      sessionStorage.setItem('url', '')
    })
  },
}
</script>

<style lang="scss" scoped></style>

export default {
  text001: '回答',
  text002: '您正在使用非WIFI網路，播放將產生流量費用',
  text003: '候選人沒有錄製視頻',
  text004: '考核勝任力',
  text005: 'L4-AI得分',
  text006: 'AI得分',
  text007: '生成中',
  text008: '收起',
  text009: '查看詳情',
  text010: '{name}打分為:{score}',
  text011: '{name}評價為:{score}',
  text012: '考核勝任力',
  text013: '定義',
  text014: '暫無維度',
  text015: '暫無視頻題',
  text015_: '未配置視頻題目',
  text016: '論述題',
  text017: '視頻題',
  text018: '單選題',
  text019: '多選題',
  text020: '判斷題',
  text021: '排序題',
  text022: '口音題',
  text023: '編程題',
  text024: 'L2-普通話',
  text025: 'L2-英語',
  text026: '測試候選人普通話水準能力，以中文識別候選人回答內容。',
  text027: '測試候選人英語水準能力，以英文識別候選人回答內容。',
  text028: '此視頻暫無法播放，請稍後再試',
  text029: '提交成功',
  text030: '提交失敗',
  text031: '通過',
  text032: '拒絕',
  text033: '待定',
  text034: '正確',
  text035: '錯誤',
  text036: '回答正確',
  text037: '回答不全',
  text038: '回答錯誤',
  text039: '候選人尚未回答該題',
  text040: '客觀題',
  text041: '正確答案',
  text042: '暫無其他面試記錄',
  text045: '展開',
  text046: 'TA的回答',
  text047: 'AI追問',
  text048: 'AI追問原因',
  text049: '文本相似度（精準）',
  text050: '文本相似度（模糊）',
  text51: '欠佳',
  text52: '中等',
  text53: '優秀',
  text54: '更多詳情',
  text55: '收起詳情',
  text56: '包括理據標示',
}

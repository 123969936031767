export default {
  text001: '考试',
  text002: '测评',
  text003: '手动添加',
  text004: '考试职位',
  text005: '成绩',
  text006: '报告',
  text007: '预览',
  text008: '返回',
  text009: '保存',
  text010: '添加考试/测评',
  text011: '名称',
  text012: '请输入名称',
  text013: '成绩',
  text014: '请输入成绩',
  text015: '类型',
  text016: '报告',
  text017: '点击上传',
  text018: '支持pdf、doc(x)、txt文件的预览，最大不超过50M',
  text019: '成绩或报告必须有一个填有信息',
  text020: '报告文件仅限上传一份',
  text021: '上传格式不正确',
  text022: '文件限制大小为50M',
  text023: '上传失败，请重试',
  text024: '提交成功',
  text025: '更新成功',
  text026: '删除成功',
  vendor: '供应商',
  haneo: '嗨优测评',
  other: '其他',
  reviewLink: '测评链接',
  reviewStatus: '测评状态',
  notReviewed: '未测评',
  underReview: '测评中',
  reviewCompleted: '测评完成',
  whetherToDelete: '是否确认删除该测评？',
}

export default {
  text001: '人工评价',
  text002: '人工综合评价',
  text003: '1.请对候选人简历进行评价，并填写具体原因',
  text004: '请填写具体原因(限制500字符)',
  text005: '1.请对候选人的综合表现进行评价，并填写具体原因',
  text006: '请填写具体评价(限制500字符)',
  text007: '1.建议岗位',
  text008: '请填写具体岗位',
  text009: '2.综合评价',
  text010: '2.请对候选人简历进行评价，并填写具体原因',
  text011: '3.请对候选人视频面试表现进行评价，并填写具体原因',
  text012: '取消',
  text013: '提交',
  text014: '提交提醒',
  text015: '您的评价尚未提交，返回后将被清空，请您确认是否返回',
  text016: '您看完候选人的简历之后，还想面试TA吗',
  text017: '请对候选人的综合表现进行五星评价，并决定是否推荐进入下一轮面试',
  text018: '请对候选人视频面试的表现进行五星评价',
  text019: '是',
  text020: '否',
  text021: '待定',
  text022: '通过',
  text023: '拒绝',
  text024: '添加失败',
  text025: '提交成功',
  text026: '评价记录',
  text027: '暂无评价记录',
  text028: '已通过',
  text029: '已待定',
  text030: '已拒绝',
  text031: '候选人简历评价',
  text032: '候选人综合评价',
  text033: '候选人视频面试评价',
  text034: '综合评价',
  text035: '建议岗位',
  text036: '请至少填写一项',
  text037: '收起',
  text038: '展开',
}

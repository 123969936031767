export default {
  text001: 'Please add a note',
  text002: 'Add',
  text003: 'Delete',
  text004: 'Remarks',
  text005: 'Communication Record',
  text006: 'Pass',
  text007: 'Rejected',
  text008: 'To be determined',
  text009: 'This function requires a login operation',
  text010: 'The note was added successfully',
  text011: 'Please enter a note',
}
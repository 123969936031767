export default {
   report: {
     login: {
       text001: 'Please enter the password',
       text002: 'Password',
       text003: 'Login',
       text004: 'Click to view candidate AI holographic resume',
       text005: 'AI Dexian Recruiter',
       text006: 'Are you sure to log out? ',
       text007: 'Exit successfully',
       text008: 'Password error',
       text009: 'Please enter the password',
       text010: 'The report password format is incorrect',
       text011: 'Login account: {name}, click to log out',
       text012: 'Mobile phone number',
       text013: 'Please fill in the mobile phone number',
       text014: 'Password',
       text015: 'Please fill in the password',
       text016: 'Submit',

       text017: 'Please select a user',
       text018: 'Enter email address or name for quick search',
       text019: '{0} people have been selected',
       text020: 'Go to recommend',
       text021: 'Remarks',
       text022: 'Please add a note (optional)',
       text023: 'Confirm',
       text024: 'Please select a user',
       text025: 'Super Administrator',
       text026: 'Administrator',
       text027: 'HR',
       text028: 'Interviewer',
       text029: 'Read only',
       text030: 'Freeze',
       text031: 'Collaborator',
       text032: 'Publisher',
     },
   },
}

export default {
  times: '次',
  riskTip: '风险提示',
  highRisk: '高风险',
  textSimilarity: '文本相似度', // Test Similarity (Accurate)
  exactMatch: '精准匹配',
  fuzzyMatch: '模糊匹配',
  abnormalPhotos: '异常照片',
  exitCount: '跳出次数',
  lognCount: '登录次数',
  actLognCount: '账号登录次数：',
  canlogsys: '候选人登录系统',
  riskDesc: '答题过程中的【异常】状态由以上情况综合判定',
  answer: 'TA的回答',
  accurate: '精准',
  fuzzy: '模糊',
  view: '查看',
  abnormalDesc: 'AI识别到以下照片存在疑似异常状态（异常状态包括：视频中人数不等于1（0或者大于1），或接听电话的情况）',
  enterPhotoWall: '进入照片墙',
  photoWall: '照片墙',
  serialNumber: '序号',
  photos: '张',
  pageExitCount: '页面跳出次数',
  pageExitTime: '页面跳出时间',
  detailsLog: '详细记录',
  candidateExitPage: '候选人跳出答题页面',
  candidateID: '候选人身份信息',
  candidateIDPositive: '身份证正面照',
  candidateIDNegative: '身份证反面照',
  wholeProcessVideo: '全程录制视频',
  screenVideo: '屏幕录制视频',
  screenVideoTip: '此处为候选人AI面试的全程屏幕录制视频，若候选人参与AI面试过程中网络不佳或多次退出刷新面试，此处可能出现多段视频，请您知悉。',
  recordTime: '录制时间',
  doubleVideo: '双机位监控视频',
  noContent: '没有内容可以查看',
  close: '关闭',
  answerProgress: '作答进度',
  screenRecording: '屏幕监控',
  computerMonitoring: '电脑监控',
  phoneMonitoring: '手机监控',
  miniProgram: '小程序',
}

<template>
	<div class="empty_page">
		<div class="no-data-img">
			<img src="@/assets/img/third-empty.png" />
		</div>
		<p>{{ title }}</p>
	</div>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
			default: function () {
				return ''
			},
		},
	},
}
</script>
<style lang="scss" scoped>
.empty_page {
	color: #c5c5c5;
	font-size: 16px;
	user-select: none;
	text-align: center;
	.no-data-img {
		padding-top: 86px;
		margin: 0 auto;
		width: 180px;
		img {
			width: 100%;
		}
	}
}
</style>

import base from './base'
import resume from './resume'
import interviewRecord from './interviewRecord'
import reportV3 from './reportv3'
import interviewPlan from './interviewPlan'
import remark from './remark'
import third from './thirdPartyEvaluation'
import attach from './attach'
import pdp from './pdp'
import cognition from './cognition'
import mental from './mental'
import professional from './professional'
import coding from './coding'
import onlinetest from './onlinetest'
import estimate from './estimate'
import antiCheating from './antiCheating'

export default {
  base,
  resume,
  interviewRecord,
  reportV3,
  interviewPlan,
  remark,
  third,
  attach,
  pdp,
  cognition,
  mental,
  professional,
  coding,
  onlinetest,
  estimate,
  antiCheating,
}

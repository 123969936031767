<template>
  <div class="coding-question">
    <div v-if="currentModuleQuestionList && currentModuleQuestionList.length" class="coding-question">
      <!-- <van-pagination
				v-if="currentModuleQuestionList.length > 1"
				v-model="currentPage"
				:total-items="currentModuleQuestionList.length"
				:show-page-size="5"
				@change="onCurrentPageChange"
				force-ellipses
				:items-per-page="1"
				prev-text="上一题"
				next-text="下一题"
			>
			</van-pagination> -->

      <div v-for="(currentQuestionInfo, index) in currentModuleQuestionList" :key="index">
        <div class="question-type">
          <span>
            <template v-if="currentQuestionInfo.question.problem_answer_method === 7"> {{ index + 1 }}.{{ $t('com.report.onlinetest.bct') }} </template>
            <template v-else> {{ index + 1 }}.{{ $t('com.report.onlinetest.lst') }}</template>
          </span>

          <span v-if="currentQuestionInfo.question.is_not_score && currentQuestionInfo.question.is_not_score === 1"> {{ $t('com.report.onlinetest.text015') }} </span>
          <span v-else>
            (<span style="color: rgb(24, 144, 255)">{{ currentQuestionInfo.question.actualscore }}</span>
            <span>/</span>
            <span>{{ $t('com.report.onlinetest.text031', [currentQuestionInfo.question.labelscore]) }}</span
            >)
          </span>
        </div>

        <question-content :content="currentQuestionInfo.innerText" />

        <div v-if="currentQuestionInfo && currentQuestionInfo.innerImage && currentQuestionInfo.innerImage.length">
          <div v-for="(img, i) in currentQuestionInfo.innerImage" :key="i + Math.random()" class="inner-img">
            <img :src="img" style="max-width: 100%" />
          </div>
        </div>
        <div v-if="currentQuestionInfo && currentQuestionInfo.innerVideo && currentQuestionInfo.innerVideo.length">
          <div class="inner-video-player" v-for="(videoItem, i) in currentQuestionInfo.innerVideo" :key="videoItem">
            <video
              :id="'parseVideo' + i"
              width="100%"
              :ref="'parseVideo' + i"
              height="200px"
              :src="videoItem"
              controls="controls"
              controlsList="nodownload"
              :poster="videoItem + '?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast'"
              webkit-playsinline="true"
              playsinline="true"
              x5-playsinline="true"
              x5-video-player-fullscreen="true"
            >
              您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>

        <div
          class="option-wrapper"
          v-if="
            currentQuestionInfo.questionOptionList &&
            currentQuestionInfo.questionOptionList.length &&
            currentModule === 'objective' &&
            judgeType.includes(currentQuestionInfo.question.problem_answer_method)
          "
        >
          <div v-for="opt in currentQuestionInfo.questionOptionList" :key="opt.optionId">{{ opt.optionCode }}、{{ opt.optionContent }}</div>
        </div>
        <div v-if="currentQuestionInfo.question.problem_answer_method === 7" class="answer-score">
          {{ $t('com.report.coding.text001') }}：
          <span style="color: #1890ff"> {{ $t('com.report.coding.text002', [currentQuestionInfo.question.score || 0]) }}</span>
          /{{ $t('com.report.coding.text002', [currentQuestionInfo.question.labelscore]) }}
          <!-- 回答及得分：(
          <span style="color: #1890ff"> {{ currentQuestionInfo.question.score || 0 }}分 </span>
          /{{ currentQuestionInfo.question.labelscore }}分) -->
        </div>
        <div v-html="getslogan(currentQuestionInfo.question.scoreReason)" style="color: #777;" v-if="currentQuestionInfo.question.scoreReason"></div>
        <div class="question-answer" v-if="currentQuestionInfo.question.answer">
          <div v-if="currentQuestionInfo.question.problem_answer_method === 7">
            <div class="answer-detail">
              <div style="white-space: nowrap" class="answer-label">{{ $t('com.report.coding.text003') }}</div>
              <div class="question-tag">{{ $t('com.report.coding.text004') }}:{{ languageMap[currentQuestionInfo.question.answer_language] }}</div>
            </div>
            <pre
              style="margin: 0"
              @click="downLoadClick"
              :class="{
                green: currentQuestionInfo.question.objective_score === 1 && currentModule === 'objective',
                red: !currentQuestionInfo.question.objective_score && currentModule === 'objective',
                yellow: currentQuestionInfo.question.objective_score === 2 && currentModule === 'objective',
              }"
              >{{ currentQuestionInfo.question.answer }}</pre
            >
          </div>
          <div v-else>
            <div style="white-space: nowrap">{{ $t('com.report.coding.text003') }}：</div>
            <pre
              @click="downLoadClick"
              :class="{
                green: currentQuestionInfo.question.objective_score === 1 && currentModule === 'objective',
                red: !currentQuestionInfo.question.objective_score && currentModule === 'objective',
                yellow: currentQuestionInfo.question.objective_score === 2 && currentModule === 'objective',
              }"
              class="current-question-answer-dom"
              v-html="currentQuestionInfo.question.answer"
              style="margin: 0"
            ></pre>
          </div>
          <div v-if="currentModule === 'objective'" style="display: flex" class="right-answer-wrapper">
            <div>{{ $t('com.report.coding.text005') }}：</div>
            <div v-if="currentQuestionInfo.question.problem_answer_method === 4" class="right-key">
              {{ currentQuestionInfo.question.right_key === 'true' ? $t('com.report.coding.text006') : $t('com.report.coding.text007') }}
            </div>
            <div class="right-key" v-else>{{ currentQuestionInfo.question.right_key }}</div>
          </div>
        </div>


        <div v-if="currentQuestionInfo.question.answerFileList.length" class="attach-list">
          <div v-for="(attach, index) in currentQuestionInfo.question.answerFileList" :key="index" class="attach-item flex btw">
            <div class="flex flex1 overflow">
              <i class="el-icon-paperclip mr5" />
              <div class="overflow" :title="attach.fileName">{{ attach.fileName }}</div>
            </div>
            <div class="flex">
              <span class="preview" @click="preview(attach.fileUrl)">
                {{ $t('com.report.third.text007') }}
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
// import { Vue, Component, Prop } from 'vue-property-decorator'
import { saveAs } from 'file-saver'
import Empty from '@/components/reportV3/common/empty.vue'
import i18n from '../../utils/i18n'
import QuestionContent from './common/question-content.vue'
import EventBus from '@/common/event-bus'
// import RateLog from './rate-log.vue'
// import hologram from '@/store/modules/hologram'

// @Component({
// 	components: {
// 		//  RateLog
// 	},
// })
export default {
  components: { Empty, QuestionContent },
  props: {
    problemType: {
      type: Object,
      default() {
        return { label: 'coding', name: i18n.t('com.report.coding.text009'), types: [7] }
      },
    },
  },

  data() {
    return {
      currentQuestionIndex: 0,
      languageMap: ['C', 'C++', 'Java', 'Python3', 'Go', 'C#', 'Ruby', 'Perl', 'JavaScript'],
      judgeType: [2, 3, 5],
      currentPage: 1
    }
  },
  computed: {
    currentModule() {
      return this.problemType.label
    },
    types() {
      return this.problemType.types || []
    },

    // currentQuestion() {
    // 	// eslint-disable-next-line @typescript-eslint/camelcase
    // 	return this.currentModuleQuestionList[this.currentQuestionIndex] || { question: {}, video_url: [] }
    // },

    //获取当前模块题目
    currentModuleQuestionList() {
      // eslint-disable-next-line camelcase
      return this.answerResult
        .filter(r => {
          const info = Array.isArray(r) ? r[0] : r
          return this.types.includes(+info.question?.problem_answer_method)
        })
        .map((r, i) => {
          const info = Array.isArray(r) ? r[0] : r
          return {
            ...info,
            ...this.getCurrentQuestionInfo(info, i),
          }
        })
    },

    //获取所有题目
    answerResult() {
      return this.$store.state.answerResult || []
    },
    // currentPerson() {
    // 	return hologram.currentPerson
    // },

    // labelscore() {
    // 	return this.currentQuestion.question.labelscore ?? null
    // },
  },

  methods: {
    preview(attachUrl) {
      EventBus.$emit('openPreviewer', attachUrl)
    },
    getslogan(des) {
      return des
        ? des
            .replace(/\r\n/g, '<br>')
            .replace(/\\r\\n/g, '<br>')
            .replace(/\n/g, '<br>')
            .replace(/\r/g, '<br>')
        : ''
    },
    getCurrentQuestionInfo(currentQuestion) {
      const tempStr = currentQuestion.question.problem
      const labelscore = currentQuestion.question.labelscore ?? null
      let innerText = tempStr
      const innerImage = []
      const innerVideo = []
      // const str = `<span style="font-size: 16px;color: #1890FF;white-space: nowrap;">（分值：${labelscore}）</span>`
      // const labelscoreText = currentQuestion.question.problem_answer_method === 7 ? '' : labelscore !== null ? str : ''

      // if (innerText && /<p.*?>/.test(innerText)) {
      //   innerText = innerText.replace(/<p.*?>/, '<p>' + labelscoreText + (index + 1) + '.')
      // } else {
      //   innerText = labelscoreText + index + 1 + '.' + innerText
      // }
      if (innerText) {
        const parser = document.createElement('div')
        parser.innerHTML = innerText
        parser.querySelectorAll('video').forEach(video => {
          // this.poster = video.getAttribute('poster') || ''
          const src = video.getAttribute('src')
          if (src) innerVideo.push(src)
          video.querySelectorAll('source').forEach(source => {
            innerVideo.push(source.getAttribute('src'))
          })
          video.parentElement?.removeChild(video)
        })
        parser.querySelectorAll('img').forEach(img => {
          const src = img.getAttribute('src')
          if (src) innerImage.push(src)
          img.parentElement?.removeChild(img)
        })
        innerText = parser.innerHTML
      }

      return {
        innerText,
        innerImage,
        innerVideo,
        labelscore: labelscore,
      }
    },
    onCurrentPageChange() {
      this.currentQuestionIndex = this.currentPage - 1
    },

    downLoadClick(e) {
      const target = e?.target
      if (target?.nodeName === 'A') {
        if (target.hasAttribute('href')) {
          e.preventDefault()
          const href = target.getAttribute('href') || ''
          const fileName = target.innerText
          saveAs(href, fileName)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.question-title {
  // padding-right: 10px;
  margin-bottom: 10px;
}
.rate-box {
  padding: 16px 0;
}
.answer-score {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
.question-title,
.question-answer {
  .objective-answer {
    pre {
      font-size: 16px;
    }
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', 'WenQuanYi Micro Hei', sans-serif;
    // display: flex;
    // align-items: baseline;
    img {
      max-width: 100%;
    }
  }

  .green {
    color: #6ed401;
  }
  .red {
    color: #ff0000;
  }
  .yellow {
    color: #f4d75e;
  }
}

.question-type {
  margin-top: 8px;
  font-weight: bold;
}

.question-answer {
  margin-top: 5px;
  padding: 12px 16px;
  border-radius: 5px;
  // background: #f8f8fa;
  border: 1px dashed #dddddd;
  .answer-detail {
    // padding-left: 20px;
    display: flex;
    color: #c1cad9;
    align-items: center;
    margin-bottom: 10px;
    .question-tag {
      border-radius: 2px;
      background-color: #5facff;
      color: #ffffff;
      padding: 3px 12px;
    }
    .answer-label {
      color: #333333;
      // font-size: 14px;
    }
  }

  .right-answer-wrapper {
    .right-key {
      padding-left: 20px;
    }
  }
}
  .attach-list {
    margin-top: 16px;
    .attach-item {
      // width: 100%;
      padding: 0 12px;
      background: #f5f5f5;
      border-radius: 2px;
      font-size: 14px;
    }
    .attach-item:hover {
      background: #e8f3ff;
    }
    .attach-item + .attach-item {
      margin-top: 8px;
    }
    .preview{
      padding: 10px 0px;
      margin-left: 12px;
      color:#1890ff;
    }
    .flex {
      display: flex;
      align-items: center;
    }
    .flex1 {
      flex: 1;
    }
    .btw {
      justify-content: space-between;
    }
    .overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .mr5 {
      margin-right: 5px;
    }
  }

</style>
<style lang="scss">
.current-question-answer-dom {
  p {
    margin: 0 !important;
  }
}
</style>

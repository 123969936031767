export default {
  times: '次',
  riskTip: '風險提示',
  highRisk: '高風險',
  textSimilarity: '文字相似度', // Test Similarity (Accurate)
  exactMatch: '精准匹配',
  fuzzyMatch: '模糊匹配',
  abnormalPhotos: '异常照片',
  exitCount: '跳出次數',
  lognCount: '登入次數',
  actLognCount: '賬號登入次數：',
  canlogsys: '候選人登入系統',
  riskDesc: '答題過程中的【异常】狀態由以上情况綜合判定',
  answer: 'TA的回答',
  accurate: '精准',
  fuzzy: '模糊',
  view: '查看',
  abnormalDesc: 'AI識別到以下照片存在疑似异常狀態（异常狀態包括：視頻中人數不等於1（0或者大於1），或接聽電話的情况）',
  enterPhotoWall: '進入照片牆',
  photoWall: '照片牆',
  serialNumber: '序號',
  photos: '張',
  pageExitCount: '頁面跳出次數',
  pageExitTime: '頁面跳出時間',
  detailsLog: '詳細記錄',
  candidateExitPage: '候選人跳出答題頁面',
  candidateID: '候選人身份資訊',
  candidateIDPositive: '身份證正面照',
  candidateIDNegative: '身份證反面照',
  wholeProcessVideo: '全程錄製視頻',
  screenVideo: '屏幕錄製視頻',
  screenVideoTip: '此處為候選人AI面試的全程荧幕錄製視頻，若候選人參與AI面試過程中網絡不佳或多次退出重繪面試，此處可能出現多段視頻，請您知悉。',
  recordTime: '錄製時間',
  doubleVideo: '雙機比特監控視頻',
  noContent: '没有內容可以查看',
  close: '關閉',
  answerProgress: '作答進度',
  screenRecording: '荧幕監控',
  computerMonitoring: '電腦監控',
  phoneMonitoring: '手機監控',
  miniProgram: '小程式',
}

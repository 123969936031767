<template>
  <div>
    <div v-if="showReport" class="meritas-report">
      <!-- 报告说明 -->
      <div class="module-card">
        <div class="module-title">{{ $t('com.report.reportV3.text091') }}</div>
        <div class="module-content">{{ reportDescription }}</div>
        <van-divider />
      </div>
      <!-- 测评总览 -->
      <div class="module-card">
        <div class="module-title">{{ $t('com.report.reportV3.text092') }}</div>
        <div class="module-content">
          <div class="row">
            <div class="col">
              <span class="label fixed-width">{{ $t('com.report.reportV3.text093') }}：</span>
              <div class="content">
                <van-rate v-model="comprehensiveCompetenceScore" readonly allow-half color="#ffcd29" />
                <span class="score">{{ comprehensiveCompetenceScore * 2 }}{{ $t('com.report.reportV3.text102') }}/10{{ $t('com.report.reportV3.text102') }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label fixed-width">{{ $t('com.report.reportV3.text094') }}</span>
              <div class="content">
                <van-rate v-model="emotionalStabilityScore" readonly allow-half color="#ffcd29" />
                <span class="score">{{ emotionalStabilityScore * 2 }}{{ $t('com.report.reportV3.text102') }}/10{{ $t('com.report.reportV3.text102') }}</span>
              </div>
            </div>
          </div>
          <!-- 需求：#6993 暂时注释 -->
          <!-- <div class="row">
            <div class="col">
              <span class="label">{{ $t('com.report.reportV3.text095') }}：</span>
              <div class="content large bold">{{ evaluateEffectiveness }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">{{ $t('com.report.reportV3.text095') }}：</span>
              <div class="content">{{ evaluateEffectivenessDesc }}</div>
            </div>
          </div> -->
          <div class="row">
            <div class="col">
              <span class="label">{{ $t('com.report.reportV3.text096') }}：</span>
              <div class="content bold grow" @click="useTimeFold = !useTimeFold">
                <span>{{ useTime }}</span>
                <span :class="['triangle', { fold: useTimeFold }]"></span>
              </div>
            </div>
          </div>
          <div :class="['card', { fold: useTimeFold }]">
            <p>{{ $t('com.report.reportV3.text097') }}：{{ firstAnswerTime }}</p>
            <p>{{ $t('com.report.reportV3.text098') }}：{{ finishAnswerTime }}</p>
          </div>
        </div>
        <van-divider />
      </div>
      <!-- 综合评价 -->
      <div class="module-card">
        <div class="module-title">{{ $t('com.report.reportV3.text043') }}</div>
        <div class="module-content">
          <div class="top">
            <p v-for="(item, index) in competencyOnes" :key="index" class="detail">
              <span>{{ item.dimension }}：</span>
              <span class="score">{{ item.score }}{{ $t('com.report.reportV3.text102') }}</span>
              <span>{{ item.desc }}</span>
            </p>
          </div>
          <!-- 图表 -->
          <div ref="radar" class="bottom" />
        </div>
        <van-divider />
      </div>
      <!-- 胜任力报告维度 -->
      <div class="module-card">
        <div class="module-title left-icon">{{ $t('com.report.reportV3.text099') }}</div>
        <div class="table-wrapper">
          <div v-for="(item, index) in competencyTwos" :key="index" class="table-wrapper_item">
            <div class="dimension">{{ item.dimension }}：</div>
            <ProcessWrapper :key="index" :type="'ten'" :score="Number(item.score)" />
            <div class="character">
              <div class="character-item">{{ item.lowScoreDefinition }}</div>
              <div class="character-item" style="text-align: right">{{ item.highScoreDefinition }}</div>
            </div>
            <div class="comment">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <!-- 胜任力报告维度 -->
      <div class="module-card">
        <div class="module-title left-icon">{{ $t('com.report.reportV3.text100') }}</div>
        <div class="table-wrapper">
          <div v-for="(item, index) in emotionalStabilities" :key="index" class="table-wrapper_item">
            <div class="dimension">{{ item.dimension }}：</div>
            <ProcessWrapper :key="index" :type="'ten'" :score="Number(item.score)" />
            <div class="character">
              <div class="character-item">{{ item.lowScoreDefinition }}</div>
              <div class="character-item" style="text-align: right">{{ item.highScoreDefinition }}</div>
            </div>
            <div class="comment">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
import * as echarts from 'echarts'
import ProcessWrapper from '@/components/reportV3/common/process-wrapper.vue'
import Empty from '@/components/reportV3/common/empty.vue'
import { getMeritasReport } from '@/api/mark.js'

export default {
  components: { ProcessWrapper, Empty },
  data() {
    return {
      showReport: false, // 是否显示报告
      useTimeFold: true, // 用时折叠
      // 报告内容
      reportDescription: '', // 报告说明
      comprehensiveCompetenceScore: 0, // 候选人胜任力综合得分
      emotionalStabilityScore: 0, // 情绪稳定性得分
      evaluateEffectiveness: '', // 评估有效性
      evaluateEffectivenessDesc: '', // 评估有效性说明
      useTime: '', // 用时
      firstAnswerTime: '', // 第一次进入时间
      finishAnswerTime: '', // 完成面试时间
      competencyOnes: [], // 综合评价
      // dimension 维度
      // score 得分
      // desc 描述
      competencyTwos: [], // 胜任力报告维度
      emotionalStabilities: [], // 情绪稳定性报告维度
      // highScoreDefinition 高分描述
      // lowScoreDefinition 低分描述
      // behaviorComments
      // dimension 维度
      // score 得分
      // desc 描述
    }
  },
  computed: {
    interviewId() {
      return this.$store.state.interviewId
    },
    // 雷达图的指标和极值
    indicatorArray() {
      return this.competencyOnes.map(item => ({ name: item.dimension, max: 10 }))
    },
    // 雷达图的指标值
    valueArray() {
      return this.competencyOnes.map(item => Number(item.score) || 1)
    },
    // 雷达图的echarts配置
    radar() {
      return {
        indicator: this.indicatorArray,
        scale: true,
        axisTick: {
          //刻度设置
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#B1D8FF',
          },
        },
        axisName: {
          show: true,
          color: '#333',
          fontWeight: 'blod',
        },
        splitNumber: 5, //刻度
        splitLine: {
          //刻度连接线
          show: false,
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)'],
          },
        },
      }
    },
    // 雷达图的echarts配置
    series() {
      return [
        {
          type: 'radar',
          label: {
            show: false,
          },
          data: [
            {
              value: this.valueArray,
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(90, 230, 191, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(24, 144, 255, 1)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                color: 'rgba(90, 230, 191, 1)',
                opacity: 1,
              },
              itemStyle: {
                color: '#409EFF',
              },
            },
          ],
        },
      ]
    },
  },
  created() {
    this.getMeritasReport() // 获取报告
  },
  methods: {
    // 初始化图表
    async initChart() {
      if (!this.showReport) return
      await this.$nextTick()
      const dom = this.$refs.radar
      let myChart = echarts.getInstanceByDom(dom)
      if (!myChart) {
        // 判断是否已经被初始化
        myChart = echarts.init(dom)
      }
      const option = {
        radar: this.radar,
        series: this.series,
      }
      myChart.setOption(option)
    },
    // 获取meritas报告内容
    async getMeritasReport() {
      const res = await getMeritasReport({ interviewId: this.interviewId }).catch(err => err)
      const { code, msg, data } = res
      if (code === 0) {
        this.showReport = Boolean(data)
        Object.keys(data || {}).forEach(key => {
          let defaultValue = '' // 根据key定义不同的默认值
          let isNumber = false // 是否是数字
          if (['competencyOnes', 'competencyTwos', 'emotionalStabilities'].includes(key)) {
            defaultValue = []
            isNumber = false
          } else if (['comprehensiveCompetenceScore', 'emotionalStabilityScore'].includes(key)) {
            defaultValue = 0
            isNumber = true
          } else {
            defaultValue = ''
            isNumber = false
          }
          let value = (data || {})[key] || defaultValue
          // 如果是两个分数，需要将10分制改成5分制的表现形式（即除以2）
          this[key] = isNumber ? Number(value) / 2 : value
        })
        // 初始化图表
        this.initChart()
      } else {
        this.showReport = false
        this.$message.error(msg)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.meritas-report {
  margin-top: 20px;
  padding: 0 4px;
  .module-card {
    margin-bottom: 36px;
    .module-title {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      margin-bottom: 16px;
      &.left-icon {
        position: relative;
        padding-left: 9px;
        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 18px;
          background: #1890ff;
          border-radius: 2px;
          left: 0;
          top: 4px;
        }
      }
    }
    .module-content {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      &.flex {
        display: flex;
      }
      .row {
        margin-bottom: 15px;
        display: flex;
        .col {
          flex-grow: 1;
          display: flex;
          width: 50%;
          white-space: nowrap;
          .label {
            font-size: 14px;
            font-weight: 400;
            color: #6d7278;
            line-height: 20px;
            flex-shrink: 0;
            &.fixed-width {
              width: 160px;
            }
          }
          .content {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            &.grow {
              flex-grow: 1;
            }
            &.large {
              font-size: 22px;
            }
            &.bold {
              font-weight: 500;
            }
            .score {
              font-weight: 600;
              margin-left: 15px;
            }
            .triangle {
              float: right;
              border-top: 8px solid #1991ff;
              border-right: 5px solid transparent;
              border-left: 5px solid transparent;
              margin-top: 6px;
              transition: transform 0.2s;
              &.fold {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
      .card {
        height: 80px;
        background: #f9f7f9;
        border-radius: 6px;
        padding: 16px 12px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(51, 51, 51, 0.85);
        line-height: 20px;
        margin-top: -5px;
        transition: all 0.2s;
        overflow: hidden;
        &.fold {
          height: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
        p {
          margin: 0;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
      .top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 22px;
        .detail {
          position: relative;
          font-size: 15px;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.85);
          line-height: 20px;
          padding-left: 14px;
          margin-bottom: 9px;
          &::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            left: 0;
            top: 6px;
            background: #1991ff;
          }
          .score {
            font-size: 15px;
            font-weight: 600;
            color: #333333;
            margin-right: 13px;
          }
        }
      }
      .bottom {
        height: 250px;
      }
    }
  }
}
.table-wrapper {
  .table-wrapper_item {
    border-bottom: 1px solid #dddddd;
    .dimension {
      font-size: 14px;
      font-weight: 600;
      color: rgba(51, 51, 51, 0.85);
      line-height: 20px;
    }
    .character {
      display: flex;
      justify-content: space-between;
      .character-item {
        max-width: 40%;
        font-size: 12px;
        font-weight: 400;
        color: #afb6ca;
        line-height: 17px;
        margin-top: 10px;
      }
    }
    .comment {
      font-size: 14px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.85);
      line-height: 20px;
      margin-top: 20px;
      padding-bottom: 29px;
    }
  }
  .table-wrapper_item + .table-wrapper_item {
    margin-top: 30px;
  }
}
</style>

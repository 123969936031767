<template>
  <div class="mental-health">
    <div class="psychology-wrapper" v-if="aiData.mentalScore">
      <div class="wrapper-text">{{ $t('com.report.mental.text001') }}</div>
      <div class="wrapper-content">{{ $t('com.report.mental.text002') }}</div>

      <div class="psychology-line1">
        <span class="pre-text">{{ $t('com.report.mental.text003', { name: interviewResult.name }) }}</span
        ><van-rate :value="aiData.mentalScore.score" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly></van-rate>
        <span class="mental-score-status">
          {{ $t('com.report.mental.text004', { status: aiData.mentalScore.score }) }} <span class="score-status">{{ translateMentalScore(aiData.mentalScore.score) }}</span>
        </span>
      </div>
      <div class="psychology-line2">
        <span class="pre-text">{{ $t('com.report.mental.text005') }} </span>：{{ aiData.mentalScore.comment }}
      </div>
      <div class="psychology-lable-wrapper">
        <span class="lable-wrapper-title positive spanTitle">{{ $t('com.report.mental.text006') }}{{ $t('com.report.mental.text008', [aiData.mentalPositiveScore.score]) }}</span>
        <div class="score-bar-wrapper" v-for="(positiveItem, index) in positiveScoreArray" :key="index">
          <div class="score-bar">
            <div class="score-bar-text">{{ positiveItem.dimName }}:</div>
            <div class="score-bar-content">
              <div class="bar-item" v-for="n in 10" :key="n" :class="computedPsychologyClass(n, positiveItem.score)">
                {{ n === positiveItem.score ? positiveItem.score : '' }}
              </div>
            </div>
            <div class="bar-tips">
              <div class="bar-tips-text">{{ getLowText(positiveItem.dimName) }}</div>
              <div class="bar-tips-text" style="text-align: right">{{ getHighText(positiveItem.dimName) }}</div>
            </div>
            <div class="bar-introduction">
              {{ positiveItem.comment }}
            </div>
          </div>
        </div>
      </div>
      <div class="psychology-lable-wrapper">
        <span class="lable-wrapper-title nagative spanTitle">{{ $t('com.report.mental.text007') }}{{ $t('com.report.mental.text008', [aiData.mentalNegtiveScore.score]) }}</span>
        <div class="score-bar-wrapper" v-for="(negtiveItem, index) in negtiveScoreArray" :key="index">
          <div class="score-bar">
            <div class="score-bar-text">{{ negtiveItem.dimName }}:</div>
            <div class="score-bar-content">
              <div class="bar-item" v-for="n in 10" :key="n" :class="computedPsychologyClass(n, negtiveItem.score)">
                {{ n === negtiveItem.score ? negtiveItem.score : '' }}
              </div>
            </div>
            <div class="bar-tips">
              <div class="bar-tips-text">{{ getLowText(negtiveItem.dimName) }}</div>
              <div class="bar-tips-text" style="text-align: right">{{ getHighText(negtiveItem.dimName) }}</div>
            </div>
            <div class="bar-introduction">
              {{ negtiveItem.comment }}
            </div>
          </div>
        </div>
      </div>
      <CopyrightNotice />
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
// import { Vue, Component } from 'vue-property-decorator'
// import hologram from '@/store/modules/hologram'
import CopyrightNotice from '@/components/reportV3/common/copyright-notice.vue'
// import PercentWrapper from '@/components/reportV3/common/percent-wrapper.vue'
import Empty from '@/components/reportV3/common/empty.vue'
import i18n from '../../utils/i18n'

// @Component({  })
export default {
  components: { CopyrightNotice, Empty },
  data() {
    return {}
  },
  computed: {
    aiData() {
      return this.$store.state.aiData || {}
    },
    interviewResult() {
      return this.$store.state.interviewResult
    },
    // interviewResult() {
    // 	return hologram.interviewResult
    // },
    // aiData() {
    // 	return hologram.aiData
    // },
    positiveScoreArray() {
      //let str = i18n.t('com.report.mental.text006')
      let arr = this.aiData.mentalScores.filter(item => {
        return item.dimType === i18n.t('com.report.mental.text006')
      })
      console.log('=======================>')
      console.log(arr)
      return arr
    },

    negtiveScoreArray() {
      //let str = i18n.t('com.report.mental.text007')
      return this.aiData.mentalScores.filter(item => {
        return item.dimType === i18n.t('com.report.mental.text007')
      })
    },
  },

  methods: {
    computedPsychologyClass(n, score) {
      if (score === n) {
        if (n > 0 && n <= 2) {
          return 'color1-active'
        } else if (n > 2 && n <= 4) {
          return 'color2-active'
        } else if (n > 4 && n <= 8) {
          return 'color3-active'
        } else if (n > 8 && n <= 10) {
          return 'color4-active'
        }
      } else {
        if (n > 0 && n <= 2) {
          return 'color1'
        } else if (n > 2 && n <= 4) {
          return 'color2'
        } else if (n > 4 && n <= 8) {
          return 'color3'
        } else if (n > 8 && n <= 10) {
          return 'color4'
        }
      }
      return {}
    },

    translateMentalScore(score) {
      switch (score) {
        case 5:
          return i18n.t('com.report.mental.text028')
        case 4:
          return i18n.t('com.report.mental.text029')
        case 3:
          return i18n.t('com.report.mental.text030')
        case 2:
          return i18n.t('com.report.mental.text031')
        case 1:
          return i18n.t('com.report.mental.text032')
      }
    },
    getHighText(str) {
      let highText = ''
      switch (str) {
        case i18n.t('com.report.mental.text033'):
          highText = i18n.t('com.report.mental.text010') // '在人际交往过程中自卑感较强，心神不安，表现出明显的不自在，并排斥各类社交活动',
          break
        case i18n.t('com.report.mental.text034'):
          highText = i18n.t('com.report.mental.text012')
          break
        case i18n.t('com.report.mental.text035'):
          highText = i18n.t('com.report.mental.text014')
          break
        case i18n.t('com.report.mental.text036'):
          highText = i18n.t('com.report.mental.text016')
          break
        case i18n.t('com.report.mental.text037'):
          highText = i18n.t('com.report.mental.text018')
          break
        case i18n.t('com.report.mental.text038'):
          highText = i18n.t('com.report.mental.text020')
          break
        case i18n.t('com.report.mental.text039'):
          highText = i18n.t('com.report.mental.text022')
          break
        case i18n.t('com.report.mental.text040'):
          highText = i18n.t('com.report.mental.text024')
          break
        case i18n.t('com.report.mental.text041'):
          highText = i18n.t('com.report.mental.text026')
          break
        default:
          break
      }
      return highText
    },
    getLowText(str) {
      let lowText = ''
      switch (str) {
        case i18n.t('com.report.mental.text033'):
          lowText = i18n.t('com.report.mental.text011')
          break
        case i18n.t('com.report.mental.text034'):
          lowText = i18n.t('com.report.mental.text013')
          break
        case i18n.t('com.report.mental.text035'):
          lowText = i18n.t('com.report.mental.text015')
          break
        case i18n.t('com.report.mental.text036'):
          lowText = i18n.t('com.report.mental.text017')
          break
        case i18n.t('com.report.mental.text037'):
          lowText = i18n.t('com.report.mental.text019')
          break
        case i18n.t('com.report.mental.text038'):
          lowText = i18n.t('com.report.mental.text021')
          break
        case i18n.t('com.report.mental.text039'):
          lowText = i18n.t('com.report.mental.text023')
          break
        case i18n.t('com.report.mental.text040'):
          lowText = i18n.t('com.report.mental.text025')
          break
        case i18n.t('com.report.mental.text041'):
          lowText = i18n.t('com.report.mental.text027')
          break
        default:
          break
      }
      return lowText
    },
  },
}
</script>
<style lang="scss" scoped>
.psychology-wrapper {
  margin-top: 20px;
  .wrapper-text {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .wrapper-content {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }

  .psychology-line1 {
    margin-top: 16px;
    // display: flex;
    .pre-text {
      font-weight: 600;
      color: #111f2c;
    }
    .mental-score-status {
      margin-left: 10px;
      .score-status {
        color: #1890ff;
      }
    }
  }
  .psychology-line2 {
    margin-top: 15px;
    .pre-text {
      font-weight: 600;
      color: #111f2c;
    }
  }

  .psychology-lable-wrapper {
    margin-top: 30px;

    .show-detail-score {
      text-align: right;
      color: #1890ff;
      cursor: pointer;
    }
    .lable-wrapper-title {
      width: 118px;
      height: 20px;
      line-height: 20px;
      border-radius: 10px;
      text-align: center;
      color: #ffffff;
    }
    .spanTitle {
      padding: 2px 20px;
    }
    .positive {
      background-color: #56aeff;
    }
    .nagative {
      background-color: #f36d55;
    }
    .lable-row {
      margin-top: 10px;
      .lable-title {
        font-weight: 600;
      }
      .green {
        color: #00ad47;
      }
    }
    .score-bar-wrapper {
      margin-top: 10px;
      .score-bar {
        .score-bar-text {
          font-weight: 600;
          color: #333333;
          margin-bottom: 6px;
        }
        .score-bar-content {
          width: 100%;
          display: flex;
          .bar-item {
            flex: 1;
            margin-left: 4px;
            width: 69px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            border-radius: 2px;
            cursor: pointer;
            color: #ffffff;
            font-weight: 600;
          }
          .color1 {
            background: #ffeae3;
          }
          .color2 {
            background: #fcf2df;
          }
          .color3 {
            background: #bde7fe;
          }
          .color4 {
            background: #c9f4f4;
          }

          .color1-active {
            background: #f36d55;
            box-shadow: 0px 2px 10px 0px #ff9683;
          }

          .color2-active {
            background: #ffaa5b;
            box-shadow: 0px 2px 10px 0px rgba(238, 116, 72, 0.5);
          }

          .color3-active {
            background: #56aeff;
            box-shadow: 0px 2px 10px 0px rgba(24, 144, 255, 0.5);
          }

          .color4-active {
            background: #44d7b6;
            box-shadow: 0px 2px 10px 0px rgba(0, 217, 199, 0.5);
          }

          .bar-item:first-child {
            margin-left: 0px;
          }
        }
        .bar-tips {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          .bar-tips-text {
            max-width: 40%;
            // height: 34px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #afb6ca;
            line-height: 17px;
          }
        }
        .bar-introduction {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"interview"},[_c('Header'),_vm._m(0),_c('section',{staticClass:"content"},[_c('h3',[_vm._v("AI视频面试")]),_vm._m(1),_c('div',{staticClass:"box"},[_c('ul',{staticClass:"level-wrap"},[_c('li',{staticClass:"normal-item"},[_c('img',{staticClass:"tag",attrs:{"src":require('@/assets/images/tag.png')}}),_vm._m(2),_vm._m(3)]),_c('li',{staticClass:"dotted-line"}),_c('li',{staticClass:"normal-item"},[_c('img',{staticClass:"tag",attrs:{"src":require('@/assets/images/tag.png')}}),_vm._m(4),_vm._m(5)]),_c('li',{staticClass:"dotted-line"}),_c('li',{staticClass:"normal-item"},[_c('img',{staticClass:"tag",attrs:{"src":require('@/assets/images/tag.png')}}),_vm._m(6),_vm._m(7)]),_c('li',{staticClass:"normal-item"},[_c('img',{staticClass:"tag",attrs:{"src":require('@/assets/images/tag.png')}}),_vm._m(8),_vm._m(9)]),_c('li',{staticClass:"dotted-line"}),_c('li',{staticClass:"normal-item"},[_c('img',{staticClass:"tag",attrs:{"src":require('@/assets/images/tag.png')}}),_vm._m(10),_vm._m(11)]),_c('li',{staticClass:"dotted-line"}),_c('li',{staticClass:"special-item"},[_c('img',{staticClass:"tag",attrs:{"src":require('@/assets/images/tag.png')}}),_vm._m(12),_vm._m(13),_vm._m(14)]),_c('li',{staticClass:"dotted-line"})])])]),_c('tools')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section1"},[_c('div',{staticClass:"barnner"},[_c('img',{staticClass:"banner-img",attrs:{"src":require("./images/banner.png")}}),_c('div',{staticClass:"text-wrapper"},[_c('span',[_vm._v("AI视频面试")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 在自动驾驶领域，基于驾驶自动化系统在执行动态驾驶任务中的角色分配，以及有无设计运行条件限制，自动驾驶汽车被划分为Level 0-Level 5共6个不同的等级；同样，在AI面试领域，根据AI应用的深度和价值，AI面试同样可以被划分为Level 0-Level 5共6个不同的等级。"),_c('br'),_c('br'),_vm._v(" AI面试领域的智能等级划分，"),_c('i',[_vm._v("AI得贤招聘官是一款L5级别自动面试系统")]),_vm._v("。她可以直接告诉企业来应聘的候选人是否应该被录用。因为她可以模拟人类面试官进行最终决策，所以特别适合校园招聘和面试量较大的社会招聘，革命性的提升招聘的效率和精准度。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-tip"},[_c('span',[_vm._v("Lv.0")]),_c('span',[_vm._v("视频转录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 面试视频录播 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-tip"},[_c('span',[_vm._v("Lv.1")]),_c('span',[_vm._v("基础面试辅助")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 语音转写文本 ")])]),_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 面试回答文本关键词提取 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-tip"},[_c('span',[_vm._v("Lv.2")]),_c('span',[_vm._v("深度面试辅助")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 以下功能中的一种或几种："),_c('br'),_vm._v(" AI表情分析"),_c('br'),_vm._v(" AI职业形象分析"),_c('br'),_vm._v(" AI声音分析 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-tip"},[_c('span',[_vm._v("Lv.3")]),_c('span',[_vm._v("高度面试辅助")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" AI文本内容分析，基于篇章级别语义识别算法预测候选人的胜任力，深度辅助人类面试决策。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-tip"},[_c('span',[_vm._v("Lv.4")]),_c('span',[_vm._v("有条件自动面试")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 基于预训练的多模态算法，结合候选人的回答质量、表情、声音、职业形象等多维度，对候选人进行综合性评估，代替人类面试决策。 ")])]),_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 适用于校园招聘和面试量较大的职位族招聘。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-tip"},[_c('span',[_vm._v("Lv.5")]),_c('span',[_vm._v("完全自动面试")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stage"},[_c('img',{attrs:{"src":require("./images/stars.png")}}),_c('i',[_vm._v("AI得贤招聘官所在阶段")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-wrap"},[_c('div',[_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 多轮对话 ")])]),_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 追问技术 ")])]),_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 无需预训练 ")])])]),_c('div',[_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 自适应面试题生成技术 ")])])]),_c('div',[_c('div',{staticClass:"item"},[_c('div',[_c('span')]),_c('div',[_vm._v(" 可自由应答候选人的提问 ")])])])])
}]

export { render, staticRenderFns }
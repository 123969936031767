<template>
  <div class="report-mobile">
    <reportReport ref="report"></reportReport>
    <ReportContent v-if="false" />
  </div>
</template>
<script>
import ReportContent from '@/components/ReportContent'
import reportReport from '../components/reportReport'
export default {
  props: {},
  data() {
    return {}
  },
  components: {
    reportReport,
    ReportContent,
  },
}
</script>

/* eslint-disable quotes */
export default {
  times: 'times',
  riskTip: 'Risk warning',
  highRisk: 'High risk',
  textSimilarity: 'Text Similarity',
  exactMatch: 'Exact match',
  fuzzyMatch: 'Fuzzy match',
  abnormalPhotos: 'Abnormal photos',
  exitCount: 'Exit count',
  lognCount: 'Login attempts',
  actLognCount: 'Account login attempts: ',
  canlogsys: 'Candidate login system',
  riskDesc: 'The 【abnormal】 state during the answering process is judged comprehensively by the above conditions',
  answer: 'Answer',
  accurate: 'Accurate',
  fuzzy: 'Fuzzy',
  view: 'View',
  abnormalDesc: 'AI recognizes the following photos as having suspicious abnormal status',
  enterPhotoWall: 'Enter Photo Wall',
  photoWall: 'Photo Wall',
  serialNumber: 'No.',
  photo: 'photos',
  pageExitCount: 'Times of page popup',
  pageExitTime: 'Time of page popup',
  detailsLog: 'Details log',
  candidateExitPage: 'Question page pops up for candidate',
  candidateID: 'ID information of candidate',
  candidateIDPositive: 'Front photo of ID card',
  candidateIDNegative: 'Back photo of ID card',
  wholeProcessVideo: 'Record video during the whole process',
  screenVideo: 'Screen records video',
  screenVideoTip:
    "Here is screen recording video during the whole process for candidate's AI interview, in case connection is poor or candidate exists and refreshes interview for many times during AI interview, multiple videos may be recorded.",
  recordTime: 'Recording time',
  doubleVideo: 'Dual camera surveillance video',
  noContent: 'No content can be viewed',
  close: 'Close',
  answerProgress: 'Answer progress',
  screenRecording: 'Screen recording',
  computerMonitoring: 'Computer monitoring',
  phoneMonitoring: 'Mobile phone monitoring',
  miniProgram: 'Mini Program',
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import cn from '@/locale/cn/index.js'
import en from '@/locale/en/index.js'
import zhTW from '@/locale/zh-tw/index.js'

import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import vanZhTW from 'vant/lib/locale/lang/zh-TW'

Vue.use(VueI18n)

const getLanguage = () => {
  return sessionStorage.getItem('lang') || 'cn'
}

export default new VueI18n({
  locale: getLanguage(),
  fallbackLocale: 'cn',

  messages: {
    cn: { ...cn, ...zhCN },
    en: { ...en, ...enUS },
    'zh-TW': { ...zhTW, ...vanZhTW },
  },
})

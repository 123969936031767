export default {
  text001: '請添加備註',
  text002: '添加',
  text003: '刪除',
  text004: '備註',
  text005: '溝通記錄',
  text006: '通過',
  text007: '拒絕',
  text008: '待定',
  text009: '該功能需要登錄操作',
  text010: '添加備註成功',
  text011: '請輸入備註',
}

import service from '@/config/http'
import qs from 'qs'

// //用户选择职位列表
export const getPositionDetail = data => {
  return service({
    url: '/enterprise/position/position/getSelectList',
    method: 'get',
    params: data,
  })
}

// 获取分享职位
export const createwxaqrcode = data => {
  const req = {
    url: '/enterprise/position/position/createwxaqrcode',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
/**
 * enterprise/miniapp/companyEnroll/save
 * "companyName":"公司名称",
 * "mobile":"手机号码",
 * "email":"邮箱",
 * "name":"个人姓名",
 * "position":"职位"
 */
export const saveInfo = data => {
  return service({
    url: '/enterprise/miniapp/companyEnroll/save',
    method: 'post',
    data: data,
  })
}
export const getCompanyInfo = data => {
  return service({
    url: '/enterprise/company/company/getCompanyInfo',
    params: data,
  })
}

/**
 * 获取海云天公司Id
 */
export const getHytCompanyId = data => {
  return service({
    url: '/enterprise/company/companyConfig/detail',
    data,
    method: 'get',
  })
}
/**
 * 获取手机监控二维码
 */
export const getMonitorQrcode = data => {
  return service({
    url: '/enterprise/position/position/createwxcheckaqrcode',
    data: qs.stringify(data),
    method: 'post',
  })
}

<template>
  <van-row type="flex" class="cell" justify="space-between" align="start">
    <van-col>
      <div class="cell-title">{{ title }}</div>
      <div class="cell-content font-12 font-light">
        <slot name="desc" v-if="$slots.desc"></slot>
        <template v-if="desc"> {{ desc }}</template>
      </div>
    </van-col>
    <van-col v-if="showScore" style="flex-shrink: 0;font-size: 10px">
      <template v-if="!(score === null || score === -1)">
        <span class="cell-score">{{ score }}</span> / {{ totle }} 分</template
      >
      <template v-if="score === null || score === -1">
        <span>分析中 </span>
      </template>
    </van-col>
  </van-row>
</template>
<script>
export default {
  props: {
    title: String,
    desc: String,
    totle: [String, Number],
    score: [String, Number],
    showScore: {
      type: Boolean,
      default: true,
    },
  },
  updated() {},
}
</script>
<style lang="scss" scoped>
.cell {
  padding-right: 20px;
  .font-12 {
    font-size: 12px;
  }
  .font-light {
    font-weight: 300;
  }
  border-bottom: 1px solid #ededed;
  color: #333333;
  margin-top: 16px;
  .cell-title {
    font-size: 12px;
    display: flex;
    align-items: center;
    div:first-child {
      margin-right: 12px;
      width: 50px;
    }
    div:last-child {
      flex: 1;
    }
  }
  .cell-content {
    color: #666666;
    font-size: 10px;
    margin: 10px 0;
  }
  .cell-score {
    color: #1890ff;
    font-size: 14px;
  }
  .van-progress {
    border-radius: 0px 7px 7px 0px;
  }
  .progress-blue {
    /deep/ .van-progress__portion {
      background: linear-gradient(90deg, rgba(53, 195, 255, 1) 0%, rgba(24, 144, 255, 1) 100%);
    }
  }
  .progress-red {
    /deep/ .van-progress__portion {
      background: linear-gradient(90deg, rgba(255, 161, 147, 1) 0%, rgba(255, 92, 68, 1) 100%);
    }
  }
  .progress-green {
    /deep/ .van-progress__portion {
      background: linear-gradient(90deg, rgba(180, 240, 150, 1) 0%, rgba(113, 199, 77, 1) 100%);
    }
  }
}
</style>

<template>
  <div class="edition-overview">
    <div v-for="(edition, index) in editions" :key="index" class="overview-item" @click="toDetail">
      <div class="overview-item_content">
        <div class="title">{{ edition.eTitle }}</div>
        <div class="price">
          ￥<span class="eprice">{{ edition.ePrice }}</span
          >{{ edition.eUnit }}
        </div>
        <div class="price-tip">{{ edition.ePriceTip }}</div>
        <div v-if="edition.ePriceTip1" class="price-tip mb12">{{ edition.ePriceTip1 }}</div>
        <div class="to-detail">查看详情<img src="@/assets/price/icon_arrow-blue.svg" /></div>
      </div>
      <div class="content">
        <div v-for="(item, i) in edition.eContents" :key="i" class="item">
          <img :src="item.vIcon" />
          <div>{{ item.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      editions: [
        {
          eTitle: '标准版本',
          type: 1,
          eTip: '适用于中小型企业和大型企业的事业部或业务单元，最多5个HR账号和50个面试官账号畅用。',
          ePrice: 0,
          eUnit: '/HR账号/年',
          ePriceTip: '免费使用',
          btnText: '立即开通',
          eExplain: '1. HR账号数量中包含超级管理员和管理账号，面试官账号数量中包含只读账号数量。2. 系统内的账号数据、职位发布数量以及邮箱账号数量都是累积量，不存在替换，按年计算的每年清零。',
          eOthers: '',
          eContents: this.getContents(1).filter(item => {
            return item.show
          }),
          background: 'linear-gradient(225deg, #1990FF 0%, #36C3FF 100%)',
        },
        {
          eTitle: '高级版本',
          type: 2,
          eTip: '适用于大中型企业，提供无限量面试官账号，移动端招聘等一站式解决方案。',
          ePrice: 688,
          eUnit: '/HR账号/月',
          ePriceTip: '每个HR账号仅需￥22.93/天',
          ePriceTip1: '￥7888/HR账号/年',
          btnText: '免费试用',
          eExplain: '1. HR账号数量中包含超级管理员和管理账号，面试官账号数量中包含只读账号数量。2. 系统内的账号数据、职位发布数量以及邮箱账号数量都是累积量，不存在替换，按年计算的每年清零。',
          eOthers: '',
          eContents: this.getContents(2).filter(item => {
            return item.show
          }),
          background: 'linear-gradient(225deg, #FE9F01 0%, #FFCD02 100%)',
        },
        {
          eTitle: '商业版本',
          type: 3,
          eTip: '适用于大中型企业，超稳定性的远程视频会议和猎头RPO管理系统等服务，并全面与生态合作伙伴融合打通。',
          ePrice: 888,
          eUnit: '/HR账号/月',
          ePriceTip: '每个HR账号仅需￥29.6/天',
          ePriceTip1: '￥9888/HR账号/年',
          btnText: '免费试用',
          eExplain: '1. HR账号数量中包含超级管理员和管理账号，面试官账号数量中包含只读账号数量。2. 系统内的账号数据、职位发布数量以及邮箱账号数量都是累积量，不存在替换，按年计算的每年清零。',
          eOthers: '',
          eContents: this.getContents(3).filter(item => {
            return item.show
          }),
          background: 'linear-gradient(225deg, #002343 0%, rgba(0, 35, 67, 0.65) 100%)',
        },
      ],
    }
  },
  methods: {
    toDetail() {
      this.$router.push({
        path: '/price',
      })
    },
    getContents(type) {
      return [
        {
          value: type === 1 ? '3个HR账号' : type === 2 ? '按季度/年购买，5个起售' : '按季度/年购买，10个起售',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          value: type === 1 ? '30个面试官账号' : type === 2 ? '1000个面试官账号' : '无限面试官账号',
          vIcon: type === 3 ? require('@/assets/price/icon_infinite.svg') : require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: '',
          value: type === 1 ? '5个接收简历邮箱' : type === 2 ? '100个接收简历邮箱' : '无限接收简历邮箱',
          vIcon: type === 3 ? require('@/assets/price/icon_infinite.svg') : require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: '',
          value: type === 1 ? '500M简历容量' : type === 2 ? '30G简历容量' : '无限简历容量',
          vIcon: type === 3 ? require('@/assets/price/icon_infinite.svg') : require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          value: type === 1 ? '10个RPA发布渠道' : type === 2 ? '无限RPA发布渠道' : '无限RPA发布渠道',
          vIcon: type !== 1 ? require('@/assets/price/icon_infinite.svg') : require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          value: type === 1 ? '50个职位' : type === 2 ? '无限职位' : '无限职位',
          vIcon: type !== 1 ? require('@/assets/price/icon_infinite.svg') : require('@/assets/price/icon_sel.svg'),
          show: true,
        },
      ]
    },
  },
}
</script>
<style lang="scss" scoped>
.edition-overview {
  // display: flex;
  border-radius: 4px;
  .overview-item {
    display: flex;
    color: #333333;
    background: #ffffff;
    // box-shadow: inset 0px 4px 0px 0px #1990ff;
    // padding-top: 20px;
    padding: 20px 10px;
    -webkit-transition: all ease 300ms;
    -o-transition: all ease 300ms;
    transition: all ease 300ms;
    .overview-item_content {
      // padding: 0 20px 20px 20px;
      width: 105px;
      margin-right: 10px;
      .title {
        font-size: 16px;
        font-weight: 600;

        line-height: 20px;
        margin-bottom: 8px;
      }
      .price {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 8px;
        .eprice {
          font-weight: 600;
          line-height: 20px;
          font-size: 16px;
        }
      }
      .price-tip {
        font-size: 8px;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
      }
      .price-tip + .price-tip {
        margin-top: 4px;
      }
      .mb12 {
        margin-bottom: 12px;
      }
      .to-detail {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        color: #1990ff;
        line-height: 16px;
        img {
          margin-left: 5px;
        }
      }
    }
  }
  .content {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .item {
      display: flex;
      font-size: 10px;
      font-weight: 400;
      color: #555555;
      // line-height: 16px;
      width: 50%;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }
    .item + .item {
      // margin-top: 10px;
    }
  }
  .overview-item:first-child {
    // border-radius: 4px 0 0 4px;
  }
  .overview-item:last-child {
    // border-radius: 0px 4px 4px 0px;
  }
  .overview-item + .overview-item {
    border-top: 1px solid #dddddd;
    .overview-item_content {
      // border-top: 1px solid #dddddd;
    }
  }
  // .overview-item:hover {
  //   transform: scale(1.1, 1.1);
  //   border-radius: 4px;

  //   .overview-item_content {
  //     border: 0px;
  //     box-shadow: 0px 12px 24px 0px rgba(0, 35, 67, 0.1);
  //   }
  //   .to-detail {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     background: #1990ff;
  //     border-radius: 0px 0px 4px 4px;
  //     cursor: pointer;
  //     font-size: 14px;
  //     font-weight: 600;
  //     color: #ffffff;
  //     line-height: 20px;
  //     height: 40px;
  //   }
  // }
}
</style>

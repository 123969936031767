<template>
  <div class="feedback-wrapper">
    <div class="feedback-head">
      <div>
        <template v-if="data.templateType === 1">
          <span>{{ $t('com.report.interviewPlan.text048') }}</span>
          <span style="color: #0c8cf6;font-size: 20px;">{{ assessmentScore }}</span>
          <span>/{{ $t('com.report.interviewPlan.text049', [totalScore]) }}</span>
        </template>
      </div>
      <div v-if="showEdit()" class="edit-wrapper">
        <van-icon name="edit" @click="toEdit" />
      </div>
    </div>
    <template v-for="(model, index) in data.modules || []">
      <section class="module" :key="index">
        <div class="module-header">
          <div class="module-widget"></div>
          <div class="module-title">
            <span>{{ model.moduleName }}</span>
            <template v-if="data.templateType === 1">
              <span> （{{ modelWeigh[model.id] }}%） </span>
            </template>
          </div>
          <div class="module-score" v-if="data.templateType === 1">
            <span>{{ $t('com.report.interviewPlan.text050') }}：{{ $t('com.report.interviewPlan.text049', [modelScore[model.id] || 0]) }}</span>
          </div>
        </div>
        <pre class="module-description">{{ model.moduleDescribe }}</pre>

        <div class="module-body" v-for="question in model.questions" :key="question.id">
          <div class="module-question" :class="question.questionType === 1 ? 'flex-module' : ''">
            <div class="question-title">
              <span>{{ question.questionName }}</span>
              <span v-if="data.templateType === 1 && question.questionType === 0"> （{{ question.weigh }}%）</span>
            </div>
            <pre class="question-description" v-if="question.description && question.questionType !== 1">
               {{ question.questionDescribe }}
            </pre>
            <div class="question-description-answer">
              <template v-if="question.questionType === 0">
                <pre>{{ question.questionAnswer }}</pre>
              </template>
              <template v-else-if="question.questionType === 2 || question.questionType === 1">
                <pre :class="question.questionType === 1 ? 'red-answer' : ''">{{ question.questionAnswer }}</pre>
              </template>

              <template v-else-if="question.questionType === 3">
                <pre class="text-answer">{{ question.questionAnswer }}</pre>
              </template>
            </div>
          </div>
          <pre class="question-description" v-if="question.questionDescribe && question.questionType === 1">
               {{ question.questionDescribe }}
          </pre>
        </div>
      </section>
    </template>
    <section class="module">
      <div class="module-header">
        <div class="module-widget"></div>
        <div class="module-title">{{ $t('com.report.interviewPlan.text051') }}</div>
      </div>
      <div class="module-question interview-result">
        <div class="question-title">
          <span>{{ $t('com.report.interviewPlan.text052') }}</span>
        </div>
        <div class="result">
          <span v-if="feedbackForm.interviewResult === 1">{{ $t('com.report.interviewPlan.text045') }}</span>
          <span v-if="feedbackForm.interviewResult === 0">{{ $t('com.report.interviewPlan.text044') }}</span>
          <span v-if="feedbackForm.interviewResult === 2">{{ $t('com.report.interviewPlan.text046') }}</span>
        </div>
      </div>

      <div class="module-question" v-if="!isZj">
        <div class="question-title">
          <span>{{ $t('com.report.interviewPlan.text053') }}</span>
        </div>
        <pre class="question-description">{{ $t('com.report.interviewPlan.text054') }}</pre>
        <div>
          <pre class="text-answer">{{ feedbackForm.feedbackContent }}</pre>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getAssessmentByRoundId, getAssessmentRecord } from '@/api/candidate'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return null
      },
    },
    feedback: {
      type: Object,
      default() {
        return null
      },
    },
    curTemplateInfo: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      feedbackForm: {
        interviewResult: 0,
        feedbackContent: '',
      },
      modelWeigh: {},
      modelScore: {},
      totalScore: 0,
      assessmentScore: 0,
    }
  },
  created() {
    this.init()
  },
  computed: {
    isZj() {
      const companyId = this.$store.state.companyId
      return this.$store.state.userInfo?.customerType === 'CUSTOMERTYPE_ZHONGJUN' || this.$store.state.zjCompanyId.includes(companyId)
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    modules() {
      return this.data.modules || []
    },
    roundId() {
      return (this.info || {}).interviewRoundId
    },
    ids() {
      const roundId = (this.info || {}).interviewRoundId
      const feedbackId = (this.feedback || {}).id
      return [roundId, feedbackId]
    },
  },
  methods: {
    init() {
      if (this.feedback) {
        this.feedbackForm = {
          id: this.feedback.id,
          interviewResult: this.feedback.interviewResult,
          feedbackContent: this.feedback.feedbackContent,
        }
        this.getAssessmentRecord()
      }
    },
    getAssessmentScore() {
      let total = 0
      for (const key in this.modelScore) {
        total += parseFloat(this.modelScore[key])
      }
      return total.toFixed(2)
    },
    toScore(model) {
      const questions = model.questions || []
      let total = 0
      questions.forEach(n => {
        if (n.weigh > 0) {
          total += n.questionAnswer * (n.weigh / 100)
        }
      })
      this.$set(this.modelScore, model.id, total.toFixed(2))
    },
    toEdit() {
      this.$emit('edit')
    },
    showEdit() {
      return this.feedback?.lastUpdateBy === this.userInfo?.userId
    },
    getAssessmentByRoundId() {
      if (!this.roundId) {
        return
      }
      this.loading = true
      getAssessmentByRoundId({ id: this.roundId, publishId: this.info.positionId, interviewAssessmentFormId: this.curTemplateInfo?.id })
        .then(res => {
          if (res.code === 0) {
            const data = res.data || {}
            let totalScore = 0
            let modules = data.modules || []
            modules.forEach(model => {
              const questions = model.questions || []
              let weigh = 0
              questions.forEach(n => {
                weigh += n.weigh
                if (n.questionType === 0 && n.weigh > 0) {
                  totalScore += n.questionValue * 1 * (n.weigh / 100)
                }
                // this.initQuestionAnswer(n)
              })
              this.$set(this.modelWeigh, model.id, weigh)
            })

            this.totalScore = totalScore.toFixed(1)
            this.data = data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAssessmentRecord() {
      this.loading = true
      const data = {
        jobseekerChannelId: this.info.jobseekerChannelId,
        interviewId: (this.feedback || {}).createBy,
        interviewAssessmentRoundId: this.info.interviewRoundId,
        planInterviewId: this.info.id,
      }
      getAssessmentRecord(data)
        .then(res => {
          if (res.code === 0) {
            const data = (res.data || [])[0]
            if (!data) {
              this.getAssessmentByRoundId()
              return
            }
            let totalScore = 0
            let modules = data.modules || []
            modules.forEach(model => {
              let weigh = 0
              const questions = model.questions || []
              questions.forEach(n => {
                weigh += n.weigh
                if (n.questionType === 0 && n.weigh > 0) {
                  totalScore += n.questionValue * 1 * (n.weigh / 100)
                }
              })
              this.$set(this.modelWeigh, model.id, weigh)
              this.toScore(model)
            })
            this.totalScore = totalScore.toFixed(1)
            this.assessmentScore = data.assessmentTotalScore ? data.assessmentTotalScore : this.getAssessmentScore()
            this.data = data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    initQuestionAnswer(question) {
      if (question.questionType === 2) {
        this.$set(question, 'questionAnswer', [])
      } else {
        this.$set(question, 'questionAnswer', '')
      }
    },
    getOptionWidth(colCount = 1) {
      return 100 / (colCount * 1) + '%'
    },
    cancel() {},
    closed() {},
    clearTemplate() {
      this.modelWeigh = {}
      this.modelScore = {}
    },
  },
  watch: {
    ids() {
      this.clearTemplate()
      this.init()
    },
  },
}
</script>

<style lang="scss" scoped>
.feedback-wrapper {
  padding: 0 24px;
  background: rgba(243, 244, 246, 1);
  height: calc(100vh - 60px);
  overflow-y: scroll;

  .feedback-head {
    display: flex;
    justify-content: space-between;
    .edit-wrapper {
      width: 40px;
      height: 40px;
      background-color: #409eff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
  .module {
    box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.16);
    padding: 14px;
    margin: 10px 0;
    border-radius: 5px;
    .module-header {
      display: flex;
      align-items: center;
      min-height: 20px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px dashed rgba(51, 51, 51, 1);
      .module-widget {
        height: 16px;
        border-left: 5px solid #0c8cf6;
      }
      .module-title {
        margin-left: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #121316;
        width: 115px;
        flex-grow: 1;
        word-break: break-all;
      }
      .module-score {
        display: flex;
        align-self: flex-end;
        align-items: center;
      }
    }
    .module-description {
      color: #89909e;
      word-break: break-word;
      white-space: pre-wrap;
    }

    .module-question {
      margin: 5px 0;
      // padding-bottom: 10px;
      // border-bottom: 1px solid #f4f4f5;
      .question-title {
        font-size: 14px;
        color: #292c32;
        font-weight: bold;
      }

      .question-description {
        margin: 5px 0;
        color: #89909e;
        word-break: break-all;
        white-space: pre-wrap;
      }

      .question-description-answer {
        pre {
          white-space: pre-wrap;
          overflow-wrap: break-word;
          word-break: break-all;
        }
        .red-answer {
          color: rgba(247, 49, 49, 1);
          min-width: 18px;
          display: block;
          font-size: 14px;
        }
      }

      .question-radio {
        margin-right: 60px;
      }

      .option-inline {
        margin-top: 8px;
        display: inline-block;
      }
    }
    .interview-result {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .result {
        font-weight: 600;
        color: rgba(247, 49, 49, 1);
      }
    }
    .flex-module {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }
  .feedback-footer {
    margin: 20px 0;
    text-align: right;
  }
  .text-answer {
    padding: 5px 15px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    // width: 100%;
    display: block;
    min-height: 80px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-all;

    margin: 10px 0;
  }
}
.question-description {
  word-break: break-all;
  white-space: pre-wrap;
}
</style>

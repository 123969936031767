export default {
  text001: '评论结果',
  text002: '类型',
  text003: '得分',
  text004: '请阅读以下注意事项，并认真填写',
  text005: '①每组有A、B、C、D四个选项，分值为1、3、5、7，分别从最不像你的性格→像你的性格。',
  text006: '②每组1、3、5、7均需出现，不能重复。',
  text007: '③汇总各组A、B、C、D选项得分，总分必须在80分且每一项均为奇数。',
  text008: '组别',
  text009: '习性的描述与说明',
  text010: '自我评分',
}

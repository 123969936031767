export default {
  inserted(el, binding) {
    const value = binding.value
    if (value) {
      const { right, bottom } = value
      if (right !== undefined && bottom !== undefined) {
        el.setAttribute('style', `position: fixed; right: ${right}; bottom: ${bottom}; z-index: 99999;`)
      } else {
        el.setAttribute('style', 'position: fixed; z-index: 99999;')
      }
    }
  },
  update(el) {
    let disX = 0
    let disY = 0
    el.draggable = true
    el.ontouchstart = e => {
      e.stopPropagation()
      disX = e.touches[0].clientX - el.offsetLeft
      disY = e.touches[0].clientY - el.offsetTop
    }
    el.ondragstart = e => {
      console.log(ondragstart, e)
      disX = e.clientX - el.offsetLeft
      disY = e.clientY - el.offsetTop
    }
    el.ontouchmove = e => {
      e.preventDefault()
      e.stopPropagation()
      el.style.left = e.touches[0].clientX - disX + 'px'
      el.style.top = e.touches[0].clientY - disY + 'px'
    }
    el.ondragover = e => {
      e.preventDefault()
      e.stopPropagation()
      el.style.left = e.clientX - disX + 'px'
      el.style.top = e.clientY - disY + 'px'
    }
    el.ontouchend = e => {
      e.stopPropagation()
    }
    el.ondragend = e => {
      e.stopPropagation()
    }
  },
  unbind(el) {
    delete el.ontouchstart
    delete el.ontouchend
    delete el.ontouchmove
    delete el.ondragstart
    delete el.ondragover
    delete el.ondragend
  },
}

<template>
  <div class="online-test">
    <!-- <div class="module-radio">
			<div
				class="module-type"
				:class="{ active: problemType.label === m.label }"
				v-for="m in questionModules"
				:key="m.label"
				@click="changeModule(m)"
			>
				{{ m.name }}
			</div>
		</div> -->
    <CodingObjective
      v-if="activeIndex === 1"
      :problemType="{
        label: 'subjectiv',
        name: $t('com.report.onlinetest.text001'),
        types: [0],
      }"
      key="subjectiv"
    />
    <ObjectiveProblem v-if="activeIndex === 2" />
    <ExamSummary v-if="activeIndex === 0" />
    <ReadingQuestion v-if="activeIndex === 3" />
  </div>
</template>
<script>
// import { Vue, Component } from 'vue-property-decorator'
import CodingObjective from '@/components/reportV3/coding-objective.vue'
import ObjectiveProblem from '@/components/reportV3/objective-problem.vue'
import ExamSummary from '@/components/reportV3/exam-summary.vue'
import ReadingQuestion from '@/components/reportV3/reading-question.vue'
import i18n from '../../utils/i18n'

export default {
  components: { CodingObjective, ObjectiveProblem, ExamSummary, ReadingQuestion },
  props: { activeIndex: { type: Number, default: 1 } },
  data() {
    return {
      problemType: { label: 'examSummary', name: i18n.t('com.report.onlinetest.text002') },
      currentModel: '',
    }
  },
  // private questionModules = [
  // 	{ label: 'examSummary', name: '笔试报告' },
  // 	{ label: 'subjectiv', name: '论述题', types: [0] },
  // 	{ label: 'objective', name: '客观题', types: [2, 3, 4, 5] },
  // ]
  // private problemType = { label: 'examSummary', name: '笔试报告' }

  // private get currentModel() {
  // 	return this.problemType.label
  // }

  // private changeModule(module: any) {
  // 	this.problemType = module
  // }
}
</script>
<style lang="scss" scoped>
.online-test {
  .module-radio {
    background: #f2f2f2;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    .module-type {
      text-align: center;
      width: 90px;
      height: 28px;
      line-height: 28px;
      color: #6d7278;
      border-radius: 5px;
      border: 1px solid #dddddd;
      cursor: pointer;
      background: #f6f7f8;
    }

    .active {
      color: #1890ff;
      border: 1px solid #1890ff;
      font-weight: 600;
    }
    .module-type:not(:first-child) {
      margin-left: 24px;
    }
  }
}
</style>

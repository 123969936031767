<template>
  <div v-if="visible" class="five-year-dialog" @click="clickDialog">
    <div class="img">
      <img src="@/assets/images/five-years/img-five.png" @click.stop="clickContent" />
      <i class="el-icon-close close-btn"></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {
    visible() {
      return this.$store.state.fiveYearsVisbile && false
    },
  },
  methods: {
    clickDialog() {
      this.$store.commit('setFiveYearsVisbile', false)
    },
    clickContent() {
      this.$store.commit('setFiveYearsVisbile', false)
      this.$router.push({
        path: '/price',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.five-year-dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  .img {
    width: 335px;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
    .close-btn {
      position: absolute;
      cursor: pointer;
      font-size: 16px;
      padding: 8px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.45);
      color: #ffffff;
      font-weight: 600;
      bottom: -40px;
    }
  }
}
</style>

<template>
  <div class="feedback-wrapper">
    <div class="feedback-body">
      <div v-if="data.templateType === 1">
        <span>{{ $t('com.report.interviewPlan.text048') }}</span>
        <span style="color: #0c8cf6;font-size: 20px;">{{ assessmentScore }}</span>
        <span>/{{ $t('com.report.interviewPlan.text049', [toScore]) }}</span>
      </div>
      <van-form ref="modelForm" :model="data">
        <template v-for="(model, index) in data.modules || []">
          <section class="module" :key="index">
            <div class="module-header">
              <div class="module-widget"></div>
              <div class="module-title">
                <div>
                  <span>{{ model.moduleName }}</span>
                  <template v-if="data.templateType === 1">
                    <span> （{{ modelWeigh[model.id] }}%） </span>
                  </template>
                </div>
              </div>
              <div class="module-score" v-if="data.templateType === 1">
                <span>{{ $t('com.report.interviewPlan.text050') }}：{{ $t('com.report.interviewPlan.text049', [modelScore[model.id] || 0]) }}</span>
              </div>
            </div>
            <pre class="module-description">{{ model.moduleDescribe }}</pre>

            <template v-for="(question, $index) in model.questions">
              <div class="module-question" :key="question.id">
                <div class="question-title">
                  <span>{{ question.questionName }}</span>
                  <span v-if="data.templateType === 1 && question.questionType === 0"> （{{ question.weigh }}%）</span>
                  <span v-if="question.isNeed" class="required">*</span>
                </div>
                <pre class="question-description">{{ question.questionDescribe }}</pre>
                <div class="questionAnswer">
                  <div
                    :prop="`modules.${index}.questions.${$index}.questionAnswer`"
                    :rules="[{ required: question.isNeed ? true : false, message: $t('com.report.interviewPlan.text055'), trigger: ['blur', 'change'] }]"
                  >
                    <van-field name="radio" :rules="[{ required: question.isNeed ? true : false, message: $t('com.report.interviewPlan.text055'), trigger: 'onChange' }]">
                      <template v-if="question.questionType === 0" #input>
                        <van-radio-group v-model="question.questionAnswer" direction="horizontal" @change="toScore(model)">
                          <van-radio icon-size="14px" class="question-radio" v-for="n in question.questionValue * 1" :key="n" :name="n + ''">{{ n }}</van-radio>
                        </van-radio-group>
                      </template>
                      <template v-else-if="question.questionType === 1" #input>
                        <van-radio-group v-model="question.questionAnswer">
                          <template v-for="option in question.options">
                            <span :key="option.id" class="option-inline" :title="option.optionValue">
                              <van-radio :name="option.optionValue" icon-size="14px" class="question-radio">{{ option.optionValue }}</van-radio>
                            </span>
                          </template>
                        </van-radio-group>
                      </template>
                      <template v-else-if="question.questionType === 2" #input>
                        <van-checkbox-group v-model="question.questionAnswer">
                          <van-checkbox v-for="option in question.options" :name="option.optionValue" :key="option.id" shape="square">{{ option.optionValue }}</van-checkbox>
                        </van-checkbox-group>
                      </template>
                      <template v-else-if="question.questionType === 3" #input>
                        <textarea v-model="question.questionAnswer" rows="5" autosize type="textarea" style="width:100%"></textarea>
                      </template>
                    </van-field>
                  </div>
                </div>
              </div>
            </template>
          </section>
        </template>
      </van-form>
      <van-form ref="feedbackForm" :model="feedbackForm" :rules="feedbackFormRules">
        <section class="module">
          <div class="module-header">
            <div class="module-widget"></div>
            <div class="module-title">{{ $t('com.report.interviewPlan.text051') }}</div>
          </div>
          <div class="module-question">
            <div class="question-title">
              <span>{{ $t('com.report.interviewPlan.text052') }}</span>
              <span class="required">*</span>
            </div>
            <div>
              <div class="interviewResult" prop="interviewResult">
                <van-radio-group v-model="feedbackForm.interviewResult" direction="horizontal">
                  <van-radio icon-size="14px" :name="1">{{ $t('com.report.interviewPlan.text045') }}</van-radio>
                  <van-radio icon-size="14px" :name="0">{{ $t('com.report.interviewPlan.text044') }}</van-radio>
                  <van-radio icon-size="14px" :name="2">{{ $t('com.report.interviewPlan.text046') }}</van-radio>
                </van-radio-group>
              </div>
            </div>
          </div>

          <div class="module-question" v-if="!isZj">
            <div class="question-title">
              <span>{{ $t('com.report.interviewPlan.text053') }}</span>
            </div>
            <pre class="question-description">{{ $t('com.report.interviewPlan.text054') }}</pre>
            <div>
              <div prop="feedbackContent" class="feedbackContent">
                <textarea v-model="feedbackForm.feedbackContent" rows="4" autosize type="textarea" placeholder="" style="width:100%"></textarea>
              </div>
            </div>
          </div>
        </section>
      </van-form>
    </div>
    <div class="feedback-footer">
      <van-button size="normal" @click="cancel(true)">{{ $t('com.report.interviewPlan.text056') }}</van-button>
      <van-button size="normal" type="info" @click="save">{{ $t('com.report.interviewPlan.text057') }}</van-button>
    </div>
  </div>
</template>

<script>
import { createInterviewFeedback } from '@/api/report'
import EventBus from '@/common/event-bus'
import { cloneDeep } from 'lodash'
import { getAssessmentByRoundId, createOrUpdateInterviewAssessment, getAssessmentRecord } from '@/api/candidate'
export default {
  props: {
    info: {
      type: Object,
      default() {
        return null
      },
    },
    feedback: {
      type: Object,
      default() {
        return null
      },
    },
    curInterviewerItem: {
      type: Object,
      default() {
        return null
      },
    },
    curTemplateInfo: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      loading: false,
      realVisible: this.visible,
      data: {},
      modelWeigh: {},
      modelScore: {},
      totalScore: 0,
      feedbackForm: {
        interviewResult: 1,
        feedbackContent: '',
      },
      feedbackFormRules: {
        // feedbackContent: [{ required: true, message: '请填写综合评价', trigger: ['change', 'blur'] }],
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    isZj() {
      const companyId = this.$store.state.companyId
      return this.$store.state.userInfo?.customerType === 'CUSTOMERTYPE_ZHONGJUN' || this.$store.state.zjCompanyId.includes(companyId)
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    modules() {
      return this.data.modules || []
    },
    roundId() {
      return (this.info || {}).interviewRoundId
    },
    ids() {
      const roundId = (this.info || {}).interviewRoundId
      const feedbackId = (this.feedback || {}).id
      return [roundId, feedbackId]
    },
    assessmentScore() {
      let total = 0
      for (let key in this.modelScore) {
        total += parseFloat(this.modelScore[key])
      }
      return total.toFixed(1)
    },
  },
  methods: {
    init() {
      if (this.feedback) {
        this.feedbackForm = {
          id: this.feedback.id,
          interviewResult: [0, 1, 2].includes(this.feedback.interviewResult) ? this.feedback.interviewResult : 1,
          feedbackContent: this.feedback.feedbackContent,
        }
        this.getAssessmentRecord()
      } else {
        this.getAssessmentByRoundId()
      }
    },
    toScore(model) {
      const questions = model.questions || []
      let total = 0
      questions.forEach(n => {
        if (n.weigh > 0) {
          total += n.questionAnswer * (n.weigh / 100)
        }
      })
      this.$set(this.modelScore, model.id, total.toFixed(2))
    },
    getAssessmentByRoundId() {
      if (!this.roundId) {
        return
      }
      this.loading = true
      getAssessmentByRoundId({ id: this.roundId, publishId: this.info.positionId, interviewAssessmentFormId: this.curTemplateInfo?.id })
        .then(res => {
          if (res.code === 0) {
            const data = res.data || {}
            let totalScore = 0
            let modules = data.modules || []
            modules.forEach(model => {
              const questions = model.questions || []
              let weigh = 0

              questions.forEach(n => {
                weigh += n.weigh
                if (n.questionType === 0 && n.weigh > 0) {
                  totalScore += n.questionValue * 1 * (n.weigh / 100)
                }
                this.initQuestionAnswer(n)
              })
              this.$set(this.modelWeigh, model.id, weigh)
            })
            this.totalScore = totalScore.toFixed(1)
            this.data = data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAssessmentRecord() {
      this.loading = true
      const data = {
        jobseekerChannelId: this.info.jobseekerChannelId,
        interviewId: (this.feedback || {}).createBy,
        interviewAssessmentRoundId: this.info.interviewRoundId,
        planInterviewId: this.info.id,
      }

      getAssessmentRecord(data)
        .then(res => {
          if (res.code === 0) {
            const data = (res.data || [])[0]
            if (!data) {
              this.getAssessmentByRoundId()
              return
            }
            let totalScore = 0
            let modules = data.modules || []
            modules.forEach(model => {
              const questions = model.questions || []
              let weigh = 0
              questions.forEach(n => {
                weigh += n.weigh
                if (n.questionType === 0 && n.weigh > 0) {
                  totalScore += n.questionValue * 1 * (n.weigh / 100)
                }
                if (n.questionType === 2) {
                  n.questionAnswer = n.questionAnswer.split(',')
                }
              })
              this.$set(this.modelWeigh, model.id, weigh)
              this.toScore(model)
            })
            this.totalScore = totalScore.toFixed(1)
            this.data = data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      if (this.data.modules && this.data.modules.length) {
        for (let i = 0; i < this.data.modules.length; i++) {
          let module = this.data.modules[i]
          if (module.questions && module.questions.length) {
            for (let j = 0; j < module.questions.length; j++) {
              let question = module.questions[j]
              if (
                question.isNeed === 1 &&
                ((!Array.isArray(question.questionAnswer) && !question.questionAnswer) || (Array.isArray(question.questionAnswer) && question.questionAnswer && !question.questionAnswer[0]))
              ) {
                this.$notify({
                  type: 'danger',
                  // message: `${question.questionName}内容不能为空`,
                  message: this.$t('com.report.interviewPlan.text058', { content: question.questionName }), // `${question.questionName}内容不能为空`,
                })
                return
              }
            }
          }
        }
      }
      const data = {
        record: this.getSaveData(),
        feedback: this.getFeedBackData(),
      }
      createOrUpdateInterviewAssessment(data).then(res => {
        if (res.code === 0) {
          this.$notify({ type: 'success', message: this.$t('com.report.interviewPlan.text059') })
          EventBus.$emit('LOAD_INTERVIEW_DATA')
          this.cancel(false)
        } else {
          this.$notify({ type: 'danger', message: res.msg })
          if (res.code === 1004 || res.code === 1001) {
            EventBus.$emit('toLogin', '1004')
          }
        }
      })
      // console.log('edit data', data)
      // saveOrUpdateAssessmentRecord(data)
      // this.updateInterviewFeedback()
    },
    updateInterviewFeedback() {
      let params = {
        planInterviewId: this.info.id,
        id: this.feedbackForm.id,
        feedbackContent: this.feedbackForm.feedbackContent,
        createBy: this.userInfo?.userId,
        createByName: this.userInfo.userName,
        interviewResult: this.feedbackForm.interviewResult,
        positionId: this.info.positionId,
        interviewerId: this.curInterviewerItem.interviewerId,
      }

      createInterviewFeedback(params).then(res => {
        if (res.code === 0) {
          this.$notify({ type: 'success', message: this.$t('com.report.interviewPlan.text059') })
          //this.$message.success('反馈成功')
          EventBus.$emit('LOAD_INTERVIEW_DATA')
          this.cancel(false)
        } else {
          this.$notify({ type: 'danger', message: res.msg })
          if (res.code === 1004 || res.code === 1001) {
            EventBus.$emit('toLogin', '1004')
          }
          // Notification.error({
          //   title: '错误',
          //   message: res.message,
          // })
        }
      })
    },
    getSaveData() {
      let template = this.data || {}
      let modules = cloneDeep(template.modules || [])

      let data = {
        id: this.feedback ? template.id : '',
        templateName: template.templateName,
        templateType: template.templateType,
        templateDescribe: template.templateDescribe,
        assessmentPoint: template.assessmentPoint, //总分,计算分数时,必填
        assessmentScore: template.assessmentScore, //总得分,选填,计分题时填
        jobseekerChannelId: this.info.jobseekerChannelId, //候选人主键
        planInterviewId: this.info.id,
        // interviewId: this.userInfo?.userId, //面试官主键
        interviewId: this.curInterviewerItem.interviewerId,
        interviewAssessmentRoundId: this.info.interviewRoundId, //面试轮次主键
        modules: [],
        interviewerId: this.curInterviewerItem.interviewerId,
      }

      modules.forEach(model => {
        if (!this.feedback) {
          delete model.id
          delete model.templateId
        }
        if (model.questions) {
          model.questions.forEach(question => {
            if (!this.feedback) {
              delete question.id
              delete question.templateId
            }
            if (Array.isArray(question.questionAnswer)) {
              question.questionAnswer = question.questionAnswer.join(',')
            }
          })
        }
        data.modules.push(model)
      })

      return data
    },
    getFeedBackData() {
      return {
        planInterviewId: this.info.id,
        id: this.feedbackForm.id,
        feedbackContent: this.feedbackForm.feedbackContent,
        // createBy: this.userInfo?.userId,
        createBy: this.curInterviewerItem.interviewerId,
        createByName: this.userInfo.userName,
        interviewResult: this.feedbackForm.interviewResult,
        positionId: this.info.positionId,
        interviewerId: this.curInterviewerItem.interviewerId,
      }
    },
    initQuestionAnswer(question) {
      if (question.questionType === 2) {
        this.$set(question, 'questionAnswer', [])
      } else {
        this.$set(question, 'questionAnswer', '')
      }
    },
    getOptionWidth(colCount = 1) {
      return 100 / (colCount * 1) + '%'
    },
    clearTemplate() {
      this.data = {}
      this.feedbackForm = {
        interviewResult: 0,
        feedbackContent: '',
      }
      // if (this.$refs.feedbackForm) {
      //   this.$refs.feedbackForm.clearValidate()
      // }
      this.modelWeigh = {}
      this.modelScore = {}
    },
    cancel(isTip = true) {
      if (!isTip) {
        this.$emit('closed')
        return
      }

      this.$emit('closed')
      // this.$confirm('检查到当前页面有未保存的内容，关闭后将丢失这部分信息', '确认离开？', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // })
      //   .then(() => {
      //     this.$emit('closed')
      //   })
      //   .catch(() => {})
    },
    closed() {
      this.clearTemplate()
    },
  },
  watch: {
    ids() {
      this.clearTemplate()
      this.init()
    },
  },
}
</script>

<style lang="scss" scoped>
.feedback-wrapper {
  .feedback-body {
    padding: 0 24px;
    height: calc(100vh - 118px);
    overflow-y: scroll;
    overflow-x: hidden;
    background: rgba(243, 244, 246, 1);
    .module {
      box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.16);
      padding: 14px;
      margin: 10px 0;
      border-radius: 5px;
      background: #ffffff;
      .module-header {
        display: flex;
        align-items: center;
        min-height: 20px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dashed rgba(51, 51, 51, 1);
        .module-widget {
          height: 16px;
          border-left: 5px solid #0c8cf6;
        }
        .module-title {
          margin-left: 10px;
          font-size: 16px;
          font-weight: bold;
          color: #121316;
          width: 115px;
          flex-grow: 1;
          word-break: break-all;
          white-space: pre-wrap;
        }

        .module-score {
          display: flex;
          align-self: flex-end;
          align-items: center;
        }
      }
      .module-description {
        color: #89909e;
        word-break: break-all;
        white-space: pre-wrap;
      }
      .module-question {
        margin: 5px 0;
        // padding-bottom: 10px;
        // border-bottom: 1px solid #f4f4f5;
        .question-title {
          font-size: 14px;
          color: #292c32;
          font-weight: bold;
        }
        .required {
          margin-left: 5px;
          font-size: 14px;
          color: #f56c6c;
        }
        .question-description {
          margin: 5px 0;
          color: #89909e;
          word-break: break-all;
          white-space: pre-wrap;
        }
        .option-inline {
          margin-bottom: 8px;
          // width: 70px;
          margin-right: 20px;
        }

        // .option-inline {
        //   // margin-top: 8px;
        //   display: inline-block;
        //   /deep/.el-radio__label,
        //   /deep/.el-checkbox__label {
        //     // display: inline-block;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     min-width: 30px;
        //     width: calc(100% - 20px);
        //     height: 20px;
        //     line-height: 20px;
        //   }
        //   /deep/.el-radio__input,
        //   /deep/.el-checkbox__input {
        //     margin-top: -12px;
        //   }
        // }

        .interviewResult {
          margin-top: 5px;
          font-size: 0.373333rem;
          padding: 5px;
        }

        .feedbackContent {
          /deep/ textarea {
            margin-top: 0.2rem;
            border: 1px solid #c8c9cc;
            border-radius: 4px;
            padding: 10px;
            transform: translateX(0.8);
            transform: translateX(10rpx);
            box-sizing: border-box;
          }
        }

        .questionAnswer {
          /deep/ textarea {
            margin-top: 0.2rem;
            border: 1px solid #c8c9cc;
            border-radius: 4px;
            padding: 10px;
            transform: translateX(0.8);
            transform: translateX(10rpx);
            box-sizing: border-box;
          }
          .van-cell {
            padding: 5px;
          }

          .van-checkbox-group {
            .van-checkbox {
              margin-top: 10px;
            }
          }

          .van-radio-group {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .feedback-footer {
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-right: 20px;
    text-align: center;
    background: #f3f4f6;
    height: 58px;
    .van-button {
      height: 36px;
      flex: 1;
    }
    .van-button:first-child {
      margin-right: 20px;
    }
  }
}
</style>

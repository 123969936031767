<template>
  <div class="timeline">
    <slot></slot>
  </div>
</template>
<script>
export default {
  provide() {
    return {
      timeline: this,
    }
  },
}
</script>
<style lang="scss">
.timeline {
  padding: 16rpx 0;
}
</style>

<template>
  <div class="htmlParse">
    <div v-if="!useQuestionContent" class="inner-text">
      <pre v-html="innerText"></pre>
    </div>
    <question-content v-else class="inner-text" :content="innerText" />
    <div class="show-more" v-if="(innerImage && innerImage.length) || (innerVideo && innerVideo.length)" @click="showMore = !showMore">
      <span>{{ showMore ? $t('com.report.interviewRecord.text008') : $t('com.report.interviewRecord.text045') }}</span>
    </div>
    <div v-if="innerImage && innerImage.length && showMore">
      <div v-for="(img, i) in innerImage" :key="i + Math.random()" class="inner-img">
        <img :src="img" style="width: 100%" />
      </div>
    </div>
    <div v-if="innerVideo && innerVideo.length && showMore">
      <div class="inner-video-player" v-for="(videoItem, i) in innerVideo" :key="videoItem">
        <video-player
          class="player vjs-big-play-centered"
          :playsinline="true"
          @play="onPlay"
          :options="getPlayerOption(videoItem, videoItem + '?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast')"
        ></video-player>
        <!-- <video
          :id="'parseVideo' + i"
          width="100%"
          :ref="'parseVideo' + i"
          height="200px"
          :src="videoItem"
          controls="controls"
          controlsList="nodownload"
          :poster="videoItem + '?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast'"
          webkit-playsinline="true"
          playsinline="true"
          x5-playsinline="true"
          x5-video-player-fullscreen="true"
        >
          您的浏览器不支持 video 标签。
        </video> -->
        <div class="small-tips" v-if="videoTips && videoTips.length && noWifi && videoTips[i]">{{ $t('com.report.interviewRecord.text002') }}</div>
        <div class="noWifiTip" @click="showAnswerVideoSmallTips(i)" v-if="noWifi && totalVideoFlag">
          <div class="text">{{ $t('com.report.interviewRecord.text002') }}</div>
          <div class="paly-btn">
            <img src="@/assets/play.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuestionContent from './reportV3/common/question-content.vue'
export default {
  components: {
    QuestionContent,
  },
  props: {
    curqes: {
      default: function () {
        return {}
      },
    },
    htmlStr: {
      default: function () {
        return ''
      },
    },
    totalVideoFlag: {
      default: function () {
        return true
      },
    },
    type: {
      default: function () {
        return 1
      },
    },
    questionIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    useQuestionContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noWifi() {
      return this.$store.state.noWifi
    },
    labelscore() {
      return this.curqes.question.labelscore ?? null
    },
  },
  data() {
    return {
      innerText: '',
      innerImage: [],
      innerVideo: [],
      videoTips: [],
      tempStr: '',
      showMore: false,
    }
  },
  created() {
    this.tempStr = this.htmlStr
    this.parseTitle()
  },
  methods: {
    onPlay(player) {
      console.log(player)
      const videoArray = document.getElementsByTagName('video')
      if (videoArray && videoArray.length) {
        for (let i = 0; i < videoArray.length; i++) {
          videoArray[i].onplay = () => {
            for (let j = 0; j < videoArray.length; j++) {
              if (i !== j) {
                videoArray[j].pause()
              }
            }
          }
          // // 阻止右键菜单
          // videoArray[i].addEventListener('contextmenu', e => e.preventDefault())
          // videoArray[i].oncontextmenu = e => e.preventDefault()
          // // 屏蔽下载
          // videoArray[i].setAttribute('controls', 'controls')
          // videoArray[i].setAttribute('controlsList', 'nodownload')
        }
      }
    },
    getPlayerOption(url, poster) {
      return {
        width: '100%',
        height: 165,
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{ type: 'video/mp4', src: url }],
        poster: poster, //require('./images/banner-poster.jpg'), // 封面地址
        notSupportedMessage: this.$t('com.report.interviewRecord.text028'), // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      }
    },
    showAnswerVideoSmallTips(index) {
      if (!this.totalVideoFlag) {
        return
      }
      this.$emit('update:totalVideoFlag', false)
      this.$set(this.videoTips, index, true)

      this.$refs['parseVideo' + index][0].play()

      setTimeout(() => {
        this.$set(this.videoTips, index, false)
      }, 5000)
    },
    parseTitle() {
      // if (this.tempStr && this.noWifi && this.totalVideoFlag) {
      // const imgTagReg = /\<img(.*?)\>/gi // /<img.*?((\/>)|(\/img>))/gi
      // const videoTagReg = /\<video.*?\<\/video\>/gi
      // // /<video.*?((\/>)|(\/video>))/gi
      // const srcReg = /src=['"]?([^'"]*)['"]?/i
      const labelscoreText =
      this.labelscore !== null
        ? `<span style="color: #1890FF;white-space: nowrap;">(分值：${this.labelscore})</span>`
        : ''
      this.innerText = this.tempStr
      if (this.showLabel) {
        let label = ''
        if (this.label) {
          label = this.label
        } else {
          if (this.questionIndex) {
            label = this.questionIndex + '.'
          }
        }
        if (label) {
          if (this.innerText && /<p.*?>/.test(this.innerText)) {
            this.innerText = this.innerText.replace(/<p>/, `<p>${label}`)
          } else {
            this.innerText = label + this.innerText
          }
        }
      }
      if (this.innerText) {
        const parser = document.createElement('div')
        parser.innerHTML = this.innerText
        parser.querySelectorAll('video').forEach(video => {
          // this.poster = video.getAttribute('poster') || ''
          const src = video.getAttribute('src')
          if (src) this.innerVideo.push(src)
          video.querySelectorAll('source').forEach(source => {
            this.innerVideo.push(source.getAttribute('src'))
          })
          video.parentElement?.removeChild(video)
        })
        parser.querySelectorAll('img').forEach(img => {
          const src = img.getAttribute('src')
          if (src) this.innerImage.push(src)
          img.parentElement?.removeChild(img)
        })
        this.innerText = parser.innerHTML
        // 添加分值
        if (/<p.*?>/.test(this.innerText)) {
          this.innerText = this.innerText.replace(/<p.*?>/, '<p>' + labelscoreText)
        } else {
          this.innerText = labelscoreText + this.innerText
        }
        // setTimeout(() => this.$nextTick(() => this.$videoListener()), 0)
      }
      // this.innerImage = (this.tempStr.match(imgTagReg) || []).map(t => (t.match(srcReg) || []).find((v, i) => i === 1) || '') || []
      // this.innerVideo = (this.tempStr.match(videoTagReg) || []).map(t => (t.match(srcReg) || []).find((v, i) => i === 1) || '') || []

      for (let i = 0; i < this.innerVideo.length; i++) {
        this.$set(this.videoTips, i, false)
      }
    },
  },
  watch: {
    htmlStr(val) {
      this.tempStr = val
      this.parseTitle()
    },
  },
}
</script>
<style lang="scss" z>
.htmlParse {
  position: relative;
  width: 100%;
  .show-more {
    width: 100%;
    text-align: right;
    margin-bottom: 10px;
    color: #14b1ff;
  }
  .inner-video-player {
    position: relative;
  }
  pre {
    white-space: pre-wrap;
    word-break: break-word;
  }
  video {
    width: 100%;
    height: 200px;
  }
  .noWifiTip {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;

    .text {
      color: #ffffff;
    }
    .paly-btn {
      margin-top: 10px;
      color: #ffffff;
      width: 30px;
      img {
        width: 100%;
      }
    }
  }
  .small-tips {
    position: absolute;
    bottom: 20%;
    left: 0;
    color: #ffffff;
    z-index: 10;
    animation-name: enterIn;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    background-color: rgba(0, 0, 0, 0.5);
  }

  @keyframes enterIn {
    0% {
      transform: translateX(-1000vw);
    }
    10% {
      transform: translateX(0);
    }
    95% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>

<template>
  <div class="case">
    <Header></Header>
    <div class="case-content">
      <div class="title">客户案例</div>
      <ul>
        <li v-for="(item, index) in data" :key="index">
          <div class="img-icon">
            <img :src="item.imgUrl" />
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="box-txt" v-html="item.text"></div>
        </li>
      </ul>
    </div>
    <tools></tools>
  </div>
</template>
<script>
import Tools from '@/components/tools'
import Header from '@/components/menu'
export default {
  components: { Tools, Header },
  data() {
    return {
      data: [
        {
          title: '西门子集团',
          imgUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/xmz_m.jpg',
          text: '西门子集团每年校招都会收到海量的候选人简历。AI得贤招聘官根据西门子集团的独特要求，与西门子集团的HR团队共同开展了双盲人机对比实验，最终设计出了定制化的AI简历筛选服务与多元全面的AI面试系统，使简历量精简至原来的53%，面试量下降至原来的30%-50%，面试的精确度提高至93%，最终招聘的成功率提升了15%-30%。',
        },
        {
          title: '三星中国（投资）有限公司',
          imgUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/sx_m.jpg',
          text: '三星中国自2019年开始使用AI得贤招聘官，与近屿科技建立了深厚的合作关系。为解决三星中国招聘量巨大、面试受时间空间局限大的难题，AI得贤招聘官引入了AI简历解析的功能，用AI算法代替HR向企业推荐优秀和合格的候选人，精简了面试流程，将面试量下降到5%，从而提升了候选人的应聘体验和雇主品牌形象，并大幅减少了面试官的工作量，缩短了招聘项目周期，提高了整个招聘效率。',
        },
        {
          title: '美团',
          imgUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/mt_m.jpg',
          text: '每逢招聘季美团都需要招聘大量的岗位，如何在减轻HR与业务领导面试量的同时保证面试的有效性及精准度一直是美团的一个焦点。在美团与AI得贤招聘官共同严密的人机对比实验等测试之中，AI得贤招聘官的打分准确率达到了95%，同时AI得贤招聘官通过专业的咨询团队对美团进行了组织诊断，业务梳理，并协同美团HR团队进行了深入的访谈沟通，为搭建了更为契合美团岗位需求的胜任力模型，有效地提高了美团的招聘人效，控制了美团的招聘成本。',
        },
        {
          title: '中骏',
          imgUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/zj_m.jpg',
          text: '中骏是中国排名前20的地产集团，中骏非常重视ATS系统的创新技术及产品研发团队的响应速度和咨询培训能力。AI得贤招聘官的服务标准和实施标准完全按照SAP的流程设计，完全符合中骏地产的需求，为中骏地产将招聘业务的流程数据打通至多个系统，满足了中骏地产不同系统间的业务流程设计无缝对接，获得了中骏团队从上至下的一致赞赏和认同。',
        },
        {
          title: '中原银行',
          imgUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/zy_m.jpg',
          text: '中原银行自2020年开始使用AI得贤招聘官。由于面试流程长，处理速度比较慢，且技术面试官的水平层次不齐，对人才质量评估的标准不一，导致中原银行在面试上需要花费大量的时间经历，AI得贤招聘官针对中原银行的需求痛点，与中原银行一起建立了程序员的模型，并不断优化和调整算法技术，降低错杀率，最终将人机对比实验的精确度达到了95%以上，有效地节约了面试成本，实现了精准收割人才。',
        },
        {
          title: '科勒（中国）投资有限公司',
          imgUrl: require('./images/case_kohler.png'),
          text: '科勒（中国）投资有限公司在亚太管培生校招项目中，采用AI得贤招聘官面试系统助力高潜力候选人筛选，节省了HR团队200多小时/人的工作量，直接将校园招聘的项目周期缩短了2周，降低83%简历筛选的时间成本，提高20%的高层管理者复试录用率，降低了10%的应届毕业生同比离职率，提升了企业效能。',
        },
      ],
    }
  },
  created() {
    document.title = '客户案例-AI得贤招聘官'
  },
}
</script>
<style lang="scss" scoped>
.case {
  background: #fff;
  .case-content {
    padding: 0px 25px 50px 25px;
    > .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      color: #333333;
      letter-spacing: 3px;
      margin-top: 35px;
      text-align: center;
      margin-bottom: 10px;
    }
    ul {
      li {
        .img-icon {
          width: 220px;
          height: 118px;
          overflow: hidden;
          margin: 0 auto 25px;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 15px;
          font-weight: 600;
          color: #333333;
          line-height: 21px;
          margin-bottom: 10px;
        }
        .box-txt {
          width: 100%;
          font-size: 12px;
          color: #333333;
          line-height: 22px;
          margin-bottom: 30px;
          text-align: justify;
        }
        &:last-child {
          .box-txt {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>

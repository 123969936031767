/* eslint-disable quotes */
export default {
  text001: 'Answer',
  text002: 'You are using a non-WIFI network, playback will incur data charges',
  text003: 'Candidate did not record video',
  text004: 'Assessment Competence',
  text005: 'L4-AI score',
  text006: 'AI score',
  text007: 'Generating',
  text008: 'Unfold',
  text009: 'View details',
  text010: '{name} scored as: {score}',
  text011: '{name} rated as: {score}',
  text012: 'Assessment Competence',
  text013: 'Definition',
  text014: 'No dimension yet',
  text015: 'No video title yet',
  text015_: 'The video topic is not configured',
  text016: 'Essay question',
  text017: 'Al video title',
  text018: 'Single question',
  text019: 'Multiple choice',
  text020: 'Judge question',
  text021: 'Ranking question',
  text022: 'Accent question',
  text023: 'Code question',
  text024: 'L2-Mandarin',
  text025: 'L2-English',
  text026: "Test the candidate's Putonghua proficiency, and identify the content of the candidate's answer in Chinese.",
  text027: "Test the candidate's English proficiency and identify the content of the candidate's answer in English.",
  text028: 'This video is temporarily unavailable, please try again later',
  text029: 'Submitted successfully',
  text030: 'Failed to submit',
  text031: 'Pass',
  text032: 'Reject',
  text033: 'TBD',
  text034: 'Correct',
  text035: 'Error',
  text036: 'The answer is correct',
  text037: 'The answer is incomplete',
  text038: 'Wrong answer',
  text039: 'The candidate has not answered the question',
  text040: 'Objective question',
  text041: 'Correct answer',
  text042: 'There are no other interview records',
  text045: 'Expand',
  text046: 'His/Her answer',
  text047: 'AI follow-up question',
  text048: 'Why AI follow up',
  text049: 'Text Similarity（Accurate）',
  text050: 'Text Similarity（Fuzzy）',
  text51: 'Subpar',
  text52: 'Medium',
  text53: 'Excellent',
  text54: 'More Details',
  text55: 'Collapse Details',
  text56: 'Include rationale',
}

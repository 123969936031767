export default {
  text001: '附件',
  text002: '預覽',
  text003: '鏈接',
  text004: '背景調查資料表',
  text005: '1.是否完成證明人調查（工作履歷/違紀情況）:',
  text006: '2.是否存在舞弊記錄:',
  text007: '3.違紀查詢結果:',
  text008: '是',
  text009: '否',
  text010: '確認刪除{0}?',
  text011: '刪除成功',
  text012: '刪除失敗',
}

<template>
  <div class="pdp-test">
    <!-- <div class="pdp-test-top" v-if="tableLength">
      <div class="ptt-l">
        <el-radio-group size="mini" v-model="currentTab" class="resume-radios">
          <el-radio-button class="origin-resume" label="pdpReport">pdp报告</el-radio-button>
          <el-radio-button class="general-resume" label="cheatRecord">防作弊记录</el-radio-button>
        </el-radio-group>
      </div>
      <div class="ptt-r" v-if="currentTab === 'pdpReport' && $route.path !== '/newShareReport' && $route.path !== '/mailReport'">
        <div v-loading="downLoading" class="tab-item" @click="downLoadPdpTestReport('download')"><img src="@/assets/img/download-icon-new.png" />下载报告</div>
        <div v-loading="printLoading" class="tab-item" @click="downLoadPdpTestReport('print')"><img src="@/assets/img/print-icon-new.png" />打印报告</div>
      </div>
    </div> -->

    <div class="pdp-test-content" id="pdp-test-content">
      <div v-if="tableLength" class="pdp-test-report" id="pdpTestReport">
        <div class="ptr-result">{{ $t('com.report.pdp.text001') }}：{{ remark }}</div>
        <!-- <Table :data="tableData" :header-cell-style="{ background: '#ffffff' }" border>
          <TableColumn align="center" label="类型" prop="label"></TableColumn>
          <TableColumn align="center" label="A" prop="scoreA"></TableColumn>
          <TableColumn align="center" label="B" prop="scoreB"></TableColumn>
          <TableColumn align="center" label="C" prop="scoreC"></TableColumn>
          <TableColumn align="center" label="D" prop="scoreD"></TableColumn>
        </Table> -->
        <table>
          <tr>
            <th style="border-left:1px solid;">{{ $t('com.report.pdp.text002') }}</th>
            <th>A</th>
            <th>B</th>
            <th>C</th>
            <th>D</th>
          </tr>
          <tr v-for="(td, index) in tableData" :key="index">
            <td style="border-left:1px solid;">{{ $t('com.report.pdp.text003') }}</td>
            <td>{{ td.scoreA }}</td>
            <td>{{ td.scoreB }}</td>
            <td>{{ td.scoreC }}</td>
            <td>{{ td.scoreD }}</td>
          </tr>
        </table>

        <div class="zj-prepare">
          <div>{{ $t('com.report.pdp.text004') }}</div>
          <div>{{ $t('com.report.pdp.text005') }}</div>
          <div>{{ $t('com.report.pdp.text006') }}</div>
          <div>{{ $t('com.report.pdp.text007') }}</div>
        </div>
        <table v-for="(table, index) in tableList" :data="table" :key="index">
          <tr :class="index === 0 ? 'first-t-tr' : 'not-show'">
            <th>{{ $t('com.report.pdp.text008') }}</th>
            <th colspan="3">{{ $t('com.report.pdp.text009') }}</th>
            <th>{{ $t('com.report.pdp.text010') }}</th>
          </tr>
          <tr v-for="(td, i) in table" :key="i" :class="getTrClass(index, i)">
            <td rowspan="4" :class="getTdClass(index, i)" v-if="i === 0">{{ td.label }}</td>
            <td :class="index !== 0 ? 'row-second-td' : ''">{{ td.optionCode }}</td>
            <td colspan="2">{{ td.optionContent }}</td>
            <td>{{ td.score }}</td>
          </tr>
        </table>

        <!-- <Table v-for="(table, index) in tableList" :data="table" :key="index" :show-header="index === 0 ? true : false" border :header-cell-style="headerCellStyle" :span-method="objectSpanMethod">
          <TableColumn width="140" align="center" label="组别" prop="label"></TableColumn>
          <TableColumn align="center" label="习性的描述与说明">
            <TableColumn width="100" align="center" prop="optionCode"></TableColumn>
            <TableColumn width="260" align="center" prop="optionContent"></TableColumn>
          </TableColumn>
          <TableColumn width="140" align="center" label="自我评分" prop="score"></TableColumn>
        </Table> -->
      </div>
      <div v-else class="no-data">
        <img :src="require('@/assets/img/third-empty.png')" />
      </div>
    </div>

    <!-- <Others v-if="currentTab === 'cheatRecord'" /> -->
  </div>
</template>
<script>
import { pdpStatistics } from '@/api/candidate'
// import { Table, TableColumn } from 'element-ui'
export default {
  data() {
    return {
      tableData: [],
      tableList: [],
      downLoading: false,
      printLoading: false,
      container: '',
      currentTab: 'pdpReport',
      remark: '-',
    }
  },
  prop: {},

  computed: {
    tableLength() {
      return this.tableList && this.tableList.length
    },
    interviewResult() {
      return this.$store.state.interviewResult
    },
    jobseekerChannelId() {
      return this.$store.state.jobseekerChannelId
    },
    interviewId() {
      return this.$store.state.interviewId || this.$store.state.interviewResult.jobseekerChannelId
    },
    companyId() {
      return this.$store.state.companyId
    },
  },

  mounted() {
    this.pdpStatistics()
  },
  methods: {
    getTdClass(index, i) {
      if (i === 0 && index === 0) {
        return 'first-td'
      } else if (index !== 0) {
        return 'row-first-td'
      }
    },

    getTrClass(index, i) {
      if (index !== 0 && i === 0) {
        return 'row-second-tr'
      }
    },
    pdpStatistics() {
      // const data = { jobseekerChannelId: '966527be336c4760b0f2839552438a37', companyId: '686d823e59304ffe953b2d548cf6819c' }
      // console.log(data)
      pdpStatistics({
        jobseekerChannelId: this.jobseekerChannelId,
        companyId: this.companyId,
        // ...data,
      }).then(res => {
        console.log(res)
        this.tableList = res.result.problemList.map(p => {
          return p.optionAnswer.map(o => {
            return { ...o, label: p.problem, score: o.index }
          })
        })
        const { scoreA, scoreB, scoreC, scoreD, remark } = res.result
        this.tableData = [{ label: '得分', scoreA, scoreB, scoreC, scoreD }]
        this.remark = remark || '-'
      })
    },

    headerCellStyle({ row = '', column = '', rowIndex = '', columnIndex = '' }) {
      console.log(row, column, rowIndex, columnIndex)
      let style = {
        // 'text-align': 'center',
        background: '#fff',
      }
      if (rowIndex === 1) {
        style = {
          ...style,
          display: 'none',
        }
        //这里为了是将第二列的表头隐藏，就形成了合并表头的效果
      }
      return style
      // return 'background:#f5f7fa'
    },

    objectSpanMethod({ row = '', column = '', rowIndex = '', columnIndex = '' }) {
      row && column
      if (+rowIndex <= 8) {
        if (+rowIndex === 0 && +columnIndex === 0) {
          return [8, 1]
        } else if (+rowIndex >= 1 && +columnIndex === 0) {
          return [0, 0]
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.pdp-test {
  .pdp-test-content {
    .pdp-test-report {
      width: 90%;
      margin: 0 auto;
      .ptr-result {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 10px;
      }
      table {
        width: 100%;
        border-spacing: 0px;
        .not-show {
          opacity: 0;
        }
        tr {
          position: relative;
          th {
            border-right: 1px solid;
            border-bottom: 1px solid;
            border-top: 1px solid;
          }
          td {
            text-align: center;
            border-right: 1px solid;
            border-bottom: 1px solid;
            position: relative;
          }
          .first-td {
            border-left: 1px solid;
          }
          .row-first-td {
            border-left: 1px solid;
            // border-top: 1px solid;
          }
        }
        .first-t-tr {
          th {
            border-top: 1px solid;
          }
          th:first-child {
            border-left: 1px solid;
          }
        }
        .row-second-tr {
          td {
            border-top: 1px solid;
          }
        }
      }
      .zj-prepare {
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
    .no-data {
      width: 180px;
      margin: 54px auto 0 auto;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
}
</style>

export default {
  text001: '面试安排',
  text002: '面试时间',
  text003: '面试轮次',
  text004: '面试负责人',
  text005: '面试助理',
  text006: '房间号码',
  text007: '会议号',
  text008: '腾讯会议链接',
  text009: '面试密码',
  text010: '面试信息',
  text011: '面试官姓名',
  text012: '面试官链接',
  text013: '复制链接',
  text014: '面试官密码',
  text015: '微信小程序搜索「得贤远程面试」输入密码开始面试。',
  text016: '面试反馈',
  text017: '填写反馈',
  text018: '查看反馈',
  text019: '撤销未进行',
  text020: '面试地点',
  text021: '面试未进行',
  text022: '等待hr安排面试~',
  text023: '您可以点击下方的按钮去安排或更新您的面试时间',
  text024: '更新空闲时间',
  text025: '未响应',
  text026: '接受',
  text027: '已拒绝',
  text028: '已取消',
  text029: '线下面试',
  text030: '线上得贤面试',
  text031: '线上语音',
  text032: '线上腾讯会议',
  text033: '视频面试',
  text034: '该功能需要登录操作',
  text035: '确认将面试修改为未进行状态？',
  text036: '操作成功',
  text037: '您暂无权限进行操作，请联系HR',
  text038: '确认后您将可以给候选人填写反馈',
  text039: '撤销面试未进行',
  text040: '复制成功',
  text041: '反馈结果不能为空',
  text042: '反馈成功',
  text043: '错误',
  text044: '拒绝',
  text045: '通过',
  text046: '待定',
  text047: '面试未进行',
  text048: '总分',
  text049: '{0}分',
  text050: '模块分数',
  text051: '综合反馈',
  text052: '面试结果',
  text053: '综合评价',
  text054: '这些是我客观的反馈评价，如有异议或疑问我们随时沟通',
  text055: '内容不允许为空',
  text056: '取消',
  text057: '确定',
  text058: '{content}内容不能为空',
  text059: '反馈成功',
}

<template>
  <div class="resume-tags" v-if="tags.length">
    <!-- <div class="left">简历标签：</div> -->
    <div class="labels" :style="showMoreStyle">
      <div v-for="(tag, i) in tags" :key="i" class="label-tag">
        <!-- [7, 8].includes(tag.labelId) -->
        <div class="label-name" :class="{ 'red-tag': [7, 8].includes(+tag.labelId) }">
          <span>{{ tag.labelName }}</span>
        </div>
      </div>
      <div class="more" @click="showMore = !showMore">
        {{ showMore === null ? '' : showMore ? $t('com.report.interviewRecord.text045') : $t('com.report.interviewRecord.text008') }}
      </div>
    </div>
  </div>
</template>

<script>
import { listCandidateLabel } from '@/api/rpa'

export default {
  props: {
    interviewResult: { required: true, default: {} },
    jobseekerChannelId: { required: true },
  },
  data() {
    return {
      tags: [],
      showMore: null,
      tagOffsetHeight: 0,
    }
  },
  created() {
    // window.addEventListener('resize', this.computedShowMore)
  },
  computed: {
    showMoreStyle() {
      if (this.showMore === true) {
        console.log('====>',this.tagOffsetHeight);
        return {
          height: `${this.tagOffsetHeight}px`,
          overflow: 'hidden',
        }
      } else {
        return {}
      }
    },
  },
  methods: {
    getLabels() {
      listCandidateLabel({ jobSeekerChannelId: this.jobseekerChannelId }).then(res => {
        if (res && res.code === 0) {
          this.tags = res.data || []
          this.computedShowMore()
        }
      })
    },
    computedShowMore() {
      this.$nextTick(() => {
        if (this.tags && this.tags.length) {
          const labels = document.getElementsByClassName('labels')[0]
          const labelTag = document.getElementsByClassName('label-tag')[0] || document.getElementsByClassName('red-tag')[0]
          console.log('labels,', labels, labelTag)
          // this.tagOffsetHeight = labelTag && labelTag.offsetHeight
          this.tagOffsetHeight = labelTag && labelTag.offsetHeight-3
          // console.log('labels.labelTag:', labels.offsetHeight, labelTag.offsetHeight)
          console.log(labelTag.style)
          if (labels && labelTag && labels.offsetHeight > this.tagOffsetHeight) {
            this.showMore = true
          } else {
            this.showMore = null
          }
        }
        // console.log(this.showMore === null)
      })
    },
  },
  watch: {
    interviewResult: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.jobseekerChannelId) {
          this.getLabels()
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.resume-tags {
  display: flex;
  align-items: baseline;
  margin-top: 10px;
  .left {
    width: fit-content;
    white-space: nowrap;
  }
  .labels {
    flex: auto;
    display: flex;
    justify-items: flex-start;
    flex-wrap: wrap;
    // padding-left: 8px;
    position: relative;
    padding-right: 28px;
    .more {
      position: absolute;
      right: 0px;
      top: 3px;
      cursor: pointer;
    }
    .label-tag {
      .label-name {
        border-radius: 3px;
        border: 1px solid #8996bf;
        padding: 2px 10px;
        // line-height: 18px;
        // height: 22px;
        margin-bottom: 5px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        min-height: 14px;

        span {
          font-size: 12px;
          // line-height: 12px;
        }
      }
      .red-tag {
        border-color: #ff4848;
        color: #ff4848;
      }
    }
    .isExpand{
      opacity: 1;
    }
    .isUnexpand{
      opacity: 0;
    }
  }
}
</style>

export default {
  report: {
    login: {
      text001: '请输入报告密码',
      text002: '报告密码',
      text003: '登录',
      text004: '点击查看候选人AI全息简历',
      text005: 'AI得贤招聘官',
      text006: '确认登出账号？',
      text007: '退出成功',
      text008: '密码错误',
      text009: '请输入报告密码',
      text010: '报告密码格式不正确',
      text011: '登录账号：{name}，点击退出登录',
      text012: '手机号',
      text013: '请填写手机号',
      text014: '密码',
      text015: '请填写密码',
      text016: '提交',

      text017: '请选择用户',
      text018: '输入邮箱或姓名快速检索',
      text019: '已选择{0}人',
      text020: '去推荐',
      text021: '备注',
      text022: '请添加备注（非必填）',
      text023: '确认',
      text024: '请选择用户',
      text025: '超级管理员',
      text026: '管理员',
      text027: 'HR',
      text028: '面试官',
      text029: '只读',
      text030: '冻结',
      text031: '协作者',
      text032: '发布者',
    },
  },
}

<template>
  <div class="question-content" :style="style">
    <pre v-if="isParsehtml" ref="contentRef" v-parsehtml="content" />
    <pre v-else ref="contentRef" v-html="content" />
    <div v-if="maxHeight < height" class="question-content-arrow" @click="toExpand">
      <i class="icon-arrow el-icon-d-arrow-left" :class="{ expand: isExpand }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    isParsehtml: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxHeight: 170,
      height: 0,
      isExpand: false,
    }
  },
  computed: {
    style() {
      if (this.isExpand) {
        return { height: 'auto' }
      } else {
        return {
          height: this.maxHeight < this.height ? '170px' : 'auto',
          'padding-bottom': this.maxHeight < this.height ? '32px' : '0',
        }
      }
    },
  },
  watch: {
    content() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const content = this.$refs.contentRef
      if (content) {
        this.height = content.offsetHeight
      }
    },
    toExpand() {
      this.isExpand = !this.isExpand
    },
  },
}
</script>

<style lang="scss" scoped>
.question-content {
  position: relative;
  // margin-bottom: 12px;
  overflow: hidden;

  pre {
    white-space: pre-wrap;
  }

  .question-content-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    width: 100%;
    height: 32px;
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, #fff 85%);
    cursor: pointer;
    .icon-arrow {
      font-size: 16px;
      font-weight: bold;
      color: #409eff;
      transform: rotate(270deg);
      transition: all 0.3s;

      &.expand {
        transform: rotate(-270deg);
      }
    }
  }
}
</style>

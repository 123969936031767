import { getOpenId } from '@/api/open'

let appid = ''
let REDIRECT_URI = encodeURIComponent('https://hr.aidexianzhaopinguan.com/wxCallback2.html')

if (process.env.NODE_ENV !== 'production') {
  //测试区
  appid = 'wx11921b61f1137d6f'
  REDIRECT_URI = encodeURIComponent('https://mini.aidexianzhaopinguan.com/wxCallback2.html')
} else {
  //正式区
  appid = 'wx612eebeda5ad3b55'
}

//const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端

function getCodeUrl() {
  console.log(process.env.VUE_APP_API_ROOT)
  const state = encodeURIComponent(btoa(window.location.href))
  const codeUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`

  window.location.href = codeUrl
}

export const wechatInit = async () => {
  const url = window.location.href
  const code = new URL(url).searchParams.get('code')
  if (code) {
    // const code = url.split('CODE')[1]
    const res = await getOpenId({ code: code })
    if (res.code === 0) {
      localStorage.setItem('openId', res.data.openId)
      window.location.href = url
    } else {
      alert('请刷新重试' + res.msg)
      //getCodeUrl()
    }
  } else {
    getCodeUrl()
  }
}

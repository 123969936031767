export default {
  text001: '回答',
  text002: '您正在使用非WIFI网络，播放将产生流量费用',
  text003: '候选人没有录制视频',
  text004: '考核胜任力',
  text005: 'L4-AI得分',
  text006: 'AI得分',
  text007: '生成中',
  text008: '收起',
  text009: '查看详情',
  text010: '{name}打分为:{score}',
  text011: '{name}评价为:{score}',
  text012: '考核胜任力',
  text013: '定义',
  text014: '暂无维度',
  text015: '暂无视频题',
  text015_: '未配置视频题目',
  text016: '论述题',
  text017: '视频题',
  text018: '单选题',
  text019: '多选题',
  text020: '判断题',
  text021: '排序题',
  text022: '口音题',
  text023: '编程题',
  text024: 'L2-普通话',
  text025: 'L2-英语',
  text026: '测试候选人普通话水平能力，以中文识别候选人回答内容。',
  text027: '测试候选人英语水平能力，以英文识别候选人回答内容。',
  text028: '此视频暂无法播放，请稍后再试',
  text029: '提交成功',
  text030: '提交失败',
  text031: '通过',
  text032: '拒绝',
  text033: '待定',
  text034: '正确',
  text035: '错误',
  text036: '回答正确',
  text037: '回答不全',
  text038: '回答错误',
  text039: '候选人尚未回答该题',
  text040: '客观题',
  text041: '正确答案',
  text042: '暂无其它面试记录',
  text045: '展开',
  text046: 'TA的回答',
  text047: 'AI追问',
  text048: 'AI追问原因',
  text049: '文本相似度（精准）',
  text050: '文本相似度（模糊）',
  text51: '欠佳',
  text52: '中等',
  text53: '优秀',
  text54: '更多详情',
  text55: '收起详情',
  text56: '包括理据标示',
}

<template>
  <div class="interview-record-content">
    <div ref="AiReport">
      <template v-if="isFinished">
        <div class="moduleTitle mt16">
          <img class="logou" src="@/assets/header-logo.jpg" alt="">
          <span>{{ $t('com.report.reportV3.text040') }}</span>
          <img class="un_fold" :class="{ 'roate180': isfold}" src="@/assets/fold_un.png" alt="" @click="unHfold">
        </div>
        <Summary v-show="tabIndex === 1 && !isfold" />
      </template>
      <div class="moduleTitle" v-if="isShowMoudle">
        <img class="logou" src="@/assets/header-logo.jpg" alt="">
        <span>{{ $t('com.report.reportV3.text144') }}</span>
        <img class="un_fold" :class="{ 'roate180': isfold0}" src="@/assets/fold_un.png" alt="" @click="unHfold0">
      </div>
      <VideoInterviewRecord v-show="tabIndex === 1 && !isfold0 && isShowMoudle" />

      <div class="tattooResults" v-if="interviewResult.tattooResults.unqualified.length">
        <div class="moduleTitle">
          <img class="logou" src="@/assets/header-logo.jpg" alt="">
          <span>{{ $t('com.report.onlinetest.text043') }}</span>
          <img class="un_fold" :class="{ 'roate180': isfold2}" src="@/assets/fold_un.png" alt="" @click="unHfold2">
        </div>

        <div v-show="!isfold2" v-for="(item,index) in interviewResult.tattooResults.unqualified" v-if="item.status !=2">
          <div class="tattooResults_content">
            <div>
              <span style="font-weight: 500">{{ item.type }}：</span>
              <span style="color: #FFCA3A;">
                {{ item.status == -1?$t('com.report.reportV3.text004'):
                item.status == 0?$t('com.report.onlinetest.text035'):
                $t('com.report.onlinetest.text036') }}
              </span>
            </div>
            <div v-if="item.desc.length>0">
              <span style="color: #999;">{{ $t('com.report.onlinetest.text042') }}：</span>
              <span style="font-weight: 500">{{ item.desc.join('；') }}</span>
            </div>
          </div>
          <div style="display: flex;flex-wrap: wrap;">
            <template v-for="val in item.url">
              <video-player
                v-if="val.video_url || val.video_url_mp4"
                class="player vjs-big-play-centered"
                style="margin-top: 10px;"
                :playsinline="true"
                @play="onPlay"
                :options="
                  getPlayerOption(
                    (val.video_url || val.video_url_mp4),
                    val.video_screenshot_url
                  )
                "
              ></video-player>
              <img
                v-else
                style="width:31%;margin: 1%;"
                :src="val"
                fit="contain"
              />  
            </template>
              
          </div>
        </div>
      </div>


      <template v-if="showAiDimenAnaly && isFinished && interviewResult.haveVideoQuestion === 1 && isL2ScoreStop">
        <!-- AI维度分析 -->
        <div class="moduleTitle">
          <img class="logou" src="@/assets/header-logo.jpg" alt="">
          <span>{{ $t('com.report.reportV3.text145') }}</span>
          <img class="un_fold" :class="{ 'roate180': isfold1}" src="@/assets/fold_un.png" alt="" @click="unHfold1">
        </div>
        <SoftSkills v-show="tabIndex === 1 && !isfold1" />
      </template>
      <VoiceResult :aiData="aiData" v-show="aiData.haveise && tabIndex === 1 && isJdCompanyId & false" />
      <div class="aigrade" v-if="showAIInterviewGrade">
        <div class="agtle">{{ $t('com.report.reportV3.text_aigd') }}</div>
        <div>
          <div class="agtag">L5</div>
          <div class="agtit">{{ $t('com.report.reportV3.text_l5') }}</div>
        </div>
        <div>
          <div class="agtag">L4</div>
          <div class="agtit">{{ $t('com.report.reportV3.text_l4') }}</div>
        </div>
        <div>
          <div class="agtag">L3</div>
          <div class="agtit">{{ $t('com.report.reportV3.text_l3') }}</div>
        </div>
        <div>
          <div class="agtag">L2</div>
          <div class="agtit">{{ $t('com.report.reportV3.text_l2') }}</div>
        </div>
      </div>
      <CopyrightNotice v-show="tabIndex === 1" />
    </div>
    <div ref="VideoInterviewRecord">
      <VideoInterviewRecord v-if="tabIndex === 0" />
    </div>
    <div ref="OtherQuestionRecord">
      <OtherQuestionRecord v-if="tabIndex === 2" />
    </div>
    <div></div>
  </div>
</template>
<script>
import EventBus from '@/common/event-bus'
import VideoInterviewRecord from '@/components/reportComponents/VideoInterviewRecord/index'
import OtherQuestionRecord from '@/components/reportComponents/OtherQuestionRecord'
import SoftSkills from '@/components/reportV3/soft-skills.vue'
import Summary from '@/components/reportV3/summary.vue'
import CopyrightNotice from '@/components/reportV3/common/copyright-notice.vue'
import VoiceResult from '@/components/reportV3/voice-result.vue'
import i18n from '@/utils/i18n'

export default {
  props: { scrollTop: { type: Number }, activeIndex: { type: Number }, tabBarHeight: { type: Number }, baseInfoHeight: { type: Number } },
  components: { VideoInterviewRecord, OtherQuestionRecord, SoftSkills, Summary, CopyrightNotice, VoiceResult },
  computed: {
    aiData() {
      return this.$store.state.aiData
    },
    token() {
      return this.$store.state.token
    },
    interviewId() {
      return this.$store.state.interviewId
    },
    isJdCompanyId() {
      const companyId = this.$store.state.companyId
      return this.$store.state.jdCompanyId.includes(companyId)
    },
    isL2ScoreStop() {
      return this.$store.state.interviewMsg.isL2ScoreStop !=1
    },
    interviewResult() {
      return this.$store.state.interviewResult
    },
    answerResult() {
      console.log('this.$store.state.answerResult',this.$store.state.answerResult)
      return this.$store.state.answerResult
    },
    isFinished() {
      return this.interviewResult?.interview_status === 'finish'
    },
    showAiDimenAnaly() {
      // return !!this.$store.state.aiReportCfg.find(e => e.code === 'report_competency_score_reason')?.isShow
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 11)
      return config?.inUse === 0 ? false : true
    },
    showAIInterviewGrade() {
      // return !!this.$store.state.aiReportCfg.find(e => e.code === 'report_percent')?.isShow
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 18)
      return config?.inUse === 0 ? false : true
    },
    questionList() {
      return this.answerResult.filter(r => {
        const info = Array.isArray(r) ? r[0] : r
        return [1, 6].includes(+info.question?.problem_answer_method)
      })
    },
    isShowMoudle() {
      //有肢体检测题目
      let hasVideoQues12 = this.answerResult.filter(r => {
        const info = Array.isArray(r) ? r[0] : r
        return [12, 13].includes(+info.question?.problem_answer_method)
      }).length
      if(hasVideoQues12 > 0){
        if(this.questionList.length > 0) return true
        return false
      }else{
        return true
      }
    },
  },
  beforeDestroy() {
    EventBus.$off('updateActiveIndex', this.tapUpdateActiveIndex)
  },
  created() {
    EventBus.$on('updateActiveIndex', this.tapUpdateActiveIndex)
  },
  data() {
    return {
      videoInterviewRecordHeight: 0,
      tabIndex: 1,
      isfold: false,
      isfold0: false,
      isfold1: false,
      isfold2: false,
    }
  },
  methods: {
    tapUpdateActiveIndex(val) {
      this.tabIndex = val
      // if (val === 0) {
      //   document.documentElement.scrollTop = 0
      //   document.body.scrollTop = 0
      // } else if (val === 1) {
      //   this.videoInterviewRecordHeight = this.$refs.VideoInterviewRecord.scrollHeight
      //   document.documentElement.scrollTop = this.videoInterviewRecordHeight + this.baseInfoHeight + 5
      //   document.body.scrollTop = this.videoInterviewRecordHeight + this.baseInfoHeight + 5
      // }
    },
    unHfold() {
      this.isfold = !this.isfold
    },
    unHfold0() {
      this.isfold0 = !this.isfold0
    },
    unHfold1() {
      this.isfold1 = !this.isfold1
    },
    unHfold2() {
      this.isfold2 = !this.isfold2
    },
    getPlayerOption(url, poster) {
      return {
        width: '100%',
        height: 165,
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{ type: 'video/mp4', src: url }],
        poster: poster , //require('./images/banner-poster.jpg'), // 封面地址
        notSupportedMessage: i18n.t('com.report.interviewRecord.text028'), // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      }
    },
    onPlay() {
      const videoArray = document.getElementsByTagName('video')
      if (videoArray && videoArray.length) {
        for (let i = 0; i < videoArray.length; i++) {
          videoArray[i].onplay = () => {
            for (let j = 0; j < videoArray.length; j++) {
              if (i !== j) {
                videoArray[j].pause()
              }
            }
          }
        }
      }
    },
  },
  watch: {
    // scrollTop: {
    //   handler: function(val) {
    //     this.videoInterviewRecordHeight = this.$refs.VideoInterviewRecord.offsetHeight
    //     if (val >= this.videoInterviewRecordHeight + this.baseInfoHeight) {
    //       this.$emit('updateShowActiveIndex', 1)
    //     } else {
    //       this.$emit('updateShowActiveIndex', 0)
    //     }
    //   },
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.videoInterviewRecordHeight = this.$refs.VideoInterviewRecord.offsetHeight
    })
  },
}
</script>
<style lang="scss" scoped>
.moduleTitle {
  height: 48px;
  margin-top: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F6F7F8;
  .logou {
    width: 23px;
    height: 23px;
    margin-right: 5px;
  }
  span {
    font-size: 24px;
    font-weight: 500;
    color: #1890FF;
  }
  .un_fold {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    cursor: pointer;
  }
  .roate180 {
    transform: rotate(180deg);
  }
}
.mt16 {
  margin-top: 16px;
}
.aigrade {
  margin-top: 16px;
  padding: 16px;
  background: #f3f9fe;
  border-radius: 8px;
  .agtle {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    text-align: center;
  }
  .agtag {
    width: 32px;
    height: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // line-height: 20px;
    // text-align: center;
    border-radius: 4px;
    background: linear-gradient(90deg, #35c3ff 0%, #1890ff 100%);
  }
  .agtit {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin: 4px 0px 8px;
  }
}
.tattooResults{
  margin-top:20px;
  .tattooResults_content{
    font-size: 14px;
    line-height: 24px;
    margin-top: 16px;
  }
  .videoScreenshotUrl {
    height: 144px;
    width: 96px;
    background:#ccc;
    position: relative;
    border-radius: 8px;
    margin-right: 10px;
    z-index: 1;
    cursor: pointer;
    .caret {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 24px;
      line-height: 24px;
      background: #666;
      border-radius: 0px 0px 8px 8px;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #fff;
      i {
        font-size: 14px;
        margin-right: 3px;
      }
    }
  }
}
</style>

export default {
  text001: '认知能力测评',
  text002: '得贤图形推理测验是一种非言语智力测验,其主要评估候选人的观察能力、问题解决能力,以及思维能力。具体来说，本测试评估的是候选人：',
  text003: '• 从大量模糊，不完整的信息当中，迅速识别有利于决策的信息的能力；',
  text004: '• 在获取各种信息后能够对信息进行整理、归类和筛选，对事物的性质、内涵、状态等形成清晰认识的能力；',
  text005: '• 在面对复杂问题时，保持头脑清楚，并灵活应对的能力。',
  text006:
    '得贤图形推理测试的得分能够有效预测候选人在某些职位上未来的工作绩效。这类职位通常需要候选人具备清晰而又准确地思维能力，能够准确识别问题发生原因的能力，以及能够利用已用信息制定最优决策的能力。这些职位包括但不仅限于：管理岗，技术人员，咨询人员等。',
  text007: '{name}百分位成绩为:{score}',
  text008: '分数解读',
  text009: '没有内容可以查看',
  text010: '{name}百分位成绩为:',
}

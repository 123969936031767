import 'babel-polyfill'
import VConsole from 'vconsole'
// new VConsole()
if (process.env.NODE_ENV !== 'production') {
  new VConsole()
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/animate.css'
import 'lib-flexible/flexible.js'
import 'element-ui/lib/theme-chalk/index.css'
import VDrag from './assets/js/drag-directive'
import { wechatInit } from '@/utils/wechatConfig'
import { getAuthInfo } from '@/api/open'
import { getHytCompanyId } from '@/api/enterprise'
import VuePreview from 'vue-preview'
import { previewOption } from '@/assets/js/keyToName.js'
import VideoPlayer from 'vue-video-player'
import i18n from './utils/i18n'
import { Locale } from 'vant'

window.videojs = VideoPlayer.videojs
require('video.js/dist/lang/zh-CN.js')
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

Vue.use(VuePreview, previewOption)

Vue.directive('drag', VDrag)

const sessionLang = sessionStorage.getItem('lang')
const localMap = {
  cn: 'zh-CN',
  en: 'en-US',
  'zh-TW': 'zh-TW',
}
if (!sessionLang) {
  const lang = store.state.lang
  sessionStorage.setItem('lang', lang)
  store.commit('setLanguage', lang)
  i18n.locale = lang
  Locale.use(localMap[lang], i18n.messages[lang])
} else {
  store.commit('setLanguage', sessionLang)
  i18n.locale = sessionLang
  Locale.use(localMap[sessionLang], i18n.messages[sessionLang])
}

// if (process.env.NODE_ENV !== 'production') {
//   new VConsole()
// }

// if (process.env.NODE_ENV !== 'production') {
//   const src = '//cdn.jsdelivr.net/npm/eruda'
//   document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>')
//   document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>')
// }

// let href = window.location.href

// if (href.indexOf('https://www.') !== -1) {
//   window.location.href = href.replace('https://www.', 'https://')
// }
Vue.config.productionTip = false
Vue.use(Vant)

//判断是否微信浏览器
function isWeixin() {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('micromessenger') !== -1) {
    return true
  } else {
    return false
  }
}
function getSearchParam(key) {
  const url = decodeURIComponent(window.location.href)
  const index = url.indexOf('?') < 0 ? url.length : url.indexOf('?')
  const search = url.substring(index)
  const searchParams = new URLSearchParams(search)
  const companyId = searchParams.has(key) ? searchParams.get(key) : ''
  return companyId || store.state.companyId
}

function addScript(src) {
  //手动添加script标签
  let script = document.createElement('script')
  script.type = 'text/JavaScript'
  script.src = src
  document.getElementsByTagName('html')[0].appendChild(script)
}

function createVue() {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}
// 不需要微信认证的路径
const paths = [
  '/',
  '/product/aiinterview',
  '/product/coding',
  '/product/recruitment',
  '/case',
  '/mandatePresentation',
  '/solution',
  '/about',
  '/vidio',
  '/real-ai',
  '/face',
  '/terms',
  '/eye',
  '/sharereport',
  '/newShareReport',
  '/interview',
  '/mail-report',
  '/tryout',
  '/emailFeedback',
  '/schedule-an-interview',
  '/schedule-an-interview/success-reserve',
  '/schedule-an-interview/feedback-success',
  '/schedule-an-interview/confirm-reserve',
  '/welfareActivities',
  '/auth',
  '/privacy',
  '/idcollect',
  '/idcheck',
  '/product/recruitment',
  '/product/OnlineTest',
  '/product/aiinterview',
  '/product/remoteInterview',
  '/product/coding',
  '/join',
  '/join/list/1',
  '/join/list/2',
  '/join/list/3',
  '/join/list/4',
  '/price',
  '/interview-satisfaction',
  '/team',
  '/aigc',
]

// pc独有的路径
const pcPaths = [
  '/product/recruitment',
  '/product/OnlineTest',
  '/product/aiinterview',
  '/product/remoteInterview',
  '/product/coding',
  '/join',
  '/join/list/1',
  '/join/list/2',
  '/join/list/3',
  '/join/list/4',
  '/price',
  // '/aigc',
]

const isDingtalk = process.env.VUE_APP_ENV === 'dingtalk'
const pathname = window.location.pathname
const companyId = getSearchParam('companyId')
let hytCompanyId = store.state.hytCompanyId
const isOutSideHyt = getSearchParam('isHyt')
let isHyt = hytCompanyId.includes(companyId) || (isOutSideHyt && +isOutSideHyt === 1)
let documentTitle = isHyt ? '海云天科技' : isDingtalk ? '得贤招聘' : 'AI得贤招聘官'
console.log('isHyt', isHyt)

function editMeta() {
  document.querySelector('meta[name="twitter:site"]').setAttribute('content', documentTitle)
  document.querySelector('meta[property="og:title"]').setAttribute('content', documentTitle)
}

function addLink() {
  //手动添加script标签
  const origin = window.location.origin
  const link = document.createElement('link')
  link.rel = 'icon'
  link.href = isHyt ? `${origin}/hyt_favicon.ico` : `${origin}/favicon.ico`
  document.head.appendChild(link)
}

function notWxPath(pathname) {
  return paths.find(path => {
    return path === pathname || path + '/' === pathname
  })
}
function isPcPath(pathname) {
  return pcPaths.find(path => {
    return path === pathname || path + '/' === pathname
  })
}

getHytCompanyId()
  .then(res => {
    if (res && res.code === 0) {
      store.commit('SET_HYTCOMPANYID', res.data)
      hytCompanyId = res.data || store.state.hytCompanyId
    }
  })
  .finally(() => {
    isHyt = hytCompanyId.includes(companyId) || (isOutSideHyt && +isOutSideHyt === 1)
    console.log('isHyt', isHyt)
    documentTitle = isHyt ? '海云天科技' : isDingtalk ? '得贤招聘' : this.$route.query?.isFrom && this.$route.query?.isFrom === 'miniapp' ? 'AI猫渴模拟面试' : 'AI得贤招聘官'
    if (notWxPath(pathname)) {
      documentTitle = isHyt ? documentTitle : 'AI得贤招聘官'
    }
    document.title = documentTitle
    addLink()
  })

async function init() {
  if (isDingtalk) {
    console.log('created')

    editMeta()
    // insertMeta('', 'twitter:site')
    createVue()
  } else {
    addScript('http://res.wx.qq.com/open/js/jweixin-1.6.0.js')
    // if (pathname !== '/sharereport' && pathname !== '/newShareReport' && pathname !== '/interview' && pathname !== '/') {
    if (notWxPath(pathname)) {
      //如果不需要微信认证的路径
      createVue()
      // pc和手机共有的路径
      const jumpPath = ['/', '/privacy', '/product/recruitment', '/product/aiinterview', '/product/remoteInterview', '/product/coding', '/solution', '/case', '/about', '/mandatePresentation', '/aigc']
      const origin = process.env.NODE_ENV === 'production' ? 'http://www.airecruitas.com' : 'https://mini.aidexianzhaopinguan.com/hp'

      if (!isMobile() && jumpPath.includes(pathname)) {
        //  && process.env.NODE_ENV === 'production'
        // https://mini.aidexianzhaopinguan.com/hp/
        const href = origin + pathname
        window.location.href = href
        return
      }
      if (!isMobile() && isPcPath(pathname)) {
        location.href = origin
        return
      }

      if (isPcPath(pathname)) {
        // 如果是pc独有的路径直接跳转到首页
        const mHref = process.env.NODE_ENV === 'production' ? 'https://m.airecruitas.com/' : 'http://mtest.aidexianzhaopinguan.com/'
        location.href = mHref
        return
      }
    } else {
      // document.title = isHyt ? documentTitle : 'AI得贤小助手'
      store.commit('setGoWechat', true)
      //process.env.NODE_ENV === 'production'
      if (window.location.href.indexOf('localhost') === -1 && !location.href.includes('192.168.')) {
        //如果不是微信浏览器
        if (!isWeixin()) {
          alert('请用微信浏览器打开')
        } else {
          if (localStorage.getItem('openId')) {
            const res = await getAuthInfo({ openId: localStorage.getItem('openId') })
            if (res.code === 0) {
              store.commit('setUserInfo', res.data)
              store.commit('setShowAiVideo', res.data.isShowAiVideo)
              if (res.data.token) {
                const search = window.location.search
                if (search.indexOf('redirect=chatbot') > -1) {
                  window.location.href = `${process.env.VUE_APP_MOLL_ORG}?token=${res.data.token}`
                }
              }
            }
            createVue()
          } else {
            wechatInit()
          }
        }
      } else {
        //测试的时候
        localStorage.setItem('token', 'fgc6OkMGF5')
        //徐钦正式区的openId localStorage.setItem('openId', 'oWlpp0i_iN9z4hW_3Pc2_fnTBP18')
        //19800000002 测试区的openId oXGSk5lyx3aUycr_kD9xNenYcZAs
        //19900000010 测试区的openId oXGSk5nR7iEKzO2L6LXm1aVqsp1E
        // oXGSk5ohH0HVHCAMxfn35NNSxuOE  19800000001
        //oXGSk5sAoyc5bdl_RJc3nQIrs824  宋磊 17321346370
        // oXGSk5nR7iEKzO2L6LXm1aVqsp1E 19900000010招商
        //oWlpp0uiOly7rjAlhPVDlcmhIuLA 宋磊 17321346370 正式
        localStorage.setItem('openId', 'oXGSk5sAoyc5bdl_RJc3nQIrs824')
        //平姐的  正式区 oWlpp0rz4sMOM0jKXWJxjPos2oio
        //localStorage.setItem('openId', ' oXGSk5utTCEzTYR-So_GuwN-uVtw')
        const res = await getAuthInfo({ openId: localStorage.getItem('openId') })
        if (res.code === 0) {
          store.commit('setUserInfo', res.data)
          store.commit('setShowAiVideo', res.data.isShowAiVideo)
          localStorage.setItem('token', res.data.token)
        }
        createVue()
      }
    }
  }
}

init()

function isMobile() {
  return /(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(navigator.userAgent.toLowerCase())
}

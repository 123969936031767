import service from '@/config/http'
import qs from 'qs'

export const getLabelWork = data => {
  const req = {
    url: '/rpa/label/getLabelWork',
    params: data,
  }
  return service(req)
}

export const getLabelEducation = data => {
  const req = {
    url: '/rpa/label/getLabelEducation',
    params: data,
  }
  return service(req)
}

export const getJobseekerLabel = data => {
  const req = {
    url: '/rpa/label/getJobseekerLabel',
    params: data,
  }
  return service(req)
}

/**
 * 获取标签
 * @param {*} data
 * @returns
 */
export const listCandidateLabel = data => {
  return service({
    url: '/rpa/label/listCandidateLabel',
    method: 'POST',
    data: qs.stringify(data),
  })
}
/**
 * 获取标签
 * @param {*} sourceUrl
 * @returns
 */
export const getPreviewUrl = data => {
  const req = {
    url: '/rpa/resume/resumePreview/getPreviewUrl',
    params: data,
  }
  return service(req)
}

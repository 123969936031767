<template>
  <div>
    <Header></Header>

    <div class="price-main">
      <div class="price-title">套餐选择</div>
      <div class="tip">可以根据您团队规模与业务需求，选择相应套餐</div>
      <div class="price-edition">
        <div v-for="(e, index) in editions" :key="index" class="edition">
          <div class="edition-header" :style="{ background: e.background }">
            <div class="edition-title">{{ e.eTitle }}</div>
            <div class="edition-tip">{{ e.eTip }}</div>
            <img class="edition-jiaobiao" src="@/assets/images/five-years/jiaobiao-5.svg" />
          </div>
          <div class="edition-action">
            <div class="price">
              <span>￥</span>
              <span class="eprice">{{ e.ePrice }}</span>
              <span>{{ e.eUnit }}</span>
            </div>
            <div class="price-tip" :style="{ color: e.type === 1 ? '#1990FF' : '' }">{{ e.ePriceTip }}</div>
            <div class="pirce-btn" @click="handleClick(e.type)">{{ e.btnText }}<img src="@/assets/price/icon_arrow.svg" /></div>
            <div class="line"></div>
          </div>
          <div class="edition-content">
            <div v-for="(c, i) in e.eContents" :key="i" class="item">
              <div class="label">{{ c.label }}</div>
              <div class="value"><img :src="c.vIcon" />{{ c.value }}</div>
            </div>
          </div>
          <div v-if="e.eExplain" class="edition-explain">
            <div class="ee-title">{{ e.eExplain.title }}</div>
            <div class="ee-info">{{ e.eExplain.tip1 }}</div>
            <div class="ee-info">
              {{ e.eExplain.tip2 }}
            </div>
          </div>
          <div v-if="e.eOthers" class="edition-other">
            {{ e.eOthers }}
          </div>
        </div>
      </div>
      <div v-if="!showEdition" class="show-edition" @click="showEdition = !showEdition">
        <img :src="showEdition ? require('@/assets/price/icon_minus-circle.svg') : require('@/assets/price/icon_plus-circle.svg')" />
        <div>{{ `${showEdition ? '收起' : '查看'}版本功能对比` }}</div>
      </div>
      <EditionContrast v-if="showEdition" style="margin-bottom: 40px" />
      <div v-if="showEdition" class="show-edition" @click="showEdition = !showEdition">
        <img :src="showEdition ? require('@/assets/price/icon_minus-circle.svg') : require('@/assets/price/icon_plus-circle.svg')" />
        <div>{{ `${showEdition ? '收起' : '查看'}版本功能对比` }}</div>
      </div>

      <van-dialog v-model="showErQcode" append-to-body width="300px" :showConfirmButton="false" :closeOnClickOverlay="true">
        <div slot="title" style="font-weight: 600;">联系客服，申请免费试用</div>
        <div style="width:100%; text-align: center;padding-bottom:20px;">
          <img src="@/assets/price/ercode.png" width="200" />
        </div>
      </van-dialog>
    </div>
    <Tools ref="tools"></Tools>
  </div>
</template>
<script>
import EditionContrast from './edition-contrast.vue'
import Tools from '@/components/tools'
import Header from '@/components/menu'
export default {
  components: { EditionContrast, Tools, Header },
  data() {
    return {
      showEdition: false,
      showErQcode: false,
      editions: [
        {
          eTitle: '标准版本',
          type: 1,
          eTip: '适用于中小型企业和大型企业的事业部或业务单元，最多5个HR账号和50个面试官账号畅用。',
          ePrice: 0,
          eUnit: '/HR账号/年',
          ePriceTip: '免费使用',
          btnText: '立即开通',
          eExplain: {
            title: '注明（仅限标准版本）：',
            tip1: '（1）HR账号数量中包含超级管理员和管理账号，面试官账号数量中包含只读账号数量。',
            tip2: '（2）系统内的账号数据、职位发布数量以及邮箱账号数量都是累积量，不存在替换，按年计算的每年清零。',
          },
          eOthers: '',
          eContents: this.getContents(1).filter(item => {
            return item.show
          }),
          background: 'linear-gradient(225deg, #1990FF 0%, #36C3FF 100%)',
        },
        {
          eTitle: '高级版本',
          type: 2,
          eTip: '适用于大中型企业，提供无限量面试官账号，移动端招聘等一站式解决方案。',
          ePrice: 688,
          eUnit: '/HR账号/月',
          ePriceTip: '每个HR账号仅需￥22.93/天，￥7888/HR账号/年',
          btnText: '免费试用',
          eExplain: {
            title: '注明：',
            tip1:
              '（1）高级版本中赠送的L2级别AI面试、在线考试系统、在线编程考试、认知能力测试、职业性格测试、心理健康测试使用次数为100次/年，不会因为购买的HR账号数量不同而发生增减。如您的使用量大于赠送量，请联系客服人员单独购买，最低起购量500次/年。',
            tip2: '（2）若按月购买，则系统内不赠送100次L2级别AI面试、100次在线考试系统、100次在线编程考试、100次认知能力测试、100次职业性格测试、100次心理健康测试服务。',
          },
          eOthers: '',
          eContents: this.getContents(2).filter(item => {
            return item.show
          }),
          background: 'linear-gradient(225deg, #FE9F01 0%, #FFCD02 100%)',
        },
        {
          eTitle: '商业版本',
          type: 3,
          eTip: '适用于大中型企业，超稳定性的远程视频会议和猎头RPO管理系统等服务，并全面与生态合作伙伴融合打通。',
          ePrice: 888,
          eUnit: '/HR账号/月',
          ePriceTip: '每个HR账号仅需￥29.6/天，￥9888/HR账号/年',
          btnText: '免费试用',
          eExplain: {
            title: '注明：',
            tip1:
              '（1）商业版本中赠送的L2级别AI面试、在线考试系统、在线编程考试、认知能力测试、职业性格测试、心理健康测试使用次数为200次/年，不会因为购买的HR账号数量不同而发生增减，如您的使用量大于赠送量，请联系客服人员单独购买，最低起购量500次/年。',
            tip2: '（2）若按月购买，则系统内不赠送200次L2级别AI面试、200次在线考试系统、200次在线编程考试、200次认知能力测试、200次职业性格测试、200次心理健康测试服务。',
          },
          eOthers: '',
          eContents: this.getContents(3).filter(item => {
            return item.show
          }),
          background: 'linear-gradient(225deg, #002343 0%, rgba(0, 35, 67, 0.65) 100%)',
        },
      ],
    }
  },
  methods: {
    handleClick(type) {
      if (type === 1) {
        // this.$bus.$emit('TRIAL', '')
        this.$nextTick(() => {
          this.$refs.tools.visible = true
        })
      } else {
        this.showErQcode = true
        // this.$bus.$emit('TRIAL', '')
      }
    },
    getContents(type) {
      // type 1标准版本2高级版本3商业版本
      return [
        {
          label: 'HR账号数',
          value: type === 1 ? '3个' : type === 2 ? '按季度/年购买，5个起售' : '按季度/年购买，10个起售',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '面试官账号数',
          value: type === 1 ? '30个' : type === 2 ? '1000个' : '无限',
          lTip: '',
          lIcon: '',
          vIcon: type === 3 ? require('@/assets/price/icon_infinite.svg') : '',
          show: true,
        },
        {
          label: '接收简历邮箱数',
          value: type === 1 ? '5个' : type === 2 ? '100个' : '无限',
          lTip: '',
          lIcon: '',
          vIcon: type === 3 ? require('@/assets/price/icon_infinite.svg') : '',
          show: true,
        },
        {
          label: '简历容量',
          value: type === 1 ? '500M' : type === 2 ? '30G' : '无限',
          lTip: '',
          lIcon: '',
          vIcon: type === 3 ? require('@/assets/price/icon_infinite.svg') : '',
          show: true,
        },
        {
          label: 'RPA发布渠道数',
          value: type === 1 ? '10个' : '无限',
          lTip: '',
          lIcon: '',
          vIcon: type !== 1 ? require('@/assets/price/icon_infinite.svg') : '',
          show: true,
        },
        {
          label: '职位数',
          value: type === 1 ? '50个' : '无限',
          lTip: '',
          lIcon: '',
          vIcon: type !== 1 ? require('@/assets/price/icon_infinite.svg') : '',
          show: true,
        },
        {
          label: '已发布职位自动同步至系统',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: '一键发布简历库集合RPA模版插件',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: 'RPA维护更新邮件通知',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: 'AI简历解析',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: '免费腾讯视频面试',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: '内部推荐+移动招聘（模版）',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: '招聘官网模版（PC端+移动端）',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: true,
        },
        {
          label: 'L2级别AI面试（可单独内购）',
          value: type === 1 ? '50次' : type === 2 ? '100次' : '200次',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '在线考试系统（可单独内购）',
          value: type === 1 ? '50次' : type === 2 ? '100次' : '200次',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '在线编程考试（可单独内购）',
          value: type === 1 ? '50次' : type === 2 ? '100次' : '200次',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '认知能力测试（可单独内购）',
          value: type === 1 ? '50次' : type === 2 ? '100次' : '200次',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '职业性格测试（可单独内购）',
          value: type === 1 ? '50次' : type === 2 ? '100次' : '200次',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '数据导出服务',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type === 3,
        },
        {
          label: '心理健康测试（可单独内购）',
          value: type === 1 ? '50次' : type === 2 ? '100次' : '200次',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '短信服务（可单独内购）',
          value: type === 1 ? '500条' : type === 2 ? '5000条' : '15000条',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '邮件服务（可单独内购）',
          value: type === 1 ? '2000条' : type === 2 ? '10000条' : '20000条',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '客户服务',
          value: type === 1 ? '远程学习群直播培训答疑' : type === 2 ? '远程专业顾问标准实施服务' : '远程专业顾问标准实施服务',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '猎头RPO服务',
          value: type === 1 ? '普通会员价' : type === 2 ? '白金会员价' : '钻石会员价',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: true,
        },
        {
          label: '复杂面试项目排班系统',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type !== 1,
        },
        {
          label: '简历智能标签',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type !== 1,
        },
        {
          label: '招聘官网自定义开发（PC端+移动端）',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type !== 1,
        },
        {
          label: '人才库激活',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type !== 1,
        },
        {
          label: '历史简历数据迁移服务',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type !== 1,
        },
        {
          label: '支持定制化开发（按人天计算，详情咨询客服）',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type !== 1,
        },
        {
          label: '自定义logo（AI面试）',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type !== 1,
        },
        {
          label: '离职数据交接',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type !== 1,
        },
        {
          label: '猎头RPO管理系统',
          value: '',
          lTip: '',
          lIcon: '',
          vIcon: require('@/assets/price/icon_sel.svg'),
          show: type === 3,
        },
        {
          label: '付费腾讯视频面试',
          value: type === 3 ? '腾讯会议房间数等于付费HR账号数' : '',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: type === 3,
        },
        {
          label: '生态打通',
          value: type === 3 ? '可与钉钉、i人事、SuccessFactors、SAP HCM、上海外服、白金软件、北森、Moka生态打通。如有其他生态需要打通，可与我们联系。' : '',
          lTip: '',
          lIcon: '',
          vIcon: '',
          show: type === 3,
        },
      ]
    },
  },
}
</script>
<style lang="scss" scoped>
.price-main {
  color: #333333;
  background: #ffffff;
  background-image: url(~@/assets/price/h5_price_banner_bg@2x.png);
  background-size: 100% 540px;
  background-repeat: no-repeat;
  .price-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    margin-bottom: 16px;
    padding-top: 70px;
  }
  .tip {
    font-size: 16px;
    color: #333333;
    line-height: 32px;
    text-align: center;
    margin-bottom: 48px;
  }
  .price-edition {
    // display: flex;
    // justify-content: center;
    margin-bottom: 60px;
    .edition {
      background: #ffffff;
      box-shadow: 0 6px 20px 5px rgba(40, 120, 255, 0.1), 0 16px 24px 2px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      // width: 100%;
      margin: 0 10px;
      padding-bottom: 20px;
      -webkit-transition: all ease 300ms;
      -o-transition: all ease 300ms;
      transition: all ease 300ms;
      .edition-header {
        color: #ffffff;
        height: 188px;
        border-radius: 4px 4px 0px 0px;
        position: relative;
        .edition-title {
          font-size: 32px;
          font-weight: 600;
          color: #ffffff;
          line-height: 40px;
          margin-bottom: 14px;
          padding: 0 30px;
          padding-top: 32px;
          // margin-top: 32px;
          text-align: center;
        }
        .edition-tip {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          padding: 0 30px;
        }
        .edition-jiaobiao {
          position: absolute;
          top: -1px;
          right: -1px;
        }
      }
      .edition-action {
        text-align: center;
        .price {
          height: 48px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 48px;
          margin-top: 32px;
          margin-bottom: 12px;
          .eprice {
            font-size: 32px;
            font-weight: 600;
          }
        }
        .price-tip {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-bottom: 24px;
        }
        .pirce-btn {
          width: 200px;
          height: 48px;
          cursor: pointer;
          background: linear-gradient(270deg, #36c3ff 0%, #1990ff 100%);
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          margin: auto;
          img {
            margin-left: 8px;
          }
        }
        .line {
          height: 1px;
          margin: 40px 30px 24px 30px;
          background: #eeeeee;
        }
      }
      .edition-content {
        margin-bottom: 16px;
        .item {
          display: flex;
          justify-content: space-between;
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          padding: 0 10px 0 10px;
          .label {
            color: #555555;
            text-align: left;
            white-space: nowrap;
            margin-right: 10px;
            line-height: 24px;
          }
          .value {
            color: #333333;
            text-align: right;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .item + .item {
          margin-top: 10px;
        }
      }
      .edition-explain {
        // width: 360px;
        // height: 132px;
        background: #fafafa;
        border-radius: 2px;
        margin: 0 10px;
        .ee-title {
          font-size: 14px;
          font-weight: 600;
          color: #666666;
          line-height: 20px;
          padding: 12px 15px 5px 15px;
        }
        .ee-info {
          font-size: 12px;
          color: #666666;
          line-height: 20px;
          padding: 0px 15px 5px 15px;
        }
      }
      .edition-other {
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: #1990ff;
        line-height: 20px;
        margin-bottom: 32px;
      }
    }
    // .edition:hover {
    //   -webkit-box-shadow: 0 6px 20px 5px rgba(40, 120, 255, 0.1), 0 16px 24px 2px rgba(0, 0, 0, 0.05);
    //   box-shadow: 0 6px 20px 5px rgba(40, 120, 255, 0.1), 0 16px 24px 2px rgba(0, 0, 0, 0.05);
    //   -webkit-transform: translateY(-30px);
    //   transform: translateY(-30px);
    // }
    .edition + .edition {
      margin-top: 20px;
    }
  }
  .show-edition {
    width: 100%;
    height: 60px;
    background: #e8f3ff;
    border-radius: 4px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 80px;
    cursor: pointer;
    img {
      margin-right: 8px;
    }
  }
}
</style>

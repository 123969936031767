<template>
  <div>
    <div class="professional-character" v-if="showEcharts || (tableList && tableList.length) || (computedTotalText && computedTotalText.length)">
      <div class="occupational-character" v-if="showEcharts">
        <div class="character-title">{{ $t('com.report.professional.text001') }}</div>
        <div class="character-content">{{ $t('com.report.professional.text002') }}</div>
      </div>
      <div class="summary-note-wrapper" v-if="computedTotalText && computedTotalText.length">
        <div class="note-title">{{ $t('com.report.professional.text003') }}:</div>
        <div class="note-content">
          <div>
            {{
              $t('com.report.professional.text007', {
                name: (interviewResult && interviewResult.name) || $t('com.report.professional.text004'),
                ta: interviewResult && interviewResult.gender === 1 ? $t('com.report.professional.text005') : $t('com.report.professional.text006') || $t('com.report.professional.text004'),
              })
            }}
            <!-- 基于{{ (interviewResult && interviewResult.name) || '该候选人' }}在得贤职业性格测试中的回答，在日常情况下，{{
              interviewResult && interviewResult.gender === 1 ? '她' : '他' || '该候选人'
            }}倾向于： -->
          </div>
          <ul>
            <li v-for="(item, index) in computedTotalText" :key="index">
              <span> • </span>{{ item && item.replace(/XXX/g, (interviewResult && interviewResult.name) || $t('com.report.professional.text004')) }}
            </li>
          </ul>
        </div>
      </div>
      <div class="eCharts-content">
        <div id="eCharts-wrapper" v-show="showEcharts"></div>
      </div>
      <div class="table-wrapper" v-if="tableList && tableList.length">
        <div v-for="(item, index) in tableList" :key="index" class="table-wrapper_item">
          <div class="dimension">{{ item.dimension }}</div>
          <ProcessWrapper :type="'ten'" :score="getScore(item)" :key="index" />
          <div class="character">
            <div class="character-item">{{ item.lowCharacter }}</div>
            <div class="character-item" style="text-align: right">{{ item.highCharacter }}</div>
          </div>
          <div class="comment">{{ item.comment }}</div>
        </div>
      </div>

      <CopyrightNotice />
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
import * as echarts from 'echarts'
import CopyrightNotice from '@/components/reportV3/common/copyright-notice.vue'
import ProcessWrapper from '@/components/reportV3/common/process-wrapper.vue'
import Empty from '@/components/reportV3/common/empty.vue'

// @Component({})
export default {
  data() {
    return {
      tableList: [],
      totalText: [],
    }
  },
  components: { CopyrightNotice, ProcessWrapper, Empty },

  computed: {
    aiData() {
      return this.$store.state.aiData || {}
    },

    interviewResult() {
      return this.$store.state.interviewResult
    },

    computedTotalText() {
      return this.totalText
    },

    showEcharts() {
      const arr = Object.keys(this.aiData.characterScore || {})
      return arr && arr.length > 0
    },

    characterScore() {
      return this.aiData.characterScore
    },

    reportDim() {
      return this.$store.state.companyInfo?.reportDim
    },

    indicatorArray() {
      if (this.reportDim === 1) {
        return [
          { name: this.$t('com.report.professional.text009'), max: 10 },
          { name: this.$t('com.report.professional.text010'), max: 10 },
          { name: this.$t('com.report.professional.text011'), max: 10 },
          { name: this.$t('com.report.professional.text012'), max: 10 },
        ]
      }
      return [
        { name: this.$t('com.report.professional.text013'), max: 10 },
        { name: this.$t('com.report.professional.text014'), max: 10 },
        { name: this.$t('com.report.professional.text015'), max: 10 },
        { name: this.$t('com.report.professional.text016'), max: 10 },
        { name: this.$t('com.report.professional.text017'), max: 10 },
        { name: this.$t('com.report.professional.text018'), max: 10 },
        { name: this.$t('com.report.professional.text019'), max: 10 },
      ]
    },

    valueArray() {
      const characterScore = this.aiData.characterScore
      if (this.reportDim === 1) {
        return [
          Number((characterScore.duty / 10).toFixed(0)) || 1,
          Number((characterScore.curious / 10).toFixed(0)) || 1,
          Number((characterScore.harmonious / 10).toFixed(0)) || 1,
          Number((characterScore.adapt / 10).toFixed(0)) || 1,
        ]
      }
      return [
        Number((characterScore.social / 10).toFixed(0)) || 1,
        Number((characterScore.ambition / 10).toFixed(0)) || 1,
        Number((characterScore.duty / 10).toFixed(0)) || 1,
        Number((characterScore.curious / 10).toFixed(0)) || 1,
        Number((characterScore.autonomy / 10).toFixed(0)) || 1,
        Number((characterScore.harmonious / 10).toFixed(0)) || 1,
        Number((characterScore.adapt / 10).toFixed(0)) || 1,
      ]
    },

    tooltipStr() {
      if (!(this.valueArray && this.valueArray.length)) return ''
      if (this.reportDim === 1) {
        return [
          this.$t('com.report.professional.text009') + ': &nbsp;&nbsp;' + this.valueArray[0] + '%<br>',
          this.$t('com.report.professional.text010') + ': &nbsp;&nbsp;' + this.valueArray[1] + '%<br>',
          this.$t('com.report.professional.text011') + ': &nbsp;&nbsp;' + this.valueArray[2] + '%<br>',
          this.$t('com.report.professional.text012') + ': &nbsp;&nbsp;' + this.valueArray[3] + '%<br>',
        ].join('')
      } else {
        return [
          this.$t('com.report.professional.text013') + ': &nbsp;&nbsp;' + this.valueArray[0] + '%<br>',
          this.$t('com.report.professional.text014') + ': &nbsp;&nbsp;' + this.valueArray[1] + '%<br>',
          this.$t('com.report.professional.text015') + ': &nbsp;&nbsp;' + this.valueArray[2] + '%<br>',
          this.$t('com.report.professional.text016') + ': &nbsp;&nbsp;' + this.valueArray[3] + '%<br>',
          this.$t('com.report.professional.text017') + ': &nbsp;&nbsp;' + this.valueArray[4] + '%<br>',
          this.$t('com.report.professional.text018') + ': &nbsp;&nbsp;' + this.valueArray[5] + '%<br>',
          this.$t('com.report.professional.text019') + ': &nbsp;&nbsp;' + this.valueArray[6] + '%<br>',
        ].join('')
      }
    },

    radar() {
      return {
        indicator: this.indicatorArray,
        scale: true,
        axisTick: {
          //刻度设置
          show: true,
        },

        axisLine: {
          show: true,
          lineStyle: {
            color: '#B1D8FF',
          },
        },
        axisName: {
          show: true,
          color: '#333',
          fontWeight: 'blod',
        },
        splitNumber: 5, //刻度
        splitLine: {
          //刻度连接线
          show: false,
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)'],
          },
        },
      }
    },

    series() {
      return [
        {
          type: 'radar',
          label: {
            show: false,
          },
          data: [
            {
              value: this.valueArray,
              // [4, 4, 4, 4, 1],
              areaStyle: {
                // color: '#409EFF',
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(90, 230, 191, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(24, 144, 255, 1)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                color: 'rgba(90, 230, 191, 1)',
                opacity: 1,
              },
              itemStyle: {
                color: '#409EFF',
                // borderType: 'solid',
                // borderWidth: 1,
                // borderCap: 'round',
              },
            },
          ],
        },
      ]
    },
  },
  methods: {
    getScore(item) {
      let score = item.percent / 10
      if (score < 1) {
        score = 1
      }
      return Number(score.toFixed(0))
    },
    initReportText() {
      if (this.reportDim === 0) {
        this.totalText.push(this.characterScore.adaptComment)
        this.totalText.push(this.characterScore.ambitionComment)
        this.totalText.push(this.characterScore.autonomyComment)
        this.totalText.push(this.characterScore.curiousComment)
        this.totalText.push(this.characterScore.dutyComment)
        this.totalText.push(this.characterScore.harmoniousComment)
        this.totalText.push(this.characterScore.socialComment)
      } else {
        this.totalText.push(this.characterScore.adaptComment)
        this.totalText.push(this.characterScore.curiousComment)
        this.totalText.push(this.characterScore.dutyComment)
        this.totalText.push(this.characterScore.harmoniousComment)
      }
    },

    drawCharts() {
      this.$nextTick(() => {
        const dom = document.getElementById('eCharts-wrapper')
        if (!dom) return
        const myChart = echarts.init(dom,null,{
          width:350,
          height:260
        })
        const option = {
          radar: this.radar,
          series: this.series,
        }
        myChart && myChart.setOption(option)
      })
    },

    initTableList() {
      this.tableList = [
        {
          dimension: this.$t('com.report.professional.text013'),
          lowCharacter: this.$t('com.report.professional.text020'),
          highCharacter: this.$t('com.report.professional.text021'),
          percent: this.characterScore.social,
          comment: this.characterScore.socialComment,
        },
        {
          dimension: this.$t('com.report.professional.text012'),
          lowCharacter: this.$t('com.report.professional.text022'),
          highCharacter: this.$t('com.report.professional.text023'),
          percent: this.characterScore.adapt,
          comment: this.characterScore.adaptComment,
        },
        {
          dimension: this.$t('com.report.professional.text016'),
          lowCharacter: this.$t('com.report.professional.text024'),
          highCharacter: this.$t('com.report.professional.text025'),
          percent: this.characterScore.curious,
          comment: this.characterScore.curiousComment,
        },
        {
          dimension: this.$t('com.report.professional.text018'),
          lowCharacter: this.$t('com.report.professional.text026'),
          highCharacter: this.$t('com.report.professional.text027'),
          percent: this.characterScore.harmonious,
          comment: this.characterScore.harmoniousComment,
        },
        {
          dimension: this.$t('com.report.professional.text015'),
          lowCharacter: this.$t('com.report.professional.text028'),
          highCharacter: this.$t('com.report.professional.text029'),
          percent: this.characterScore.duty,
          comment: this.characterScore.dutyComment,
        },
        {
          dimension: this.$t('com.report.professional.text014'),
          lowCharacter: this.$t('com.report.professional.text030'),
          highCharacter: this.$t('com.report.professional.text031'),
          percent: this.characterScore.ambition,
          comment: this.characterScore.ambitionComment,
        },
        {
          dimension: this.$t('com.report.professional.text017'),
          lowCharacter: this.$t('com.report.professional.text032'),
          highCharacter: this.$t('com.report.professional.text033'),
          percent: this.characterScore.autonomy,
          comment: this.characterScore.autonomyComment,
        },
      ]

      if (this.reportDim === 1) {
        const keys = ['适应性', '好奇心', '和谐性', '责任心']
        this.tableList = this.tableList.filter(item => {
          return keys.includes(item.dimension)
        })
      }
    },
    filterColor(val) {
      if (val <= 25) {
        return '#FF0000'
      } else if (val <= 50) {
        return '#FFC000'
      } else if (val <= 75) {
        return '#92D050'
      } else {
        return '#00B050'
      }
    },

    filterColor2(val) {
      if (val <= 10) {
        return '#FF0000'
      } else if (val <= 90) {
        return '#92D050'
      } else {
        return '#00B050'
      }
    },
  },

  watch: {
    showEcharts: {
      immediate: true,
      handler() {
        if (this.showEcharts) {
          this.drawCharts()
          this.initTableList()
          this.initReportText()
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.professional-character {
  margin-top: 20px;
}
.bar-wrapper {
  margin: 0 auto;
  background-color: #f0f3f7;
  overflow: hidden;
  border-radius: 9px;
  position: relative;
  width: 100px;
  height: 16px;
  .bar-content {
    position: absolute;
    height: 16px;
    border-radius: 9px;
  }

  .blue {
    background-color: #14b1ff;
  }

  .red {
    background-color: #ff0000;
  }
}
.number {
  width: 100px;
  margin: 0 auto;
  font-size: 12px;
  color: #333333;
}
.eCharts-content {
  width: 100%;
  #eCharts-wrapper {
    box-sizing: border-box;
    width: 100%;
    margin: 1rem auto 0 auto;
    height: 300px;
    display: flex;
    justify-content: center;
    // background-color: #14b1ff;
  }
}
.summary-note-wrapper {
  // width: calc(100% - 20px);
  margin: 16px auto 0 auto;
  border: 1px dashed #d6dde8;
  color: #333333;
  padding: 15px 10px;
  font-size: 14px;
  border-radius: 5px;
  .note-title {
    font-size: 14px;
    font-weight: 600;
  }

  .note-content {
    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
    // ul {
    // 	// padding-left: 14px;
    // }
  }
}
.table-wrapper {
  .table-wrapper_item {
    border-bottom: 1px solid #dddddd;
    .dimension {
      font-size: 14px;
      font-weight: 600;
      color: rgba(51, 51, 51, 0.85);
      line-height: 20px;
    }
    .character {
      display: flex;
      justify-content: space-between;
      .character-item {
        max-width: 40%;
        font-size: 12px;
        font-weight: 400;
        color: #afb6ca;
        line-height: 17px;
        margin-top: 10px;
      }
    }
    .comment {
      font-size: 14px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.85);
      line-height: 20px;
      margin-top: 20px;
      padding-bottom: 29px;
    }
  }
  .table-wrapper_item + .table-wrapper_item {
    margin-top: 30px;
  }
}
.character-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 24px;
  margin-bottom: 10px;
}
.character-content {
  line-height: 24px;
  font-size: 14px;
  color: #333333;
}
</style>

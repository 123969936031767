import axios from 'axios'
import i18n from '@/utils/i18n.js'
import store from '@/store/index.js'
// import baseURL from '../config/host.js'
import { Notify, Dialog } from 'vant'
import dd from 'dingtalk-jsapi'

// let serviceLoading = false

const service = axios.create({
  timeout: 60000,
  baseURL: process.env.VUE_APP_API_ROOT,
  validateStatus: status => {
    // switch (status) {
    //   case 400:
    //     errorHandle({ status, message: '' })
    //     break
    //   case 401:
    //     errorHandle({ status, message: '授权失败，请重新登录' })
    //     break
    //   case 403:
    //     errorHandle({ status, message: '拒绝访问' })
    //     break
    //   case 404:
    //     errorHandle({ status, message: '请求错误, 未找到该资源' })
    //     break
    //   case 500:
    //     errorHandle({ status, message: '服务端错误' })
    //     break
    // }
    return status >= 200 && status < 300
  },
})

const reqInterceptors = request => {
  // serviceLoading && store.commit('LOADING', true)
  // console.log('request: ', request.url)
  request.headers['lang'] = i18n.locale || 'en'
  // console.log('store.state.userInfo.token', store.state.userInfo && store.state.userInfo.token)
  //请求头添加token
  if (store.state.userInfo && store.state.userInfo.token) {
    //request.headers['Authorization'] = `Bearer ${store.state.user.token}`
    request.headers['token'] = `${decodeURIComponent(store.state.userInfo.token)}`
  } else {
    // request.headers['Authorization'] = `Bearer ${'testtoken'}`
    // request.headers['token'] = `${'testtoken'}`
  }
  // request.headers['Authorization'] = `Bearer ${'testtoken'}`
  // request.headers['token'] = `${'testtoken'}`
  return request
}

const resInterceptors = response => {
  // cancelLoading()
  // return response.data
  return new Promise((resolve, reject) => {
    cancelLoading()
    const data = response.data
    if (data.code === 1000 || data.code === 1001) {
      if (store.state.userInfo?.token) {
        reject('Error, token is expired')
      }
      if (process.env.VUE_APP_ENV !== 'dingtalk') {
        store.dispatch('getAuthInfo')
      }
      if (process.env.VUE_APP_ENV === 'dingtalk') {
        // store.dispatch('getUserInfo')
        Dialog.alert({
          title: '提示',
          message: data.msg || data.message || 'token过期',
        }).then(() => {
          dd.biz.navigation.close({})
        })
      }
    } else {
      resolve(data)
    }
  })
}

const cancelLoading = () => {
  let t = setTimeout(() => {
    // store.commit('LOADING', false)
    clearTimeout(t)
  }, 260)
}

export const errorHandle = error => {
  console.log('errorHandle', error)
  cancelLoading()
  Notify({
    type: 'danger',
    title: error.status,
    message: error.msg ? error.msg : error.message,
  })
}

service.interceptors.request.use(reqInterceptors, errorHandle)
service.interceptors.response.use(resInterceptors, errorHandle)

export const loading = {
  service: request => {
    // serviceLoading = true
    return service(request)
  },
}

export default request => {
  // serviceLoading = false
  return service(request)
}

<template>
  <div class="copyright-notice">
    <div class="note-title">{{ $t('com.report.reportV3.text051') }}：</div>
    <div class="note-content">{{ $t('com.report.reportV3.text052') }}</div>
    <div class="note-title mt16">{{ $t('com.report.reportV3.text053') }}：</div>
    <div class="note-content">
      {{ $t('com.report.reportV3.text054') }}
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.copyright-notice {
  margin: 24px 0px 20px 0px;
  padding: 16px;
  background: #F5F5F5;
  border-radius: 8px;
  font-size: 14px;
  .note-title {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
  }
  .mt16 {
    margin-top: 16px;
  }
  .note-content {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 20px;
  }
}
</style>

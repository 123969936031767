/* eslint-disable quotes */
export default {
  text001: 'Occupational personality assessment',
  text002: `AI RecruiTas occupational personality test is a set of occupational personality survey aiming at business organizations. The tool focus on occupational personality under measurement status, including 7 measurement dimensions, to descirbe candidate's performance at workplace, including how to adjust emotion under pressure, how to interact and communicate with others, how to cope with task, and how to address issues etc. The report shows score in each dimension one by one, and points out candidate's advantages and disadvantages in each dimension, which provides guidance and opinion about recruitment, leadership development, succession planning, and talent management.`,
  text003: 'Report conclusion',
  text004: 'The candidate',
  text005: 'She',
  text006: 'He',
  text007: `Base on the answers by {name}in AI RecruiTas' occupational personality test,{ta} tends to:`,
  text008: 'There is no content to view',
  text009: 'Responsibility',
  text010: 'curiosity',
  text011: 'Harmony',
  text012: 'Adaptability',
  text013: 'Sociality',
  text014: 'Ambition',
  text015: 'Responsibility',
  text016: 'Curiosity',
  text017: 'Self-discipline',
  text018: 'Harmony',
  text019: 'adaptability',
  text020: 'Reserved, quiet, not sociable, likes to be alone; restrained, cautious, not very interested in the outside world. ',
  text021: 'Extroverted and outgoing, enjoys company, actively seeks attention; enthusiastic, decisive, energetic and talkative. ',
  text022: 'More sensitive to negative events; poor emotion regulation ability; not easy to restrain when feeling strong temptation. ',
  text023: 'Calm and calm, able to maintain emotional stability and clear mind under pressure; strong self-control, able to resist temptation. ',
  text024: 'Pragmatism, preference for routine, more traditional and conservative; follow convention, lack of innovation. ',
  text025:
    'Imagination and creativity, preference for abstract thinking; strong curiosity, pursuit of artistic enjoyment; active change, individuals are willing to consider trying new ideas when engaging in a certain task. ',
  text026: 'Compared with caring about others, he pays more attention to his own interests; cold and tough, straightforward, and dares to face conflicts. ',
  text027: 'Warm and enthusiastic, considerate and friendly, willing to help others; willing to be humble, avoid conflicts, and always get along with others in harmony. ',
  text028: 'Negative, passive, procrastinating in doing things; impulsive, disorderly, sloppy in doing things; step-by-step, not caring about work results. ',
  text029: 'Reliable, conscientious, proactive; organized, cautious, detail-oriented; hard-working, enthusiastic, and pursuing high-quality completion of tasks. ',
  text030:
    'Complacent with the status quo, take a pessimistic attitude towards challenges or difficulties; in the face of difficulties/setbacks, they will think that the achievement of the goal is out of reach, thus adopting passive avoidance behaviors. ',
  text031:
    'Achievement-oriented, competitive, and dare to set challenging goals; when faced with difficulties or deep crises, never give up, and can persistently try various methods to overcome obstacles and complete tasks. ',
  text032: 'Capricious, inconsistent; highly accepting of bad behavior; favoring one another and favoring others. ',
  text033: 'Keep your promises and do what you say; be strict with yourself and strictly abide by the rules and regulations; be fair and just and treat everyone equally. ',
}

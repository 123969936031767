<template>
  <div>
    <div class="offline-interview" v-if="list && list.length">
      <div class="card" v-for="(item, index) in list" :key="index">
        <div class="first_line">
          <div class="interview_round">
            <!-- <span class="round_label">面试轮次</span> -->
            <span class="round_name">第{{ item.interviewRound }}轮面试（{{ item.interviewType }}）</span>
            <!-- <span class="round_name">({{ '无领导小组' }})</span> -->
          </div>
          <div class="create_time">
            <span v-if="item.createTime"> {{ moment(item.createTime).format('YYYY-MM-DD') }} 提交</span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="line_item ">
          <div class="label">面试结果：</div>
          <div class="result">{{ item.interviewResult || '-' }}</div>
        </div>
        <div class="line_item score">
          <div class="label">面试得分：</div>
          <div class="content">{{ item.interviewScore || '-' }}</div>
        </div>
        <div class="bottom">
          <div class="line_item interview_mark">
            <div class="label">面试评语：</div>
            <div class="content remark">
              <div class="interview_remark" v-for="(v, i) in item.interviewRemark.split('\n')" :key="i">{{ v }}</div>
            </div>
          </div>
          <div class="line_item position">
            <div class="label">建议岗位：</div>
            <div class="content">{{ item.suggestPosition }}</div>
          </div>
          <div class="line_item interviewer">
            <div class="label">面试官姓名：</div>
            <div class="content">{{ item.interviewerName }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-empty description="暂无记录" v-else />
  </div>
</template>
<script>
import moment from 'moment'
import { offlineInterviewResultList } from '@/api/candidate'

export default {
  data() {
    return {
      list: [],
      // {
      //   createTime: '2021-03-31T03:45:57.000+0000',
      //   id: '1',
      //   interviewRemark: '不错\nasds',
      //   interviewResult: '通过',
      //   interviewRound: 1,
      //   interviewScore: '10',
      //   interviewerName: '谢广坤',
      //   jobseekerChannelId: '0db37bd6af2e41fa8b17bdce43205ceb',
      //   submitTime: '2021-03-31T03:45:23.000+0000',
      //   suggestPosition: '算法工程师',
      //   updateTime: 'nul',
      // },
      // {

      moment: moment,
      form: {
        createTime: '2021-03-31T03:45:57.000+0000',
        id: '1',
        interviewRemark: '不错\nasds',
        interviewResult: '通过',
        interviewRound: 1,
        interviewScore: '10',
        interviewerName: '谢广坤',
        jobseekerChannelId: '0db37bd6af2e41fa8b17bdce43205ceb',
        submitTime: '2021-03-31T03:45:23.000+0000',
        suggestPosition: '算法工程师',
        updateTime: 'nul',
      },
    }
  },
  props: { jobseekerChannelId: { type: String } },
  created() {
    const arr = this.form.interviewRemark.split('\n')
    console.log('arr', arr)
    this.offlineInterviewResultList()
  },
  methods: {
    offlineInterviewResultList() {
      console.log('arrsddddddddd')
      const params = {
        jobSeekerChannelId: this.jobseekerChannelId || '',
      }
      offlineInterviewResultList(params).then(res => {
        console.log(res)
        if (res && res.code === 0) {
          this.list = res.data || []
          if (this.list.length === 1) {
            this.list[0].interviewType = '无领导'
          } else if (this.list.length === 2) {
            this.list[0].interviewType = '无领导'
            this.list[1].interviewType = '半结构'
          }
          //写死两个
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.offline-interview {
  .card {
    // width: 642px;
    min-height: 280px;
    margin: 0 auto;
    padding: 24px 20px;
    .first_line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .interview_round {
        line-height: 22px;
        .round_label {
          width: 68px;
          height: 20px;
          background: #44d7b6;
          box-shadow: 0px 2px 10px 0px rgba(80, 98, 118, 0.2);
          border-radius: 2px;
          font-size: 14px;
          color: #ffffff;
          padding: 0 6px;
          margin-right: 10px;
        }
        .round_name {
          height: 22px;
          line-height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
          text-shadow: 0px 2px 10px rgba(80, 98, 118, 0.2);
        }
        .round_name + .round_name {
          margin-left: 10px;
        }
      }
      .interview_round::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #4f8fff;
        box-shadow: 0px 2px 10px 0px rgba(80, 98, 118, 0.2);
        border-radius: 50%;
        position: relative;
        right: 5px;
      }
      .create_time {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #aaaaaa;
        line-height: 20px;
        text-shadow: 0px 2px 10px rgba(80, 98, 118, 0.2);
      }
    }
    .bottom {
      padding: 10px 0px;
      background: #f8f8f8;
      border-radius: 5px;
    }
    .interview_mark {
      padding-bottom: 10px;
      border-bottom: 1px solid #dddddd;
    }
    .line_item {
      display: flex;
      margin-bottom: 10px;
      margin-left: 10px;
      .label {
        min-width: 95px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        text-shadow: 0px 2px 10px rgba(80, 98, 118, 0.2);
        white-space: nowrap;
      }
      .result {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        background: rgba(0, 181, 80, 0.69);
        border-radius: 2px;
        text-shadow: 0px 2px 10px rgba(80, 98, 118, 0.2);
        padding: 0 10px;
      }
      .content {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        text-shadow: 0px 2px 10px rgba(80, 98, 118, 0.2);
        // padding-left: 9px;
        // .interview_remark::before {
        //   content: '';
        //   display: inline-block;
        //   width: 8px;
        //   height: 8px;
        //   background: #f7b500;
        //   box-shadow: 0px 2px 10px 0px rgba(80, 98, 118, 0.2);
        //   border-radius: 50%;
        //   position: relative;
        //   right: 5px;
        // }
        .interview_remark + .interview_remark {
          margin-top: 4px;
        }
      }
      // .remark {
      //   padding-left: 0px;
      //   .interview_remark {
      //     padding-left: 2px;
      //     display: flex;
      //     align-items: center;
      //   }
      // }
    }
  }
  .card + .card {
    border-top: 10px solid #f3f3f3;
  }
}
</style>

/* eslint-disable quotes */
export default {
  text001: 'Cognition capability assessment',
  text002:
    "AI RecruiTas' graphic deductive test, a kind of non-language intelligent test, mainly appraises candidate's observation ability, issue-addressing ability, and thinking ability. To be specific, what the test appraises is candidate's capability to recognize information beneficial to decision-making:",
  text003: '• The ability to quickly identify information useful for decision-making from numerous ambitious and incomplete information;',
  text004: '• The ability to sort out, classify and screen information after obtaining various information, as well as to form clear cognition about property, connotation, status of the things;',
  text005: '• The capability to keep clear mind and cope with complicated issues flexibly when facing them.',
  text006:
    "Score of AI RecruiTas' graphic deductive test can effectively anticipate candidate's future performance in some positions. Such positions usually requires candidate to have clear and accurate thinking ability, capability to identify reasons for issues, as well as ability to make good use of available information to make the best decision. Such positions include but not be limited to management, technician, consultant, and so forth.",
  text007: "{name}'s percentile score is: {score}",
  text008: 'Score interpretation',
  text009: 'No content can be viewed',
}

/**
 * @param step 时间间隔分钟
 * @param timeZone 总时间
 * @returns 返回时间数组
 */
export function timeSlot(step, timeZone) {
  const date = new Date()
  date.setHours(0) // 时分秒设置从零点开始
  date.setSeconds(0)
  date.setUTCMinutes(0)
  // console.log(date.getHours())
  // console.log(date.getSeconds())
  // console.log(new Date(date.getTime()))

  // let arr = []
  let timeArr = []
  const slotNum = (timeZone * 60) / step // 算出多少个间隔
  for (let f = 0; f < slotNum; f++) {
    const time = new Date(Number(date.getTime()) + Number(step * 60 * 1000 * f)) // 获取：零点的时间 + 每次递增的时间
    let hour = '',
      sec = ''
    time.getHours() < 10 ? (hour = '0' + time.getHours()) : (hour = time.getHours()) // 获取小时
    time.getMinutes() < 10 ? (sec = '0' + time.getMinutes()) : (sec = time.getMinutes()) // 获取分钟
    timeArr.push(hour + ':' + sec)
  }
  timeArr = timeArr.map(i => {
    return { time: i, value: getTimeStamp(i + ':' + '00') }
  })
  return timeArr
}

export function getTimeStamp(time) {
  const arr = time.split(':')
  return arr[0] * 60 * 60 * 1000 + arr[1] * 60 * 1000 + arr[2] * 1000
}

export function charToUnicode(ipt) {
  return ipt
    .split('')
    .map(v => '/charunicode' + (v.codePointAt(0)?.toString(16) ?? '').padStart(4, '0'))
    .join('')
}

export function unicodeToChar(_str) {
  return _str.replace(/(\/charunicode)(\w{4})/gi, function($0, $1, $2) {
    // console.log($0, $1, $2)
    return String.fromCharCode(parseInt($2, 16))
  })
}

export const chinaPhoneReg = /(^(13|14|15|16|17|18|19)[0-9]{9}$)/

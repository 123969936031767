<template>
  <div class="interviewPlanContent">
    <div class="log-wrapper" v-if="list && list.length">
      <div class="module_content paddingLeft">
        <van-steps direction="vertical">
          <van-step v-for="(item, index) in list" class="online" :key="index" :timestamp="$t('com.report.interviewPlan.text001') + item.createDate" placement="top">
            <div class="spot" slot="active-icon"></div>
            <div class="spot" slot="inactive-icon"></div>
            <div class="time-title">
              <div class="week">
                {{ moment(item.interviewDate).format('dddd') }}<span class="interviewDate">{{ moment(item.interviewDate).format('MM/DD') }}</span>
              </div>
              <div
                v-if="item.responseStatus !== 0 && item.status !== 3"
                class="responseStatus"
                :class="{ 'no-response': item.responseStatus === 0, accept: item.responseStatus === 1, reject: item.responseStatus === 2 }"
              >
                {{ item.responseStatus | translateResponseStatus }}
              </div>
              <div v-if="item.status === 3" class="responseStatus cancle">
                {{ item.status | translateResponseStatus }}
              </div>
              <div class="interview-mode" :class="{ offline: item.planRoomType === 0, online: item.planRoomType !== 0 }">{{ interviewModeName[item.planRoomType] }}</div>
            </div>
            <div class="detail-wrapper-plan" v-if="item.planRoomType !== 0">
              <div class="detail-row">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text002') }}:</div>
                <div class="detail-content">{{ item.interviewDate + '   ' + item.interviewStartTime + '-' + item.interviewEndTime }}</div>
              </div>
              <div class="detail-row">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text003') }}:</div>
                <div class="detail-content">{{ item.interviewRoundName }}</div>
              </div>
              <div class="detail-row">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text004') }}:</div>
                <div class="detail-content">{{ item.createUserName }}</div>
              </div>
              <div class="detail-row" v-if="item.guestUserList && item.guestUserList.length">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text005') }}:</div>
                <div class="detail-content">{{ item.guestUserList.map(r => r.guestUserName).join() }}</div>
              </div>
              <div v-if="[1, 3].includes(item.planRoomType)" class="detail-row">
                <div class="detail-label">{{ item.planRoomType === 1 ? $t('com.report.interviewPlan.text006') : $t('com.report.interviewPlan.text007') }}:</div>
                <div class="detail-content">{{ item.interviewRoomNo }}</div>
              </div>
              <div class="detail-row" v-if="item.planRoomType === 3">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text008') }}:</div>
                <div class="detail-content" style="overflow-wrap: break-word; color: rgb(24, 144, 255)" @click="goUrl(item.tencentMeetingUrl)">
                  {{ item.tencentMeetingUrl }}
                </div>
              </div>
              <div class="detail-row" v-if="item.planRoomType === 1">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text009') }}:</div>
                <div class="detail-content">
                  {{ item.candidatePassword }}
                </div>
              </div>

              <div class="detail-row" v-if="item.planRoomType === 4">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text010') }}:</div>
                <div class="detail-content">
                  {{ item.interviewOtherInfo }}
                </div>
              </div>

              <div class="interviewer-info-list-wrapper" v-for="interviewerItem in item.interviewerInfoList" :key="interviewerItem.interviewerId">
                <div class="interviewer-row">
                  <div class="interviewer-label">{{ $t('com.report.interviewPlan.text011') }}:</div>
                  <div class="interviewer-content">
                    {{ interviewerItem.interviewerName }}
                  </div>
                </div>
                <div class="interviewer-row" v-if="item.planRoomType === 1 && isDingtalk">
                  <div class="interviewer-label">{{ $t('com.report.interviewPlan.text012') }}:</div>
                  <div class="interviewer-content" style="display: flex">
                    {{ interviewerItem.interviewerPcInterviewUrl }}
                    <div class="copy-text" @click="copyUrl(interviewerItem.interviewerPcInterviewUrl)">{{ $t('com.report.interviewPlan.text013') }}</div>
                  </div>
                </div>
                <div class="interviewer-row" v-if="item.planRoomType === 1">
                  <div class="interviewer-label">{{ $t('com.report.interviewPlan.text014') }}:</div>
                  <div class="interviewer-content">
                    {{ interviewerItem.interviewerPassword }}
                    <div v-if="!isDingtalk" class="tips">{{ $t('com.report.interviewPlan.text015') }}</div>
                  </div>
                </div>
                <div class="interviewer-row" v-if="item.status !== 3">
                  <div class="interviewer-label">{{ $t('com.report.interviewPlan.text016') }}:</div>
                  <div class="interviewer-content">
                    <span v-if="interviewerItem.znInterviewFeedbackVO" class="zn-interview-feedback">
                      <template v-if="isOwner(interviewerItem)">
                        <span v-if="interviewerItem.znInterviewFeedbackVO.interviewResult || interviewerItem.znInterviewFeedbackVO.interviewResult === 0"
                          ><span class="interviewResult-text">{{ getFeedbackStatus(interviewerItem.znInterviewFeedbackVO.interviewResult) }}</span></span
                        >
                      </template>
                      <span
                        v-if="interviewerItem.znInterviewFeedbackVO.interviewResult == 4"
                        style="margin-left: 10px"
                        class="feed-btn"
                        :class="{ disabled: !isOwner(interviewerItem) }"
                        @click="showFeedback(item, interviewerItem, interviewerItem.znInterviewFeedbackVO, interviewerItem.templateInfo, 'edit')"
                      >
                        {{ $t('com.report.interviewPlan.text017') }}
                      </span>
                      <span
                        style="margin-left: 10px"
                        class="feed-btn"
                        :class="{ disabled: !isOwner(interviewerItem) }"
                        v-if="interviewerItem.znInterviewFeedbackVO.interviewResult !== 4"
                        @click="showFeedback(item, interviewerItem, interviewerItem.znInterviewFeedbackVO, interviewerItem.templateInfo, 'detail')"
                        >{{ $t('com.report.interviewPlan.text018') }}</span
                      >
                      <span
                        v-if="interviewerItem.znInterviewFeedbackVO.interviewResult === 4 && false"
                        class="unfinish"
                        style="margin-left: 10px"
                        @click="revokeUnfinished(item, interviewerItem, interviewerItem.znInterviewFeedbackVO)"
                        >{{ $t('com.report.interviewPlan.text019') }}
                      </span>
                    </span>
                    <div v-else class="unfinish-wrapper">
                      <span
                        v-if="item.status !== 3"
                        class="feed-btn"
                        :class="{ disabled: !isOwner(interviewerItem) }"
                        @click="showFeedback(item, interviewerItem, interviewerItem.znInterviewFeedbackVO, interviewerItem.templateInfo, 'edit')"
                        >{{ $t('com.report.interviewPlan.text017') }}</span
                      >
                      <span class="unfinish" :class="{ disabled: !isOwner(interviewerItem) }" @click="interviewUnfinished(item, interviewerItem.interviewerId, interviewerItem)">{{
                        $t('com.report.interviewPlan.text021')
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="detail-wrapper-plan">
              <div class="detail-row">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text002') }}:</div>
                <div class="detail-content">{{ item.interviewDate + '   ' + item.interviewStartTime + '-' + item.interviewEndTime }}</div>
              </div>
              <div class="detail-row">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text003') }}:</div>
                <div class="detail-content">{{ item.interviewRoundName }}</div>
              </div>
              <div class="detail-row">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text004') }}:</div>
                <div class="detail-content">{{ item.createUserName }}</div>
              </div>
              <div class="detail-row" v-if="item.guestUserList && item.guestUserList.length">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text005') }}:</div>
                <div class="detail-content">{{ item.guestUserList.map(r => r.guestUserName).join() }}</div>
              </div>
              <div class="detail-row">
                <div class="detail-label">{{ $t('com.report.interviewPlan.text020') }}:</div>
                <div class="detail-content">{{ item.offlineLocation }}</div>
              </div>
              <div
                class="interviewer-info-list-wrapper"
                :style="{ paddingBottom: index < item.interviewerInfoList.length - 1 ? '0px' : '10px' }"
                v-for="(interviewerItem, index) in item.interviewerInfoList"
                :key="interviewerItem.interviewerId"
              >
                <div class="interviewer-row">
                  <div class="interviewer-label">{{ $t('com.report.interviewPlan.text011') }}:</div>
                  <div class="interviewer-content">
                    {{ interviewerItem.interviewerName }}
                  </div>
                </div>
                <div class="interviewer-row" v-if="item.status !== 3">
                  <div class="interviewer-label">{{ $t('com.report.interviewPlan.text016') }}:</div>
                  <div class="interviewer-content">
                    <span v-if="interviewerItem.znInterviewFeedbackVO" class="zn-interview-feedback">
                      <template v-if="isOwner(interviewerItem)">
                        <span v-if="interviewerItem.znInterviewFeedbackVO.interviewResult || interviewerItem.znInterviewFeedbackVO.interviewResult === 0" style="margin-right: 10px"
                          ><span class="interviewResult-text">{{ getFeedbackStatus(interviewerItem.znInterviewFeedbackVO.interviewResult) }}</span>
                        </span>
                      </template>
                      <span
                        v-if="interviewerItem.znInterviewFeedbackVO.interviewResult == 4"
                        style="margin-right: 10px"
                        class="feed-btn"
                        :class="{ disabled: !isOwner(interviewerItem) }"
                        @click="showFeedback(item, interviewerItem, interviewerItem.znInterviewFeedbackVO, interviewerItem.templateInfo, 'edit')"
                      >
                        {{ $t('com.report.interviewPlan.text017') }}
                      </span>
                      <span
                        class="feed-btn"
                        :class="{ disabled: !isOwner(interviewerItem) }"
                        v-if="interviewerItem.znInterviewFeedbackVO.interviewResult !== 4"
                        @click="showFeedback(item, interviewerItem, interviewerItem.znInterviewFeedbackVO, interviewerItem.templateInfo, 'detail')"
                        >{{ $t('com.report.interviewPlan.text018') }}</span
                      >
                      <span
                        v-if="interviewerItem.znInterviewFeedbackVO.interviewResult === 4 && false"
                        style="margin-right: 10px"
                        class="feed-btn"
                        @click="revokeUnfinished(item, interviewerItem, interviewerItem.znInterviewFeedbackVO)"
                        >{{ $t('com.report.interviewPlan.text019') }}
                      </span>
                    </span>
                    <div v-else class="unfinish-wrapper">
                      <span
                        v-if="item.status !== 3"
                        class="feed-btn"
                        :class="{ disabled: !isOwner(interviewerItem) }"
                        @click="showFeedback(item, interviewerItem, interviewerItem.znInterviewFeedbackVO, interviewerItem.templateInfo, 'edit')"
                        >{{ $t('com.report.interviewPlan.text017') }}</span
                      >
                      <span
                        v-if="item.status !== 3"
                        class="unfinish"
                        :class="{ disabled: !isOwner(interviewerItem) }"
                        @click="interviewUnfinished(item, interviewerItem.interviewerId, interviewerItem)"
                        >{{ $t('com.report.interviewPlan.text021') }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="hr" v-if="index < item.interviewerInfoList.length - 1"></div>
              </div>
            </div>
          </van-step>
        </van-steps>
      </div>
    </div>
    <div class="nocandidate-wrapper" v-else>
      <div class="nocandidate">
        <!-- <img src="@/assets/img/no-data.png" /> -->
        <Empty :title="$t('com.report.onlinetest.text016')" />
        <!-- <div class="text">此候选人尚未安排任何面试</div> -->
      </div>
      <template v-if="$store.state.userInfo && $store.state.userInfo.userId">
        <div class="description">
          {{ $t('com.report.interviewPlan.text022') }}<br />
          {{ $t('com.report.interviewPlan.text023') }}
        </div>
        <div class="goUpdateDatePick" @click="goUpdateDatePick">{{ $t('com.report.interviewPlan.text024') }}</div>
      </template>
    </div>
    <Feedback
      :visible.sync="feedbackVisible"
      @close="feedbackVisible = false"
      :info="curItem"
      :curInterviewerItem="curInterviewerItem"
      :feedback="curFeedback"
      :feedbackType.sync="feedbackType"
      :curTemplateInfo="curTemplateInfo"
    />
  </div>
</template>
<script>
import { createInterviewFeedback } from '@/api/report'
import { interviewerInfo } from '@/api/candidate'
import Feedback from '@/components/feedback/'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
import EventBus from '@/common/event-bus'
import { Dialog } from 'vant'
import i18n from '../../utils/i18n'
import Empty from '@/components/reportV3/common/empty.vue'
export default {
  components: {
    Feedback,
    Empty
  },
  props: {
    isCheckOwner: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('LOAD_INTERVIEW_DATA')
    })

    EventBus.$on('LOAD_INTERVIEW_DATA', this.loadData)

    //this.$store.dispatch('queryInterviewPlan')
  },
  computed: {
    isDingtalk() {
      return process.env.VUE_APP_ENV === 'dingtalk'
    },
    userId() {
      return this.$store.state.userInfo?.userId
    },
    isInterviewerAccount() {
      return this.userInfo.userType === 'interviewer'
    },
    userInfo() {
      return this.$store.state.userInfo
    },

    jobseekerChannelId() {
      return this.$store.state.jobseekerChannelId
    },
    positionId() {
      return this.$store.state.positionId
    },
    interviewPlanList() {
      return this.$store.state.interviewPlanList
    },
  },
  filters: {
    translateResponseStatus(index) {
      if (index === 0) {
        return i18n.t('com.report.interviewPlan.text025')
      } else if (index === 1) {
        return i18n.t('com.report.interviewPlan.text026')
      } else if (index === 2) {
        return i18n.t('com.report.interviewPlan.text027')
      } else if (index === 3) {
        return i18n.t('com.report.interviewPlan.text028')
      }
    },
  },
  watch: {
    interviewPlanList: {
      handler() {
        let tempList = []
        if (this.interviewPlanList && this.interviewPlanList.length) {
          tempList = this.interviewPlanList.slice()
          tempList.forEach(item => {
            item.loading = false
            let feedbackList = item.interviewFeedbackList || []
            if (feedbackList.length) {
              if (this.isNewFeedback(item)) {
                item.feedbackContent = ''
              } else {
                item.feedbackContent = feedbackList[0].feedbackContent
              }
              item.feedbackUserName = feedbackList[0].lastUpdateByName
            }

            // let interviewUrl = ''
            // if (process.env.NODE_ENV === 'production') {
            //   interviewUrl = 'https://candidate.aidexianzhaopinguan.com/videocall/' + item.interviewerId
            // } else {
            //   interviewUrl = 'https://candidatetest.aidexianzhaopinguan.com/videocall/' + item.interviewerId
            // }
            // item.interviewUrl = interviewUrl
          })
        }
        this.list = tempList
      },
      deep: true,
      immediate: true,
    },
    jobseekerChannelId: {
      handler() {
        this.requestQueryInterviewPlan
      },
      immediate: true,
    },
  },
  data() {
    return {
      list: [],
      feedbackEditMap: {},
      moment,
      interviewModeName: [
        //
        i18n.t('com.report.interviewPlan.text029'),
        i18n.t('com.report.interviewPlan.text030'),
        i18n.t('com.report.interviewPlan.text031'),
        i18n.t('com.report.interviewPlan.text032'),
        i18n.t('com.report.interviewPlan.text033'),
      ],
      cancleEditMap: {},
      curItem: {},
      curFeedback: null,
      feedbackType: '',
      feedbackVisible: false,
      positionInfo: {},
      curInterviewerItem: null,
      curTemplateInfo: null,
    }
  },
  methods: {
    goUrl(url) {
      window.location.href = url
    },
    interviewUnfinished(row, interviewerId, interviewerItem) {
      if (!this.isOwner(interviewerItem)) {
        return
      }
      if (!this.userInfo) {
        return Dialog.confirm({
          title: this.$t('com.report.interviewPlan.text034'),
          confirmButtonColor: '#1989fa',
        }).then(() => {
          EventBus.$emit('toLogin')
        })
      }
      this.whetherShowPlanAction({ interviewerId: interviewerId }, row).then(val => {
        if (val) {
          this.$dialog
            .confirm({
              message: this.$t('com.report.interviewPlan.text035'),
              title: this.$t('com.report.interviewPlan.text021'),
              messageAlign: 'left',
            })
            .then(() => {
              const user = this.$store.state.userInfo
              const params = {
                planInterviewId: row.id,
                feedbackContent: '',
                createBy: user.userId,
                createByName: user.userName,
                interviewResult: 4,
                positionId: row.positionId,
                interviewerId,
              }

              createInterviewFeedback(params).then(res => {
                if (res.code === 0) {
                  this.$toast({ type: 'success', message: this.$t('com.report.interviewPlan.text036') })
                  this.loadData()
                } else {
                  this.$toast({ type: 'fail', message: res.message })
                  if (res.code === 1004 || res.code === 1001) {
                    EventBus.$emit('toLogin', '1004')
                  }
                }
              })
            })
            .catch(() => {})
        } else {
          this.$notify({ type: 'danger', message: this.$t('com.report.interviewPlan.text037') })
        }
      })
      // if (this.userInfo && (interviewerId === this.userInfo.userId || this.userInfo.userId === row.userId)) {
      // } else {
      //   this.$notify({ type: 'danger', message: '您暂无权限进行操作，请联系HR' })
      // }
    },
    revokeUnfinished(row, interviewerId) {
      console.log(row, interviewerId)
      this.$dialog
        .confirm({
          message: this.$t('com.report.interviewPlan.text039') + this.$t('com.report.interviewPlan.text038'),
          title: this.$t('com.report.interviewPlan.text039'),
          messageAlign: 'left',
        })
        .then(() => {})
        .catch(() => {})
    },
    hasFeedBack(item) {
      if (item.interviewerInfoList && item.interviewerInfoList.length) {
        let flag = item.interviewerInfoList.some(info => {
          return info.znInterviewFeedbackVO !== null
        })
        return flag
      }
      return false
    },
    isOwner(item) {
      if (this.isCheckOwner) {
        return item.interviewerId === this.userId
      } else {
        return true
      }
    },
    showFeedback(item, interviewerItem, feedback = null, templateInfo = null, type) {
      if (!this.isOwner(interviewerItem)) {
        return
      }
      if (type === 'edit') {
        if (!this.userInfo) {
          return Dialog.confirm({
            title: this.$t('com.report.interviewPlan.text034'),
            confirmButtonColor: '#1989fa',
          }).then(() => {
            EventBus.$emit('toLogin')
          })
        }
      }
      if (type === 'detail') {
        this.showFeedbackDetail(item, interviewerItem, feedback, templateInfo, type)
      } else {
        this.whetherShowPlanAction(interviewerItem, item).then(val => {
          if (val) {
            this.showFeedbackDetail(item, interviewerItem, feedback, templateInfo, type)
          } else {
            this.$notify({ type: 'danger', message: this.$t('com.report.interviewPlan.text037') })
          }
        })
      }
    },
    showFeedbackDetail(item, interviewerItem, feedback = null, templateInfo = null, type) {
      this.curItem = item //当前面试的信息
      this.curFeedback = feedback //是否有历史反馈信息
      this.curInterviewerItem = interviewerItem
      this.curTemplateInfo = templateInfo //面试评价表信息
      console.log('feedback===>', feedback, feedback === null)
      this.feedbackType = type
      this.feedbackVisible = true
    },

    async whetherShowPlanAction(interviewer, item) {
      console.log(interviewer, item)
      // this.isModifyAuth()

      if (this.isInterviewerAccount) {
        /**
         * 用人部门账户，当前登录的用户是该面试官的时候才显示
         */
        return this.userId === interviewer.interviewerId || this.userId === item.userId
      } else {
        /**
         * 一般账户的权限从后台获取
         * 1.分享简历登录的超管可能不是候选人所属的公司的超管
         * 2.或者登录的管理员没有简历的操作权限
         */
        // let isAuth = false
        return new Promise((resolve, reject) => {
          interviewerInfo({ planInterviewId: item.id })
            .then(res => {
              if (res && res.code === 0) {
                resolve(res.data.isModifyAuth === 1)
              } else {
                if (res.code === 1004 || res.code === 1001) {
                  EventBus.$emit('toLogin', '1004')
                }
                reject()
              }
            })
            .catch(() => {
              EventBus.$emit('toLogin', '1004')
              reject()
            })
        })
      }
    },
    isSelfInterviewer(row) {
      const userId = this.userInfo?.userId
      const interviewer = this.list.find(n => {
        return userId === n.interviewerId
      })
      return !!interviewer && row.interviewerId === userId
    },
    hasInterviewer() {
      const userId = this.userInfo?.userId
      const interviewer = this.list.find(n => {
        return userId === n.interviewerId
      })
      return interviewer
    },
    isNewFeedback(row) {
      if (!this.userInfo) {
        return false
      }

      let list = []

      if (row.interviewerInfoList && row.interviewerInfoList) {
        list = row.interviewerInfoList
      }
      const userId = this.userInfo?.userId
      let findItem = list.find(item => {
        return item.interviewerId === userId
      })

      if (findItem && !findItem.znInterviewFeedbackVO) {
        return true
      }
      return false
    },
    toCancleEdit(row) {
      this.cancleEditMap[row.id] = false
      row.cancelReason = row.cancelReasonHistory
    },
    toEditCancle(row) {
      row.cancelReasonHistory = row.cancelReason
      this.$set(this.cancleEditMap, row.id, true)
    },
    copyUrl(url) {
      var oInput = document.createElement('textarea')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.$toast({ type: 'info', message: this.$t('com.report.interviewPlan.text040') })
      document.body.removeChild(oInput)
    },
    goUpdateDatePick() {
      this.$router.push('/datePicker')
    },
    loadData() {
      this.$store.dispatch('queryInterviewPlan')
    },
    updateInterviewFeedback(row, feedback) {
      if (row.interviewResult === '') {
        this.$toast({ type: 'info', message: this.$t('com.report.interviewPlan.text041') })
        return false
      }
      // if (!row.feedbackContent) {
      //   this.$toast({ type: 'info', message: '反馈内容不能为空' })
      //   return false
      // }
      row.loading = true
      let params = {
        planInterviewId: row.id,
        id: (feedback || {}).id,
        feedbackContent: feedback ? feedback.feedbackContent : row.feedbackContent,
        createBy: this.userInfo?.userId,
        createByName: this.userInfo?.userName,
        interviewResult: feedback ? feedback.interviewResult : row.interviewResult,
      }

      createInterviewFeedback(params)
        .then(res => {
          if (res && res.code === 0) {
            this.$toast({ type: 'info', message: this.$t('com.report.interviewPlan.text042') })
            if (feedback) {
              this.$set(this.feedbackEditMap, feedback.id, false)
            }
            row.feedbackUserName = this.userInfo.userName
            this.$store.dispatch('queryInterviewPlan')
          } else {
            this.$toast({ type: 'info', message: this.$t('com.report.interviewPlan.text043') })
          }
        })
        .finally(() => {
          row.loading = false
        })
    },
    isEditFeedback(row) {
      return this.userInfo && row.lastUpdateBy === this.userInfo?.userId
    },
    toEditFeedback(row) {
      this.$set(this.feedbackEditMap, row.id, true)
    },
    getFeedbackStatus(status) {
      if (parseInt(status) === 0) {
        return this.$t('com.report.interviewPlan.text044')
      } else if (parseInt(status) === 1) {
        return this.$t('com.report.interviewPlan.text045')
      } else if (parseInt(status) === 2) {
        return this.$t('com.report.interviewPlan.text046')
      } else if (parseInt(status) === 4) {
        return this.$t('com.report.interviewPlan.text047')
      }
      return ''
    },
    // isNewFeedback(row) {
    //   let list = row.interviewFeedbackList || []
    //   let findItem = null

    //   if (this.userInfo && this.userInfo.userId) {
    //     findItem = list.find(item => {
    //       return item.lastUpdateBy === this.userInfo.userId
    //     })
    //   }

    //   return !findItem
    // },
    getTime(row) {
      let startDate = moment(row.interviewDate + ' ' + row.interviewStartTime).toDate()
      let endDate = moment(row.interviewDate + ' ' + row.interviewEndTime).toDate()
      let time = endDate.getTime() - startDate.getTime()
      return time / 60000 + 'min'
    },
  },
}
</script>
<style lang="scss">
.interviewPlanContent {
  .log-wrapper {
    width: 100%;
    padding: 0.6rem 0.5rem;

    .time-title {
      width: 100%;
      display: flex;
      padding-left: 10px;
      align-items: center;

      .week {
        color: #333333;
        font-weight: 600;
        font-size: 16px;
        margin-right: 10px;

        .interviewDate {
          margin-left: 5px;
        }
      }

      .responseStatus {
        width: 52px;
        // height: 18px;
        // line-height: 20px;
        font-size: 12px;
        text-align: center;
        color: #ffffff;
        border-radius: 2px;
        text-align: center;
        padding: 2px 0;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin-right: 6px;
      }

      .interview-mode {
        width: 90px;
        // height: 18px;
        // line-height: 20px;
        padding: 1px 0px;
        border-radius: 2px;
        font-size: 12px;
        text-align: center;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      .online {
        color: #1890ff;
        border: 1px solid #1890ff;
      }
      .offline {
        color: #44d7b6;
        border: 1px solid #44d7b6;
      }

      .accept {
        background-color: #00b550;
      }

      .reject {
        background-color: #f15959;
      }

      .cancle {
        background-color: #e02020;
      }

      .no-response {
        background-color: #f7b500;
      }
    }

    .detail-wrapper-plan {
      margin-top: 16px;
      .detail-row {
        width: 100%;
        padding-left: 10px;
        display: flex;
        .detail-label {
          width: 100px;
        }
        .detail-content {
          width: calc(100% - 110px);
          color: #5c5e61;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .detail-row:not(:first-child) {
        margin-top: 10px;
      }

      .interviewer-info-list-wrapper {
        background-color: #f8f8f8;
        width: 100%;
        padding: 10px;
        .interviewer-row {
          display: flex;
          color: #5c5e61;
          font-size: 14px;
          .interviewer-label {
            width: 100px;
          }
          .interviewer-content {
            width: calc(100% - 100px);
            font-weight: 600;
            word-break: break-all;

            .tips {
              width: 100%;
              color: #aaaaaa;
              font-size: 12px;
            }
            .interviewResult-text {
              color: #333333;
              font-weight: 600;
            }
            .zn-interview-feedback {
              display: flex;
              align-items: center;
            }
            .unfinish {
              border: 1px solid #627098;
              color: #627098;
              padding: 1px 10px;
              border-radius: 2px;
              // line-height: 12px;
              // height: 18px;
              display: block;
              // line-height: 20px;
              font-weight: 500;
            }

            .unfinish-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .unfinish {
                border: 1px solid #627098;
                color: #627098;
                padding: 1px 10px;
                border-radius: 2px;
                // line-height: 12px;
                // height: 20px;
                display: block;
                // line-height: 20px;
              }
            }
          }
        }
        .interviewer-row:not(:first-child) {
          margin-top: 10px;
        }

        .hr {
          width: 100%;
          height: 1px;
          background-color: #dddddd;
          margin-top: 5px;
          min-height: 1px;
        }
      }
    }

    .red {
      color: red !important;
    }

    .green {
      color: rgba(25, 133, 255, 1) !important;
    }

    .orange {
      color: orange;
    }
    .van-step__title--active {
      color: #999999 !important;
    }
    .spot {
      width: 10px;
      height: 10px;
      background: rgba(24, 144, 255, 1);
      //border: 0.1rem solid #b9ddff;
      border-radius: 100%;
      box-sizing: content-box;
    }

    .row1 {
      display: flex;
      justify-content: space-between;
    }

    .detail-wrapper2 {
      margin-top: 0.3rem;
      background: rgba(250, 250, 250, 1);
      border-radius: 8px;
      padding: 0.5rem 0 0.5rem 0.3rem;
      position: relative;
      .tip {
        position: absolute;
        top: 0;
        right: 0;
        color: #ffffff;
        width: 71px;
        height: 63px;

        span {
          transform: rotate(45deg) translateY(9px) translateX(10px);
          color: #ffffff;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
        }
      }
      .item {
        font-size: 14px;
        margin: 0 0 20px 10px;
        .edit-content {
          display: flex;
          .van-radio--horizontal {
            margin-right: 0.2rem;
          }
        }

        .feedback-content {
          textarea {
            border: 0.03rem solid #eaeaea;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
        &:after {
          content: '';
          clear: both;
          display: block;
        }

        .value {
          margin-left: 20px;
          color: #333333;
          .interviewUrl {
            width: 2rem;
            display: inline-block;
            border: none;
          }

          .van-cell {
            padding: 0;
            margin-top: 0.3rem;
            textarea {
              border: 0.03rem solid #eaeaea;
            }
          }
        }
      }
      .submit-item {
        font-size: 14px;
        width: 100%;
        padding-left: 10px;
        margin: 0 0 20px 0;

        .row1 {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          .label {
            color: #999999;
            width: 56px;
            white-space: nowrap;
          }

          .value {
            width: 100%;
            padding-left: 20px;
            color: #333333;

            .van-radio-group {
              display: flex;
              justify-content: space-between;
            }
          }
        }
        .van-cell {
          padding: 0;
          margin-top: 0.3rem;
          textarea {
            border: 0.03rem solid #eaeaea;
          }
        }
        .row3 {
          margin-top: 0.3rem;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .description {
    color: #333333;
    text-align: center;
    font-size: 0.3rem;
    line-height: 0.6rem;
  }

  .goUpdateDatePick {
    font-size: 0.4rem;
    width: 4.2rem;
    margin: 1rem auto 0 auto;
    background-color: #1890ff;
    text-align: center;
    color: #ffffff;
    padding: 0.2rem 0;
  }

  .nocandidate-wrapper {
    .nocandidate {
      // width: 80%;
      // margin: 20px auto;
      text-align: center;
      img {
        // width: 100%;
        width: 250px;
        height: 186px;
      }
      .text {
        margin-top: 20px;
        color: #666666;
        text-align: center;
        font-size: 20px;
      }
    }
  }
  .copy-text {
    color: #1890ff;
    margin-left: 0.4rem;
    cursor: pointer;
    white-space: nowrap;
  }

  .feed-btn {
    color: rgb(24, 144, 255);
    cursor: pointer;
  }

  .disabled {
    color: #c0c4cc !important;
    cursor: not-allowed !important;
  }
}
</style>

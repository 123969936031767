import service from '@/config/http'
import qs from 'qs'

//微信公众号-获取用户的openId
export const getOpenId = data => {
  return service({
    url: '/open/wechat/ai/user/getOpenId',
    method: 'post',
    data: qs.stringify(data),
  })
}

//进行用户验证,同时返回用户登录信息及token
export const auth = data => {
  return service({
    url: '/open/wechat/ai/user/auth',
    method: 'POST',
    data: qs.stringify(data),
  })
}

//根据openId获取用户信息
export const getAuthInfo = data => {
  return service({
    url: '/open/wechat/ai/user/getAuthInfo',
    method: 'POST',
    data: qs.stringify(data),
  })
}

//取消系统用户认证

export const cancelAuth = data => {
  return service({
    url: '/open/wechat/ai/user/cancelAuth',
    method: 'POST',
    data: qs.stringify(data),
  })
}

export const sendSms = data => {
  return service({
    url: '/open/wechat/ai/user/sendSms',
    method: 'POST',
    data: qs.stringify(data),
  })
}

<template>
  <div class="other-question">
    <section v-if="!sleep">
      <div v-if="(subQuestion && subQuestion.length) || (objectQuestion && objectQuestion.length) || (codeQuestion && codeQuestion.length)">
        <div v-if="subQuestion.length > 0">
          <div class="question-type-title">{{ $t('com.report.interviewRecord.text016') }}</div>
          <!-- 论述题 -->
          <div v-for="(currentQuestion, currentQuestionIndex) in subQuestion" :key="currentQuestionIndex">
            <van-row class="question">
              <div class="question-content">
                <HtmlParse :htmlStr="currentQuestion.question.problem" :questionIndex="currentQuestionIndex + 1" :noWifi="noWifi" :totalVideoFlag.sync="totalVideoFlag" />
              </div>
              <div class="obj-answer">
                <div>
                  <span>{{ $t('com.report.interviewRecord.text001') }}：</span>
                  <!-- <span v-if="currentQuestion.question.is_not_score && currentQuestion.question.is_not_score === 1">( 本题不计分 )</span> -->
                </div>
                <span v-if="!currentQuestion.question.answer">{{ $t('com.report.interviewRecord.text039') }} </span>
                <HtmlParse :htmlStr="currentQuestion.question.answer" :noWifi="noWifi" :totalVideoFlag.sync="totalVideoFlag" v-else />
              </div>
            </van-row>
            <div class="single-problem-estimate-wrapper">
              <div class="suggerlog-wrapper" v-if="currentQuestion.logList && currentQuestion.logList.length">
                <div class="suggerlog" v-for="(l, index) of currentQuestion.logList" :key="index">
                  <div class="line1">
                    <i18n path="com.report.interviewRecord.text010" tag="div" class="left">
                      <div place="name" class="name">{{ l.createByName }}</div>
                      <van-rate place="score" v-model="l.estimateScore" color="#ffd21e" :size="12" void-icon="star" :gutter="0" void-color="#eee" readonly />
                    </i18n>
                    <div class="right">{{ moment(l.createDate).format('YYYY-MM-DD HH:mm:ss') }}</div>
                  </div>
                  <div class="line2">
                    <i18n path="com.report.interviewRecord.text011" tag="span">
                      <span place="name"></span>
                      <span place="score" class="text">{{ l.estimateOpinion }}</span>
                    </i18n>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :title="$t('com.report.interviewRecord.text040')" v-if="objectQuestion.length > 0">
          <div class="question-type-title">{{ $t('com.report.interviewRecord.text040') }}</div>
          <van-row class="question" v-for="(q, index) in objectQuestion" :key="index">
            <div class="question-content">
              <HtmlParse :htmlStr="q.question.problem" :questionIndex="index + 1" :noWifi="noWifi" :totalVideoFlag.sync="totalVideoFlag" />
            </div>
            <div class="sub-option gray" v-if="q.question.problem_answer_method != 4">
              <div v-for="(op, ind) in q.questionOptionList" :key="ind">{{ op.optionCode }}、{{ op.optionContent }}</div>
            </div>
            <div class="sub-answer">
              <div>
                <span>{{ $t('com.report.interviewRecord.text001') }}：</span>
                <span v-if="!q.question.answer">{{ $t('com.report.interviewRecord.text039') }} </span>
                <span v-else :class="{ green: q.question.objective_score === 1, red: !q.question.objective_score, yellow: q.question.objective_score === 2 }">{{ q.question.answer }}</span>
              </div>
              <div class="line-20">
                <span v-if="q.question.answer">{{ q.question.objective_score | rightText }}</span>
                <!-- <span v-if="q.question.is_not_score && q.question.is_not_score === 1">( 本题不计分 )</span> -->
              </div>

              <div class="line-20">
                <span>{{ $t('com.report.interviewRecord.text041') }}：</span><span> {{ q.question.right_key | RightKey }}</span>
              </div>
            </div>
          </van-row>
        </div>

        <div :title="$t('com.report.interviewRecord.text023')" v-if="codeQuestion.length > 0">
          <div class="question-type-title">{{ $t('com.report.interviewRecord.text023') }}</div>

          <van-row class="question" v-for="(currentQuestionCode, index) in codeQuestion" :key="index">
            <div class="question-content">
              <HtmlParse :htmlStr="currentQuestionCode.question.problem" :questionIndex="index + 1" :noWifi="noWifi" :totalVideoFlag.sync="totalVideoFlag" />
            </div>
            <div class="obj-answer">
              <div>
                <span>{{ $t('com.report.interviewRecord.text001') }}：</span>
                <!-- <span v-if="currentQuestionCode.question.is_not_score && currentQuestionCode.question.is_not_score === 1">( 本题不计分 )</span> -->
              </div>
              <span v-if="!currentQuestionCode.question.answer">{{ $t('com.report.interviewRecord.text039') }} </span>
              <pre class="answer" v-else>{{ currentQuestionCode.question.answer }}</pre>
            </div>
          </van-row>
        </div>
      </div>
      <van-empty :description="$t('com.report.interviewRecord.text042')" v-else />
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import HtmlParse from '@/components/HtmlParse'
import { listInterviewOperLog, submitEstimate, report4MobileV2 } from '@/api/report'
import i18n from '../../utils/i18n'

export default {
  components: { HtmlParse },
  props: [],
  data() {
    return {
      moment: moment,
      objectQuestion: [], // 客观题
      subQuestion: [], //主观题
      codeQuestion: [], // 编程题
      currentPage: 1,
      fullRecoreds: [],
      currentPageCode: 1,
      active: 0,
      answerList: [],
      interviewName: '',
      questionTypeAry: [
        //
        i18n.t('com.report.interviewRecord.text017'),
        i18n.t('com.report.interviewRecord.text018'),
        i18n.t('com.report.interviewRecord.text019'),
        i18n.t('com.report.interviewRecord.text020'),
        i18n.t('com.report.interviewRecord.text021'),
        i18n.t('com.report.interviewRecord.text022'),
        i18n.t('com.report.interviewRecord.text023'),
      ],
      currentQuestion: { question: {}, video_url: [{}] }, //主观题区域题目
      currentQuestionCode: { question: {}, video_url: [{}] }, // 编程题区域题目
      sleep: false,

      answerInnerText: '',
      answerInnerImage: [],
      answerInnerVideo: [],
      problemVideoTips: [],
      answerVideoTips: [],
      showAnswerVideoSmallTipsFlag: false,
      totalVideoFlag: true,
      allProblemEstimateLogList: [],
    }
  },
  mounted() {},
  watch: {
    active() {
      this.sleep = true
      this.$nextTick(() => {
        this.sleep = false
        this.filterVideo()
      })
    },
    currentQuestion() {
      this.sleep = true
      this.$nextTick(() => {
        this.sleep = false
        this.filterVideo()
      })
    },
    answerResult: {
      handler: function(val) {
        if (val) {
          this.classify()
          this.getlistInterviewOperLog()
          this.$nextTick(() => {
            this.filterVideo()
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    getAIReportData() {
      report4MobileV2({ interviewId: this.interviewId }).then(res => {
        if (res && res.code === 0) {
          this.$store.commit('setAIData', res.data)
        }
      })
    },
    updateSingleProblemEstimateLogList(currentQuestion) {
      listInterviewOperLog({ interviewId: this.interviewId, problemId: currentQuestion.question.id }).then(res => {
        if (res && res.code === 0) {
          currentQuestion.logList = res.data
        }
      })
    },
    cancleSingleProblemEstimate(currentQuestion) {
      currentQuestion.estimateText = ''
      currentQuestion.row = 1
      currentQuestion.estimateValue = 0
    },
    submitSingleProblemEstimate(currentQuestion) {
      const data = {
        id: currentQuestion.question.id,
        estimateUserId: this.userInfo.userId,
        estimateOpinion: currentQuestion.estimateText,
        estimateScore: currentQuestion.estimateValue,
        interviewid: this.interviewId,
      }

      submitEstimate(data).then(res => {
        if (res && res.code === 0) {
          currentQuestion.estimateText = ''
          currentQuestion.row = 1
          currentQuestion.estimateValue = 0
          this.$toast({ type: 'info', message: i18n.t('com.report.interviewRecord.text029') })
          this.updateSingleProblemEstimateLogList(currentQuestion)
          this.getAIReportData()
        }
      })
    },
    getlistInterviewOperLog() {
      listInterviewOperLog({ interviewId: this.interviewId }).then(res => {
        if (res && res.code === 0) {
          this.allProblemEstimateLogList = res.data
          this.subQuestion.forEach(item => {
            let logList = this.allProblemEstimateLogList.filter(logItem => {
              return logItem.problemAnswerId === item.question.id
            })
            item.logList = logList
          })
        }
      })
    },
    showAnswerVideoSmallTips(index) {
      if (!this.totalVideoFlag) {
        return
      }
      this.totalVideoFlag = false

      this.$set(this.answerVideoTips, index, true)
      this.$refs['answerVideo' + index][0].play()

      this.showAnswerVideoSmallTipsFlag = true

      setTimeout(() => {
        this.$set(this.answerVideoTips, index, false)
      }, 5000)
    },
    listenVideo() {
      let videoArray = document.getElementsByTagName('video')
      if (videoArray && videoArray.length) {
        for (let i = 0; i < videoArray.length; i++) {
          videoArray[i].onplay = () => {
            for (let j = 0; j < videoArray.length; j++) {
              if (i !== j) {
                videoArray[j].pause()
              }
            }
          }
        }
      }
    },

    filterVideo() {
      this.$nextTick(() => {
        //移除a标签下载链接
        let tags = document.getElementsByTagName('a')
        for (let index = 0; index < tags.length; index++) {
          const element = tags[index]
          element.setAttribute('href', 'javascript:void(0)')
          element.removeAttribute('download')
          element.removeAttribute('target')
        }

        this.listenVideo()
      })
    },

    classify() {
      let tempArray = this.answerResult.slice(0)
      // 1, 6是视频题
      let subarr = [0]
      let objarr = [2, 3, 4, 5]
      let codearr = [7]

      tempArray.forEach(t => {
        const info = Array.isArray(t) ? t[0] : t
        if (subarr.includes(info.question.problem_answer_method)) {
          this.subQuestion.push({
            ...info,
            estimateValue: 0,
            estimateText: '',
            row: 1,
            logList: [],
          })
        }
        if (objarr.includes(info.question.problem_answer_method)) {
          this.objectQuestion.push(info)
        }
        if (codearr.includes(info.question.problem_answer_method)) {
          this.codeQuestion.push(info)
        }
      })
    },
    pageChange() {
      this.currentQuestion = this.subQuestion[this.currentPage - 1]
    },
    pageChangeCode() {
      this.currentQuestionCode = this.codeQuestion[this.currentPageCode - 1]
      console.log(this.currentQuestionCode)
    },
    getQuestionType(q) {
      let arr = this.questionTypeAry.find((item, index) => q === index)
      return arr
    },
  },
  computed: {
    interviewId() {
      return this.$store.state.interviewId
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    answerResult() {
      return this.$store.state.answerResult
    },
    answer_method() {
      return this.currentQuestion.question?.problem_answer_method
    },
    code_answer_method() {
      return this.currentQuestionCode.question?.problem_answer_method
    },
    noWifi() {
      return this.$store.state.noWifi
    },
  },
  filters: {
    RightKey(rightKey) {
      if ('true' === rightKey) {
        return i18n.t('com.report.interviewRecord.text034')
      } else if ('false' === rightKey) {
        return i18n.t('com.report.interviewRecord.text035')
      }
      return rightKey
    },
    rightText(key) {
      switch (key) {
        case 1:
          return i18n.t('com.report.interviewRecord.text036')
        case 2:
          return i18n.t('com.report.interviewRecord.text037')
        default:
          return i18n.t('com.report.interviewRecord.text038')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  height: 200px;
  margin: 10px 0;
}
.other-question {
  .single-problem-estimate-wrapper {
    margin-top: 0.2rem;
    .single-problem-estimate {
      border: 1px solid #cccccc;
      border-radius: 5px;
      padding: 0.2rem 0.3rem;
      .title {
        display: flex;
        align-items: center;
        .van-rate {
          margin-left: 0.4rem;
        }
      }
      /deep/ textarea {
        margin-top: 0.2rem;
        border: 1px solid #c8c9cc;
        border-radius: 4px;
        padding: 10px;
        transform: translateX(0.8);
        transform: translateX(10rpx);
        box-sizing: border-box;
      }
      .btn-list {
        width: 100%;
        display: flex;
        color: #ffffff;
        margin-top: 0.3rem;
        text-align: center;
        .submit {
          padding: 0.2rem 0;
          width: 2rem;
          background-color: #00aaff;
          border-radius: 5px;
        }

        .cancle {
          border-radius: 5px;
          padding: 0.2rem 0;
          width: 2rem;
          margin-left: 0.5rem;
          background-color: #00aaff;
        }
      }
    }

    .suggerlog-wrapper {
      margin-top: 0.2rem;
      border: 1px dashed #dddddd;
      border-radius: 5px;
      padding: 12px 16px;
      .suggerlog {
        padding: 0.2rem 0;
        .line1 {
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;
            align-items: center;
            .name {
              max-width: 1.7rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 600;
            }

            .dafen {
              margin-left: 0.1rem;
              //color: #cccccc;
            }
          }
        }
        .line2 {
          margin-top: 0.2rem;
        }
      }
    }
  }
  .question-type-title {
    text-align: left;
    width: 100%;
    font-size: 0.4rem;
    padding: 0.2rem 0;
    font-weight: 600;
  }
  .question-content {
    display: flex;
  }
  padding: 0.5rem 0.3rem 0 0.3rem;
  .sub-title {
    position: relative;
  }
  .noWifiTip {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 220px;

    .text {
      color: #ffffff;
    }
    .paly-btn {
      margin-top: 10px;
      color: #ffffff;
      width: 30px;
      img {
        width: 100%;
      }
    }
  }
  .inner-video-player {
    position: relative;
    width: 100%;
  }
  .small-tips {
    position: absolute;
    bottom: 20%;
    left: 0;
    color: #ffffff;
    z-index: 10;
    animation-name: enterIn;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    background-color: rgba(0, 0, 0, 0.5);
  }

  @keyframes enterIn {
    0% {
      transform: translateX(-1000vw);
    }
    10% {
      transform: translateX(0);
    }
    95% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  video {
    width: 100%;
    height: 200px;
    margin: 10px 0;
  }
  .score {
    padding: 0 20px;
    div {
      color: #666;
    }
    .test {
      margin: 10px 0;
      line-height: 20px;
    }
  }
  section {
    .gray {
      color: #666666;
    }
    .green {
      color: #6ed401;
    }
    .red {
      color: #ff0000;
    }
    .yellow {
      color: #f4d75e;
    }
    .line-20 {
      line-height: 20px;
    }
    .video {
      width: 100%;
      height: 200px;
      margin: 10px 0;
    }
    pre {
      word-break: break-word;
      white-space: pre-wrap;
    }

    /deep/ .van-tabs {
      .van-tabs__line {
        background: #1890ff;
      }
      .van-tab--active {
        font-weight: 550;
      }
      .van-tabs__content {
        padding-bottom: 40px;
      }
    }
    .comlun {
      flex-direction: column;
      justify-content: space-between;
    }
    /deep/ .obj-pagination {
      justify-content: center;
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      .van-pagination__page {
        flex-grow: 1;
      }
      li:last-child {
        display: none;
      }
      li:first-child {
        display: none;
      }
      li {
        cursor: pointer;
        //display: block;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        margin: 0 8px;
        border: 1px solid rgba(221, 221, 221, 1);
      }
    }
    .video-title {
      padding: 0 20px;
    }
    .obj-title {
      // padding: 0 20px;
      font-size: 12px;
      color: #666666;
    }
    .obj-answer {
      // margin: 0 20px;
      border: 1px dashed #dddddd;
      border-radius: 5px;
      padding: 12px 16px;
      padding: 14px;
      div:first-child {
        color: #333333;
        font-weight: 500;
        // font-size: 14px;
        // margin-bottom: 10px;
      }
    }
    .sub-answer {
      border: 1px dashed #dddddd;
      border-radius: 5px;
      padding: 12px 16px;
      margin-top: 10px;
      div:first-child {
        color: #333333;
        font-weight: 500;
        // font-size: 14px;
        // margin-bottom: 10px;
      }
    }
    /deep/.van-panel {
      .van-panel__header {
        padding-top: 14px;
        padding-bottom: 14px;
        .van-cell__title {
          font-size: 15px;
          color: #333333;
          font-weight: 550;
        }
      }
      .van-panel__content {
        padding: 0 20px;
      }
    }
    /deep/ .van-panel::after {
      content: none;
    }
    .question {
      /deep/ img {
        max-width: 100%;
      }
      .video {
        max-width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.obj-answer {
  pre {
    p {
      margin: 0 !important;
    }
  }
}
</style>

<template>
  <div class="general-resume">
    <div v-for="(val, vIndex) in standardList" :key="vIndex">
      <!-- 个人信息 -->
      <div class="container" v-if="val.menuKey === 'baseInfo' && val.isHasValue">
        <div class="title">
          <div class="title-prefix"></div>
          <div>{{ val.menuName }}</div>
        </div>
        <div class="content">
          <!-- <el-row> -->
          <div v-for="(childItem, cIndex) in val.children" :key="cIndex">
            <div v-if="childItem.fileValue" :span="(childItem.fileValue + childItem.menuName).length > 18 ? 24 : 12" class="content-col">
              <span class="content-title">{{ childItem.menuName }} : </span>
              <span v-if="dateType.indexOf(childItem.inputType) != -1">{{ childItem.fileValue }}</span>
              <span v-else>{{ childItem.fileValue }} {{ unitJudge(childItem.fileValue, childItem.unit, childItem.menuKey) }}</span>
            </div>
          </div>
          <!-- </el-row> -->
        </div>
      </div>
      <!-- 标准模板 -->
      <div class="container" v-if="complexTypeInfo.indexOf(val.menuKey) === -1 && val.menuKey !== 'baseInfo' && val.isHasValue">
        <div class="title">
          <div class="title-prefix"></div>
          <div>{{ val.menuName }}</div>
        </div>
        <div class="content">
          <!-- <el-row> -->
          <div v-for="(childItem, cIndex) in val.children" :key="cIndex">
            <div v-if="childItem.fileValue" :span="(childItem.fileValue + childItem.menuName).length > 18 ? 24 : 12" class="content-col">
              <span class="content-title">{{ childItem.menuName }} : </span>
              <span v-if="dateType.indexOf(childItem.inputType) != -1">{{ childItem.fileValue }}</span>
              <span v-else>{{ childItem.fileValue }} {{ unitJudge(childItem.fileValue, childItem.unit, childItem.menuKey) }}</span>
            </div>
          </div>
          <!-- </el-row> -->
        </div>
      </div>
      <!-- 复杂模板 -->
      <div v-if="complexTypeInfo.indexOf(val.menuKey) !== -1 && val.isHasValue">
        <div class="title container">
          <div class="title-prefix"></div>
          <div>{{ val.menuName }}</div>
        </div>
        <div class="complex-content" v-for="(ed, edIndex) in val.childrenList" :key="edIndex">
          <div class="complex-title">
            <span :key="edIndex" class="complex-title-time">
              <template v-for="(ele, eIndex2) in ed">
                <span v-if="benginDateList.indexOf(ele.menuKey) != -1 && ele.fileValue" :key="eIndex2">{{ formatStrDate(ele.fileValue) }}</span>
                <span v-if="endDateList.indexOf(ele.menuKey) != -1 && ele.fileValue" :key="ele.menuKey + eIndex2">
                  -
                  {{ ele.fileValue === '至今' ? $t('com.report.resume.text001') : formatStrDate(ele.fileValue) }}
                </span>
              </template>
            </span>
            <span class="complex-title-text">
              <template v-for="(ele, eIndex) in ed">
                <span v-if="defaultTile.indexOf(ele.menuKey) !== -1" :key="eIndex">
                  <span>{{ ele.fileValue }}</span>
                  <span v-if="ele.schoolRank" class="school_rank">
                    <span class="school_top">TOP</span>
                    <span class="school_num">{{ ele.schoolRank }}</span>
                  </span>
                </span>
              </template>
            </span>
            <!-- <span v-if="ele.schoolrank"></span> -->
          </div>
          <div class="complex-content-body">
            <template v-for="(ele, eIndex) in ed">
              <div v-if="getDateCheck(ele)" :key="eIndex" style="display: flex">
                <div class="body-title">{{ ele.menuName }}</div>
                <div class="body-content">{{ ele.fileValue }} {{ unitJudge(ele.fileValue, ele.unit, ele.menuKey) }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPositionRelationFormId, getEmployInfo, getTempleteList, showFormsList } from '@/api/report'
export default {
  props: {
    currentPerson: { default: () => ({ interviewId: '', positionId: '', jobseekerChannelId: '' }) },
  },
  data() {
    return {
      standardList: [],
      benginDateList: ['school_beginDate', 'work_beginDate', 'project_start'],
      endDateList: ['school_endDate', 'work_endDate', 'project_end'],
      defaultTile: ['schoolName', 'workplace', 'project_name'],
      // 多选参数
      checkboxType: ['2'],
      // 日期参数
      dateType: ['5', '7'],
      complexTypeInfo: ['eduExperience', 'workExperience', 'projectExperience'],
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.requestData()
    // })
    // this.requestData()
  },
  methods: {
    async requestData() {
      const formId = (await getPositionRelationFormId({ positionId: this.$store.state.positionId, formType: 1 }))?.data
      console.log('formId = ', formId)
      console.log('this.$store.state.interviewResult.phone = ', this.$store.state.interviewResult.phone)
      console.log('phone = ', this.phone)
      const phone = this.$store.state.interviewResult.phone
      const positionId = this.$store.state.interviewResult.positionid || this.$store.state.interviewResult.positionId
      const jobseekerChanneId = this.$store.state.interviewResult.jobseekerChannelId
      if (phone || positionId || jobseekerChanneId) {
        const result = await Promise.all([
          getEmployInfo({
            phone,
            positionId,
            jobseekerChanneId,
            formType: 1,
          }),
          getTempleteList({ formType: 1 }),
          showFormsList({ formId: formId }),
        ])
        console.log('resultresultresult', result)

        if (result[0] && result[0].code === 0 && result[1] && result[1].code === 0) {
          if (result[0].data && result[1].data) {
            const standardInfoList = result[0].data.infos
            const templeteList = result[1].data
            const formList = result[2].data.formConfigslist
            const parentMaxNumMap = new Map()
            standardInfoList.forEach(item => {
              const parentId = item.parentId
              if (parentMaxNumMap.has(parentId)) {
                if (parentMaxNumMap.get(parentId) < item.arrayIndex) {
                  parentMaxNumMap.set(parentId, item.arrayIndex)
                }
              } else {
                parentMaxNumMap.set(parentId, item.arrayIndex)
              }
            })
            const parentItemArray = []

            for (const [parentId, maxNum] of parentMaxNumMap) {
              for (let i = 0; i <= maxNum; i++) {
                const parentItem = {
                  parentId: parentId,
                  menuKey: '',
                  menuName: '',
                  children: [],
                  childrenList: [],
                  isHasValue: false,
                  sortIndex: 0,
                }

                standardInfoList.forEach(chidlItem => {
                  if (chidlItem.parentId === parentId && chidlItem.arrayIndex === i) {
                    if (!parentItem.isHasValue) {
                      parentItem.isHasValue = chidlItem.fileValue ? true : false
                    }
                    const arr = formList.find(sortIndexItem => {
                      return sortIndexItem.menuKey === chidlItem.menuKey
                    })
                    parentItem.children.push({ ...chidlItem, sortIndex: arr && arr.sortIndex })
                  }
                })

                const templeteItem = templeteList.find(templeteItem => {
                  return parseInt(templeteItem.id) === parseInt(parentId)
                })

                const templeteSortIndexItem = formList.find(sortIndexItem => {
                  return sortIndexItem.menuKey === templeteItem?.menuKey
                })
                parentItem.menuName = templeteItem?.menuName
                parentItem.menuKey = templeteItem?.menuKey
                parentItem.sortIndex = templeteSortIndexItem?.sortIndex ?? ''
                parentItemArray.push(parentItem)
              }
            }
            const eduList = []
            const workList = []
            const projectList = []
            parentItemArray.forEach(item => {
              if (item.menuKey === 'eduExperience') {
                eduList.push(item.children)
              } else if (item.menuKey === 'workExperience') {
                workList.push(item.children)
              } else if (item.menuKey === 'projectExperience') {
                projectList.push(item.children)
              }
            })
            const res = new Map()
            const resultList = parentItemArray.filter(item => !res.has(item['menuKey']) && res.set(item['menuKey'], 1))
            resultList.forEach(item => {
              if (item.menuKey === 'eduExperience') {
                item.childrenList = eduList
              } else if (item.menuKey === 'workExperience') {
                item.childrenList = workList
              } else if (item.menuKey === 'projectExperience') {
                item.childrenList = projectList
              }
            })
            this.standardList = resultList
            console.log('this.standardList', this.standardList)
            // 总排序
            this.standardList.sort((a, b) => {
              return a.sortIndex - b.sortIndex
            })

            // 子层排序
            this.standardList.forEach(item => {
              if (this.complexTypeInfo.indexOf(item.menuKey) === -1) {
                item.children.sort((a, b) => {
                  return a.sortIndex - b.sortIndex
                })
              } else {
                item.childrenList.forEach(ele => {
                  ele.sort((a, b) => {
                    return a.sortIndex - b.sortIndex
                  })
                })
              }
            })
          }
        }
      }
    },
    formatStrDate(val) {
      // 判断是否是时间戳
      const re = /^\d+$/
      if (re.test(val)) {
        const date = new Date(parseInt(val))
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        return y + '.' + m
      } else {
        return val.replace(/\/|\\|-/g, '.').substring(0, 7)
      }
    },
    getDateCheck(ele) {
      return this.defaultTile.indexOf(ele.menuKey) === -1 && this.benginDateList.indexOf(ele.menuKey) === -1 && this.endDateList.indexOf(ele.menuKey) === -1 && ele.fileValue
    },
    unitJudge(value, unit, key) {
      if (value.indexOf(unit) !== -1) {
        return ''
      } else if (key === 'actualWorkyear') {
        if (value === 'number' && !isNaN(value)) {
          return unit
        }
        return ''
      } else if (key === 'expectSalary') {
        if (value === '面议') {
          return ''
        }
      }
    },
  },
  computed: {
    interviewResult() {
      return this.$store.state.interviewResult
    },
    phone() {
      return this.$store.state.interviewResult?.phone
    },
    jobseekerChannelId() {
      return this.$store.state.interviewResult?.jobseekerChannelId
    },
  },

  watch: {
    jobseekerChannelId: {
      immediate: true,
      deep: true,
      handler() {
        this.requestData()
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.general-resume {
  .container {
    padding: 0 0.2rem;
  }
  .title {
    display: flex;
    // margin-top: 8px;
    // height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    // line-height: 20px;
    align-items: center;
    .title-prefix {
      // margin-top: 8px;
      margin-right: 8px;
      width: 4px;
      height: 16px;
      background: #d8d8d8;
    }
  }
  .content {
    padding: 10px;
    font-size: 14px;
    .content-title {
      margin-right: 8px;
    }
    .content-col {
      margin-bottom: 8px;
      span {
        word-break: break-all;
      }
    }
  }
  .complex-content {
    margin-top: 20px;
    margin-bottom: 20px;
    .complex-title {
      // width: 100%;
      // height: 32px;
      line-height: 32px;
      min-height: 32px;
      background: #f4f4f6;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .complex-title-time {
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #666666;
        width: 150px;
        margin-left: 20px;
        margin-right: 20px;
        display: inline-block;
        text-align: left;
        white-space: nowrap;
      }
      .complex-title-text {
        // height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 32px;
        width: 100%;
        padding-right: 9px;

        .school_rank {
          color: #ffffff;
          font-size: 14px;
          font-family: ArialMT;
          color: #ffffff;
          background: linear-gradient(180deg, #7eeece 0%, #0f7dff 100%);
          border-radius: 2px;
          line-height: 16px;
          margin-left: 14px;
          padding: 0 8px 0 5px;
          .school_top {
            font-size: 8px;
            font-weight: 400;
          }
          .school_num {
            font-style: italic;
          }
        }
      }
    }
    .complex-content-body {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding-left: 12px;
      .body-title {
        // width: 80px;
        text-align: left;
        padding: 8px 0px 8px 10px;
        margin-right: 20px;
        width: 90px;
      }
      .body-content {
        flex: 1;
        padding: 8px 0;
        white-space: pre-line;
        word-break: break-word;
      }
    }
  }
}
</style>

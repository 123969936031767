/* eslint-disable quotes */
export default {
  text001: 'Interview arrangement',
  text002: 'Interview time',
  text003: 'Interview round',
  text004: 'Recruiter',
  text005: 'Interview assistant',
  text006: 'Tecent meeting No.',
  text007: 'Meeting No.',
  text008: 'Tecent meeting link',
  text009: 'Interview password',
  text010: 'Interviewer information',
  text011: 'Interviewer name',
  text012: 'Interviewer link',
  text013: 'Copy Link',
  text014: 'Interviewer password',
  text015: `Search for "Dexian's remote interview" in the WeChat applet and enter the password to start the interview.`,
  text016: 'View feedback',
  text017: 'Input',
  text018: 'Push',
  text019: 'Cancellation not done',
  text020: 'Interview location',
  text021: 'Not interviewed',
  text022: 'Waiting for hr to arrange an interview~',
  text023: 'You can click the button below to schedule or update your interview time',
  text024: 'Update free time',
  text025: 'No response',
  text026: 'Accepted',
  text027: 'Rejected',
  text028: 'Cancelled',
  text029: 'Offline interview',
  text030: "Dexian's online interview",
  text031: 'Online voice',
  text032: 'Online Tecent',
  text033: 'Video interview',
  text034: 'This feature requires a login operation',
  text035: 'Are you sure to modify the interview to not in progress?',
  text036: 'Successful operation',
  text037: 'You do not have permission to operate, please contact HR',
  text038: 'After confirmation, you will be able to fill in feedback to the candidate',
  text039: 'Withdrawal interview not conducted',
  text040: 'Copy successfully',
  text041: 'Feedback result cannot be empty',
  text042: 'feedback successfully',
  text043: 'Error',
  text044: 'Reject',
  text045: 'Pass',
  text046: 'TBD',
  text047: 'Not interviewed',
  text048: 'Total score',
  text049: '{0}points',
  text050: 'Module score',
  text051: 'Comprehensive feedback',
  text052: 'Interview result',
  text053: 'Comprehensive assessment',
  text054: 'Here is my objective feedback and assessment, if you have any question, please contact with us at any time',
  text055: 'Content is not allowed to be empty',
  text056: 'Cancel',
  text057: 'Confirm',
  text058: '{content}content cannot be empty',
  text059: 'Feedback successfully',
}

<template>
  <div class="edition-contrast">
    <div class="headers">
      <div class="header">功能</div>
      <div class="header header1">标准版本</div>
      <div class="header header1">高级版本</div>
      <div class="header header1">商业版本</div>
    </div>
    <div class="moudles">
      <div v-for="(m, index) in moudles" :key="index" class="module">
        <div class="m-header l-item">
          <div class="item l-label m-title mb">{{ m.title }}</div>
          <div class="item l-free mb"></div>
          <div class="item l-advance mb"></div>
          <div class="item l-business mb"></div>
        </div>
        <div class="m-list">
          <div v-for="(l, i) in m.list" :key="`${i}_l`" class="l-item">
            <div class="item l-label">{{ l.label }}</div>
            <div class="item l-free">
              <div v-if="l.freeValue">{{ l.freeValue }}</div>
              <img v-else-if="l.free" src="@/assets/price/icon_sel.svg" />
              <div v-else>-</div>
            </div>
            <div class="item l-advance">
              <div v-if="l.advanceValue">{{ l.advanceValue }}</div>
              <img v-else-if="l.advance" src="@/assets/price/icon_sel.svg" />
              <div v-else>-</div>
            </div>
            <div class="item l-business">
              <div v-if="l.businessValue">{{ l.businessValue }}</div>
              <img v-else-if="l.business" src="@/assets/price/icon_sel.svg" />
              <div v-else>-</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      moudles: [
        {
          title: '工作台',
          list: [
            {
              label: '保温期提醒',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '导入职位',
          list: [
            {
              label: '添加职位',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '导入职位',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '简历导入',
          list: [
            {
              label: '插件同步简历',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '邮箱导入简历',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '候选人管理',
          list: [
            {
              label: '招聘流程管理',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '简历',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: 'AI简历解析',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '人工评估',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '用人部门筛选',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '安排面试',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '录用',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '待入职',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '已入职',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '标签功能',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '邀请更新简历',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '投递次数限制',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '附件信息',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '邮件发送状态查看',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '候选人搜索',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '候选人信息登记表',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '面试管理',
          list: [
            {
              label: '复杂面试项目排班系统',
              free: false,
              advance: true,
              business: true,
            },
            {
              label: '面试签到二维码',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '面试管理',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '预约面试',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '职位管理',
          list: [
            {
              label: '职位管理相关功能',
              free: false,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: 'HC管理',
          list: [
            {
              label: 'HC管理',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '人才库管理',
          list: [
            {
              label: '人才库管理',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '新建人才库',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '人才库激活',
              free: false,
              advance: true,
              business: true,
            },
            {
              label: '激活效果追踪',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '移动招聘',
          list: [
            {
              label: '简历筛选',
              free: false,
              advance: true,
              business: true,
            },
            {
              label: 'AI全息简历筛选',
              free: false,
              advance: true,
              business: true,
            },
            {
              label: '面试通知',
              free: false,
              advance: true,
              business: true,
            },
            {
              label: '面试官时间选择',
              free: false,
              advance: true,
              business: true,
            },
            {
              label: '面试反馈',
              free: false,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '招聘门户',
          list: [
            {
              label: '招聘门户（电脑版）',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '招聘门户（手机版）',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '内推',
          list: [
            {
              label: '设置内推官网',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '职位发布到内推',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '认证为企业员工',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: 'HR审核自动',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: 'HR审核手动',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '设置职位相应的奖励规则',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '上传简历',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '分享到朋友圈',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '查看内推进来的简历',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: '猎头RPO管理',
          list: [
            {
              label: '猎头RPO管理系统高级版本',
              free: false,
              advance: false,
              business: true,
            },
          ],
        },
        {
          title: '报表管理',
          list: [
            {
              label: '阶段数据分析',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '职位进展',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '渠道质量',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '渠道时间分析',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '招聘效率',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '用人部门效率',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '候选人分析',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '面试分析',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '归档原因分析',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '职位数据对比分析',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '自定义报表高级版本',
              free: false,
              advance: false,
              business: true,
            },
          ],
        },
        {
          title: '设置中心',
          list: [
            {
              label: '权限管理',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '项目管理',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: 'RPA设置',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '消息模板',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '简历模版',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '登记表模版自定义',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '面试评价表自定义',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '招聘渠道',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '系统智能标签设置',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '自动化筛选规则',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '招聘流程自定义',
              free: false,
              advance: false,
              business: true,
            },
            {
              label: '自动化功能',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '自动拒信',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '消息提醒设置',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '锁定功能',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: '自定义简历智能标签',
              free: false,
              advance: false,
              business: true,
            },
          ],
        },
        {
          title: '用人部门账户',
          list: [
            {
              label: '手机端操作',
              free: true,
              advance: true,
              business: true,
            },
            {
              label: 'PC端操作',
              free: true,
              advance: true,
              business: true,
            },
          ],
        },
        {
          title: 'AI视频面试',
          list: [
            {
              label: 'L5级别AI视频面试（可单独内购）',
              free: false,
              advance: false,
              business: false,
            },
            {
              label: 'L2级别AI视频面试（可单独内购）',
              free: true,
              freeValue: '50次',
              advance: true,
              advanceValue: '100次',
              business: true,
              businessValue: '200次',
            },
          ],
        },
        {
          title: '在线模拟编程系统',
          list: [
            {
              label: '在线程序员编程考试（可单独内购）',
              free: true,
              freeValue: '50次',
              advance: true,
              advanceValue: '100次',
              business: true,
              businessValue: '200次',
            },
          ],
        },
        {
          title: '在线笔试系统',
          list: [
            {
              label: '开放式在线笔试系统（可单独内购）',
              free: true,
              freeValue: '50次',
              advance: true,
              advanceValue: '100次',
              business: true,
              businessValue: '200次',
            },
          ],
        },
        {
          title: '心理测评',
          list: [
            {
              label: '认知能力测评（可单独内购）',
              free: true,
              freeValue: '50次',
              advance: true,
              advanceValue: '100次',
              business: true,
              businessValue: '200次',
            },
            {
              label: '心理健康测评（可单独内购）',
              free: true,
              freeValue: '50次',
              advance: true,
              advanceValue: '100次',
              business: true,
              businessValue: '200次',
            },
            {
              label: '职业性格测评（可单独内购）',
              free: true,
              freeValue: '50次',
              advance: true,
              advanceValue: '100次',
              business: true,
              businessValue: '200次',
            },
          ],
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.edition-contrast {
  // width: 1205px;
  margin: auto;
  .headers {
    display: flex;
    // justify-content: center;
    .header {
      height: 66px;
      width: 340px;
      padding: 0px 5px 0 15px;
      background: rgba(25, 144, 255, 0.06);
      // padding: 27px 24px 27px 24px;
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      display: flex;
      align-items: center;
      // justify-content: center;
    }
    .header1 {
      width: 270px;
      text-align: center;
      padding: 0px;
      justify-content: center;
    }
    .header + .header {
      margin-left: 5px;
    }
  }
  .moudles {
    .module {
      .m-header {
        display: flex;
        // justify-content: center;
        height: 64px;

        margin: auto;
      }
      .m-list {
        // display: flex;
        // justify-content: center;
        // width: 1205px;
      }
      .l-item {
        display: flex;
        height: 48px;
        -webkit-transition: all ease 300ms;
        -o-transition: all ease 300ms;
        transition: all ease 300ms;
        .item {
          width: 270px;
          background: #fafafa;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .l-label {
          width: 340px;
          background: #ffffff;
          justify-content: left;
          padding: 0px 5px 0 15px;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
        }
        .m-title {
          font-size: 12px;
          font-weight: 600;
          color: #333333;
          line-height: 24px;
          border-bottom: 1px solid #eeeeee;
        }
        .l-free {
        }
        .l-advance {
        }
        .l-business {
        }
        .mb {
          margin-bottom: 4px;
        }
        .item + .item {
          margin-left: 5px;
        }
      }
      .l-item:hover {
        background: #ffffff;
        transform: scale(1, 1.1);
        box-shadow: 0px 2px 16px 0px rgba(0, 35, 67, 0.08);
        .m-title {
          border: 0px;
        }
        .item {
          background: #ffffff;
        }
      }
    }
  }
}
</style>

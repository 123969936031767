<template>
  <div class="report-mobile">
    <div v-if="!showDialog">
      <!-- <van-nav-bar :title="`${baseInfo.name}的面试报告`" left-arrow @click-left="onClickLeft" /> -->
      <section>
        <!-- 基础信息 -->
        <div class="base-info">
          <!-- <img class="logo" src="../assets/avatar.png" alt="logo" /> -->
          <img class="picture" :src="imgUrl ? imgUrl : baseInfo.gender !== 1 ? require('../assets/man.png') : require('../assets/female.png')" alt="picture" />
          <div class="info-content">
            <div class="font-small font-light abs">INTERVIEW REPORT</div>
            <div class="font-blod">
              <span>{{ baseInfo.name }}的面试报告</span>
            </div>
            <div class="margin-bottom-10 font-light abs">手机号码：{{ baseInfo.phone }}</div>
            <div class="margin-bottom-10 font-light abs">电子邮箱：{{ baseInfo.email }}</div>
            <div class="margin-bottom-10 font-light abs">应聘职位：{{ baseInfo.jobtitle }}</div>
          </div>
        </div>
        <!-- 教育经历 -->
        <van-panel class="module" title>
          <template v-slot:header>
            <div class="van-cell van-panel__header">
              <div class="van-cell__title cell-title" style="display:flex;justify-content:space-between">
                <span>教育经历</span>
                <span style="color:#1890ff" @click="lookReport">查看原始简历</span>
              </div>
            </div>
          </template>
          <van-steps direction="vertical">
            <van-step v-for="(e, index) in education" :key="index + Math.random()">
              <div class="spot" slot="active-icon"></div>
              <div class="spot" slot="inactive-icon"></div>
              <div class="ed-time font-light font-15">{{ e.startdate }} - {{ e.enddate }}</div>
              <div class="ed-title font-15">{{ e.school }}</div>
              <div class="ed-content">{{ e.education | F_education }} · {{ e.professional }}</div>
            </van-step>
          </van-steps>
        </van-panel>
        <!-- 工作经验 -->
        <!-- <van-panel class="module" title="面试反馈">
          <van-steps direction="vertical">
            <van-step
              v-for="(item, index) in list"
              class="online"
              :class="{ 'no-response': item.responseStatus === 0, accept: item.responseStatus === 1, reject: item.responseStatus === 2 }"
              :key="index"
              :timestamp="'面试安排 ' + item.createDate"
              placement="top"
            >
              <div slot="dot" class="dot "></div>
              <div class="el-card interview">
                <div class="tip">{{ interviewModeName[item.interviewMode] }}</div>
                <p class="item">
                  <span class="label">面试时间</span>
                  <span class="value">{{ item.interviewDate }}<span style="margin-left: 15px;"></span>{{ item.interviewStartTime }}-{{ item.interviewEndTime }} </span>
                </p>
                <p class="item" v-if="item.interviewMode === 0">
                  <span class="label">面试地点</span>
                  <span class="value">
                    {{ item.offlineLocation }}
                  </span>
                </p>
                <p class="item">
                  <span class="label">面试时长</span>
                  <span class="value">{{ getTime(item) }}</span>
                </p>
                <div class="item">
                  <span class="label" style="height:21px;float: left;"><span>面试反馈</span></span>
                  <div style="float:left">
                    <p v-for="(feedback, $index) in item.interviewFeedbackList" :key="'feedback' + $index">
                      <span class="value" style="display:block;margin-bottom:10px;" v-if="!feedbackEditMap[feedback.id]">
                        {{ feedback.lastUpdateByName }}
                        <span v-if="feedback.interviewResult || feedback.interviewResult === 0"
                          >&nbsp;&nbsp;#<span class="orange" :class="{ red: feedback.interviewResult == 0, green: feedback.interviewResult == 1 }">{{
                            getFeedbackStatus(feedback.interviewResult)
                          }}</span></span
                        >
                        &nbsp;&nbsp;#{{ feedback.feedbackContent }}
                      </span>
                      <span class="value" v-if="feedbackEditMap[feedback.id]"> </span>
                    </p>
                  </div>
                </div>
              </div>
            </van-step>
          </van-steps>
        </van-panel>-->
        <van-panel class="module" title="工作经验">
          <van-steps direction="vertical">
            <van-step v-for="(w, index) in workInfo" :key="index + Math.random()">
              <div class="spot" slot="active-icon"></div>
              <div class="spot" slot="inactive-icon"></div>
              <div class="ed-time font-light font-15">{{ w.startdate }} - {{ w.enddate }}</div>
              <div class="ed-title" style="font-size: 18px;">{{ w.companyName }}</div>
              <div class="ed-title font-15">职位：{{ w.jobtitle }}</div>
              <pre class="ed-content" v-html="w.workContent"></pre>
            </van-step>
          </van-steps>
        </van-panel>
        <van-panel class="module" title="面试反馈">
          <van-steps direction="vertical">
            <div class="interview">
              <div class="log" v-if="list.length">
                <!-- <div class="title" style="font-size:16px">面试反馈</div> -->
                <!-- <el-timeline class="module_content"> -->
                <van-step
                  v-for="(item, index) in list"
                  class="online"
                  :class="{ 'no-response': item.responseStatus === 0, accept: item.responseStatus === 1, reject: item.responseStatus === 2 }"
                  :key="index"
                  :timestamp="'面试安排 ' + item.createDate"
                  placement="top"
                >
                  <div class="spot" slot="active-icon"></div>
                  <div class="spot" slot="inactive-icon"></div>
                  <!-- <div slot="dot" class="dot"></div> -->
                  <el-card>
                    <div class="tip">{{ interviewModeName[item.interviewMode] }}</div>
                    <p class="item">
                      <span class="label">面试时间</span>
                      <span class="value">
                        {{ item.interviewDate }}
                        <span style="margin-left: 15px;"></span>
                        {{ item.interviewStartTime }}-{{ item.interviewEndTime }}
                      </span>
                    </p>
                    <p class="item" v-if="item.interviewMode === 0">
                      <span class="label">面试地点</span>
                      <span class="value">{{ item.offlineLocation }}</span>
                    </p>
                    <p class="item">
                      <span class="label">面试时长</span>
                      <span class="value">{{ getTime(item) }}</span>
                    </p>
                    <div class="item" style v-if="item.interviewFeedbackList && item.interviewFeedbackList.length > 0">
                      <span class="label" style="height:21px;float: left;">
                        <span>面试反馈</span>
                      </span>
                      <div style="float:left">
                        <p v-for="(feedback, $index) in item.interviewFeedbackList" :key="'feedback' + $index">
                          <span class="value" style="display:block;margin-bottom:10px;" v-if="!feedbackEditMap[feedback.id]">
                            {{ feedback.lastUpdateByName }}
                            <span v-if="feedback.interviewResult || feedback.interviewResult === 0">
                              &nbsp;&nbsp;#
                              <span class="orange" :class="{ red: feedback.interviewResult == 0, green: feedback.interviewResult == 1 }">{{ getFeedbackStatus(feedback.interviewResult) }}</span>
                            </span>
                            &nbsp;&nbsp;#{{ feedback.feedbackContent }}
                          </span>
                          <span class="value" v-if="feedbackEditMap[feedback.id]"></span>
                        </p>
                      </div>
                    </div>
                  </el-card>
                </van-step>
                <!-- </el-timeline> -->
              </div>
              <div v-else>
                <p style="color:#666666;padding-left:1em">暂无面试反馈</p>
              </div>
            </div>
          </van-steps>
        </van-panel>
        <!-- 身份证信息 -->
        <van-panel class="module" title="候选人身份信息" v-if="idCardInfo">
          <van-row type="flex" class="cell" justify="space-around" align="start">
            <van-col :span="24">
              <div class="card_info">
                <div class="info">
                  <img :src="item" @click="open" v-for="(item, index) in mgUrlPrewer" :key="index" />
                </div>
              </div>
            </van-col>
          </van-row>
        </van-panel>
        <!-- 得分总览 -->
        <AiTanlent :aiData="reportDetail" :isNoshow="isNoshow" :usetime="usetime" :labelGetScore="labelGetScore" :labelTotalScore="labelTotalScore"></AiTanlent>
        <!--  潜能分析 -->
        <van-panel class="module" v-if="reportDetail.sortaiscore && reportDetail.sortaiscore.length > 0">
          <template v-slot:header>
            <div class="van-cell van-panel__header">
              <div class="van-cell__title">
                <span>AI Talent-DNA 核心潜能分析</span>
                <span v-if="reportDetail.aitdna === null || reportDetail.aitdna == -1">分析中</span>
                <span else class="padne-score">{{ reportDetail.aitdna }}</span> / 5 分
              </div>
            </div>
          </template>
          <van-row type="flex" class="cell" justify="space-around" align="start" v-for="(item, index) in reportDetail.sortaiscore" :key="index + Math.random()">
            <van-col :span="24">
              <div class="cell-title">
                <div>{{ item.competence_name ? item.competence_name : '胜任力未设置' }}</div>
                <!-- <van-progress
                  :percentage="progressNum(item.accurate_score)"
                  stroke-width="10"
                  :class="progressColor(item.accurate_score)"
                  :show-pivot="Boolean(item.accurate_score)"
                  :pivot-text="String(item.accurate_score)"
                />-->
                <!-- <div class="font-light font-12">{{ item.score }}/5分</div> -->
              </div>
              <div class="cell-content font-12 font-light">{{ item.competence_comment }}</div>
            </van-col>
          </van-row>
        </van-panel>
        <!-- AI 表情分析 -->
        <van-panel class="module" v-if="reportDetail.emotionscore && JSON.stringify(reportDetail.emotionscore) != '{}'">
          <template v-slot:header>
            <div class="van-cell van-panel__header">
              <div class="van-cell__title">
                <span>AI 表情分析</span>
                <span v-if="reportDetail.aiemotion === null || reportDetail.aiemotion == -1">分析中</span>
                <span else class="padne-score">{{ reportDetail.aiemotion }}</span> / 5 分
              </div>
            </div>
          </template>
          <ExpressionCharts :emotionscore="reportDetail.emotionscore"></ExpressionCharts>
          <ul class="top-emotion">
            <li v-for="(em, index) in reportDetail.emotionscore.topemotion" :key="index">
              <div class="item_li" style="margin-bottom: 18px">
                <span style="margin-right: 10px;font-size: 12px;">{{ em.emotionname }}</span>
                <span class="font-light font-small" style="line-height:20px;">{{ em.emotiondesc }}</span>
              </div>
            </li>
          </ul>
        </van-panel>
        <!-- 定制化考核项目得分 -->
        <van-panel class="module" v-if="isNoshow">
          <template v-slot:header>
            <div class="van-cell van-panel__header">
              <div class="van-cell__title">
                <span>定制化考核项目得分</span>
                <span class="padne-score">{{ labelGetScore }}</span>
                / {{ labelTotalScore }} 分
              </div>
            </div>
          </template>
          <div class="font-light font-small" style="margin-top: 10px;padding-right:20px">定制化考核项目基于企业自定义要求对以上维度进行考核，作为该岗位人才筛选时的重要参考维度。</div>
          <DescList v-for="(item, index) in labelScore" :key="index" :title="item.label" :desc="item.labeldesc" :totle="item.labelinfo.totalLabelScore" :score="item.labelinfo.score"></DescList>
        </van-panel>
        <!-- 关键指标体系解析 -->
        <van-panel class="module" title="关键指标体系解析" v-if="reportDetail.sortablescore && reportDetail.sortablescore.length > 0">
          <div style="margin: 10px 0 5px 0">
            该岗位人才画像测评时分为
            <span class="padne-score">{{ reportDetail.sortablescore.length }}</span> 内容
          </div>
          <ul class="ul">
            <li v-for="(item, index) in reportDetail.sortablescore" :key="index">{{ item.label }}</li>
          </ul>
          <DescList title="AI 人才画像总分" :showScore="false">
            <template v-slot:desc>
              <span class="font-12 justify">AI人才画像总分是基于企业岗位的胜任力要求，利用AI技术对候选人的核心潜力（AI Talent DNA)、表情、声音等进行深度分析得出的综合评价。</span>
            </template>
          </DescList>
          <DescList title="AI Talent-DNA" :showScore="false">
            <template v-slot:desc>
              <span class="font-12 justify">
                Talent DNA是我们针对候选人在该岗位上的胜任能力要求为企业量身定制的一组核心胜任力，拥有这一组核心胜任力要求的候选人便拥有了企业在该岗位上需要的“人才基因”。 AI Talent
                DNA得分是我们自研发的领先NLP算法模型基于对候选人全篇幅（篇章级语义）回答的深度学习，在候选人针对胜任力问题回答的整体内容基础上做出精准评价打分，是衡量人岗匹配的重要核心指标。
              </span>
            </template>
          </DescList>
          <DescList title="AI 表情分析" :showScore="false">
            <template v-slot:desc>
              <span class="font-12 justify"
                >人的面部拥有的持续时间在1/25s~1/2s的表情，称为微表情；与之相对的，持续时间稍长，在1s~5s之间的表情，称为宏表情。我们的AI表情分析是基于对候选人在面部数千个探测点上，从宏表情的七个维度做出深度数据挖掘（开心、中性、伤心、惊讶、恐惧、愤怒、厌恶等）后由AI算法进行的综合分析。目前，宏表情分析在理论研究上已相对成熟，是一门科学；而微表情在研究上还处于早期，离真正在商业场景上的应用至少还需要几年的时间。因此我们严谨地采用了宏表情分析，作为面试过程里的参考指标。</span
              >
            </template>
          </DescList>
          <DescList title="AI声音分析" :showScore="false">
            <template v-slot:desc>
              <span class="font-12 justify"
                >人的声音在沟通表达上有丰富呈现和作用。我们的AI声音分析是基于自研发领先人工智能语音算法对候选人回答面试问题时的音量、音调等五大声音维度进行综合分析，从而判断候选人在语音表达上的水平。</span
              >
            </template>
          </DescList>
        </van-panel>
        <div class="foot">
          <h4>关于报告</h4>
          <div>
            本报告是基于上海近屿智能科技有限公司研发的AI得贤招聘官系统⾃动生成，可广泛⽤于企业的招聘选拔活动。
            本报告基于答题者所回答测验题目的结果生成，充分体现了答题者的答案含义。在理解报告内容时，必须考虑答题者答题时的主观特征。
          </div>
          <div>
            本报告为自动生成，使用者能够使用软件对报告⽂本进行修改。
            本测验的结果可以为企业招聘、选拔、培养管理类人才提供参考，但不能作为唯一的依据。应当与应聘者的其它信息，简历背景资料、面试结果、工作经历等结合参照解释。
            本报告结果注意保密，只供公司领导、⼈力资源部相关负责人或测评者本⼈阅读，并请在专业⼈士指导下阅读。
          </div>
          <p>
            版权声明：本报告包含的所有内容（包括但不限于文本、数据、图片、图标、logo等）都受到商标法、知识产权法的保护，归上海近屿智能科技有限公司所有。未经上海近屿智能科技有限公司书面许可，任何人不得使用、修改、复制、翻译、公开及出售任何与本报告有关的内容及形式。
          </p>
        </div>
      </section>
      <div class="footer">
        <van-button plain type="primary" @click="pageTo('reportDetail')">视频面试记录</van-button>
        <van-button plain type="primary" @click="showEmailDialog = true" :icon="require('../assets/recommend.svg')">推荐给好友</van-button>
      </div>
    </div>
    <!-- 报告密码 -->
    <van-dialog v-model="showDialog" confirm-button-color="#1890FF" closeOnPopstate @confirm="validate" :before-close="closeDialog" @closed="afterClose" title="请输入报告密码">
      <van-cell-group>
        <van-field v-model="psw" label="报告密码" placeholder="请输入报告密码" @blur="validate" />
      </van-cell-group>
    </van-dialog>
    <!-- 分享报告 -->
    <van-dialog v-model="showEmailDialog" closeOnClickOverlay closeOnPopstate :show-confirm-button="false" @closed="afterClose" title="请输入分享邮箱">
      <van-cell-group>
        <van-field v-model="email" label="邮箱" placeholder="请输入好友邮箱" />
      </van-cell-group>
      <van-button class="van-button--large van-dialog__confirm" @click="validateEmail">确认</van-button>
    </van-dialog>
  </div>
</template>
<script>
// import axios from 'axios'
import ExpressionCharts from '../components/ExpressionCharts'
import DescList from '../components/DescList'
import AiTanlent from '../components/AiTanlent'
// import host from '../config/host'
import { interviewInfo, findAllEducation, shareByEmail, reportNew4Mobile, findAllWorkInfo, findId, checkReportPwd, interviewInfoTalent, convertFile, queryInterviewPlan } from '../api/report'
import moment from 'moment'
import { ImagePreview } from 'vant'
export default {
  components: {
    ExpressionCharts,
    DescList,
    AiTanlent,
  },
  props: {},
  data() {
    return {
      idCardValue: '',
      show: false,
      moment: moment,
      list: [],
      copyForm: null,
      feedbackEditMap: {},
      interviewModeName: ['线下', '线上视频', '线上语音'],
      value1: 0,
      value2: 0,
      rate: 10,
      showDialog: false,
      showEmailDialog: false,
      psw: '',
      email: '',
      error: '', // 密码状态
      emailStatus: false,
      interviewId: '',
      companyId: '',
      positionId: '',
      emtions: [
        { emotioncount: 1, emotionname: '惊讶' },
        { emotioncount: 1, emotionname: '高兴' },
        { emotioncount: 163, emotionname: '中性' },
        { emotioncount: 57, emotionname: '难过' },
        { emotioncount: 32, emotionname: '厌恶' },
        { emotioncount: 8, emotionname: '愤怒' },
        { emotioncount: 1, emotionname: '害怕' },
      ],
      baseInfo: {},
      idCard: {},
      education: [],
      workInfo: [],
      reportDetail: { sortablescore: [], usetime: '' },
      usetime: 0,
      isNoshow: true,
      labelGetScore: 0,
      labelScore: [],
      labelTotalScore: 0,
      interviewResults: {},
      previewResumeUrl: '',
      stageId: '',
      jobseekerChannelId: '',
    }
  },
  created() {
    let param = this.$route.query
    this.interviewId = param.userid
    if (sessionStorage) {
      const psw = sessionStorage.getItem(this.interviewId)
      if (psw) {
        this.showDialog = false
        this.psw = psw
        this.findId()
      } else {
        this.showDialog = true
      }
    }
  },
  beforeMount() {},
  mounted() {},
  watch: {
    reportDetail: {
      deep: true,
      handler(val) {
        if (val.sortablescore) {
          this.labelScore = val.sortablescore.filter(item => {
            return item.labelinfo.isVideo !== 1
          })
        }
      },
    },
  },
  computed: {
    imgUrl() {
      return this.baseInfo.url
    },
    idCardInfo() {
      return this.idCard.frontIdCard && this.idCard.backIdCard
    },
    mgUrlPrewer() {
      return [this.idCard.frontIdCard, this.idCard.backIdCard]
    },
  },
  methods: {
    open() {
      var _this = this
      ImagePreview({
        images: _this.mgUrlPrewer,
        closeable: true,
      })
    },
    loadData(callback) {
      console.log(this.currentPerson, '---')
      queryInterviewPlan({ positionId: this.currentPerson.positionid, jobseekerChannelId: this.currentPerson.jobseekerChannelId })
        .then(res => {
          if (res.code === 0) {
            let list = Array.isArray(res.result) ? res.result : []
            list.forEach(item => {
              item.loading = false
              let feedbackList = item.interviewFeedbackList || []
              if (feedbackList.length) {
                item.feedbackContent = feedbackList[0].feedbackContent
                item.feedbackUserName = feedbackList[0].lastUpdateByName
              }
            })
            this.list = list
          }
        })
        .finally(() => {
          if (typeof callback === 'function') {
            this.$nextTick(() => {
              callback()
            })
          }
        })
    },
    getTime(row) {
      let startDate = moment(row.interviewDate + ' ' + row.interviewStartTime).toDate()
      let endDate = moment(row.interviewDate + ' ' + row.interviewEndTime).toDate()
      let time = endDate.getTime() - startDate.getTime()
      return time / 60000 + 'min'
    },
    getFeedbackStatus(status) {
      if (parseInt(status) === 0) {
        return '不通过'
      } else if (parseInt(status) === 1) {
        return '通过'
      } else {
        return '待定'
      }
    },
    parseResumeUrl() {
      this.previewResumeUrl = ''
      const url = this.interviewResults.resumeUrl
      const ext = url.substr(url.lastIndexOf('.')).toLowerCase()
      console.log('ext = ', ext)
      const extList = ['.html', '.htm', '.png', 'jpg']
      if (!extList.includes(ext)) {
        let type = ''
        if (ext === '.doc' || ext === '.docx') type = 'doc2html'
        if (ext === '.mht' || ext === '.mhtml') type = 'mht2html'
        if (ext === '.pdf') type = 'pdf2pdf'
        this.convertFile(url, type)
      } else {
        this.previewResumeUrl = url
        this.jump()
      }
    },
    convertFile(url, type) {
      convertFile({
        sourceUrl: url,
        type,
      }).then(res => {
        if (res.code === 0) {
          this.previewResumeUrl = res.data
          this.jump()
        }
      })
    },
    jump() {
      if (this.previewResumeUrl) {
        sessionStorage.setItem('url', this.previewResumeUrl)
        this.$router.push({
          path: '/originalResume',
        })
      }
    },
    lookReport() {
      // 基本信息
      const interviewInfo = {
        interviewId: this.interviewId,
        positionId: this.positionId,
        companyId: this.companyId,
      }
      // const interviewInfo = { interviewId: '126c60d1e37a453881c9836f713b0abe', positionId: 'c0c7ae951d75428d8aba5d3a1cccb398' }
      interviewInfoTalent(interviewInfo).then(res => {
        this.interviewResults = res.data.interviewResult || {}
        if (this.interviewResults.resumeUrl) {
          this.parseResumeUrl()
        } else {
          this.$toast({ type: 'info', message: '暂无原始简历' })
        }
      })
    },
    onClickLeft() {},
    pageTo(path) {
      this.$router.push({
        path: path,
        query: { id: this.interviewId },
      })
    },
    percentChange(scroe, totle) {
      return (scroe / totle) * 100
    },
    validate() {
      if (!this.psw) {
        this.error = '请输入报告密码'
        // this.$toast({
        //   type: 'fail',
        //   message: '请输入报告密码'
        // })
      } else {
        let rule = /^[a-zA-Z0-9]{6}$/
        let bool = rule.test(this.psw)
        if (!bool) {
          this.error = '报告密码格式不正确'
          this.$toast({ type: 'fail', message: '报告密码格式不正确' })
        } else {
          this.error = ''
        }
      }
    },
    validateEmail() {
      if (!this.email) {
        this.$toast({ type: 'fail', message: '请输入邮箱' })
        return false
      }
      let myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      let bool = myreg.test(this.email)
      if (!bool) {
        this.$toast({ type: 'fail', message: '邮箱格式不正确' })
        return false
      } else {
        this.shareByEmail()
      }
      return true
    },
    closeDialog(a, done) {
      if (this.error) {
        return done(false)
      }
      this.checkReportPwd(done)
      // done(true)
    },
    closeEmail(a, done) {
      if (this.emailStatus) {
        this.shareByEmail(done)
      } else {
        done(false)
      }
    },
    afterClose() {
      // this.checkReportPwd()
    },
    //分享报告
    shareByEmail() {
      let data = {
        email: this.email,
        shareUrl: `${process.env.VUE_APP_API_ROOT}/sharereport?userid=${this.interviewId}`, //
        companyId: this.companyId,
      }
      shareByEmail(data).then(res => {
        if (res.code === 0) {
          this.showEmailDialog = false
          this.$toast({ type: 'success', message: '分享成功' })
        }
      })
    },
    //校验密码
    checkReportPwd(f) {
      let data = {
        id: this.interviewId,
        pin: this.psw,
        jobseekerChannelId: this.$route.query.jobseekerChannelId,
      }
      checkReportPwd(data).then(res => {
        if (res.code === 0) {
          if (sessionStorage) {
            sessionStorage.setItem(this.interviewId, this.psw)
            // console.log(sessionStorage.getItem(this.interviewId))
          }

          f(true)
          this.findId()
        } else {
          f(false)
          this.$notify({ type: 'danger', message: '密码错误' })
        }
      })
    },
    findId() {
      let userId = this.interviewId
      findId({ userId, jobseekerChannelId: this.$route.query.jobseekerChannelId }).then(res => {
        if (res.code === 0) {
          this.companyId = res.data.company_id || ''
          this.positionId = res.data.positionid || ''
          this.stageId - res.data.stageId || ''
          this.currentPerson = res.data
          this.jobseekerChannelId = res.data.jobseekerChannelId
          this.interviewInfo()
          this.findAllEducation()
          this.findAllWorkInfo()
          this.reportNew4Mobile()
          this.loadData()
        }
      })
    },
    interviewInfo() {
      const data = {
        userId: this.interviewId,
        companyId: this.companyId,
        positionId: this.positionId,
        jobSeekerChannelId: this.jobseekerChannelId,
      }
      interviewInfo(data).then(res => {
        if (res.code === 0) {
          this.baseInfo = res.data.interviewResult
          this.usetime = this.baseInfo.usetime
          this.idCard = res.data.idCard
        }
      })
    },
    // 教育经历
    findAllEducation() {
      const jobseekerChannelId = this.jobseekerChannelId
      findAllEducation({ jobseekerChannelId }).then(res => {
        if (res.code === 0) {
          this.education = res.data
        }
      })
    },
    // 工作经历
    findAllWorkInfo() {
      const jobseekerChannelId = this.jobseekerChannelId
      findAllWorkInfo({ jobseekerChannelId }).then(res => {
        if (res.code === 0) {
          this.workInfo = res.data
        }
      })
    },
    reportNew4Mobile() {
      const interviewId = this.interviewId
      const pin = this.psw
      reportNew4Mobile({ interviewId, pin }).then(res => {
        if (res.code === 0) {
          this.$nextTick(() => {
            this.reportDetail = res.data
            // 定制化的得分
            var labelScore = []
            if (this.reportDetail.sortablescore) {
              labelScore = this.reportDetail.sortablescore.filter(item => {
                return item.labelinfo.isVideo !== 1
              })
            }
            this.labelGetScore = labelScore.reduce((prev, cur) => {
              return prev + cur.labelinfo.score
            }, 0)
            this.labelTotalScore = labelScore.reduce((prev, cur) => {
              return prev + cur.labelinfo.totalLabelScore
            }, 0)
            this.isNoshow = labelScore.some(value => {
              return value.labelinfo.isVideo === 0
            })
          })
        }
      })
    },
    progressNum(score) {
      let num = score ? (score / 5) * 100 : 0
      return num
    },
    progressColor(score) {
      if (score >= 0 && score <= 2) {
        return { 'progress-red': true }
      } else if (score >= 3 && score <= 4) {
        return { 'progress-blue': true }
      } else if (score > 4) {
        return { 'progress-green': true }
      }
    },
  },

  filters: {
    F_education(val) {
      switch (Number(val)) {
        case 1:
          return '初中及以下'
        case 2:
          return '高中/中专/中技'
        case 3:
          return '大专'
        case 4:
          return '本科'
        case 5:
          return '硕士'
        case 6:
          return '博士'
        default:
          '初中及以下'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.van-dialog {
  .van-dialog__header {
    padding-bottom: 16px;
  }
  .van-field {
    padding: 30px 10px 30px 25px;
  }
  .van-field__label {
    width: 80px;
  }
}
.report-mobile {
  @import '../common/styles.scss';
  height: 100vh;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  font-size: 14px;
  position: relative;
  .van-nav-bar {
    position: sticky;
    top: 0;
  }
  /deep/ .van-overlay {
    display: flex;
    align-items: center;
  }

  .wrappe {
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .card_info {
    .info {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      img {
        width: 49%;
        margin-bottom: 10px;
        max-height: 130px;
        object-fit: contain;
      }
    }
    margin-bottom: 10px;
  }
  section {
    height: calc(100vh - 55px);
    overflow-y: auto;
    overflow-x: hidden;
    pre {
      word-break: break-word;
      white-space: pre-wrap;
    }
    .spot {
      width: 3px;
      height: 3px;
      background: rgba(24, 144, 255, 1);
      border: 4px solid #b9ddff;
      border-radius: 100%;
    }
    .justify {
      text-align: justify;
      display: inline-block;
    }
    .base-info {
      position: relative;
      height: 180px;
      color: #ffffff;
      background: url('../assets/top@2x.png') no-repeat;
      background-size: 100% 180px;
      margin-bottom: 10px;
      // background: linear-gradient(135deg, rgba(44, 151, 241, 1) 0%, rgba(18, 89, 220, 1) 32%, rgba(20, 62, 222, 1) 59%, rgba(106, 49, 206, 1) 100%);
      .logo {
        width: 30px;
        position: absolute;
        top: 14px;
        left: 14px;
      }

      .picture {
        position: absolute;
        bottom: -20px;
        left: 30px;
        width: 80px;
        height: 80px;
        z-index: 1;
        box-shadow: 0px 0px 2px 5px rgba(255, 255, 255, 0.4);
        border-radius: 100%;
      }
      .info-content {
        // float: right;
        // margin-right: 50px;
        // text-align: center;
        padding-top: 20px;
        // margin-top: 20px;
        margin-bottom: 15px;
        // width: 240px;
        .abs {
          margin-left: 160px;
        }
      }
    }
    .padne-score {
      color: #1890ff;
      font-size: 15px;
    }
    .cell {
      border-bottom: 1px solid #ededed;
      color: #333333;
      margin-top: 16px;
      padding-right: 20px;
      .cell-title {
        font-size: 12px;
        display: flex;
        align-items: center;
        div:first-child {
          margin-right: 12px;
          width: 65px;
        }
        div:last-child {
          flex: 1;
        }
        // div:nth-child(2) {
        //   flex: 1;
        //   margin-right: 4px;
        // }
      }
      .cell-content {
        color: #666666;
        font-size: 10px;
        margin: 10px 0;
      }
      .cell-score {
        color: #1890ff;
        font-size: 14px;
      }
      /deep/ .van-progress {
        border-radius: 0px 7px 7px 0px;
        .van-progress__pivot {
          background: transparent;
          // margin-left: -14px;
          position: unset;
          float: right;
          transform: translate(10px, -20%);
        }
      }
      .progress-blue {
        /deep/ .van-progress__portion {
          background: linear-gradient(90deg, rgba(53, 195, 255, 1) 0%, rgba(24, 144, 255, 1) 100%);
        }
      }
      .progress-red {
        /deep/ .van-progress__portion {
          background: linear-gradient(90deg, rgba(255, 161, 147, 1) 0%, rgba(255, 92, 68, 1) 100%);
        }
      }
      .progress-green {
        /deep/ .van-progress__portion {
          background: linear-gradient(90deg, rgba(180, 240, 150, 1) 0%, rgba(113, 199, 77, 1) 100%);
        }
      }
    }
  }
  .footer {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: -1px;
    .van-button {
      width: 50%;
      height: 50px;
      border: none;
      color: #666666;
      font-size: 16px;
      border-radius: 0;
    }
    .van-button:first-child {
      color: #ffffff;
      background: #1890ff;
    }
  }
  .foot {
    padding: 58px 20px;
    background: url('../assets/footer@2x.png') no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
    text-align: justify;
    h4 {
      color: #ffffff;
      font-size: 15px;
      margin-bottom: 12px;
      margin-top: 20px;
    }
    div,
    p {
      font-weight: lighter;
      font-size: 13px;
    }
  }
  .interview {
    .dot {
      width: 4px;
      height: 4px;
      background: rgba(24, 144, 255, 1);
      border: 4px solid #b9ddff;
      border-radius: 100%;
      box-sizing: content-box;
    }
    height: 100%;
    .btn-wrapper {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #1890ff;
      .new-btn {
        text-align: center;
        height: 68px;
        line-height: 68px;
        width: 300px;
        margin: 0 auto;
        background: #ffffff;
        border: 1px solid #dcdfe6;
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
    .title {
      color: #333333;
      line-height: 24px;
      margin-bottom: 15px;
    }
    .log {
      margin: 30px 99px 0 0p;
      /deep/.el-timeline-item__node {
        background: #1890ff;
        border: 4px solid rgba(24, 144, 255, 0.3);
      }
      /deep/.el-timeline-item__tail {
        border-left: 1px solid #ededed;
      }

      /deep/.el-icon-edit-outline,
      /deep/.el-icon-s-promotion {
        color: #9fd0fe;
        font-size: 18px;
        cursor: pointer;
      }

      .no-response {
        /deep/.el-timeline-item__timestamp:after {
          content: '\672a\54cd\5e94';
          color: #fda655;
        }
      }

      .tip {
        position: absolute;
        top: 0;
        right: 1px;
        padding: 3px;
        color: #fff;
        background: red;
        font-size: 12px;
      }

      .accept {
        /deep/.el-timeline-item__timestamp:after {
          content: '\63a5\53d7';
          color: #5dd7ae;
        }
      }

      .reject {
        /deep/.el-timeline-item__timestamp:after {
          content: '\62d2\7edd';
          color: red;
        }
      }

      .red {
        color: red !important;
      }

      .green {
        color: rgba(25, 133, 255, 1) !important;
      }

      .orange {
        color: orange;
      }

      .el-card {
        background: #fafafa;
        /deep/.el-card__body {
          position: relative;
        }
      }

      .item {
        font-size: 14px;
        color: #333333;
        margin: 0 0 10px 5px;
        p {
          margin-bottom: inherit;
          margin-top: inherit;
          .value {
            word-break: break-word;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:after {
          content: '';
          clear: both;
          display: block;
        }
        .label {
          display: inline-block;
          text-align: right;
          margin-right: 40px;
          color: #666;
        }
      }
    }
  }
}
</style>

<template>
  <div class="third-part-evaluation">
    <!-- <div class="add-exam">
      <van-button class="button" type="primary" size="small" @click="dialogFormVisible = true">添加考试/测评</van-button>
    </div> -->
    <ul v-if="thirdPartyEvaluationList.length > 0 && !dialogFormVisible" class="content-wrap">
      <li v-for="(list, index) in thirdPartyEvaluationList" :key="index">
        <div class="header">
          <div class="type">
            <div class="left">
              <span :style="{ background: list.testType ? '#F7B500' : '#44d7b6' }">{{ list.testType ? $t('com.report.third.text001') : $t('com.report.third.text002') }}</span>
              <span>{{ list.testVendor === 1 ? $t('com.report.base.text015') : list.testName }}</span>
            </div>
            <div v-if="false" class="right">
              <van-icon name="edit" size="25" @click="editEvaluate(list)" />
              <van-icon name="delete" size="25" @click="deleteEvaluate(list)" />
            </div>
          </div>
          <div class="time">{{ moment(list.createDate).format('YYYY-MM-DD') }} {{ $t('com.report.third.text003') }}</div>
        </div>
        <div class="content">
          <!-- 嗨优测评 -->
          <template v-if="list.testVendor === 1">
            <!-- 供应商 -->
            <div class="name">
              <span>{{ $t('com.report.third.vendor') }}：</span>
              <span>{{ $t('com.report.third.haneo') }}</span>
            </div>
            <!-- 测评链接 -->
            <div class="name">
              <span>{{ $t('com.report.third.reviewLink') }}：</span>
              <span class="link">{{ list.fileName }}</span>
              <i v-if="list.fileName" class="el-icon-document-copy icon" @click="copy(list.fileName)" />
            </div>
            <!-- 测评状态 -->
            <div class="name">
              <span>{{ $t('com.report.third.reviewStatus') }}：</span>
              <span>{{ reviewStatusMap[list.testStatus] }}</span>
            </div>
          </template>
          <!-- 其他 -->
          <template v-else>
            <div class="name">
              <span>{{ $t('com.report.third.text004') }}：</span>
              <span>{{ list.positionName }}</span>
            </div>
            <div class="grade">
              <span>{{ $t('com.report.third.text005') }}：</span>
              <span>{{ list.score }}</span>
            </div>
          </template>
          <!-- 报告 -->
          <div class="report">
            <div class="left-side">
              <span class="label">{{ $t('com.report.third.text006') }}：</span>
              <!-- <span v-if="list.fileUrl">*</span> -->
              <!-- 嗨优测评时显示报告链接 -->
              <span v-if="list.fileUrl" class="report-url">
                <span class="file-name">{{ list.testVendor === 1 ? list.fileUrl : list.fileName }}</span>
                <span class="file-btn" style="" @click="preview(list)">{{ $t('com.report.third.text007') }}</span>
              </span>
            </div>
            <div class="right-side">
              <!-- <span class="link-down" :style="{ color: list.fileUrl ? '#1890FF' : 'silver' }" @click="downloadFile(list.fileUrl, list.fileName)">下载</span> -->
              <!-- <span class="link-preview" :style="{ color: list.fileUrl ? '#1890FF' : 'silver' }" style="" @click="filePreview(list.fileUrl, list.fileName)">预览</span> -->
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="no-data">
      <!-- <img :src="require('@/assets/no-data2.png')" /> -->
      <Empty :title="$t('com.report.onlinetest.text016')" />
    </div>
    <div class="input-form" v-if="dialogFormVisible">
      <div class="header">
        <van-button class="button" plain size="small" type="primary" @click="resetForm">{{ $t('com.report.third.text008') }}</van-button>
        <div class="title">{{ $t('com.report.third.text010') }}</div>
        <van-button class="button" size="small" type="primary" @click="confirmSubmit">{{ $t('com.report.third.text009') }}</van-button>
      </div>
      <ul class="form-content">
        <li>
          <span class="row-name">{{ $t('com.report.third.text011') }}</span>
          <input class="form-input" type="text" :placeholder="$t('com.report.third.text012')" v-model="form.testName" />
        </li>
        <li>
          <span class="row-name">{{ $t('com.report.third.text013') }}</span>
          <input class="form-input" type="text" :placeholder="$t('com.report.third.text014')" v-model="form.score" />
        </li>
        <li>
          <span class="row-name">{{ $t('com.report.third.text015') }}</span>
          <div class="type-choose">
            <div :class="['type', item.type === form.testType ? 'active' : '']" v-for="(item, index) in examTypes" :key="index" @click="form.testType = item.type">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </li>
        <li>
          <span class="row-name">{{ $t('com.report.third.text016') }}</span>
          <div class="upload-area">
            <el-upload
              name="files"
              :action="actionUrl"
              :on-exceed="handleExceed"
              :before-upload="handleBeforeUpload"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :limit="1"
              :file-list="fileList"
            >
              <div class="upload-text-area">
                <el-button size="small" type="primary">{{ $t('com.report.third.text017') }}</el-button>
                <span slot="tip" class="el-upload__tip">{{ $t('com.report.third.text018') }}</span>
              </div>
            </el-upload>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
// import host from '@/config/host.js'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { getTestRecord, saveTestRecord, deleteTestRecord, updateTestRecord } from '@/api/candidate'
import { convertFile } from '@/api/report'
import EventBus from '@/common/event-bus'
import i18n from '../../utils/i18n'
import Empty from '@/components/reportV3/common/empty.vue'

export default {
  components: { Empty },
  data() {
    var validatorReport = (rule, value, callback) => {
      // 验证报告和成绩二填一
      if (this.form.score === '' && this.form.fileUrl === '') {
        callback(new Error(i18n.t('com.report.third.text019')))
      } else {
        callback()
      }
    }
    return {
      id: '', // 编辑状态id
      dialogFormVisible: false, // 添加考试/测评弹框是否展示
      actionUrl: `${process.env.VUE_APP_API_ROOT}/admin/file/upload/upload`,
      form: {
        testName: '', // 名称
        score: '', // 成绩
        testType: 0, // 选中的考试类型
        fileUrl: '', // 文件地址
        fileName: '', // 文件名称
      },
      fileList: [], // 数据类型[{name: '', url: ''}]
      thirdPartyEvaluationList: [], // 第三方测评结果数据
      rules: {
        report: [{ validator: validatorReport }],
      },
      reviewStatusMap: {
        0: this.$t('com.report.third.notReviewed'), // 未测评
        1: this.$t('com.report.third.underReview'), // 测评中
        2: this.$t('com.report.third.reviewCompleted'), // 测评完成
      },
    }
  },
  computed: {
    moment() {
      return moment
    },
    examTypes() {
      // 考试类型
      return [
        {
          name: i18n.t('com.report.third.text002'),
          type: 0,
        },
        {
          name: i18n.t('com.report.third.text001'),
          type: 1,
        },
      ]
    },
  },
  props: ['positionId', 'jobseekerChannelId'],
  methods: {
    preview({ fileUrl, testVendor }) {
      if (testVendor === 1) {
        window.open(fileUrl, '_blank') // 嗨优测评新打开页面
      } else {
        EventBus.$emit('openPreviewer', fileUrl)
      }
    },
    handleExceed() {
      this.$message.warning(i18n.t('com.report.third.text020'))
    },
    handleBeforeUpload(file) {
      // 验证格式

      var testFile = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/pdf', 'text/plain'].indexOf(file.type) > -1
      if (!testFile) {
        this.$message.error(i18n.t('com.report.third.text021'))
        return false
      }

      const isLt50M = file.size / 1024 / 1024 < 50
      if (!isLt50M) {
        this.$message.error(i18n.t('com.report.third.text022'))
        return false
      }
    },
    handleSuccess(res, file) {
      // 上传文件
      if (res && res.code === 0 && res.data) {
        this.form.fileName = file.name // 文件名称
        this.form.fileUrl = res.data[0].url // // 文件地址
      } else {
        this.$message.error(i18n.t('com.report.third.text023'))
      }
    },
    handleRemove() {
      // 移除文件
      this.form.fileName = '' // 文件名称
      this.form.fileUrl = '' // // 文件地址
      this.fileList = []
    },
    validInput() {
      // 验证输入
      return true
    },
    confirmSubmit() {
      // 确认提交
      if (this.validInput()) {
        let url = saveTestRecord
        let msg = i18n.t('com.report.third.text024')
        const postData = {
          jobseekerChannelId: this.jobseekerChannelId, // 简历ID String
          positionId: this.positionId, // 职位ID String
          ...this.form,
        }
        if (this.id) {
          // 编辑状态
          postData.id = this.id
          url = updateTestRecord
          msg = i18n.t('com.report.third.text025')
        }
        url(postData).then(res => {
          if (res && res.code === 0) {
            this.$message.success(msg)

            // 重置设置
            this.resetForm()
            if (this.id) this.id = ''

            this.dialogFormVisible = false

            // 查询数据
            this.getThirdPartyEvaluation()
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    resetForm() {
      // 重置输入项
      this.form = {
        testName: '', // 名称
        score: '', // 成绩
        testType: 0, // 选中的考试类型
        fileUrl: '', // 文件地址
        fileName: '', // 文件名称
      }
      this.fileList = []

      // 隐藏
      this.dialogFormVisible = false
    },
    editEvaluate(list) {
      // 编辑测评记录
      this.id = list.id
      this.dialogFormVisible = true

      this.form.testName = list.testName // 名称
      this.form.score = list.score // 成绩
      this.form.testType = list.testType // 选中的考试类型
      this.form.fileUrl = list.fileUrl // 文件地址
      this.form.fileName = list.fileName // 文件名称
      if (list.fileUrl && list.fileName) this.fileList = [{ name: list.fileName, url: list.fileUrl }] // 数据类型[{name: '', url: ''}]
    },
    deleteEvaluate(list) {
      // 删除测评记录
      const postData = {
        id: list.id,
      }
      deleteTestRecord(postData).then(res => {
        if (res && res.code === 0) {
          this.$message.success(i18n.t('com.report.third.text026'))
          this.getThirdPartyEvaluation()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    downloadFile(fileUrl, fileName) {
      // 下载
      if (fileUrl) saveAs(fileUrl.replace('http://', 'https://'), fileName)
    },
    filePreview(fileUrl) {
      // 文件转换进行预览
      if (!fileUrl) return
      if (fileUrl.indexOf('txt') > -1) {
        window.open(fileUrl)
      } else {
        convertFile({
          sourceUrl: fileUrl,
          type: 'doc2html',
        }).then(res => {
          if (res && res.code === 0) {
            window.open(res.data)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    getThirdPartyEvaluation() {
      // 获取第三方测试结果
      const postData = {
        jobseekerChannelId: this.jobseekerChannelId, // 简历ID String
        positionId: this.positionId, // 职位ID String
      }
      getTestRecord(postData).then(res => {
        if (res && res.code === 0) {
          if (res.data && res.data.length > 0) {
            this.thirdPartyEvaluationList = res.data
          } else {
            this.thirdPartyEvaluationList = []
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 复制
    copy(text) {
      const oInput = document.createElement('textarea')
      oInput.value = text
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.$toast({ type: 'info', message: this.$t('com.report.interviewPlan.text040') })
      document.body.removeChild(oInput)
    },
  },
  created() {
    this.getThirdPartyEvaluation()
  },
}
</script>
<style lang="scss" scoped>
.third-part-evaluation {
  position: relative;
  .add-exam {
    margin: 20px 0;
    text-align: center;
    .button {
      border-radius: 8px;
    }
  }
  .content-wrap {
    list-style: none;
    width: 80%;
    margin: auto;
    padding-bottom: 100px;
    li {
      margin: 30px 0 0;
      padding: 30px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(80, 98, 118, 0.2);
      border-radius: 5px;
      .header {
        .type {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            display: flex;
            align-items: center;
            span:first-of-type {
              width: 40px;
              height: 20px;
              line-height: 22px;
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              color: #ffffff;
            }
            span:last-of-type {
              margin-left: 8px;
              font-size: 14px;
              font-weight: bold;
              color: #333333;
              min-width: 0;
              flex: 1;
            }
          }
          .right {
            i {
              &:last-of-type {
                margin-left: 10px;
              }
            }
          }
        }
        .time {
          font-size: 12px;
          color: #aaaaaa;
          line-height: 20px;
        }
      }
      .content {
        .name,
        .grade {
          display: flex;
          align-items: baseline;
          font-size: 13px;
          margin-top: 10px;
          // height: 15px;
          line-height: 15px;

          span:first-of-type {
            color: #5c5e61;
            width: 75px;
            min-width: 75px;
            white-space: nowrap;
          }
          span:last-of-type {
            color: black;
            text-shadow: 0px 2px 10px rgba(80, 98, 118, 0.2);
          }
        }
        .link {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .icon {
          margin-left: 10px;
          color: #1890ff;
          cursor: pointer;
        }
        .report {
          margin-top: 10px;
          display: flex;
          align-items: baseline;
          font-size: 13px;
          .left-side {
            display: flex;
            align-items: baseline;
            line-height: 15px;
            width: 100%;
            .label {
              white-space: nowrap;
              width: 75px;
              min-width: 75px;
            }
            .report-url {
              color: #5c5e61;
              display: flex;
              justify-content: space-between;
              max-width: calc(100% - 75px);
              position: relative;
              flex: 1;
              .file-name {
                // max-height: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // max-width: 130px;
                // max-width: calc(100% - 75px);
              }
              .file-btn {
                margin-left: 10px;
                cursor: pointer;
                color: #1890ff;
                white-space: nowrap;
              }
            }
          }
          .right-side {
            .link-preview {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .no-data {
    text-align: center;
    img {
      margin: 60px auto;
      width: 40%;
      height: auto;
    }
  }
  .input-form {
    position: absolute;
    top: -15px;
    padding-left: 5%;
    padding-bottom: 100px;
    width: 95%;
    background: white;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        border-radius: 5px;
        padding: 0 15px;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
    }
    .form-content {
      margin-top: 20px;
      li {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        .row-name {
          min-width: 50px;
          font-size: 14px;
          color: #333333;
        }
        .form-input {
          height: 36px;
          padding-left: 10px;
          border: 1px solid #dddddd;
          flex-grow: 1;
          font-size: 14px;
          &::placeholder {
            color: #aaaaaa;
          }
        }
        .type-choose {
          display: flex;
          .type {
            &:last-of-type {
              margin-left: 20px;
            }
            position: relative;
            width: 68px;
            height: 36px;
            border-radius: 3px;
            border: 1px solid #dddddd;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              font-size: 14px;
              color: #333333;
            }
            &.active {
              background: #14b1ff;
              span {
                color: white;
              }
            }
          }
        }
        .upload-area {
          /deep/ .el-button--primary {
            background-color: #14b1ff;
            border-color: #14b1ff;
          }
          /deep/ .el-upload-list__item-name {
            width: 200px;
          }
          .upload-text-area {
            display: flex;
            align-items: center;
            .el-upload__tip {
              text-align: left;
              color: silver;
              padding-left: 15px;
              margin-top: unset;
            }
          }
        }
      }
    }
  }
}
</style>

import i18n from '@/utils/i18n.js'

export const workingYears = [
  {
    id: 0,
    label: '不限',
  },
  {
    id: 1,
    label: '1年',
  },
  {
    id: 2,
    label: '1-3年',
  },
  {
    id: 3,
    label: '3-5年',
  },
  {
    id: 4,
    label: '5-10年',
  },
  {
    id: 5,
    label: '10年以上',
  },
]
export const noticeTemplateList = [
  {
    isEmailNotice: 1,
    isSmsNotice: 1,
    templateId: '',
    type: 1,
    label: '候选人通知（AI视频面试）',
  },
  {
    isEmailNotice: 1,
    isSmsNotice: 1,
    templateId: '',
    type: 2,
    label: '候选人通知（线上面试）',
  },
  {
    isEmailNotice: 1,
    isSmsNotice: 0,
    templateId: '',
    type: 3,
    label: '面试官通知（线上面试）',
  },
  {
    isEmailNotice: 1,
    isSmsNotice: 1,
    templateId: '',
    type: 4,
    label: '候选人通知（线下面试）',
  },
  {
    isEmailNotice: 1,
    isSmsNotice: 0,
    templateId: '',
    type: 5,
    label: '面试官通知（线下面试）',
  },
]
export const estimateContent = [
  {
    label: '视频题',
    value: 1,
  },
  {
    label: '客观题',
    value: 2,
  },
  {
    label: '编程题',
    value: 3,
  },
  {
    label: '论述题',
    value: 4,
  },
]
export const aiInterviewStatus = [
  {
    label: '待安排',
    value: 1,
  },
  {
    label: '已安排',
    value: 2,
  },
  {
    label: '已结束',
    value: 3,
  },
  {
    label: '待反馈',
    value: 4,
  },
  {
    label: '已反馈',
    value: 5,
  },
  {
    label: '通过',
    value: 6,
  },
  {
    label: '待定',
    value: 7,
  },
  {
    label: '拒绝',
    value: 8,
  },
]
export const offerStatus = [
  {
    label: '未创建',
    value: 1,
  },
  {
    label: '已发送',
    value: 2,
  },
  {
    label: '已接受',
    value: 3,
  },
  {
    label: '未响应',
    value: 5,
  },
  {
    label: '已拒绝',
    value: 4,
  },
]
export const jobCategory = [
  {
    // 工作性质
    id: 0,
    label: '全职',
  },
  {
    id: 1,
    label: '兼职',
  },
  // {
  //   id: '2',
  //   label: '实习(全职)',
  // },
  // {
  //   id: '3',
  //   label: '实习(兼职)',
  // },
  {
    id: 4,
    label: '实习',
  },
]

export const jobType = [
  {
    id: 1,
    label: '社招',
  },
  {
    // 招聘类型
    id: 0,
    label: '校招',
  },
]

export const RESPONSE_TIME_LIMITS = {
  '0': '无限制',
  '24': '24小时',
  '48': '48小时',
  '72': '72小时',
}

export const educationInfo = [
  {
    id: '0',
    label: i18n.t('com.report.base.不限'),
  },
  {
    id: '1',
    label: i18n.t('com.report.base.初中及以下'),
  },
  {
    id: '2',
    label: i18n.t('com.report.base.highSchool'),
  },
  {
    id: '3',
    label: i18n.t('com.report.base.大专'),
  },
  {
    id: '4',
    label: i18n.t('com.report.base.本科'),
  },
  {
    id: '5',
    label: i18n.t('com.report.base.硕士'),
  },
  {
    id: '6',
    label: i18n.t('com.report.base.博士'),
  },
  {
    id: '7',
    label: 'MBA',
  },
]
// 薪酬
export const payType = [
  {
    label: '3k',
    id: '3',
  },
  {
    label: '4k',
    id: '4',
  },
  {
    label: '5k',
    id: '5',
  },
  {
    label: '6k',
    id: '6',
  },
  {
    label: '7k',
    id: '7',
  },
  {
    label: '8k',
    id: '8',
  },
  {
    label: '9k',
    id: '9',
  },
  {
    label: '10k',
    id: '10',
  },
  {
    label: '10k+',
    id: '-1',
  },
]
/**   人才库薪酬  */
export const payTypeNew = [
  { label: '1k以下', id: [-1, -1] },
  { label: '1k - 2k', id: [1, 2] },
  { label: '2k - 4k', id: [2, 4] },
  { label: '4k - 6k', id: [4, 6] },
  { label: '8k - 10k', id: [8, 10] },
  { label: '10k - 15k', id: [10, 15] },
  { label: '15k - 20k', id: [15, 20] },
  { label: '20k - 30k', id: [20, 30] },
  { label: '30k - 50k', id: [30, 50] },
  { label: '50k - 70k', id: [50, 70] },
  { label: '70k - 100k', id: [70, 100] },
  { label: '100k以上', id: [100, 100] },
]

// 分数
export const scoreStatus = [
  {
    label: '0',
    id: 0,
  },
  {
    label: '1',
    id: 1,
  },
  {
    label: '2',
    id: 2,
  },
  {
    label: '3',
    id: 3,
  },
  {
    label: '4',
    id: 4,
  },
  {
    label: '5',
    id: 5,
  },
]

export const positionStatus = [
  // { label: '草稿箱', value: 0 },
  { label: '面试中', value: 4 },
  // { label: '审核中', value: 1 },
  // { label: '未通过', value: 5 },
  { label: '已暂停', value: 2 },
  { label: '已过期', value: 9 },
  { label: '已删除', value: 3 },
]
export const positionStatusList = [
  { label: '面试中', value: 4 },
  { label: '已暂停', value: 2 },
  { label: '已过期', value: 9 },
]

/**
 *  应聘管理人才列表用 --- start
 */

export const tabList = [
  { label: '简历', name: 'firstTab', group: 'chushai', number: 0, status: '' },
  { label: 'AI面试', name: 'aiTab', group: 'aims', number: 0, status: 'yqms' },
  { label: '人工评估', name: 'estimateTab', group: 'pg', number: 0, status: '评估' },
  { label: '安排面试', name: 'interviewTab', group: 'apms', number: 0, status: 'apms' },
  { label: '录用', name: 'offerTab', group: 'offer', number: 0, status: 'offer' },
  // { label: '背景调查', name: 'bdTab', group: 'bd', number: 0, status: 'bd' },
  { label: '待入职', name: 'waitEntryTab', group: 'drz', number: 0, status: 'drz' },
  { label: '已入职', name: 'enrolledTab', group: 'yrz', number: 0, status: 'yrz' },
  { label: '淘汰', name: 'eliminateTab', group: 'tt', number: 0, status: 'taotai' },
  { label: '黑名单', name: 'blackListTab', group: 'hmd', number: 0, status: 'hmd' },
]
export const tabLists = [
  { label: '简历', name: 'firstTab', group: 'chushai', number: 0, status: '' },
  { label: 'AI面试', name: 'aiTab', group: 'aims', number: 0, status: 'yqms' },
  { label: '人工评估', name: 'estimateTab', group: 'pg', number: 0, status: '评估' },
  { label: '安排面试', name: 'interviewTab', group: 'apms', number: 0, status: 'apms' },
  { label: '录用', name: 'offerTab', group: 'offer', number: 0, status: 'offer' },
]
export const resumeStatus = [
  { label: '新简历', value: 0 },
  { label: '已查阅', value: 1 },
]
export const sorTypeList = [
  { label: '升序', value: 'ASC' },
  { label: '降序', value: 'DESC' },
]
export const personStatus = [
  { value: 'accepted', label: '接受' },
  { value: 'no_answer', label: '拒绝' },
  { value: 'withdraw', label: '未响应' },
]
export const apmsResponseStatus = [
  { value: 1, label: '接受' },
  { value: 2, label: '拒绝' },
  { value: 3, label: '未响应' },
]

export const sorFildList = [
  { label: 'L4-AI总分排序', value: 'aiAllScore' },
  { label: 'AI-TDNA排序', value: 'ai_result_score' },
  { label: '考核得分', value: 'labelScore' },
  { value: 'finishTime', label: '面试完成时间' },
]
export const sorFildListEstimate = [
  // { label: 'L4-AI总分排序', value: 'aiAllScore' },
  // { label: 'AI-TDNA排序', value: 'ai_result_score' },
  { label: '考核得分', value: 'labelScore' },
  { label: '申请时间', value: 'createtime' },
  { value: 'finishTime', label: 'AI完成时间' },
  { value: 'estimateHunman', label: '人工打分' },
  { value: 'objectScoreNum', label: '客观题分' },
]
export const assessStatus = [
  { label: '已评估', value: 'ai_estimate' },
  { label: '待评估', value: 'ai_unestimate' },
]
export const statusaiList = [
  { label: '未完成', value: 'ai_uncomplete' },
  { label: '已完成', value: 'ai_complete' },
]
export const firstTabOperMenu = [
  { value: 'yqms', label: '邀请AI面试', type: 1 },
  { value: 'apms', label: '安排面试', type: 4 },
  { value: 'offer', label: '录用', type: 5 },
  // { value: 'bd', label: '背景调查', type: 6 },
  { value: 'drz', label: '待入职', type: 1 },
  { value: 'yrz', label: '已入职', type: 1 },
  { value: 'taotai', label: '淘汰', type: 1 },
  { value: 'hmd', label: '黑名单', type: 1 },
]
/**  应聘管理人才列表用 --- end  */

// 评估状态变更
export const estimateStatus = [
  { value: 'apms', label: '安排面试' },
  { value: 'offer', label: '录用' },
  // { value: 'bd', label: '背景调查' },
  { value: 'drz', label: '待入职' },
  { value: 'yrz', label: '已入职' },
  { value: 'taotai', label: '淘汰' },
  { value: 'hmd', label: '黑名单' },
]
/**  评估列表用 --- start */
export const estimateStatusList = [
  { value: '2', label: '待评估' },
  { value: 'estimateFinish', label: '已评估' },
]
export const estimateStatusNo = [
  { value: '1', label: '视频待评估' },
  { value: '0', label: '论述待评估' },
  { value: '7', label: '编程待评估' },
]
export const estimateStatusYes = [
  { value: '1', label: '视频已评估' },
  { value: '0', label: '论述已评估' },
  { value: '7', label: '编程已评估' },
]
export default function charToUnicode(str) {
  let temp = null
  let r = ''
  for (let val of str) {
    temp = val.codePointAt(0).toString(16)

    while (temp.length < 4) temp = '0' + temp

    r += '/u' + temp
  }

  return r
}
export const previewOption = {
  maxSpreadZoom: 4, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
  fullscreenEl: true, //控制是否显示右上角全屏按钮
  closeEl: true, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: true, //控制是否显示放大缩小按钮
  counterEl: false, //控制是否显示左上角图片数量按钮
  arrowEl: true, //控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: true, //点击应切换控件的可见性
  clickToCloseNonZoomable: true, //点击图片应关闭图库，仅当图像小于视口的大小时
}

export const l2Dimensions = [
  {
    key: 'language',
    label: i18n.t('com.report.reportV3.text066'), // 'L2-普通话',
    info: i18n.t('com.report.reportV3.text068'), // '测试候选人普通话水平能力，以中文识别候选人回答内容。',
    ratioKey: 'languageRatio',
    scoreKey: 'languageStar',
    keys: ['language', 'languageRatio', 'languageScore', 'languageStar'],
    // language: 0, //本题语言开启 0=未开启，1=开启
    // languageRatio: 'BigDecimal', //本题语言维度占比
    // languageScore: 'BigDecimal', //本题语言得分
  },
  {
    key: 'will',
    label: i18n.t('com.report.reportV3.text070'),
    info: i18n.t('com.report.reportV3.text071'),
    ratioKey: 'willRatio',
    scoreKey: 'willStar',
    keys: ['will', 'willRatio', 'willScore', 'willStar'],
    // will: 0, //本题意愿开启 0=未开启，1=开启
    // willPrefer: 0, //本题意愿正负向 1=正向，2=负向
    // willRatio: 'BigDecimal', //占比
    // willScore: 'BigDecimal', //得分
  },
  {
    key: 'keyPoint',
    label: i18n.t('com.report.reportV3.text072'),
    info: i18n.t('com.report.reportV3.text073'),
    ratioKey: 'keyPointRatio',
    scoreKey: 'keyPointStar',
    keys: ['keyPoint', 'keyPointRatio', 'keyPointScore', 'keyPointStar'],
    // keyPoint: 0, //关键点分析是否开启
    // keyPointRatio: 'BigDecimal', //占比
    // keyPointScore: 'BigDecimal', //得分
  },
  {
    key: 'audioSpeed',
    label: i18n.t('com.report.reportV3.text074'),
    info: i18n.t('com.report.reportV3.text075'),
    ratioKey: 'audioSpeedRatio',
    scoreKey: 'audioSpeedStar',
    keys: ['audioSpeed', 'audioSpeedRatio', 'audioSpeedScore', 'audioSpeedStar'],
    // audioSpeed: 0, //语速是否开启
    // audioSpeedRatio: 'BigDecimal', //占比
    // audioSpeedScore: 'BigDecimal', //得分
  },
  {
    key: 'audioRich',
    label: i18n.t('com.report.reportV3.text076'),
    info: i18n.t('com.report.reportV3.text077'),
    ratioKey: 'audioRichRatio',
    scoreKey: 'audioRichStar',
    keys: ['audioRich', 'audioRichRatio', 'audioRichScore', 'audioRichStar'],
    // audioRich: 0, //语言丰富度是否开启
    // audioRichRatio: 'BigDecimal', //占比
    // audioRichScore: 'BigDecimal', //得分
  },
  {
    key: 'audioFluency',
    label: i18n.t('com.report.reportV3.text078'),
    info: i18n.t('com.report.reportV3.text079'),
    ratioKey: 'audioFluencyRatio',
    scoreKey: 'audioFluencyStar',
    keys: ['audioFluency', 'audioFluencyRatio', 'audioFluencyScore', 'audioFluencyStar'],
    // audioFluency: 0, //语言流利度是否开启
    // audioFluencyRatio: 'BigDecimal', //占比
    // audioFluencyScore: 'BigDecimal', //得分
  },
  {
    key: 'apperenceBeauty',
    label: i18n.t('com.report.reportV3.text080'),
    info: i18n.t('com.report.reportV3.text081'),
    ratioKey: 'apperenceBeautyRatio',
    scoreKey: 'apperenceBeautyStar',
    keys: ['apperenceBeauty', 'apperenceBeautyRatio', 'apperenceBeautyScore', 'apperenceBeautyStar'],
    // apperenceBeauty: 0, //形象职业形象是否开启
    // apperenceBeautyRatio: 'BigDecimal', //占比
    // apperenceBeautyScore: 'BigDecimal', //得分
  },
  {
    key: 'apperenceAge',
    label: i18n.t('com.report.reportV3.text082'),
    info: i18n.t('com.report.reportV3.text083'),
    ratioKey: 'apperenceAgeRatio',
    scoreKey: 'apperenceAgeStar',
    keys: ['keyPoint', 'apperenceAgeRatio', 'apperenceAgeScore', 'apperenceAgeStar'],
    // apperenceAge: 0, //形象年龄是否开启
    // apperenceAgeRatio: 'BigDecimal', //占比
    // apperenceAgeScore: 'BigDecimal', //得分
  },
  {
    key: 'apperenceSmile',
    label: i18n.t('com.report.reportV3.text084'),
    info: i18n.t('com.report.reportV3.text085'),
    ratioKey: 'apperenceSmileRatio',
    scoreKey: 'apperenceSmileStar',
    keys: ['apperenceSmile', 'apperenceSmileRatio', 'apperenceSmileScore', 'apperenceSmileStar'],
    // apperenceSmile: 0, //形象微笑是否开启
    // apperenceSmileRatio: 'BigDecimal', //占比
    // apperenceSmileScore: 'BigDecimal', //得分
  },
]

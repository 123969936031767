export default {
  report: {
    login: {
      text001: '請輸入報告密碼',
      text002: '報告密碼',
      text003: '登錄',
      text004: '點擊查看候選人AI全息簡歷',
      text005: 'AI得賢招聘官',
      text006: '確認登出帳號？',
      text007: '退出成功',
      text008: '密碼錯誤',
      text009: '請輸入報告密碼',
      text010: '報告密碼格式不正確',
      text011: '登錄帳號：{name}，點擊退出登錄',
      text012: '手機號',
      text013: '請填寫手機號',
      text014: '密碼',
      text015: '請填寫密碼',
      text016: '提交',

      text017: '請選擇用戶',
      text018: '輸入郵箱或姓名快速檢索',
      text019: '已選擇{0}人',
      text020: '去推薦',
      text021: '備註',
      text022: '請添加備註（非必填）',
      text023: '確認',
      text024: '請選擇用戶',
      text025: '超級管理員',
      text026: '管理員',
      text027: 'HR',
      text028: '面試官',
      text029: '只讀',
      text030: '凍結',
      text031: '協作者',
      text032: '發佈者',
    },
  },
}

<template>
  <div class="resume-content">
    <div id="originResume" ref="originResume">
      <div class="origin-resume-wrapper" v-show="isActive('originResume')">
        <!-- <DocPreviewer :previewResumeUrl="previewResumeUrl" /> -->
        <!-- <div v-if="previewResumeUrl"> -->

        <i class="el-icon-full-screen" @click="figureClick"></i>

        <vue-preview v-if="isImage && previewResumeUrl" :slides="thumbsList" class="imgPrev"></vue-preview>

        <!-- <van-image v-if="isImage && previewResumeUrl" @click="popupImage" fit="contain" :src="previewResumeUrl" /> -->
        <!-- <img :src="previewResumeUrl" width="10rem" /> -->
        <iframe v-if="!isImage && previewResumeUrl" style="height:100vh;" id="iframeResumeUrl" class="resumeUrl" frameborder="0" scrolling="auto" width="100%" :src="previewResumeUrl"></iframe>
        <!-- </div> -->
      </div>
    </div>
    <div ref="standardResume">
      <StandardResume id="standtardResume" v-show="isActive('standardResume')" />
    </div>
    <div ref="candidateForm">
      <CandidateForm id="candidateForm" v-show="isActive('candidateForm')" @removeCanidateForm="$emit('removeCanidateForm')" />
    </div>
    <van-popup class="image_popup" v-if="showImage" v-model="showImage" @close="priviewClose" @before-leave="priviewClose" closeable custom-style="height: 100%,overflow: hidden;">
      <!-- <img :style="moveImage" id="priviewImage" class="priview_image" :src="previewResumeUrl" /> -->
      <vue-preview v-if="isImage && previewResumeUrl" :slides="thumbsList" class="imgPrev"></vue-preview>
    </van-popup>
  </div>
</template>
<script>
import EventBus from '@/common/event-bus'
import StandardResume from '@/components/reportComponents/StandardResume'
import CandidateForm from '@/components/reportComponents/candidateForm'
// import DocPreviewer from '@/components/reportComponents/docPreviewer/index.vue'
export default {
  components: { StandardResume, CandidateForm },
  props: { scrollTop: { type: Number }, activeIndex: { type: Number }, tabBarHeight: { type: Number }, baseInfoHeight: { type: Number }, resumeTab: { type: Array }, tabItem: { required: true } },
  data() {
    return {
      data: '',
      originResumeOffsetTop: 0,
      standardResumeOffsetTop: 0,
      originResumeOffsetHeight: 0,
      standardResumeOffsetHeight: 0,
      candidateFormOffsetHeight: 0,
      isImage: false,
      showImage: false,
      priviewImage: '',
      store: {
        scale: 1,
      },
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      moveX: 0,
      moveY: 0,
      oldX: 0,
      oldY: 0,
      thumbsList: [],
      imageLoading: false,
    }
  },
  beforeDestroy() {
    EventBus.$off('updateActiveIndex', this.tapUpdateActiveIndex)
    document.removeEventListener('touchstart', this.touchstart)
    document.removeEventListener('touchmove', this.touchmove)
    document.removeEventListener('touchend', this.touchend)
    document.removeEventListener('touchcancel', this.touchcancel)
  },
  created() {
    EventBus.$on('updateActiveIndex', this.tapUpdateActiveIndex)
  },
  methods: {
    figureClick() {
      const figure = document.querySelector('#originResume').querySelector('figure')
      figure && figure.click()
    },
    popupImage() {
      this.showImage = true

      this.$nextTick(() => {
        this.priviewImage = document.getElementById('priviewImage')
        document.addEventListener('touchstart', this.touchstart)
        document.addEventListener('touchmove', this.touchmove)
        document.addEventListener('touchend', this.touchend)
        document.addEventListener('touchcancel', this.touchcancel)
      })
    },
    touchstart(event) {
      let store = this.store
      let touches = []
      let events = ''
      let events2 = ''
      touches = event.touches
      events = touches[0] || ''
      events2 = touches[1]
      // console.log('event', event)
      // console.log('events', events.pageX)
      // console.log('events2', events2)

      // event.preventDefault()

      // 第一个触摸点的坐标
      if (events) {
        store.pageX = (events && events.pageX) || ''
        store.pageY = events.pageY || ''
      }

      store.moveable = true

      if (events2) {
        store.pageX2 = events2.pageX
        store.pageY2 = events2.pageY
      } else if (events) {
        this.startX = store.pageX
        this.startY = store.pageY
      }

      store.originScale = store.scale || 1
      this.store = store
    },
    touchmove(event) {
      let store = this.store
      if (!store.moveable) {
        return
      }

      // event.preventDefault()

      var touches = event.touches
      var events = touches[0]
      var events2 = touches[1]
      // console.log('touchmove', events.pageX)
      // 双指移动
      if (events2) {
        // 第2个指头坐标在touchmove时候获取
        if (!store.pageX2) {
          store.pageX2 = events2.pageX
        }
        if (!store.pageY2) {
          store.pageY2 = events2.pageY
        }

        // 获取坐标之间的举例
        var getDistance = function(start, stop) {
          return Math.hypot(stop.x - start.x, stop.y - start.y)
        }
        // 双指缩放比例计算
        var zoom =
          getDistance(
            {
              x: events.pageX,
              y: events.pageY,
            },
            {
              x: events2.pageX,
              y: events2.pageY,
            }
          ) /
          getDistance(
            {
              x: store.pageX,
              y: store.pageY,
            },
            {
              x: store.pageX2,
              y: store.pageY2,
            }
          )
        // 应用在元素上的缩放比例
        var newScale = store.originScale * zoom
        // 最大缩放比例限制
        if (newScale > 3) {
          newScale = 3
        }
        // 记住使用的缩放值
        if (store.storeXYre) {
          store.storeXYre.scale = newScale
        }
        // 图像应用缩放效果
        this.priviewImage.style.transform = 'scale(' + newScale + ')'
      } else if (events) {
        this.endX = events.pageX
        this.endY = events.pageY
        console.log('start:', this.startX, this.startY)
        console.log('end:', this.endX, this.endY)
        if (this.endX - this.startX || this.endY - this.startY) {
          this.moveX = this.endX - this.startX
          this.moveY = this.endY - this.startY
        }
      }
      this.store = store
    },
    touchend(event) {
      this.store.moveable = false
      console.log('touchend', event)

      delete this.store.pageX2
      delete this.store.pageY2
      if (this.moveX || this.moveY) {
        this.oldX = this.moveX + this.oldX
        this.oldY = this.moveY + this.oldY
      }
      this.moveX = 0
      this.moveY = 0

      // var touches = event.touches
      // var events = touches[0]
      // var events2 = touches[1]
      // if (events2) {
      //   console.log('double')
      // } else if (events) {
      //   this.store.endTouchX = events.pageX || 0
      //   this.store.endTouchY = events.pageY || 0
      //   this.moveX = this.store.endTouchX - this.store.startTocuhX
      //   this.moveY = this.store.endTouchY - this.store.startTocuhY
      //   console.log(this.moveX, this.moveY)
      // }

      // console.log('touchend', event)
    },
    touchcancel() {
      // let store = this.store
      this.store.moveable = false
      // console.log('touchcancel', event)

      delete this.store.pageX2
      delete this.store.pageY2
      // console.log('touchcancel')
    },
    priviewClose() {
      console.log('close')
      this.store = {
        scale: 1,
      }
      this.startX = 0
      this.startY = 0
      this.endX = 0
      this.endY = 0
      this.moveX = 0
      this.moveY = 0
      this.oldX = 0
      this.oldY = 0
      document.removeEventListener('touchstart', this.touchstart)
      document.removeEventListener('touchmove', this.touchmove)
      document.removeEventListener('touchend', this.touchend)
      document.removeEventListener('touchcancel', this.touchcancel)

      console.log('this.store', this.store)
    },
    isActive(val) {
      if (val === this.tabItem.ref) {
        return true
      } else {
        return false
      }
      // let flag = this.resumeTab.find(item => {
      //   return item.ref === val
      // })

      // return flag !== undefined
    },
    tapUpdateActiveIndex(val) {
      let total = this.baseInfoHeight
      if (val === 0) {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      } else if (val >= 1) {
        for (let i = 0; i < val; i++) {
          total = total + this.$refs[this.resumeTab[i].ref].offsetHeight
        }
        total = total + 3
        document.documentElement.scrollTop = total
        document.body.scrollTop = total
      }
    },
    // tapUpdateActiveIndex(val) {
    //   if (val === 0) {
    //     document.documentElement.scrollTop = 0
    //     document.body.scrollTop = 0
    //   } else if (val === 1) {
    //     document.documentElement.scrollTop = this.$refs.originResume.scrollHeight + this.baseInfoHeight + 5
    //     document.body.scrollTop = this.$refs.originResume.scrollHeight + this.baseInfoHeight + 5
    //   } else if (val === 2) {
    //     document.documentElement.scrollTop = this.$refs.originResume.scrollHeight + this.$refs.standardResume.scrollHeight + this.baseInfoHeight + 5
    //     document.body.scrollTop = this.$refs.originResume.scrollHeight + this.$refs.standardResume.scrollHeight + this.baseInfoHeight + 5
    //   }
    // },
  },
  computed: {
    moveImage() {
      return {
        position: 'relative',
        left: `${this.moveX + this.oldX}px`,
        top: `${this.moveY + this.oldY}px`,
      }
    },
    previewResumeUrl() {
      return this.$store.state.previewResumeUrl
    },
  },
  watch: {
    showImage: {
      handler() {
        if (!this.showImage) {
          this.priviewClose()
        }
      },
    },
    scrollTop: {
      handler: function() {
        // this.AISummaryHeight = this.$refs.AISummary.offsetHeight
        // this.AIVideoReportHeight = this.$refs.AIVideoReport.offsetHeight
        // this.CustomizeQuestionHeight = this.$refs.CustomizeQuestion.offsetHeight
        // this.CognitiveAbilityHeight = this.$refs.CognitiveAbility.offsetHeight
        // this.OccupationalCharacterHeight = this.$refs.OccupationalCharacter.offsetHeight
        // this.ImitateProgrameHeight = this.$refs.ImitatePrograme.offsetHeight
        // for (let i = 0; i < this.resumeTab.length; i++) {
        //   let total = this.baseInfoHeight
        //   for (let j = 0; j <= i; j++) {
        //     total += this.$refs[this.resumeTab[j].ref].offsetHeight
        //   }
        //   if (val < total) {
        //     this.$emit('updateShowActiveIndex', i)
        //     return
        //   }
        // }
      },
    },
    previewResumeUrl: {
      immediate: true,
      handler() {
        const url = this.previewResumeUrl
        const ext = url.substr(url.lastIndexOf('.')).toLowerCase()
        const extList = ['.png', '.jpg', '.jpeg']
        if (!extList.includes(ext)) {
          this.isImage = false
        } else {
          this.isImage = true
          this.imageLoading = true
          const img = new Image()
          img.src = this.previewResumeUrl
          img.onload = () => {
            const width = img.width
            const height = img.height
            this.thumbsList = [
              {
                w: width, //设置以大图浏览时的宽度
                h: height, //设置以大图浏览时的高度
                src: this.previewResumeUrl, //大图
                msrc: this.previewResumeUrl, //小图
              },
            ]
            this.imageLoading = false
          }
        }
      },
    },
    // scrollTop: {
    //   handler: function(val) {
    //     this.originResumeOffsetHeight = this.$refs.originResume.offsetHeight
    //     if (val >= this.originResumeOffsetHeight + this.standardResumeOffsetHeight + this.baseInfoHeight) {
    //       this.$emit('updateShowActiveIndex', 2)
    //     } else if (val >= this.originResumeOffsetHeight + this.baseInfoHeight || !this.previewResumeUrl) {
    //       this.$emit('updateShowActiveIndex', 1)
    //     } else {
    //       this.$emit('updateShowActiveIndex', 0)
    //     }
    //   },
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.originResumeOffsetHeight = this.$refs.originResume.offsetHeight
      this.standardResumeOffsetHeight = this.$refs.standardResume.offsetHeight
      // this.originResumeOffsetTop = this.$refs.originResume.offsetTop
      // this.standardResumeOffsetTop = this.$refs.standardResume.offsetTop
    })
  },
}
</script>
<style lang="scss">
.resume-content {
  padding: 0.2rem 0;

  .origin-resume-wrapper {
    min-height: 100vh;
    overflow: scroll;
    position: relative;
    i {
      position: absolute;
      top: 5px;
      right: 12px;
      font-size: 18px;
      color: #ffffff;
      background: rgba(51, 51, 51, 0.5);
      width: 32px;
      height: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .imgPrev {
      // height: 100vh;
      width: 100vw;
      overflow: hidden;
      figure {
        margin: 0;
      }
      img {
        // height: 100vh;
        width: 100vw;
        overflow: hidden;
      }
    }
  }
}
.priview_image {
  width: 100vw;
}
.image_popup {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
</style>

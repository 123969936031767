export default {
  text001: 'Meritas-心理健康测评',
  text002:
    '得贤Meritas-心理健康问卷基于心理健康双因素模型理论（Keyes,1998)，将 “积极心理指标”和“心理症状指标”作为心理健康诊断的两个必不可少 的因素，以综合评估候选人的心理健康程度。积极心理指标包括：自我肯定、希望、乐观、韧性这4个维度。拥有这些心理状态的个体能够 从消极事件中快速恢复、调解自己的心理状态，顺应环境，从而在工作 中往往展现更高的工作效率和更强的工作满意度。心理症状指标包括：焦虑、抑郁、人际敏感、敌对、偏执这5个在职场中最为常见的心理症状。',
  text003: '{name}的心理健康度：',
  // text004: '处于555{status}',
  text004: '处于',
  text005: '具体表现为',
  text006: '积极心理',
  text007: '消极心理',
  text008: '{0}分',
  text009: '没有内容可以查看',
  text010: '在人际交往过程中自卑感较强，心神不安，表现出明显的不自在，并排斥各类社交活动',
  text011: '合群，外向，活跃，能够自如应付人际关系，且乐于和他人沟通交流。',
  text012: '情绪低落，缺乏活力，干什么都打不起精神，以及对生活缺乏信心，感觉自己没有什么价值等。',
  text013: '乐观积极，心境愉快，认可自己的价值，工作生活中充满活力。',
  text014: '情绪反复无常，急躁易怒、行为冲动、甚至还可能存在妄想等情况。',
  text015: '心态平和，放松，稳定，遇事之后能够积极调解情绪，不慌乱。',
  text016: '脾气难以控制，好争论，对他人缺少基本的信任，难以合作，且经常与他人发生矛盾或争论。',
  text017: '阳光、乐观，随和、待人友好，不喜欢争论，信任他人，愿意与任何人合作。',
  text018: '多疑，敏感，固执，不愿意相信别人，以及很难接受别人的建议和意见',
  text019: '谦虚，灵活，客观理性，能虚心倾听、接受别人的建议和意见，并加以改正。',
  text020: '对成长和成就有着强烈的需求，能够锲而不舍地追求目标的完成',
  text021: '顺从，缺乏成就动因，缺乏目标，容易退缩，自暴自弃',
  text022: '积极，现实，灵活，把成功归因为自身的能力，而把失败事件归因为运气等不稳定的因素。',
  text023: '消极，悲观，把失败归结于自身的能力，把成功归结于运气等不稳定的因素',
  text024: '自信心高，即使面对挑战性的任务，依然能够充满自信并努力达成目标',
  text025: '自信心低，常常自我否定，往往在困难面前犹豫不决，怀疑自己的能力。',
  text026: '意志坚定，受到打击或身处逆境时，能够及时地自我调节，使自己迅速地恢复过来',
  text027: '意志薄弱，遇到挫折或困难时，倾向于放弃，逃避',
  text028: '非常健康的心理状态',
  text029: '较为健康的心理状态',
  text030: '正常状态',
  text031: '部分健康心理状态',
  text032: '完全障碍心理状态',
  text033: '人际敏感',
  text034: '抑郁',
  text035: '焦虑',
  text036: '敌对',
  text037: '偏执',
  text038: '希望',
  text039: '乐观',
  text040: '自我肯定',
  text041: '韧性',
}
